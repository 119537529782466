import { Container } from 'reactstrap';
import styled from 'styled-components';

const BannerContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
  flex-shrink: 0;
  padding: 0.6rem;
`;

export const Banner = ({
  children,
  color = 'var(--yellow-lighter)',
  ...props
}) => {
  return (
    <BannerContainer style={{ backgroundColor: color }} {...props}>
      <Container className="d-flex">{children}</Container>
    </BannerContainer>
  );
};
