import TopicPathwayFactory from 'factories/topicPathwayFactory';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useGuestDynamicQuiz from 'hooks/useGuestDynamicQuiz';

export const TopicPathwayContext = createContext();

export const TopicPathwayProvider = ({
  children,
  studentId = undefined,
  schoolId = undefined
}) => {
  const [states, setStates] = useState({
    isLoaded: false,
    factory: {},
    data: {},
    cache: {}
  });

  const { guestDynamicQuiz } = useGuestDynamicQuiz();

  const reload = useCallback(async () => {
    if (studentId) {
      setStates({ isLoaded: false });
      const factory = TopicPathwayFactory(
        studentId,
        schoolId,
        guestDynamicQuiz
      );
      const topicPathwayData = await factory.fetchTopicPathway();
      setStates(s => ({
        ...s,
        data: topicPathwayData,
        cache: topicPathwayData,
        isLoaded: true,
        factory
      }));
    }
  }, [studentId, schoolId, guestDynamicQuiz]);

  const setLevel = useCallback(
    async targetLevel => {
      const {
        data: { levelProgress, topicPathwayCollectionStudent },
        factory
      } = states;

      if (!topicPathwayCollectionStudent) return;

      // Do not update if level is unchanged to save bandwidth
      if (levelProgress.level === targetLevel) return;

      setStates(s => ({ ...s, isLoaded: false }));

      const topicPathwayData = await factory.fetchTopicPathway(targetLevel);

      setStates(s => ({ ...s, data: topicPathwayData, isLoaded: true }));
    },
    [states]
  );

  useEffect(() => {
    if (studentId) {
      reload();
    }
  }, [reload, studentId]);

  return (
    <TopicPathwayContext.Provider value={{ reload, setLevel, ...states }}>
      {children}
    </TopicPathwayContext.Provider>
  );
};

export const CurrentChildPathwayProvider = ({ children }) => {
  const { childStudentId, userStudentId, schoolId } = useSelector(s => ({
    childStudentId: s.school.child?.studentId,
    userStudentId: s.school.studentId,
    schoolId: s.school.schoolId
  }));

  // NOTE:
  // `studentId` can come from two different location depending on the
  //  userRole of the user.
  // For student user, it comes from s.school.studentId; hence userStudentId
  // For parent, it comes from s.school.child?.studentId; hence childStudentId
  // Only one of them can be null.
  //
  // The `childStudentId ?? userStudentId` below is essentially a userRole check
  //
  // TODO: We need to modernise this for Eedi-Family so the store only has one place
  //    where we store the `studentId` in-focus

  return (
    <TopicPathwayProvider
      studentId={childStudentId ?? userStudentId}
      schoolId={schoolId}>
      {children}
    </TopicPathwayProvider>
  );
};
