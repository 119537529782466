import styled from 'styled-components';
import { Logo, Navbar, Nav } from 'components/common';

const Navigation = ({
  top,
  bottom,
  leftComponent,
  rightComponent,
  middleComponent,
  isHomeLinkDisabled = false,
  isLeftLogoDisabled = false,
  logo = null
}) => {
  return (
    <Navbar className={bottom ? 'fixed-bottom' : 'sticky'}>
      <Left>
        {leftComponent ? (
          leftComponent
        ) : !isLeftLogoDisabled ? (
          !isHomeLinkDisabled ? (
            <Nav.Brand to="/">{logo ? logo : <Logo />}</Nav.Brand>
          ) : (
            <Nav.BrandNoLink>{logo ? logo : <Logo />}</Nav.BrandNoLink>
          )
        ) : null}
      </Left>
      <Middle>{middleComponent ? middleComponent : null}</Middle>
      <Right>{rightComponent ? rightComponent : null}</Right>
    </Navbar>
  );
};

const Middle = styled.div`
  display: flex;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  text-overflow: ellipsis;
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export default Navigation;
