import {
  completeDynamicQuizSignupActionType,
  completeDynamicQuizActionType,
  newQuizSessionActionType,
  setGuestDynamicQuizActionType
} from 'actions/guest';

const initialState = {
  step: 'about',
  yearGroup: null,
  confidenceLevel: null,
  accompanied: null,
  guestDynamicQuiz: null
};

const guest = (state = initialState, action) => {
  switch (action.type) {
    case newQuizSessionActionType:
      return initialState;

    case completeDynamicQuizSignupActionType:
      return {
        ...state,
        ...action.payload
      };

    case completeDynamicQuizActionType:
      return {
        ...state,
        step: action.payload.step,
        quizSessionId: action.payload.quizSessionId
      };

    case setGuestDynamicQuizActionType:
      return {
        ...state,
        guestDynamicQuiz: action.payload.guestDynamicQuiz
      };

    default:
      return state;
  }
};

export default guest;
