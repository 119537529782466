const types = {
  student: {
    typename: 'student',
    keys: ['studentId', 'userId']
  },
  liveClass: {
    typename: 'liveClass',
    keys: ['sequence', 'yearGroup']
  },
  userPreferences: {
    typename: 'userPreferences',
    keys: ['userId']
  },
  country: {
    typename: 'country',
    keys: ['countryId']
  },
  prizeDraw: {
    typename: 'prizeDraw',
    keys: ['prizeDrawRequirementId']
  },
  prizeDrawEntry: {
    typename: 'prizeDrawEntry',
    keys: ['userTransactionId']
  },
  wordEediDictionaries: {
    typename: 'wordEediDictionaries',
    keys: []
  },
  numberEediDictionaries: {
    typename: 'numberEediDictionaries',
    keys: []
  },
  topicPathwayQuizAssignmentInsights: {
    typename: 'topicPathwayQuizAssignmentInsights',
    keys: ['assignmentId', 'topicPathwayQuizId']
  },
  topicPathwayQuizs: {
    typename: 'topicPathwayQuizs',
    keys: ['topicPathwayQuizId']
  },
  topicPathwayCollection: {
    typename: 'topicPathwayCollection',
    keys: ['topicPathwayCollectionId', 'topicPathwayCollectionStudentId']
  },
  retrievalPracticeAssignmentInsights: {
    typename: 'retrievalPracticeAssignmentInsights',
    keys: ['assignmentId']
  },
  assignmentInsights: {
    typename: 'assignmentInsights',
    keys: ['assignmentId', 'topicPathwayQuizId']
  },
  quiz: {
    typename: 'quiz',
    keys: ['quizId']
  },
  quizInsights: {
    typename: 'quizInsights',
    keys: ['quizId']
  },
  rctExperiment: {
    typename: 'rctExperiment',
    keys: ['experimentGroup']
  },
  topicPathwayGrowthComfortQuizzes: {
    typename: 'topicPathwayGrowthComfortQuizzes',
    keys: ['topicPathwayCollectionId', 'topicPathwayQuizId']
  },
  parentInvitation: {
    typename: 'parentInvitation',
    keys: ['parentId']
  },
  accessToken: {
    typename: 'accessToken',
    keys: ['userId']
  },
  userTransactionsInsights: {
    typename: 'userTransactionsInsights',
    keys: ['userId']
  },
  userItems: {
    typename: 'userItems',
    keys: ['userId']
  },
  userRoleContext: {
    typename: 'userRoleContext',
    keys: ['userId']
  },
  userState: {
    typename: 'userStates',
    keys: ['userId']
  },
  userRoutineState: {
    typename: 'userRoutineState',
    keys: ['userId']
  },
  teamStudent: {
    typename: 'teamStudent',
    keys: ['studentId']
  },
  qotdInsights: {
    typename: 'qotdInsights',
    keys: ['userId']
  },
  bookings: {
    typename: 'bookings',
    keys: ['studentId']
  },
  userQuizInferences: {
    typename: 'userQuizInferences',
    keys: ['quizId', 'userId']
  }
};

export default types;
