import {
  SetPricingInfoActionType,
  SetPricingInfoLoadingActionType
} from 'actions/pricingActions';

const initialState = {
  isLoading: false,
  hasLoaded: false,
  freeTrialPeriodDays: null,
  priceOptions: []
};

export const pricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SetPricingInfoLoadingActionType:
      return {
        ...state,
        isLoading: !!action.isLoading
      };

    case SetPricingInfoActionType:
      return {
        ...state,
        hasLoaded: true,
        ...action.pricingInfo
      };

    default:
      return state;
  }
};
