import { Container } from 'components/common';
import styled from 'styled-components';
import { media } from 'styles';

export const Main = ({ container, children, ...props }) => (
  <StyledMain {...props}>
    <Container className={container ? `container-${container}` : ''}>
      {children}
    </Container>
  </StyledMain>
);

const StyledMain = styled.main`
  padding: 1rem 0 6rem 0;
  min-height: 100%;
  flex: 1;
  ${media.sm`
  padding: 1.5rem 0 4rem 0;
  `};
`;

export default Main;
