export const getStudentAbilityOverrideString = yearGroup => {
  // TODO - this might need changing if we create new level boosters for years 1-4 or 10+.
  if (yearGroup) {
    const yearNumber = yearGroup.match(/\d+$/)[0];
    if (yearNumber <= 4) {
      // Year 5 is currently min year we have a level-booster generated for.
      return 'year5';
    } else if (yearNumber >= 10) {
      // Year 9 is currently mx year we have a level-booster generated for.
      return 'year9';
    }
  }

  return yearGroup ? yearGroup.toLowerCase().replace(/\s+/g, '') : 'year5';
};
