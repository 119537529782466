import {
  SetChildrenType,
  SetChildType,
  SwitchSchoolType,
  SetChildPreferencesType,
  SetAllStudentsType
} from 'actions/school';

const initialState = {
  schoolId: null,
  studentId: null,
  childId: null,
  child: null,
  childPreferences: {},
  allChildren: [],
  allChildrenLoaded: false,
  allStudents: []
};

const schools = (state = initialState, action) => {
  switch (action.type) {
    case SwitchSchoolType:
      return {
        ...state,
        schoolId: action.schoolId,
        studentId: action.studentId
      };

    case SetChildrenType:
      return {
        ...state,
        allChildren: action.children,
        allChildrenLoaded: true
      };

    case SetChildType:
      return {
        ...state,
        schoolId: action.schoolId,
        childId: action.child?.childId ?? null,
        studentId: action.child?.studentId ?? null,
        child: action.child
      };

    case SetChildPreferencesType:
      return {
        ...state,
        childPreferences: action.preferences
      };

    case SetAllStudentsType:
      return {
        ...state,
        allStudents: action.students
      };

    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default schools;
