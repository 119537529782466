export const directions = {
  north: 'north',
  east: 'east',
  south: 'south',
  west: 'west'
};

const AbstractCircleHalf = ({
  width = '24px',
  height = '24px',
  direction = directions.south,
  disabled = false,
  ...props
}) => {
  const backgroundColor = disabled ? 'var(--gray-lighter)' : 'var(--indigo)';
  let foregroundColor = 'var(--gray)';
  let angle = 0;

  switch (direction) {
    case directions.north:
      angle = 180;
      foregroundColor = disabled ? 'var(--gray)' : 'var(--blue-light)';
      break;
    case directions.east:
      angle = 270;
      foregroundColor = disabled ? 'var(--gray)' : 'var(--orange-lighter)';
      break;
    case directions.south:
      angle = 0;
      foregroundColor = disabled ? 'var(--gray)' : 'var(--red-lighter)';
      break;
    case directions.west:
      angle = 90;
      foregroundColor = disabled ? 'var(--gray)' : 'var(--yellow)';
      break;
    default:
      break;
  }

  return (
    <svg
      style={{ width, height }}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        width="60"
        height="60"
        rx="30"
        className="topic-circle-background"
        fill={backgroundColor}
      />
      <path
        d="M46 30C47.1046 30 48.0115 30.8981 47.889 31.9959C47.7017 33.6745 47.2787 35.3217 46.6298 36.8883C45.7252 39.0722 44.3994 41.0565 42.7279 42.7279C41.0565 44.3994 39.0722 45.7252 36.8883 46.6298C34.7044 47.5344 32.3638 48 30 48C27.6362 48 25.2956 47.5344 23.1117 46.6298C20.9278 45.7252 18.9435 44.3994 17.2721 42.7279C15.6006 41.0565 14.2748 39.0722 13.3702 36.8883C12.7213 35.3217 12.2983 33.6745 12.111 31.9959C11.9885 30.8981 12.8954 30 14 30L30 30H46Z"
        className="topic-circle-foreground"
        fill={foregroundColor}
        transform={`rotate(${angle} 30 30)`}
      />
    </svg>
  );
};

export default AbstractCircleHalf;
