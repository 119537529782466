import { setCoinsState } from 'actions/coins';
import { useSignalROn } from 'hooks/useSignalROn';
import { useDispatch, useSelector } from 'react-redux';
import CoinsEarnedSound from 'assets/sounds/coins_earned.mp3';
import CoinsSpentSound from 'assets/sounds/coins_spent.mp3';

export const CoinsProvider = () => {
  const dispatch = useDispatch();
  const currentBalance = useSelector(s => s.user.coins.currentBalance);

  const playSound = newBalance => {
    try {
      if (currentBalance < newBalance) {
        new Audio(CoinsEarnedSound).play();
      } else if (currentBalance > newBalance) {
        new Audio(CoinsSpentSound).play();
      }
    } catch (e) {}
  };

  useSignalROn('CoinWalletUpdated', async state => {
    playSound(state.currentBalance);
    dispatch(setCoinsState(state));
  });

  return null;
};
