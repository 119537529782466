export const newQuizSessionActionType = 'NEW_GUEST_QUIZ_SESSION';
export const newQuizSession = () => ({
  type: newQuizSessionActionType
});

export const completeDynamicQuizSignupActionType = 'COMPLETE_STANDALONE_SIGNUP';
export const completeDynamicQuizSignup = init => ({
  type: completeDynamicQuizSignupActionType,
  payload: {
    step: 'quiz',
    ...init
  }
});

export const completeDynamicQuizActionType = 'COMPLETE_GUEST_DYNAMIC_QUIZ';
export const completeDynamicQuiz = quizSessionId => ({
  type: completeDynamicQuizActionType,
  payload: {
    step: 'report',
    quizSessionId
  }
});

export const initiateGuestUser =
  initialisationData => async (dispatch, getState) => {
    dispatch(completeDynamicQuizSignup({ ...initialisationData }));
  };

export const setGuestDynamicQuizActionType = 'SET_GUEST_DYNAMIC_QUIZ';
export const setGuestDynamicQuiz = guestDynamicQuiz => ({
  type: setGuestDynamicQuizActionType,
  payload: {
    guestDynamicQuiz
  }
});
