import CrossInCircleIcon from 'assets/icons/crossInCircle';
import TickInCircleIcon from 'assets/icons/tickInCircle';
import Bowser from 'bowser';
import { Button, Card, EmojiSpan, Info, Logo } from 'components/common';
import {
  API_URL,
  FLOW_API_URL,
  FUNCTIONS_API_URL,
  KEYSTROKE_LOGGER_URL
} from 'config/settings';
import { useCallback, useState } from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';

const checkTypes = {
  api: 'api',
  localStorage: 'localStorage',
  sessionStorage: 'sessionStorage',
  browser: 'browser',
  plugins: 'plugins',
  cookies: 'cookies',
  googleAuth: 'googleAuth'
};

const checkEndpoint = async endpoint => {
  try {
    const response = await fetch(endpoint);
    if (response.status >= 400) {
      throw new Error(`Request to ${endpoint} failed`);
    }
    return { isSuccess: true, error: null };
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      error: `Request to ${endpoint} failed`
    };
  }
};

const compatChecks = [
  {
    sequence: 1,
    type: checkTypes.api,
    label: 'Primary API',
    endpoint: `${API_URL}/v3/billing/info`,
    endpointSimple: 'api.eedi.com',
    test: async () => await checkEndpoint(`${API_URL}/v3/billing/info`)
  },
  {
    sequence: 2,
    type: checkTypes.api,
    label: 'Logging API',
    endpoint: `${FUNCTIONS_API_URL}`,
    endpointSimple: 'eedi-functions-api-prod.azurewebsites.net',
    test: async () => await checkEndpoint(`${FUNCTIONS_API_URL}`)
  },
  {
    sequence: 3,
    type: checkTypes.api,
    label: 'Lesson API',
    endpoint: `${FLOW_API_URL}/v1/health-check`,
    endpointSimple: 'flow-api.eedi.com',
    test: async () => await checkEndpoint(`${FLOW_API_URL}/v1/health-check`)
  },
  {
    sequence: 4,
    type: checkTypes.localStorage,
    label: 'localStorage',
    test: () => {
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        return { isSuccess: true };
      } catch (error) {
        return {
          isSuccess: false,
          error: `LocalStorage threw and error: ${error}`
        };
      }
    }
  },
  {
    sequence: 5,
    type: checkTypes.sessionStorage,
    label: 'sessionStorage',
    test: () => {
      try {
        sessionStorage.setItem('test', 'test');
        sessionStorage.removeItem('test');
        return { isSuccess: true };
      } catch (error) {
        return {
          isSuccess: false,
          error: `SessionStorage threw an error: ${error}`
        };
      }
    }
  },
  {
    sequence: 6,
    type: checkTypes.cookies,
    label: 'Cookies',
    test: () => {
      try {
        document.cookie = 'test=test';
        document.cookie = 'test=test; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        return { isSuccess: true };
      } catch (error) {
        return {
          isSuccess: false,
          error: `Cookies threw an error: ${error}`
        };
      }
    }
  },
  {
    sequence: 7,
    type: checkTypes.browser,
    label: 'Browser',
    test: () => {
      try {
        const result = Bowser.parse(window.navigator.userAgent);
        const unsupportedBrowserNames = ['Internet Explorer', 'Edge Legacy'];

        let error = null;

        if (unsupportedBrowserNames.includes(result.browser.name)) {
          error = `Unsupported browser: ${result.browser.name} ${result.browser.version}`;
        }

        return {
          isSuccess: !error,
          error,
          message: JSON.stringify(result)
        };
      } catch (error) {
        return { isSuccess: false, error: `${error}` };
      }
    }
  },
  {
    sequence: 9,
    type: checkTypes.api,
    label: 'Image CDN #1 - home-content.eedi.com',
    test: () =>
      checkEndpoint(
        'https://home-content.eedi.com/eedi/constructs/949/teach/3-read-the-maths-image.png'
      )
  },
  {
    sequence: 10,
    type: checkTypes.api,
    label: 'Image CDN #2 - images.diagnosticquestions.com',
    test: () =>
      checkEndpoint(
        'https://images.diagnosticquestions.com/uploads/questions/80aadb1e-bd30-4f96-b0e4-4e7763220597.jpg'
      )
  },
  {
    sequence: 11,
    type: checkTypes.plugins,
    label: 'Plugins (optional)',
    test: async () => {
      try {
        const plugins = [
          'https://js.stripe.com/v3',
          'https://consent-manager.confirmic.com/error-reporting.js',
          KEYSTROKE_LOGGER_URL
        ];
        const promises = plugins.map(async plugin => {
          const promise = checkEndpoint(plugin);
          return await promise;
        });
        const results = await Promise.all(promises);
        if (results.some(r => !r.isSuccess)) {
          const error = `${
            results.filter(r => !r.isSuccess).length
          } Plugins failed to load: ${results
            .filter(r => !r.isSuccess)
            .map(r => r.error)
            .join('. ')}`;
          return { isSuccess: false, error };
        }
        return { isSuccess: true };
      } catch (error) {
        console.log(error);
        return { isSuccess: false, error: `${error}` };
      }
    },
    isOptional: true
  }
];

const CompatibilityCheckerPage = () => {
  const [isChecking, setIsChecking] = useState(false);
  const [compatResults, setCompatResults] = useState([]);

  console.log('settings', {
    env: process.env,
    apiUrl: process.env.REACT_APP_API_URL,
    functionsApiUrl: process.env.REACT_APP_FUNCTIONS_API_URL,
    omrFunctionsApiUrl: process.env.REACT_APP_OMR_FUNCTIONS_API_URL,
    flowApiUrl: process.env.REACT_APP_FLOW_API_URL,
    settings: {
      flowApiUrl: FLOW_API_URL,
      apiUrl: API_URL,
      ksl: KEYSTROKE_LOGGER_URL
    }
  });

  const checkCompatibility = useCallback(async () => {
    if (isChecking) return;
    setIsChecking(true);
    setCompatResults([]);

    try {
      compatChecks.forEach(async check => {
        if (typeof check.test !== 'function') return;
        const result = await check.test();
        setCompatResults(prev => [
          ...prev,
          {
            sequence: check.sequence,
            label: check.label,
            isSuccess: result.isSuccess,
            message: result.message,
            error: result.error,
            isOptional: check.isOptional
          }
        ]);
      });
    } catch (error) {
      console.error(error);
    }

    setIsChecking(false);
  }, [isChecking]);

  return (
    <Container>
      <Content>
        <Card>
          <StyledCardBody>
            <Intro>
              <Logo />
              <h2 style={{ margin: '1rem 0 0 0' }}>
                Website Compatibility Checker{' '}
                <EmojiSpan emoji="🔎" ariaLabel="inspect" />
              </h2>
              <p>
                Click the "Check" button to verify if your setup is compatible
                with family.eedi.com and teacher.eedi.com.
              </p>
            </Intro>
            {!!compatResults?.length &&
            compatResults.filter(r => !r.isOptional).every(r => r.isSuccess) ? (
              <Message>All essiential checks passed!</Message>
            ) : !!compatResults?.length &&
              compatResults
                .filter(r => !r.isOptional)
                .some(r => !r.isSuccess) ? (
              <Error>
                {compatResults.filter(r => !r.isSuccess).length} checks failed!
              </Error>
            ) : null}
            {!!compatResults?.length &&
            !!compatResults.filter(r => r.isOptional && !r.isSuccess).length ? (
              <Warning>
                {compatResults.filter(r => r.isOptional && !r.isSuccess).length}{' '}
                optional checks failed. This may cause some features to not work
                correctly.
              </Warning>
            ) : null}
            <CompatGrid>
              {compatResults
                .sort((a, b) => a.sequence - b.sequence)
                .map((result, i) => (
                  <Row key={i} className="row">
                    <div className="test-result">
                      <div className="label">
                        {result.label}{' '}
                        {!!result.info ? (
                          <Info id={`label-${i}`} tooltip="test!" />
                        ) : null}
                      </div>
                      <div>
                        {result.isSuccess ? (
                          <TickInCircleIcon color="var(--green)" />
                        ) : (
                          <CrossInCircleIcon color="var(--red)" />
                        )}
                      </div>
                    </div>
                    {!result.isSuccess && !!result.error ? (
                      <Error>{result.error}</Error>
                    ) : null}
                    {!!result.message ? (
                      <Message>{result.message}</Message>
                    ) : null}
                  </Row>
                ))}
            </CompatGrid>
            <Button
              color="primary"
              disabled={isChecking}
              loading={isChecking}
              onClick={() => checkCompatibility()}>
              Check
            </Button>
          </StyledCardBody>
        </Card>
      </Content>
      {!isChecking &&
        !!compatResults?.length &&
        compatResults.every(r => r.isSuccess) && (
          <Confetti
            numberOfPieces={100}
            initialVelocityX={0}
            gravity={0.12}
            recycle={false}
          />
        )}
    </Container>
  );
};

const StyledCardBody = styled(Card.Body)`
  max-height: 100vh;
  overflow-y: auto;
`;

const CompatGrid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
  .row {
    border-bottom: 1px solid #ccc;
  }
  .row:last-child {
    border-bottom: none;
  }
`;

const Row = styled.div`
  padding: 0.5rem 0 0.5rem 0;
  .label {
  }
  .test-result {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const Error = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: var(--red-lighter);
  border-radius: 0.5rem;
  border: 1px solid var(--red);
  width: 100%;
`;

const Message = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: var(--green-lighter);
  border-radius: 0.5rem;
  border: 1px solid var(--green);
  width: 100%;
`;

const Warning = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: var(--orange-lighter);
  border-radius: 0.5rem;
  border: 1px solid var(--orange);
  width: 100%;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 300;
  background-color: var(--tint-color);
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 1.5rem;
  max-width: 35rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Intro = styled.div``;

export default CompatibilityCheckerPage;
