const AbstractTriangle = ({
  width = '24px',
  height = '24px',
  flipped = false,
  disabled = false,
  ...props
}) => {
  const backgroundColor = disabled ? 'var(--gray-lighter)' : 'var(--indigo)';
  const foregroundColor = disabled
    ? 'var(--gray)'
    : flipped
    ? 'var(--blue-lighter)'
    : 'var(--red-lighter)';

  const angle = flipped ? 180 : 0;

  return (
    <svg
      style={{ width, height }}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        width="60"
        height="60"
        rx="30"
        className="topic-circle-background"
        fill={backgroundColor}
      />
      <path
        d="M28.2679 14C29.0377 12.6667 30.9622 12.6667 31.732 14L44.7224 36.5C45.4922 37.8333 44.53 39.5 42.9904 39.5H17.0096C15.47 39.5 14.5078 37.8333 15.2776 36.5L28.2679 14Z"
        className="topic-circle-foreground"
        fill={foregroundColor}
        transform={`rotate(${angle} 30 30)`}
      />
    </svg>
  );
};

export default AbstractTriangle;
