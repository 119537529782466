import { useNavigate } from 'react-router';
import { Button, EmojiSpan } from 'components/common';
import Tooltip from 'components/common/Tooltip';
import analytics, { FAMILY_PROJECT_KEY } from 'analytics';
import tracking from 'analytics/tracking';
import { ActivityStatus, ActivityStatuses } from './ActivityStatus';
import { getUTCDate } from 'helpers/dateTimeHelper';
import { activityTypes } from '../helpers/learningActivityHelpers';

const ActivityButton = ({
  activity,
  type,
  source,
  dismissPopover = () => {},
  ...props
}) => {
  const navigate = useNavigate();

  if (!activity) return null;

  const {
    topicPathwayQuizId,
    level,
    quizSessions,
    assignmentId,
    isActivityDisabled
  } = activity;

  const quizSessionId = quizSessions?.[0]?.quizSessionId;
  const status = ActivityStatus(activity, type);

  const navigateToChallengeGuarded = () =>
    navigate(
      `/topic/${topicPathwayQuizId}/challenge?quizSessionId=${quizSessionId}`
    );

  const navigateToChallenge = () => {
    document.body.click();
    navigateToChallengeGuarded();
  };

  const resumeTopic = async () => {
    await tracking.track(tracking.eventNames.QuizResumed, false, {
      topic_id: topicPathwayQuizId
    });
    analytics.track('Quiz Resumed', {
      category: 'Take Quiz'
    });
  };

  const startTopic = () => {
    analytics.track(
      'Quiz Intended',
      {
        source,
        topic_level: level
      },
      {
        project: FAMILY_PROJECT_KEY
      }
    );
  };

  const isDisabled =
    assignmentId &&
    getUTCDate(activity?.startDate ?? new Date()) >
      getUTCDate(new Date().toISOString());

  switch (status) {
    case ActivityStatuses.StartTopic:
      return (
        <div id={`startTopicButton-${topicPathwayQuizId}-${assignmentId}`}>
          <Button
            color="primary"
            {...props}
            onClick={startTopic}
            to={
              type === activityTypes.retrievalpractice
                ? `/retrieval-topic/${assignmentId}`
                : `/topic/${topicPathwayQuizId}${
                    !!assignmentId ? `?assignmentId=${assignmentId}` : ''
                  }`
            }
            disabled={isActivityDisabled || isDisabled}>
            Start topic
          </Button>
          {isDisabled ? (
            <Tooltip
              placement="top"
              target={`startTopicButton-${topicPathwayQuizId}-${assignmentId}`}>
              Assignment cannot be started until after the start date.
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      );

    case ActivityStatuses.ResumeTopic:
      return (
        <Button
          color="primary"
          {...props}
          onClick={resumeTopic}
          to={
            type === activityTypes.retrievalpractice
              ? `/retrieval-topic/${assignmentId}`
              : `/topic/${topicPathwayQuizId}${
                  !!assignmentId ? `?assignmentId=${assignmentId}` : ''
                }`
          }>
          Resume
        </Button>
      );

    case ActivityStatuses.EnableStretchChallenge:
      return (
        <Button color="secondary" onClick={navigateToChallenge}>
          <EmojiSpan ariaLabel="strong" emoji="💪" /> Take Stretch &amp;
          Challenge{' '}
        </Button>
      );

    case ActivityStatuses.AllCompleted:
      return (
        <Button
          color="secondary"
          {...props}
          to={`/topic/${quizSessions?.[0]?.quizSessionId}/review`}>
          View results
        </Button>
      );

    default:
      return null;
  }
};

export default ActivityButton;
