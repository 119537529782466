import { Loading, SearchBox, Dropdown } from 'components/common';
import useDebouncedValue from 'hooks/useDebouncedValue';
import isFunction from 'lodash/isFunction';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { InputGroup } from 'reactstrap';
import { sizes } from 'styles';
import TopicPathwayGrid from '../TopicPathwayGrid';

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopicSearch = ({
  onSearchQueryChange,
  isLoading: dataLoading = false,
  searchResults = [],
  searchSubjects = [],
  setCurrentSubject = () => {},
  currentSubject = null
}) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState({
    searchText: '',
    filteredSubjectIds: [],
    rawSearchText: '',
    isTyping: false
  });

  const onChangeRef = useRef();
  onChangeRef.current = onSearchQueryChange;
  useEffect(() => {
    if (isFunction(onChangeRef.current)) {
      onChangeRef.current(searchQuery);
    }
  }, [searchQuery]);

  // Debouncing this for a millisecond avoids the display flickering when typing
  // stops, but starting to load the new results hasn't happened yet.
  const isValidAndTyping = useDebouncedValue(
    searchQuery.rawSearchText.length >= 3 && searchQuery.isTyping,
    1
  );
  const isLoading = isValidAndTyping || dataLoading;

  return (
    <SearchContainer>
      <SearchBoxWrapper>
        <StyledSearchBox
          onTypingChange={v => {
            if (!!v.debouncedText) {
              const params = new URLSearchParams({
                search: v.debouncedText
              });
              navigate({
                pathname: window.location.pathname,
                search: params.toString(),
                replace: true
              });
            } else {
              navigate({
                pathname: window.location.pathname,
                search: '',
                replace: true
              });
            }

            setSearchQuery(q => ({
              ...q,
              rawSearchText: v.rawText,
              isTyping: v.isTyping,
              searchText: v.debouncedText
            }));
            setCurrentSubject(searchSubjects?.[0]?.id);
          }}
          searchSubjects={searchSubjects}
          setCurrentSubject={setCurrentSubject}
          placeholder="Search for a topic"
        />
        <StyledDropDown>
          {searchSubjects?.length > 0 && (
            <DropdownWrapper>
              <StyledDropdown
                disabled={searchQuery.searchText?.length > 0}
                searchtext={searchQuery.searchText}>
                <Dropdown.Toggle outline caret style={{ fontSize: '15px' }}>
                  {currentSubject ? currentSubject : 'All Subjects'}
                </Dropdown.Toggle>
                {
                  <Dropdown.Menu right>
                    {searchSubjects?.map((s, i) => (
                      <Dropdown.Item
                        key={i}
                        onClick={() => setCurrentSubject(s.id)}>
                        <span>{s.value}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                }
              </StyledDropdown>
            </DropdownWrapper>
          )}
        </StyledDropDown>
      </SearchBoxWrapper>

      {searchQuery.rawSearchText.length > 0 && (
        <div className="mt-3 d-flex flex-column" style={{ minHeight: '12rem' }}>
          {isLoading ? (
            <Loading className="my-auto" />
          ) : searchQuery.searchText.length >= 3 ? (
            <TopicPathwayGrid
              topicPathwayCollectionGroups={[
                {
                  topicPathwayQuizGroups: searchResults.map((s, i) => ({
                    sequence: i + 1,
                    topicPathwayQuizs: [s]
                  }))
                }
              ]}
            />
          ) : (
            <span className="text-muted align-self-center my-auto">
              Type to search
            </span>
          )}
        </div>
      )}
    </SearchContainer>
  );
};

const SearchBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${sizes.md}px) {
    flex-direction: column;
  }
`;

const StyledDropdown = styled(Dropdown)`
  float: right;
  opacity: ${props => (props?.searchtext?.length > 0 ? '0.5' : '1')};
  cursor: ${props =>
    props?.searchtext?.length > 0 ? 'not-allowed' : 'pointer'};
  width: 100%;
`;

const DropdownWrapper = styled.div`
  align-self: center;
  margin-left: auto;
  @media (max-width: ${sizes.md}px) {
    margin: 0.5rem 0 0 0;
  }
`;

const StyledSearchBox = styled(SearchBox)`
  width: 75%;
  @media (max-width: ${sizes.lg}px) {
    width: 75%;
  }
  @media (max-width: ${sizes.md}px) {
    width: 100%;
  }
`;

const StyledDropDown = styled(InputGroup)`
  width: 25%;
  @media (max-width: ${sizes.lg}px) {
    width: 25%;
  }
  @media (max-width: ${sizes.md}px) {
    width: 100%;
  }
`;
