import styled from 'styled-components';

const EediWatermark = ({ color }) => {
  return (
    <Root>
      <Content>
        <Power color={color ?? '#A7AABA'}>Powered by </Power>
        <Svg
          width="63"
          height="16"
          viewBox="0 0 63 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.892578 15.283H3.7627V0.0131836H0.892578V15.283ZM6.63282 6.12095H15.2429V3.06695H6.63282V6.12095ZM15.2429 12.2289H6.63282V9.17495H15.2429V12.2289Z"
              fill={color ?? '#A7AABA'}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.5326 0.0131836C59.6042 0.0131836 58.8514 0.813942 58.8514 1.80206C58.8514 2.78993 59.6042 3.59069 60.5326 3.59069C61.461 3.59069 62.2137 2.78993 62.2137 1.80206C62.2137 0.813942 61.461 0.0131836 60.5326 0.0131836ZM59.1384 15.2945H61.9265V4.96819H59.1384V15.2945ZM51.3066 6.9333C49.9665 6.9333 49.0307 8.24858 49.0307 10.1314C49.0307 12.0442 49.9038 13.3294 51.2039 13.3294C52.3877 13.3294 53.5823 12.4428 53.5823 10.4623V9.82265C53.5823 8.91568 53.3361 8.16152 52.8704 7.64156C52.4611 7.18476 51.9058 6.9333 51.3066 6.9333ZM50.5274 4.65947C51.7726 4.65947 52.8497 5.25372 53.4093 6.2494L53.562 6.52097V0.0266676H56.3501V15.2945H53.8804L53.6643 13.8387L53.5111 13.8163C53.1505 14.4836 52.2648 15.6033 50.384 15.6033C47.9094 15.6033 46.1809 13.3533 46.1809 10.1314C46.1809 6.90964 47.9683 4.65947 50.5274 4.65947ZM41.9806 9.11581L41.9779 9.02568C41.9381 7.6127 41.184 6.73482 40.0098 6.73482C38.8099 6.73482 38.07 7.56846 37.98 9.0226L37.974 9.11581H41.9806ZM43.596 6.28796C44.3616 7.26472 44.7663 8.6091 44.7663 10.1754V10.8602H37.9562L37.9593 10.9508C37.9829 11.6482 38.1505 12.2382 38.4444 12.6574C38.8086 13.1766 39.3559 13.4397 40.0711 13.4397C41.1438 13.4397 41.6969 12.786 41.9766 12.2065L44.363 13.2417C43.5553 14.7658 42.0498 15.6033 40.112 15.6033C38.6305 15.6033 37.3818 15.0994 36.5007 14.146C35.6041 13.1754 35.1301 11.7797 35.1301 10.1094C35.1301 8.47142 35.6077 7.08895 36.5109 6.11125C37.3886 5.16146 38.5985 4.65947 40.0098 4.65947C41.5209 4.65947 42.7612 5.22249 43.596 6.28796ZM26.8057 8.93768H33.0797V6.37691H26.8057V2.5872H33.5512V0.0266676H23.9355V15.2945H33.6537V12.7338H26.8057V8.93768Z"
              fill={color ?? '#A7AABA'}
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="61.3209"
                height="15.5901"
                fill="white"
                transform="translate(0.892578 0.0131836)"
              />
            </clipPath>
          </defs>
        </Svg>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-evenly;
`;

const Content = styled.div`
  display: flex;
`;

const Power = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${({ color }) => color};
  align-self: center;
  margin: 0 1rem;
`;

const Svg = styled.svg`
  align-self: center;
`;

export default EediWatermark;
