import { Button } from 'components/common';

export const InlineTextButton = ({ style, ...props }) => {
  return (
    <span>
      <Button
        color="link"
        style={{
          fontSize: 'inherit',
          padding: 0,
          verticalAlign: 'unset',
          fontWeight: 'inherit',
          border: 'none',
          ...style
        }}
        {...props}
      />{' '}
    </span>
  );
};
