import tracking from 'analytics/tracking';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { store } from 'store';

const useTrackPageChange = () => {
  const { userId } = store.getState()?.user;

  const ref = useRef({
    pathname: null,
    dateEntered: null
  });
  const { pathname } = useLocation();
  const formatPathName = pathname => {
    pathname = pathname
      ?.replace(/\d+/g, '')
      .replace(/\/\//g, '/')
      .replace(/^\/+|\/+$/g, '');

    return pathname?.length ? pathname : 'dashboard';
  };

  const track = useCallback(
    async (action, payload) => {
      if (!userId) return;
      await tracking.track(action, false, payload);
    },
    [userId]
  );

  useEffect(() => {
    const currentPage = formatPathName(pathname || window.location.pathname);

    if (!currentPage) return;

    const previousPage = ref.current.pathname;
    const now = new Date();

    if (previousPage !== currentPage && !!previousPage) {
      track('Student Page Exit', {
        page: previousPage,
        date_entered: ref.current.dateEntered.toISOString(),
        date_exited: now.toISOString(),
        seconds_on_page: !!ref.current.dateEntered
          ? Math.round((now - ref.current.dateEntered) / 1000)
          : null,
        destination: currentPage
      });
    }

    try {
      track('Student Page Change', {
        page: currentPage,
        previous_page: ref.current.pathname,
        date_entered: now.toISOString()
      });
    } catch (e) {
      console.error(e);
    }

    ref.current = {
      ...ref.current,
      pathname: currentPage,
      dateEntered: now
    };
  }, [pathname, track]);
};

export default useTrackPageChange;
