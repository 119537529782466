import styled from 'styled-components';
import StreakFullFlame from 'assets/icons/streakFullFlame';

export const StreakPointsPill = ({ value, ...props }) => {
  return (
    <Wrapper {...props}>
      <ValueSpan>{value}</ValueSpan>
      <StreakFullFlame
        width={14}
        height={18}
        style={{ position: 'relative', top: '-2px' }}
      />
    </Wrapper>
  );
};

const ValueSpan = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: 0.3rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1.5rem;
  min-width: 3rem;
  padding: 0.4rem 0.2rem;

  background-color: var(--gray-lightest);
  border-radius: 3rem;
`;
