import { Loading } from 'components/common';
import { CurrentChildPathwayProvider } from './TopicPathwayProvider';
import { EediPlusProvider } from './EediPlus/EediPlusProvider';
import { ParentInvitationProvider } from './ParentInvitationProvider';
import { AchievementsProvider } from './AchievementsProvider';
import { RoutineStateProvider } from './RoutineStateProvider';
import { TeamsProvider } from './TeamsProvider';
import { CoinsProvider } from './CoinsProvider';
import { SignalRProvider } from './SignalRProvider';
import IntercomAppProvider from './IntercomAppProvider';
import AnalyticsProvider from 'analytics/AnalyticsProvider';
import { ConsentGate } from '@confirmic/react';
import useSetUserContext from './useUserContext';

const AppProviders = ({ children }) => {
  const { loading } = useSetUserContext();

  if (loading) {
    return <Loading />;
  }

  return (
    <SignalRProvider hubName="SignalRHub">
      <CurrentChildPathwayProvider>
        <TeamsProvider>
          <AchievementsProvider />
          <RoutineStateProvider />
          <CoinsProvider />
          <EediPlusProvider>
            <ParentInvitationProvider>{children}</ParentInvitationProvider>
          </EediPlusProvider>
          <IntercomAppProvider />
          <ConsentGate micropolicy="statistics">
            <AnalyticsProvider />
          </ConsentGate>
        </TeamsProvider>
      </CurrentChildPathwayProvider>
    </SignalRProvider>
  );
};

export default AppProviders;
