import { Banner } from 'components/Billing/Banner';
import {
  BannerNotificationTypes,
  processNotification
} from 'components/Notifications/notificationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CloseButton } from 'components/common';
import { useCallback } from 'react';
import { Api } from 'api';
import { setNotifications } from 'actions/user';
import analytics from 'analytics';
import { cache } from 'store';
import types from 'cache/types';

const NotificationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const NotificationBannerItem = ({ notification, onDismiss = () => {} }) => {
  if (!notification) return null;

  const { body, dismissible } = processNotification(notification);

  if (!body) return null;

  return (
    <Banner>
      <NotificationRow>
        <div></div>
        <div>{body}</div>
        {dismissible ? (
          <CloseButton
            onClick={() => onDismiss(notification.notificationId)}
            size="small"
            style={{}}
          />
        ) : (
          <div></div>
        )}
      </NotificationRow>
    </Banner>
  );
};

const NotificationBanner = () => {
  const dispatch = useDispatch();
  const { userId, notifications } = useSelector(s => ({
    userId: s.user.userId,
    schoolId: s.school.schoolId,
    schoolIds: s.user?.students?.map(s => s.schoolId) ?? [],
    notifications: s.user.notifications
  }));

  const handleDismiss = useCallback(
    async notificationId => {
      if (!notificationId) return;

      try {
        analytics.track('Notification Banner Dismissed', {
          category: 'Notifications'
        });
        await Api.dismissNotification(notificationId);
        dispatch(
          setNotifications({
            data: notifications.data.filter(
              n => n.notificationId !== notificationId
            ),
            total: notifications.total - 1
          })
        );
        cache.bustTypeCache({
          typenames: [types.userRoleContext.typename]
        });
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, notifications.data, notifications.total]
  );

  const currentBannerNotification = notifications?.data
    ?.filter(
      n =>
        BannerNotificationTypes.includes(n.notificationType) && !n.dateDismissed
    )
    ?.sort((a, b) => b.notificationId - a.notificationId)
    ?.find(n => `${n.userId}` === `${userId}`);

  return (
    <NotificationBannerItem
      notification={currentBannerNotification}
      onDismiss={handleDismiss}
    />
  );
};

export default NotificationBanner;
