import { Api } from 'api';
import { toaster } from 'components/common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { completeDynamicQuiz, initiateGuestUser } from 'actions/guest';
import { getStudentAbilityOverrideString } from 'helpers/studentAbilityOverrideHelper';
import DynamicQuizStepIntro from 'components/DynamicQuiz/GuestQuiz/DynamicQuizStepIntro';
import DynamicQuizStepOutro from 'components/DynamicQuiz/GuestQuiz/DynamicQuizStepOutro';
import {
  DynamicQuiz,
  DynamicQuizState
} from 'components/DynamicQuiz/DynamicQuiz';
import { useNavigate, useParams } from 'react-router';
import { setAuthToken } from 'config/settings';
import { cache } from 'store';
import { getTheme } from './themes';

const DynamicQuizStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { claimCode } = useParams();
  const {
    guestStudentUserId,
    yearGroup,
    confidenceLevel,
    topicPathwayCollectionStudent,
    claimCodeParam,
    userIdParam,
    schoolIdParam,
    localeParam,
    redirectUriParam,
    orgIdParam
  } = useSelector(s => s.guest);

  const [isLoading, setIsLoading] = useState(false);
  const [dynamicQuizSession, setDynamicQuizSession] = useState(null);

  const studentAbilityOverride = useCallback(
    () => getStudentAbilityOverrideString(yearGroup, confidenceLevel),
    [confidenceLevel, yearGroup]
  );

  const onSubmitAnswer = useCallback(
    async answer => {
      setIsLoading(true);
      try {
        const updatedSession = await Api.updateClaimCodeGuestDynamicQuizSession(
          dynamicQuizSession?.claimCode || claimCode || claimCodeParam,
          {
            answers: [answer]
          }
        );
        setDynamicQuizSession(updatedSession);
      } catch (e) {
        toaster.danger(e?.message);
      }
      setIsLoading(false);
    },
    [claimCode, claimCodeParam, dynamicQuizSession?.claimCode]
  );

  const onGetReport = () => {
    navigate(`/guest/dynamic-quiz/report/${dynamicQuizSession.claimCode}`);
    dispatch(completeDynamicQuiz(dynamicQuizSession.quizSessionId));
  };

  const loadExistingSession = useCallback(async () => {
    try {
      const currentClaimCode = claimCode || claimCodeParam;

      if (!!dynamicQuizSession || !currentClaimCode || isLoading) return;

      setIsLoading(true);

      if (!topicPathwayCollectionStudent) {
        const guest = cache.get({ key: 'guest-user-data' });

        if (!guest) {
          navigate('/guest/dynamic-quiz');
          return;
        }

        const guestToken = await Api.getGuestUserToken(
          guest.guestStudentUserId
        );
        setAuthToken(guestToken);
        dispatch(initiateGuestUser(guest));
        setIsLoading(false);
        return;
      }

      const quizSession = await Api.getClaimCodeGuestDynamicQuizSession(
        currentClaimCode,
        false
      );
      setDynamicQuizSession(quizSession);

      if (quizSession?.dateCompleted) {
        navigate(`/guest/dynamic-quiz/report/${quizSession.claimCode}`);
        setIsLoading(false);
        return;
      }

      navigate(`/guest/dynamic-quiz/quiz/${quizSession.claimCode}`);
    } catch (e) {
      toaster.danger('Oops - something went wrong!');
    }

    setIsLoading(false);
  }, [
    claimCode,
    claimCodeParam,
    dispatch,
    dynamicQuizSession,
    isLoading,
    navigate,
    topicPathwayCollectionStudent
  ]);

  const createDynamicQuizSession = async () => {
    try {
      if (dynamicQuizSession || !topicPathwayCollectionStudent) return;
      setIsLoading(true);

      const quizSession = await Api.createClaimCodeGuestDynamicQuizSession(
        guestStudentUserId,
        topicPathwayCollectionStudent.topicPathwayCollectionId,
        studentAbilityOverride(),
        claimCode || claimCodeParam,
        false,
        userIdParam,
        schoolIdParam,
        localeParam
      );

      if (!!quizSession?.quizSessionId) {
        setDynamicQuizSession(quizSession);
        navigate(`/guest/dynamic-quiz/quiz/${quizSession.claimCode}`);
      } else {
        toaster.danger('Oops - something went wrong!');
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      toaster.danger(e?.message);
    }
  };

  useEffect(() => {
    async function runEffect() {
      if (!guestStudentUserId && !claimCode) navigate('/guest/dynamic-quiz');
      if (claimCode) {
        await loadExistingSession();
      }
    }
    runEffect();
  }, [claimCode, guestStudentUserId, loadExistingSession, navigate]);

  const isCompleted = !!dynamicQuizSession?.dateCompleted;

  const quizState =
    !isLoading && !dynamicQuizSession?.quizSessionId
      ? DynamicQuizState.Intro // Not loading quiz & No quiz session & no claim code
      : dynamicQuizSession?.quizSessionId && !isCompleted
      ? DynamicQuizState.InProgress // There is quiz session and not complete
      : isCompleted
      ? DynamicQuizState.Outro // Quiz session is completed
      : undefined;

  const theme = getTheme({ orgId: orgIdParam, locale: localeParam });

  return (
    <DynamicQuiz
      dynamicQuizSession={dynamicQuizSession}
      isLoading={isLoading}
      quizState={quizState}
      Intro={
        <DynamicQuizStepIntro
          createDynamicQuizSession={createDynamicQuizSession}
        />
      }
      Outro={
        <DynamicQuizStepOutro
          onFinish={() => {
            if (redirectUriParam) {
              window.location.href = redirectUriParam;
              return;
            }

            onGetReport();
          }}
          body={
            redirectUriParam ? (
              <>
                <p>Thanks for completing the diagnostic quiz! </p>
                <p>Click the "Finish" button to exit.</p>
              </>
            ) : (
              <>
                <p>Thanks for completing the diagnostic quiz!</p>
                <p> Click the "Get Report" button to see your results.</p>
              </>
            )
          }
          onFinishCopy={redirectUriParam ? 'Finish' : 'Get Report'}
        />
      }
      onSubmitAnswer={onSubmitAnswer}
      options={theme}
    />
  );
};

export default DynamicQuizStep;
