import { createContext, useMemo, useState } from 'react';
import { EediPlusUpSellModal } from './EediPlusUpSellModal/EediPlusUpSellModal';

export const EediPlusFeatureFocus = {
  LiveChat: 0,
  StretchAndChallenge: 1, // Deprecated. No longer a EediPlus feature
  LittleMysteryPack: 2
};

export const EediPlusContext = createContext({
  launchModal: (initialPage = null) => {}
});

export const EediPlusProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    initialPage: undefined
  });

  const contextValue = useMemo(
    () => ({
      launchModal: (initialPage = null) =>
        setModalState({
          initialPage,
          showModal: true
        })
    }),
    []
  );

  return (
    <EediPlusContext.Provider value={contextValue}>
      <EediPlusUpSellModal
        isOpen={modalState.showModal}
        initialPage={modalState.initialPage}
        onDismiss={() =>
          setModalState(s => ({
            ...s,
            showModal: false
          }))
        }
      />
      {children}
    </EediPlusContext.Provider>
  );
};
