import { initiateGuestUser } from 'actions/guest';
import {
  Main,
  Button,
  Card,
  EmojiSpan,
  Form,
  toaster
} from 'components/common';
import { useDispatch } from 'react-redux';
import useApiValidatedForm from 'hooks/useApiValidatedForm';
import { FamilyYearGroupSelector } from 'components/Child/FamilyYearGroupSelector';
import Navigation from 'components/Navigation';
import styled from 'styled-components';
import { media } from 'styles';
import CachedApi from 'cache/CachedApi';
import { Api } from 'api';
import { setAuthToken } from 'config/settings';
import { cache } from 'store';
import useQuery from 'hooks/useQuery';
import { useNavigate } from 'react-router';
import { getTheme } from './themes';

const StyledMain = styled(Main)`
  padding-top: clamp(1rem, 5%, 8rem);
  ${({ theme }) => theme}
`;

const StyledCard = styled(Card)`
  max-width: 100%;
  ${media.md`
    max-width: 75%;
  `};
`;

const DynamicQuizSignupStep = () => {
  const dispatch = useDispatch();
  const params = useQuery();
  const userIdParam = params.get('userId');
  const schoolIdParam = params.get('schoolId');
  const claimCodeParam = params.get('claimCode');
  const localeParam = params.get('locale');
  const redirectUriParam = params.get('redirectUri');
  const orgIdParam = params.get('orgId');
  const navigate = useNavigate();

  const {
    formState: { isSubmitting },
    control,
    handleSubmit
  } = useApiValidatedForm({
    mode: 'onChange'
  });

  const handleSave = async formData => {
    if (isSubmitting || !formData.yearGroup) return;

    try {
      const guestUserDto = await CachedApi.getGuestUsers();
      const guestToken = await Api.getGuestUserToken(
        guestUserDto.guestStudentUserId
      );
      setAuthToken(guestToken);

      const topicPathwayCollectionStudents =
        await CachedApi.getAllTopicPathwayCollectionsForStudent(
          guestUserDto.guestStudentId
        );

      const data = {
        guestStudentUserId: guestUserDto.guestStudentUserId,
        guestStudentId: guestUserDto.guestStudentId,
        yearGroup: formData.yearGroup.name,
        topicPathwayCollectionStudent: topicPathwayCollectionStudents[0],
        userIdParam,
        schoolIdParam,
        claimCodeParam,
        localeParam,
        redirectUriParam,
        orgIdParam
      };

      cache.set({
        key: 'guest-user-data',
        value: data,
        ttlMinutes: 20,
        isUseSessionStorage: true
      });

      dispatch(initiateGuestUser(data));
      navigate(`/guest/dynamic-quiz/quiz`);
    } catch (e) {
      console.error(e);
      toaster.danger('Oops - something went wrong!');
    }
  };

  const theme = getTheme({ orgId: orgIdParam, locale: localeParam });

  return (
    <>
      <Navigation
        top
        logo={theme.logo}
        isHomeLinkDisabled={theme.homeLinkDisabled}
      />
      <StyledMain container="lg" theme={theme.theme}>
        <StyledCard className="mx-auto fadeInAndDown">
          <Card.Body>
            <Card.Title>
              <h1>Welcome to our diagnostic quiz</h1>
              <p>
                Before we begin, please let us know a bit more about yourself{' '}
                <EmojiSpan ariaLabel="grinning face" emoji="😀" />
              </p>
            </Card.Title>
            <Form onSubmit={handleSubmit(handleSave)} loading={isSubmitting}>
              <Form.Group>
                <FamilyYearGroupSelector
                  control={control}
                  disabled={isSubmitting}
                  locale={localeParam}
                />
              </Form.Group>
              <Form.Buttons>
                <Button
                  color="primary"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}>
                  Let's Start
                </Button>
              </Form.Buttons>
            </Form>
          </Card.Body>
        </StyledCard>
      </StyledMain>
    </>
  );
};

export default DynamicQuizSignupStep;
