import { Api } from 'api';
import groupBy from 'lodash/groupBy';
import subjects from 'constants/subjects';
import { HOME_SCHOOL_IDS } from 'config/settings';
import { cache } from 'store';
import types from 'cache/types';

const TopicPathwayFactory = (studentId, schoolId, guestDynamicQuiz) => {
  const resolveTopicPathway = async (
    topicPathwayCollectionStudent,
    level = undefined
  ) => {
    if (!topicPathwayCollectionStudent) {
      return {
        topicPathwayCollection: null,
        currentLevelProgress: null
      };
    }

    const levelToFetch = level ?? topicPathwayCollectionStudent.currentLevel;
    const levelProgress = topicPathwayCollectionStudent.progress?.levelProgress;
    const topicPathwayCollectionId =
      topicPathwayCollectionStudent.topicPathwayCollectionId;

    const topicPathwayCollection =
      await Api.getTopicPathwayCollectionForStudentAndLevel(
        studentId,
        topicPathwayCollectionId,
        ['statistics', 'quiz-sessions', 'quiz'],
        levelToFetch
      );

    // I'm recreating the structure of the pathway groups / quiz groups even though they don't exist any more
    // TBH we should simplify all this and remove the factory and utilise simple paging through quiz levels
    // as this is all a bit OTT IMHO FTW.
    if (!topicPathwayCollection?.topicPathwayCollectionGroups) {
      const topicPathwayCollectionGroups = [];

      topicPathwayCollection.topicPathwayQuizs.forEach(tpq => {
        if (!topicPathwayCollectionGroups?.find(c => c.level === tpq.level)) {
          topicPathwayCollectionGroups.push({
            level: tpq.level,
            topicPathwayQuizGroups: topicPathwayCollection.topicPathwayQuizs
              .filter(tpq2 => tpq2.level === tpq.level)
              .sort((a, b) => a.sequence - b.sequence)
              .map((tpq, i) => ({
                sequence: i,
                topicPathwayQuizs: [tpq]
              }))
          });
        }
      });

      topicPathwayCollection.topicPathwayCollectionGroups =
        topicPathwayCollectionGroups;
    }

    const currentLevelProgress = levelProgress?.find(
      lp => lp.level === levelToFetch
    );

    return {
      topicPathwayCollection,
      currentLevelProgress
    };
  };

  const generateTopicPathwayCollectionGroups = (
    topicPathwayCollection,
    currentLevelProgress
  ) => {
    if (
      !topicPathwayCollection ||
      !topicPathwayCollection?.topicPathwayCollectionGroups?.length ||
      !currentLevelProgress?.level
    ) {
      return [];
    }

    let filteredTopicPathwayCollectionGroups = [];

    let sequence = 1;

    topicPathwayCollection.topicPathwayCollectionGroups.forEach(tpcg => {
      if (!tpcg.topicPathwayQuizGroups) return;
      tpcg.topicPathwayQuizGroups.forEach(tpqg => {
        const topicPathwayQuizs = groupBy(tpqg.topicPathwayQuizs, 'subjectId');
        Object.keys(topicPathwayQuizs).forEach(sid => {
          const subjectId = parseInt(sid, 10);
          const existingGroup = filteredTopicPathwayCollectionGroups.find(
            ftpq => ftpq.subjectId === subjectId
          );
          if (!existingGroup) {
            sequence++;
            filteredTopicPathwayCollectionGroups.push({
              name:
                subjects.find(s => s.subjectId === subjectId)?.label ??
                'All Topics',
              level: tpcg.level,
              subjectId,
              topicPathwayId: sequence,
              sequence: sequence,
              topicPathwayCollectionGroupId: sequence,
              topicPathwayQuizGroups: [
                {
                  sequence: tpqg.sequence,
                  topicPathwayQuizs: topicPathwayQuizs[sid].sort(
                    (a, b) => a.sequence - b.sequence
                  )
                }
              ]
            });
          } else {
            existingGroup.topicPathwayQuizGroups.push({
              sequence: tpqg.sequence,
              topicPathwayQuizs: topicPathwayQuizs[sid].sort(
                (a, b) => a.sequence - b.sequence
              )
            });
          }
        });
      });
    });

    if (filteredTopicPathwayCollectionGroups.length === 1) {
      filteredTopicPathwayCollectionGroups[0].topicPathwayId = -1;
      filteredTopicPathwayCollectionGroups[0].topicPathwayCollectionGroupId =
        -1;
      filteredTopicPathwayCollectionGroups[0].sequence = -1;
    } else {
      filteredTopicPathwayCollectionGroups.unshift({
        level: currentLevelProgress.level,
        topicPathwayId: -1,
        topicPathwayCollectionGroupId: -1,
        topicPathwayCollectionId:
          topicPathwayCollection.topicPathwayCollectionId,
        name: 'All Topics',
        sequence: -1, // This will be the default view
        topicPathwayQuizGroups: filteredTopicPathwayCollectionGroups
          .map(tpcg =>
            tpcg.topicPathwayQuizGroups.filter(
              tpqg => tpqg.topicPathwayQuizs?.length > 0
            )
          )
          .reduce((acc, curr) => [...acc, ...curr])
          .sort(
            (a, b) =>
              a.outerSequence - b.outerSequence || a.sequence - b.sequence
          )
      });
    }

    return filteredTopicPathwayCollectionGroups;
  };

  const tryClaimGuestDynamicQuiz = async () => {
    if (!guestDynamicQuiz?.dynamicQuizClaimCode) return;
    try {
      await Api.claimDynamicQuizSessionForStudent(
        studentId,
        guestDynamicQuiz.dynamicQuizClaimCode
      );
    } catch (e) {
      // Errors here won't fail the onboarding of the user, they just won't be assigned the quiz / set a level.
      // You can find the backend errors logged in the GuestDynamicQuizzes table in the Error column.
      console.error(e);
    }
  };

  const fetchTopicPathway = async (level = undefined) => {
    // if (!localStorage.getItem('authToken')) return;

    const cacheKey = `topicPathwayFactory-${studentId}-level-${level}`;
    const result = cache.get({ key: cacheKey });

    if (result) return result;

    // Get hold of topic pathway collections for a given student
    let topicPathwayCollectionStudents;

    try {
      topicPathwayCollectionStudents =
        await Api.getAllTopicPathwayCollectionsForStudent(studentId);
    } catch (e) {
      console.error(e, localStorage.getItem('authToken'), studentId);
      return {
        levelProgress: 0,
        topicPathwayCollection: null,
        topicPathwayCollectionStudent: null,
        topicPathwayCollectionGroups: []
      };
    }

    let topicPathwayCollectionStudent;

    if (!HOME_SCHOOL_IDS.includes(schoolId)) {
      return {
        levelProgress: 0,
        topicPathwayCollection: null,
        topicPathwayCollectionStudent: null,
        topicPathwayCollectionGroups: []
      };
    }

    if (!topicPathwayCollectionStudents.length) {
      const topicPathwayCollections =
        await Api.getEediHomeTopicPathwayCollections();

      if (!topicPathwayCollections)
        throw new Error(
          'No valid Eedi Family topic pathway collections found!'
        );

      const defaultTopicPathwayCollection = topicPathwayCollections[0];
      try {
        topicPathwayCollectionStudent =
          await Api.upsertTopicPathwayCollectionStudent(
            studentId,
            defaultTopicPathwayCollection.topicPathwayCollectionId,
            null
          );
        await tryClaimGuestDynamicQuiz();
      } catch (e) {
        console.error(e);
      }
    } else {
      topicPathwayCollectionStudent = topicPathwayCollectionStudents[0];
    }

    const { currentLevelProgress, topicPathwayCollection } =
      await resolveTopicPathway(topicPathwayCollectionStudent, level);

    const topicPathwayCollectionGroups = generateTopicPathwayCollectionGroups(
      topicPathwayCollection,
      currentLevelProgress
    );

    const topicPathwayData = {
      levelProgress: currentLevelProgress,
      topicPathwayCollection,
      topicPathwayCollectionStudent,
      topicPathwayCollectionGroups
    };

    if (
      currentLevelProgress &&
      topicPathwayCollection &&
      topicPathwayCollectionStudent &&
      topicPathwayCollectionGroups
    ) {
      cache.set({
        key: cacheKey,
        value: topicPathwayData,
        ttlMinutes: 2,
        isUseSessionStorage: true,
        typename: types.topicPathwayCollection.typename
      });
    }

    return topicPathwayData;
  };

  return {
    fetchTopicPathway
  };
};

export default TopicPathwayFactory;
