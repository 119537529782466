import larder from '../larder';
import Client from 'api/Client';
import { getApiUri } from 'api/helpers';
import types from '../types';

export const getCountries = async () => {
  let uri = `v3/countries`;

  return await larder.pickAsync({
    type: types.country,
    key: uri,
    callback: () => Client.get(getApiUri(uri)),
    isUseSessionStorage: true,
    ttlMinutes: 60 * 24 * 30
  });
};
