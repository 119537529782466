import { Api } from 'api';
import { TEACHER_URL } from 'config/settings';
import { store } from 'store';
import { logout } from 'actions/auth';
//import Cookies from 'js-cookie';

const redirectToTeacher = schoolId => {
  redirectAndLogin(TEACHER_URL, schoolId);
};

const redirectAndLogin = async (url, schoolId) => {
  let redirectUrl = new URL(`${url}`);
  //const authToken = Cookies.get('authToken');

  const {
    user: { userRole, userId }
  } = store.getState();

  if (!userId || !userRole) return;

  try {
    //if (!authToken) {
    if (userRole === 'Teacher') {
      if (!schoolId) {
        const usersTeachers = await Api.getUsersTeachers(userId);
        schoolId = usersTeachers?.length ? usersTeachers[0].schoolId : null;
      }
      if (schoolId) {
        await Api.getAndOrEnrollHybridSchool(schoolId);
      }
    }

    const redirectToken = await Api.getRedirectToken();
    redirectUrl = new URL(`${url}/loginredirect`);
    if (schoolId) {
      redirectUrl.searchParams.append('redirectUri', `${schoolId}`);
    }
    redirectUrl.searchParams.append('token', redirectToken);
    store.dispatch(logout());
    localStorage.clear();
    //}
  } catch (e) {
    console.error(e);
  }

  window.location.replace(redirectUrl);
};

export { redirectToTeacher, redirectAndLogin };
