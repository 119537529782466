export const formatPrice = price => {
  const bits = price.toString().split('.');
  return (
    <>
      <span className="currency">£</span>
      <span className="number" style={{ fontSize: '1.2em' }}>
        {bits[0]}
      </span>
      <span className="decimal" style={{ fontSize: '0.5em' }}>
        .{bits[1]}
      </span>
    </>
  );
};

export const penniesToPoundsGbp = (pennies = 0) => (pennies / 100).toFixed(2);
