import { PricePlanChooser } from 'components/EediPlus/PricePlanChooser';
import { Container } from 'components/common';

export const PricePlanModalPage = ({ onConfirm = priceId => {}, ...props }) => {
  return (
    <Container className="container-md">
      <div style={{ padding: '4rem' }}>
        <PricePlanChooser onConfirm={onConfirm} />
      </div>
    </Container>
  );
};
