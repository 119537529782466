const EediSquiggle = () => (
  <svg
    width="1280px"
    height="960px"
    viewBox="0 0 304 137"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeOpacity="0.5">
      <path
        d="M2,81.4209033 C8.52715265,66.45869 14.2843192,51.1364315 21.581458,36.5342634 C39.6184129,0.440849875 102.481704,-0.169885635 119.477458,35.7891633 C127.699011,53.1840493 127.042142,87.5545029 126.646225,104.496429 C126.421775,114.101003 126.832558,131.469893 113.680353,134.052069 C88.3538282,139.024433 117.224866,72.0839322 118.111441,70.0411918 C131.351078,39.5360169 177.606032,10.2908032 207.1622,38.5494207 C217.430557,48.3669852 218.993501,71.1248922 220.037756,81.4096139 C222.05041,101.231956 222.107738,109.596966 212.490795,125.884191 C212.163457,126.43857 204.403942,134.051859 201.590883,130.958774 C197.059406,125.976214 197.156239,106.55787 197.571858,103.181215 C203.044644,58.7180703 230.272169,29.1602834 268.97729,9.61469784 C274.71501,6.71722323 280.914873,4.67580814 287.187086,3.27005709 C292.331697,2.11702823 297.697514,2.42335236 302.952728,2"
        id="Path-2"
        stroke="#333333"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default EediSquiggle;
