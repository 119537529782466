import { AchievementIcon } from './AchievementIcon';
import DefaultBackgroundSVG from 'assets/images/achievements/default_background.svg';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { withContentRect } from 'react-measure';

const StyledCardHeader = styled.div`
  margin: -1px;
  border: none;
  min-height: 17.5rem;
  background: ${({ backgroundUrl, isLocked }) =>
    isLocked
      ? 'var(--gray-light)'
      : `url('${backgroundUrl || DefaultBackgroundSVG}')`};
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  &.unlock-background {
    @keyframes swapBackground {
      0% {
        filter: contrast(1) brightness(1);
      }

      83% {
        background: none;
        filter: contrast(0) brightness(2);
      }

      84% {
        background: url(${({ backgroundUrl }) =>
          backgroundUrl || DefaultBackgroundSVG});
        filter: contrast(0) brightness(2);
      }

      100% {
        background: url(${({ backgroundUrl }) =>
          backgroundUrl || DefaultBackgroundSVG});
        filter: contrast(1) brightness(1);
      }
    }

    animation swapBackground 6s linear;
    animation-fill-mode: forwards;
  }
`;

export const AchievementCardHeader = withContentRect('bounds')(
  ({
    measureRef,
    measure: _,
    contentRect,
    achievement,
    isLocked = false,
    playUnlock = false,
    ...props
  }) => {
    const [confettiActive, setConfettiActive] = useState(false);

    useEffect(() => {
      let handle;
      if (playUnlock) {
        handle = setTimeout(() => setConfettiActive(true), 5000);
      }

      return () => clearTimeout(handle);
    }, [playUnlock]);

    return (
      <StyledCardHeader
        ref={measureRef}
        className={playUnlock ? 'card-header unlock-background' : 'card-header'}
        backgroundUrl={achievement.backgroundUrl}
        isLocked={isLocked}
        {...props}>
        {confettiActive && (
          <Confetti
            numberOfPieces={100}
            initialVelocityX={0}
            gravity={0.12}
            recycle={false}
            onConfettiComplete={() => setConfettiActive(false)}
            width={contentRect.bounds.width}
            height={contentRect.bounds.height}
          />
        )}
        <AchievementIcon
          onClick={() => setConfettiActive(!isLocked)}
          achievement={achievement}
          isLocked={isLocked}
          playUnlock={playUnlock}
        />
      </StyledCardHeader>
    );
  }
);
