import { Card, ProgressBar } from 'components/common';
import { useAverageColour } from 'hooks/useAverageColour';
import DefaultBackgroundSVG from 'assets/images/achievements/default_background.svg';
import styled from 'styled-components';
import { AchievementCardHeader } from './AchievementCardHeader';
import { useEffect, useState } from 'react';
import TickInCircleIcon from 'assets/icons/tickInCircle';

const defaultAchievement = {
  description: 'Description',
  level: 1,
  count: 0,
  totalGoal: 1,
  dateCompletedUTC: null,
  achievement: {
    name: 'Achievement',
    iconUrl: '',
    backgroundUrl: ''
  }
};

const ColouredBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${({ colour }) => colour};
  opacity: 0.2;
  border-radius: 0 0 1rem 1rem;
  transition: all 0.2s linear;
`;

const CompletedIcon = styled(TickInCircleIcon)`
  svg {
    margin: 0 5px 0 0;
  }
`;

const AchievementCard = ({
  userAchievement = defaultAchievement,
  playUnlockAnimation = false,
  ...props
}) => {
  const isLocked = userAchievement.dateCompletedUTC === null;
  const averageHeaderColour = useAverageColour(
    userAchievement.achievement.backgroundUrl || DefaultBackgroundSVG
  );

  const [highlightColour, setHighlightColour] = useState();
  useEffect(
    () =>
      setHighlightColour(
        isLocked ? 'var(--gray-light)' : averageHeaderColour?.hex
      ),
    [averageHeaderColour?.hex, isLocked]
  );

  useEffect(() => {
    let handle;
    if (playUnlockAnimation) {
      handle = setTimeout(
        () => setHighlightColour(averageHeaderColour?.hex),
        5000
      );
    }

    return () => clearTimeout(handle);
  }, [
    averageHeaderColour,
    isLocked,
    playUnlockAnimation,
    userAchievement.achievement.backgroundUrl
  ]);

  return (
    <Card {...props} className="mb-4">
      <AchievementCardHeader
        achievement={userAchievement.achievement}
        isLocked={isLocked}
        playUnlock={playUnlockAnimation}
      />
      <Card.Body style={{ position: 'relative' }}>
        <div className="d-flex justify-content-between">
          <h2>{userAchievement.achievement.name}</h2>
          {userAchievement.dateCompletedUTC && (
            <CompletedIcon color="var(--green)" />
          )}
        </div>
        <div>{userAchievement.description}</div>

        <ProgressBar
          style={{ marginTop: '0.5rem' }}
          currentIndex={userAchievement.count}
          total={userAchievement.totalGoal}
          showFooter
        />
        <ColouredBackground colour={highlightColour} />
      </Card.Body>
    </Card>
  );
};

export default AchievementCard;
