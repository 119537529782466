import { useState, useEffect } from 'react';
import { unified } from 'unified';
import * as prod from 'react/jsx-runtime';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';

export const parseHtmlContent = text => {
  try {
    const file = unified()
      .use(rehypeParse, { fragment: true })
      .use(rehypeReact, {
        Fragment: prod.Fragment,
        jsx: prod.jsx,
        jsxs: prod.jsxs
      })
      .processSync(text);

    return file.result;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const useHtmlProcessor = text => {
  const [Content, setContent] = useState(null);

  useEffect(() => {
    const runEffect = () => {
      setContent(() => parseHtmlContent(text));
    };
    runEffect();
  }, [text]);

  return Content;
};
