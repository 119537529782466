import { Button } from 'components/common';
import pluralize from 'helpers/pluralizeString';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ControlsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const LevelControls = ({
  currentLevel,
  nextLevel,
  levelAfterNext,
  canProgress = false,
  canBack = false,
  onProgressLevel = () => {},
  onPreviousLevel = () => {},
  topicsToComplete,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleProgressLevel = () => {
    if (nextLevel) {
      setIsLoading('next');
      try {
        onProgressLevel(nextLevel);
      } catch {
        setIsLoading(false);
      }
    }
  };

  const handlePreviousLevel = () => {
    if (canBack) {
      setIsLoading('previous');
      try {
        onPreviousLevel();
      } catch {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => setIsLoading(false), [currentLevel]);

  return (
    <ControlsContainer {...props}>
      <Button
        outline
        className="mr-1"
        disabled={!canBack || isLoading === 'next'}
        loading={isLoading === 'previous'}
        onClick={handlePreviousLevel}>
        ← Previous
      </Button>

      <span className="font-weight-bold">
        {topicsToComplete > 0
          ? `There are ${topicsToComplete} ${pluralize(
              'topic',
              topicsToComplete
            )} to complete in this pathway`
          : currentLevel === nextLevel && levelAfterNext === null
          ? 'Topic Pathway Collection Completed! No more Levels!'
          : 'Next Level'}
      </span>

      <Button
        className="ml-1"
        disabled={!canProgress || isLoading === 'previous'}
        loading={isLoading === 'next'}
        onClick={handleProgressLevel}
        color="primary">
        Next Level →
      </Button>
    </ControlsContainer>
  );
};
