import { ENVIRONMENT } from 'config/settings';
import { useCallback } from 'react';
import { useReducer } from 'react';

const useStateReducer = ({ stateName = 'default', initialState = {} }) => {
  const getReducer = useCallback(
    (state, action) => {
      if (ENVIRONMENT !== 'prod') {
        console.log(`State Reducer: ${stateName}`, action, state);
      }
      switch (action.type) {
        case `${stateName}_UPDATE`:
          const updatedState = { ...state, ...action.payload };
          if (ENVIRONMENT !== 'prod') {
            console.log(
              `State Reducer: ${stateName} updated state:`,
              updatedState
            );
          }
          return updatedState;
        case `${stateName}_SET`:
          return action.payload;
        case `${stateName}_RESET`:
          return initialState;
        default:
          return state;
      }
    },
    [initialState, stateName]
  );

  const [state, dispatch] = useReducer(getReducer, initialState);

  return {
    state,
    setState: updatedState =>
      dispatch({ type: `${stateName}_UPDATE`, payload: updatedState }),
    updateState: updatedState =>
      dispatch({ type: `${stateName}_UPDATE`, payload: updatedState }),
    overrideState: updatedState =>
      dispatch({ type: `${stateName}_SET`, payload: updatedState }),
    resetState: () => dispatch({ type: `${stateName}_RESET` })
  };
};

export default useStateReducer;
