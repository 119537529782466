import { ENVIRONMENT } from 'config/settings';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const IntercomAppProvider = ({ children = null }) => {
  const { user, school } = useSelector(({ user, school }) => ({
    user,
    school
  }));
  const bootedUserId = useRef(null);

  useEffect(() => {
    if (
      !user?.isAuthenticated ||
      bootedUserId.current === user?.userId ||
      !['Parent', 'Student'].includes(user?.userRole)
    ) {
      return;
    }

    if (bootedUserId.current) {
      bootedUserId.current = null;
      window.Intercom('shutdown');
    }

    if (user?.userRole === 'Teacher') return;

    const isInvitedPayload = {};
    try {
      const invitedPayload = localStorage.getItem('isInvited');
      const isInvited = !!invitedPayload;
      if (isInvited) {
        localStorage.removeItem('isInvited');
        isInvitedPayload.invited_by_child = true;
      }
    } catch {}

    window.Intercom('boot', {
      app_id: ENVIRONMENT === 'prod' ? 'e3tvlko6' : 'h12v3b77',
      created_at: new Date().valueOf(),
      user_id: user?.userId,
      first_name: user?.firstName,
      last_name: user?.lastName,
      email: user?.email,
      date_joined: user?.dateJoined,
      date_joined_at: user?.dateJoined
        ? Math.floor(new Date(user?.dateJoined).getTime() / 1000)
        : null,
      user_role: user?.userRole,
      username: user?.userName,
      user_type: user?.userRole,
      date_of_birth: user?.dateOfBirth,
      school_name: user?.schoolName,
      school_id: user?.schoolId,
      verified_in_school: user?.verifiedInSchool,
      is_school_administrator: user?.isSchoolAdministrator,
      trial_end_at: user.subscription?.trialPeriodend
        ? Math.floor(
            new Date(user.subscription?.trialPeriodend).getTime() / 1000
          )
        : null,
      current_period_end_at: user.subscription?.currentPeriodEndUTC
        ? Math.floor(
            new Date(user.subscription?.currentPeriodEndUTC).getTime() / 1000
          )
        : null,
      user_hash:
        ENVIRONMENT === 'prod'
          ? user?.intercom?.eediUserHash
          : user?.intercom?.eediTestUserHash,
      ...isInvitedPayload
    });

    window.Intercom('update', {
      hide_default_launcher: true
    });

    bootedUserId.current = user?.userId;
  }, [user, school]);

  return children;
};

export default IntercomAppProvider;
