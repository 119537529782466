import styled from 'styled-components';
import CampfirePoints0 from 'assets/images/routines/campfire-points-0.svg';
import CampfirePoints1 from 'assets/images/routines/campfire-points-1.svg';
import CampfirePoints5 from 'assets/images/routines/campfire-points-5.svg';
import CampfirePoints10 from 'assets/images/routines/campfire-points-10.svg';
import CampfirePoints30 from 'assets/images/routines/campfire-points-30.svg';
import CampfirePoints50 from 'assets/images/routines/campfire-points-50.svg';

const CampfireWrapper = styled.div`
  position: relative;
  width: min-content;
  height: min-content;
`;

const PointsContainer = styled.div`
  display: flex;
  position: absolute;
  right: -0.5rem;
  bottom: -0.8rem;

  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  font-weight: 800;

  width: 2rem;
  height: 2rem;

  box-shadow: var(--shadow2);
`;

const FireSVGs = [
  { minPoints: 50, svg: CampfirePoints50 },
  { minPoints: 30, svg: CampfirePoints30 },
  { minPoints: 10, svg: CampfirePoints10 },
  { minPoints: 5, svg: CampfirePoints5 },
  { minPoints: 1, svg: CampfirePoints1 },
  { minPoints: 0, svg: CampfirePoints0 }
];

export const GetFireSVG = points =>
  FireSVGs.find(s => s.minPoints <= points)?.svg ??
  FireSVGs[FireSVGs.length - 1].svg;

export const CampfirePointsIndicator = ({ points, ...props }) => {
  return (
    <CampfireWrapper title={`You have ${points} points`}>
      <img src={GetFireSVG(points)} alt="campfire" />

      <PointsContainer {...props}>
        <span>{points}</span>
      </PointsContainer>
    </CampfireWrapper>
  );
};
