import { Api } from 'api';
import {
  Modal,
  Button,
  Select,
  Input,
  EmojiSpan,
  Col,
  Row
} from 'components/common';
import { components } from 'react-select';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTeamStudent } from 'actions/teams';
import styled from 'styled-components';
import { userStates } from 'constants/userStates';
import { updateUserStateKvp } from 'actions/user';
import { getCountries } from 'cache/api/countries';
import Celebration from 'assets/images/school-league/celebration.svg';
import SchoolIcon from 'components/Leaderboard/SchoolIcon';
import {
  schoolLeaguePageBackground,
  ChallengeNavigationIcon
} from 'pages/classLeague/components/classLeagueHelper';

const Option = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        <SchoolIcon
          name={data.label}
          width="2rem"
          className="mr-2 flex-shrink-0"
        />
        <div>
          {data.label}
          <small className="ml-2 text-muted">{data.area}</small>
        </div>
      </div>
    </components.Option>
  );
};

const SchoolSelectionModal = ({ onDismiss, ...props }) => {
  const dispatch = useDispatch();

  const [isCountriesLoading, setIsCountriesLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [schoolUrl, setSchoolUrl] = useState(null);
  const [addingNewSchool, setAddingNewSchool] = useState(false);
  const [celebrate, setCelebrate] = useState(false);

  const studentId = useSelector(state => state.school.studentId);

  useEffect(() => {
    let isCancelled = false;

    async function fetchData() {
      setIsCountriesLoading(true);
      try {
        const countries = await getCountries();
        const formattedCountries = countries.map(c => ({
          value: c.countryId,
          label: c.name
        }));

        if (!isCancelled) {
          setSelectedCountry(formattedCountries.find(c => c.value === 235));
          setCountries(formattedCountries);
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (!isCancelled) {
          setIsCountriesLoading(false);
        }
      }
    }

    fetchData();
    return () => (isCancelled = true);
  }, []);

  const selectSchool = school => {
    if (!school) return;
    setSelectedSchool(school);
    setAddingNewSchool(!!school.__isNew__);
  };

  const joinSchool = async () => {
    if (!studentId) return;
    setIsFormSubmitting(true);
    try {
      let updatedTeamStudent;
      if (addingNewSchool) {
        updatedTeamStudent = await Api.addTeamSchool(
          studentId,
          selectedCountry.value,
          selectedSchool.value,
          schoolUrl
        );
      } else {
        updatedTeamStudent = await Api.joinTeamSchool(
          studentId,
          selectedSchool.value
        );
      }

      if (updatedTeamStudent) {
        dispatch(setTeamStudent(updatedTeamStudent));
        setCelebrate(true);
      }
    } catch (e) {
      console.error(e);
    }
    setIsFormSubmitting(false);
  };

  const onClose = () => {
    dispatch(updateUserStateKvp(userStates.isTeamSchoolModalSkipped, 'true'));
    onDismiss();
  };

  const searchTermTimeoutHandler = useRef();
  const getSchoolOptions = async input => {
    if (input.length < 3) {
      return;
    }
    return new Promise((resolve, reject) => {
      clearTimeout(searchTermTimeoutHandler.current);
      searchTermTimeoutHandler.current = setTimeout(async () => {
        try {
          const schools = await Api.getSchoolsByCountry(
            selectedCountry.value,
            input
          );
          const formattedSchools = schools.map(s => ({
            value: s.teamSchoolId,
            label: s.name,
            area: s.city || s.postcode
          }));
          resolve(formattedSchools);
        } catch (e) {
          reject(e?.message);
        }
      }, 400);
    });
  };

  return (
    <Modal isOpen={true} size="lg" {...props}>
      {celebrate ? (
        <Modal.Body>
          <div
            style={{
              padding: '3rem',
              backgroundColor: '#FBF3DC',
              borderRadius: '0.5rem'
            }}>
            <Row className="align-items-center">
              <Col md="6" className="text-center">
                <img
                  src={Celebration}
                  alt="Celebration"
                  style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    width: '100%'
                  }}
                />
                <div style={{ padding: '2.5rem 4rem' }}>
                  <SchoolIcon name={selectedSchool.label} width="100%" />
                </div>
                <h3>{selectedSchool.label}</h3>
                <h4 className="subtitle">{selectedSchool.area}</h4>
              </Col>
              <Col md="6">
                {addingNewSchool ? (
                  <h2>We're adding your school to the School League!</h2>
                ) : (
                  <h2>
                    Welcome to the team!{' '}
                    <EmojiSpan ariaLabel="prayer hands" emoji="🙌" />
                  </h2>
                )}
                <Button color="primary" onClick={onClose}>
                  Start learning
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      ) : (
        <>
          <StyledModalHeader toggle={onClose} className="header">
            <ChallengeNavigationIcon width="14rem" />
          </StyledModalHeader>
          <Modal.Body className="pt-0">
            <div className="container container-sm">
              <p className="text-lg text-center mb-5">
                Add your school below to get start and compete with other
                schools near you and around the world.
              </p>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <Select
                  label="1. Select your country"
                  options={countries}
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  disabled={isCountriesLoading}
                />
              </div>
              <div className="col-sm-6 mb-3">
                <Select
                  label="2. Search for your school"
                  loadOptions={getSchoolOptions}
                  value={selectedSchool}
                  components={{ Option }}
                  onChange={selectSchool}
                  isCreatable
                  isAsync
                />
              </div>
              {addingNewSchool && (
                <div className="col-sm-6">
                  <Input
                    label="3. Add your school's website"
                    value={schoolUrl}
                    onChange={e => setSchoolUrl(e.target.value)}
                    required
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="link"
              className="text-dark"
              type="button"
              onClick={onClose}>
              Skip
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={joinSchool}
              disabled={
                !selectedSchool || isFormSubmitting || isCountriesLoading
              }>
              Join School League
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

const StyledModalHeader = styled.div`
  background-image: url('${schoolLeaguePageBackground}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  height: 15rem;
  margin: 1rem 1rem 2rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SchoolSelectionModal;
