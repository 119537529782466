import styled from 'styled-components';
import { DropAnimation } from '../DropAnimation';
import { GetFireSVG } from './CampfirePointsIndicator';
import { config, useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import { EmojiSpan } from 'components/common';
import { RoutinePointsModal } from '../RoutinePointsModal';
import { useSelector } from 'react-redux';

const animState = {
  initial: { background: 'var(--gray-lightest)', width: '0' },
  pointsGain: { background: 'var(--yellow)', width: '230px' },
  pointsDecrease: { background: 'var(--blue-light)' }
};
export const RoutineUserPointsIndicator = props => {
  const routinePoints = useSelector(s => s.user.routine.totalPoints);

  const previousPoints = useRef(routinePoints);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [{ background, width }, api] = useSpring(() => ({
    from: animState.initial,
    config: config.slow
  }));

  useEffect(() => {
    if (
      typeof routinePoints !== 'number' ||
      routinePoints === previousPoints.current
    )
      return;

    const isIncrease = routinePoints > previousPoints.current;
    previousPoints.current = routinePoints;

    api.start(isIncrease ? animState.pointsGain : animState.pointsDecrease);
    const handle = setTimeout(() => api.start(animState.initial), 3000);

    return () => clearTimeout(handle);
  }, [api, routinePoints]);

  return (
    <Wrapper onClick={() => setIsModalOpen(true)} {...props}>
      <RoutinePointsModal
        open={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        points={routinePoints}
      />
      <Banner style={{ width }}>
        Keep up the good work! <EmojiSpan ariaLabel="celebration" emoji="🎉" />
      </Banner>
      <PointsContainer
        style={{ background }}
        title={`You have ${routinePoints} total points`}>
        <div className="mr-2" style={{ flex: '0 0 1.5rem' }}>
          <img
            src={GetFireSVG(Math.max(1, routinePoints))}
            alt="fire"
            style={{ height: '1.75rem' }}
          />
        </div>
        <DropAnimation value={routinePoints} />
      </PointsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const PointsContainer = styled(animated.div)`
  display: flex;
  background: var(--gray-lightest);
  border-radius: 3rem;
  padding: 0 0.75rem 0 0.5rem;
  z-index: 1;
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
  min-width: 4.5rem;
  z-index: 100;
  overflow: hidden;
`;
const Banner = styled(animated.div)`
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;

  position: absolute;
  right: 2rem;
  background: var(--gray-lightest);
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  padding-left: 1rem;
  z-index: 10;
`;
