const initialState = {
  loginError: null,
  loginPending: false,
  signupPending: false,
  googleData: null,
  signupError: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        loginPending: false,
        signupPending: false
      };

    case 'LOGIN_START':
      return { ...state, loginPending: true };

    case 'LOGIN_ERROR':
      return {
        ...state,
        loginError: action.message,
        loginPending: false
      };

    case 'SIGNUP_START':
      return { ...state, signupPending: true, signupError: null };

    case 'SIGNUP_ERROR':
      return {
        ...state,
        signupError: action.message,
        signupPending: false
      };

    case 'SET_GOOGLE_DATA':
      return { ...state, googleData: action.data };

    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default user;
