import jwt_decode from 'jwt-decode';

import {
  SetParentInvitationStatusActionType,
  SetUserPreferencesActionType,
  SetUserStateActionType,
  RefreshAvatarActionType,
  SetSubscriptionActionType,
  SetNotificationsActionType,
  SetUserActionType
} from 'actions/user';
import {
  DismissCompletedAchievementType,
  SetAchievementsActionType
} from 'actions/achievements';
import { findCompletedAchievements } from './achievements';
import { SetRoutineStateActionType } from 'actions/routine';
import { SetCoinsStateActionType } from 'actions/coins';
import { SetShopItemsActionType } from 'actions/shopItems';

import { cache } from 'store';
import types from 'cache/types';
import localforage from 'localforage';
import { setAuthToken } from 'config/settings';

const initialState = {
  isAuthenticated: false,
  userId: null,
  refreshToken: '',
  decodedToken: null,
  userState: { stateValues: [] },
  achievements: {
    all: [],
    newAchievements: []
  },
  routine: {
    totalPoints: undefined,
    dailyPoints: []
  },
  coins: {
    currentBalance: undefined
  },
  notifications: { data: [], total: 0 },
  shopItems: [],
  guestDynamicQuiz: null,
  lastUpdatedAvatar: Date.now()
};

const bustUserContextCache = () =>
  cache.bustTypeCache({ typenames: [types.userRoleContext.typename] });

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      bustUserContextCache();
      setAuthToken(action.refreshToken);
      const decoded = jwt_decode(action.refreshToken);

      return {
        ...state,
        isAuthenticated: true,
        refreshToken: action.refreshToken,
        decodedRefreshToken: decoded
      };

    case SetUserActionType:
      bustUserContextCache();
      const completedUserAchievements = findCompletedAchievements(
        action.user?.achievements ?? []
      );
      return {
        isAuthenticated: true,
        ...state,
        ...action.user,
        userState: {
          stateValues: Array.isArray(action.user?.userState)
            ? action.user?.userState
            : []
        },
        achievements: {
          ...(state.achievements ?? []),
          isLoaded: true,
          all: action.user.achievements ?? [],
          newAchievements:
            state.achievements?.newAchievements?.length > 0
              ? state.achievements.newAchievements.concat(
                  findCompletedAchievements(completedUserAchievements)
                )
              : completedUserAchievements
        }
      };

    case SetSubscriptionActionType:
      bustUserContextCache();
      return {
        ...state,
        subscription: action.subscription
      };

    case SetUserPreferencesActionType:
      bustUserContextCache();
      return {
        ...state,
        userPreferences: action?.userPreferences
      };

    case SetUserStateActionType:
      bustUserContextCache();
      return {
        ...state,
        userState: action.userState
      };

    case SetAchievementsActionType:
      bustUserContextCache();
      const completedAchievements = findCompletedAchievements(
        action.achievements
      );
      return {
        ...state,
        achievements: {
          ...state.achievements,
          isLoaded: true,
          all: action.achievements,
          newAchievements:
            state.achievements?.newAchievements?.length > 0
              ? state.achievements.newAchievements.concat(completedAchievements)
              : completedAchievements
        }
      };

    case DismissCompletedAchievementType:
      bustUserContextCache();
      return {
        ...state,
        achievements: {
          ...state.achievements,
          newAchievements: state.achievements.newAchievements.filter(
            a => !action.achievementIds.includes(a.achievement.achievementId)
          )
        }
      };

    case SetNotificationsActionType:
      return {
        ...state,
        notifications: action.notifications
      };

    case SetRoutineStateActionType:
      return {
        ...state,
        routine: {
          ...state.routine,
          ...action.routineState
        }
      };

    case SetCoinsStateActionType:
      return {
        ...state,
        coins: {
          ...state.coins,
          ...action.coinState
        }
      };

    case SetShopItemsActionType:
      return {
        ...state,
        shopItems: action.shopItems
      };

    case SetParentInvitationStatusActionType:
      bustUserContextCache();
      return {
        ...state,
        parentInvitationStatus: action.status
      };

    case RefreshAvatarActionType:
      return {
        ...state,
        lastUpdatedAvatar: Date.now()
      };

    case 'LOGOUT':
      sessionStorage.clear();
      localStorage.clear();
      localforage.clear();
      return initialState;

    default:
      return state;
  }
};

export default user;
