import { useEffect, useRef } from 'react';
import analytics from 'analytics';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

const AnalyticsProvider = () => {
  const user = useSelector(({ user }) => user);
  const school = useSelector(({ school }) => school);
  const location = useLocation();

  useEffect(() => {
    analytics.init();

    // Register this first pageview
    analytics.page();
  }, []);

  const lastLocation = useRef();
  useEffect(() => {
    // Have we changed page? Track it!
    if (location.pathname !== lastLocation.current) {
      analytics.page(location.pathname);
      lastLocation.current = location.pathname;
    }
  }, [location.pathname]);

  useEffect(() => {
    const runEffect = async () => {
      // If the user is logged in and a member of a school, identify them
      if (user?.isAuthenticated && user?.userId && school?.schoolId) {
        analytics.identify({
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          dateJoined: user.dateJoined,
          username: user.userName,
          userRole: user.userRole,
          dateOfBirth: user.dateOfBirth,
          schoolId: school?.schoolId,
          schoolName: school?.name,
          allowEmailMessages: user?.userPreferences?.allowEmailMessages,
          allowTextMessages: user?.userPreferences?.allowTextMessages
        });
      }
    };
    runEffect();
  }, [user, school]);

  return null;
};

export default AnalyticsProvider;
