import { Card } from 'components/common';
import { penniesToPoundsGbp } from 'constants/plans';
import styled from 'styled-components';
import { media } from 'styles';

const StyledCard = styled(Card)`
  position: relative;
  cursor: pointer;

  width: 9.2rem;
  margin: 0.5rem;
  text-align: center;
  transition: all ease-in 0.15s;

  ${media.sm`
  width: 14.5rem;
  `}

  .interval-text {
    color: var(--gray);
  }

  &.selected {
    border: 1px solid var(--primary);
    color: var(--primary);
    background: var(--indigo-lighter);

    .interval-text {
      color: inherit;
    }
  }
`;

const AmountSaved = styled.span`
  width: min-content;
  border-radius: 1rem;
  white-space: nowrap;
  padding: 0 1rem;
  background-color: var(--green);
  color: white;
`;

export const PricePlanTile = ({
  id,
  option,
  isSelected,
  onSelect = () => {},
  disabled = false,
  ...props
}) => (
  <StyledCard
    {...props}
    onClick={onSelect}
    className={
      isSelected ? `selected ${props.className ?? ''}` : props.className ?? ''
    }>
    <Card.Body className="d-flex flex-column align-items-center">
      <span className="interval-text">{option.interval}</span>

      <h2>£{option.pricePerMonth} / month</h2>

      <span style={{ color: 'var(--gray-dark)' }}>{option.subText}</span>

      {!!option.yearlySaveAmount ? (
        <AmountSaved className="mt-2">
          Save £{penniesToPoundsGbp(option.yearlySaveAmount)} / year
        </AmountSaved>
      ) : (
        <span style={{ color: 'var(--gray-dark)' }} className="mt-2">
          Cancel Anytime
        </span>
      )}
    </Card.Body>
  </StyledCard>
);
