import Cookies from 'js-cookie';

const env = 'prod' || process.env.NODE_ENV;
let teacherUrl =
  process.env.REACT_APP_TEACHER_URL || 'https://teacher-qa.eedi.com';
let apiUrl = '';
let functionsApiUrl = '';
let omrFunctionsApiUrl = '';
let stripeApiKey = '';
let flowApiUrl = '';
let keystrokeLoggerUrl = '';
switch (env) {
  case 'prod':
    apiUrl = 'https://api.eedi.com';
    functionsApiUrl = 'https://eedi-functions-api-prod.azurewebsites.net';
    omrFunctionsApiUrl = 'https://omr-api.eedi.com';
    stripeApiKey = 'pk_live_pypeIsnPgZnGRFxT0peYl4X5';
    flowApiUrl = 'https://flow-api.eedi.com';
    teacherUrl = 'https://teacher.eedi.com';
    keystrokeLoggerUrl = 'https://keystrokeapi-prod.azurewebsites.net';
    break;
  default:
    apiUrl = process.env.REACT_APP_API_URL || 'https://api-qa.eedi.com';
    functionsApiUrl =
      process.env.REACT_APP_FUNCTIONS_API_URL ||
      'https://eedi-functions-api-qa.azurewebsites.net';
    omrFunctionsApiUrl =
      process.env.REACT_APP_OMR_FUNCTIONS_API_URL ||
      'https://omr-api-qa.eedi.com';
    stripeApiKey =
      process.env.REACT_APP_STRIPE_API_KEY ||
      'pk_test_yFbC08JVbfxTN3XcErgUkY9i';
    flowApiUrl =
      process.env.REACT_APP_FLOW_API_URL || 'https://flow-api-qa.eedi.com';
    keystrokeLoggerUrl =
      process.env.REACT_APP_KEYSTROKE_LOGGER_URL ||
      'https://keystrokeapi-qa.azurewebsites.net';
    break;
}

// Export these settings and allow them to be overridden by local .env files
export const API_URL = apiUrl;
export const FUNCTIONS_API_URL = functionsApiUrl;
export const OMR_FUNCTIONS_API_URL = omrFunctionsApiUrl;
export const FLOW_API_URL = flowApiUrl;
export const ENVIRONMENT = env;
export const TEACHER_URL = teacherUrl;
export const FAMILY_URL = window.location.origin;

export const KEYSTROKE_LOGGER_URL = keystrokeLoggerUrl;

// The BUILD_ID is the Azure Dev ops build identifier.
export const BUILD_ID = 'eedi_family_prod_buildid_13402'|| 'Development';
export const DISABLE_ANALYTICS =
  process.env.REACT_APP_DISABLE_ANALYTICS || false;
export const STRIPE_API_KEY =
  process.env.REACT_APP_STRIPE_API_KEY || stripeApiKey;
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://images.diagnosticquestions.com';
export const MARKETING_SITE_URL =
  process.env.REACT_APP_MARKETING_SITE_URL || 'https://eedi.com';

export const SKIP_SUBSCRIPTION_VALIDATION =
  process.env.REACT_APP_SKIP_SUBSCRIPTION_VALIDATION === 'true' || false;

export const HOME_SCHOOL_IDS = [167333, 161922];

export const GOOGLE_AUTH_CLIENT_ID =
  '250963904963-e2uegfjodagts71r9lmv58j3ablqv6c6.apps.googleusercontent.com';

export const CLEVER_CALLBACK_URL = API_URL + '/v3/clever-login-callback';

export const CLEVER_CLIENT_ID =
  env === 'prod' ? '13eeffcb842e4660df96' : '5f4242f5e2ec9b137a53';

export const CLEVER_SSO_URL = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${CLEVER_CALLBACK_URL}&client_id=${CLEVER_CLIENT_ID}`;

/**
 * @desc - Returns the imageUrl parsed against the environment variables.
 * @param string $imageUrl - The imageUrl to be parsed.
 * @return string - The parsed imageUrl.
 */
export const getImageUrl = src => {
  let imageUrl = src !== null && src !== undefined ? `${IMAGE_URL}/${src}` : '';
  if (imageUrl.length) {
    if (IMAGE_URL.length === 0 || src.indexOf('http') !== -1) {
      imageUrl = src;
    }
  }
  return imageUrl.replace(/([^:]\/)\/+/g, '$1');
};

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const setAuthToken = token => {
  localStorage.setItem('authToken', token);
  Cookies.set('authToken', token, {
    path: '/',
    domain: '.eedi.com',
    secure: true,
    sameSite: 'strict'
  });
};

export const removeAuthToken = () => {
  Cookies.remove('authToken');
};
