import { hotjar } from 'react-hotjar';

export class Hotjar {
  init() {
    hotjar.initialize(2626342, 6);
  }

  identify(user) {
    hotjar.identify('USER_ID', { userProperty: user.userId.toString() });
  }
}
