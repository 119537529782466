import { useEffect, useState } from 'react';
import FastAverageColor from 'fast-average-color';

const fac = new FastAverageColor();

export const useAverageColour = imageRef => {
  const [colour, setColour] = useState(null);

  useEffect(() => {
    let isMounted = true;
    async function getColour() {
      if (!imageRef) {
        return;
      }

      try {
        const colour = await fac.getColorAsync(imageRef);
        if (isMounted) {
          setColour(colour);
        }
      } catch (e) {
        console.error(e);
      }
    }

    getColour();
    return () => (isMounted = false);
  }, [imageRef]);
  return colour;
};
