import queryString from 'querystring-es3';
import Client from './Client';
import { getApiUri, getFunctionsApiUri } from './helpers';
import { getUserRole } from 'helpers/tokenHelper';
import { getAuthToken } from 'config/settings';

class Api {
  static async getSignalRConnectionInfo(userId, hubName, userRole) {
    return Client.get(
      getFunctionsApiUri(`/negotiate?hubName=${hubName}`),
      {},
      {
        headers: {
          'x-ms-signalr-userid': userId,
          userRole: userRole || 'Student'
        }
      }
    );
  }

  static async signalRJoinSchoolGroups(userId, groupName, userRole) {
    return Client.post(
      getFunctionsApiUri(`/JoinSchoolGroup`),
      {
        userId,
        groupName
      },
      {
        headers: {
          'x-ms-signalr-userid': userId,
          userRole: userRole || 'Student'
        }
      }
    );
  }

  static async signIn(username, password) {
    const params = {
      username,
      password
    };
    return Client.post(getApiUri('v3/tokens/refresh'), params);
  }

  static async signInTogether(username, password) {
    const params = {
      username,
      password
    };
    return Client.post(getApiUri('v3/tokens/shadowed-refresh'), params);
  }

  //#region Context

  static async getUserRoleContext() {
    const token = getAuthToken();

    if (!token) return null;

    const userRole = getUserRole(token);

    if (userRole === 'Student') return this.getStudentContext();

    if (userRole === 'Parent') return this.getParentContext();

    // Fallback to legacy context e.g. Teacher role
    return Client.get(getApiUri('v3/users/me'));
  }

  static async getParentContext(
    includes = ['children', 'students', 'user-preferences', 'feature-flags']
  ) {
    return await this.getUserContext(includes);
  }

  static async getStudentContext(
    includes = [
      'students',
      'user-state',
      'user-preferences',
      'feature-flags',
      'achievements',
      'routine-state',
      'user-items',
      'transaction-insights',
      'notifications'
    ]
  ) {
    return await this.getUserContext(includes);
  }

  static async getUserContext(includes = []) {
    return Client.get(
      getApiUri(`v4/users/me?${queryString.stringify({ includes })}`)
    );
  }

  //#endregion Context

  static async updateUser(userId, userUpdateDto) {
    return Client.put(getApiUri(`v3/users/${userId}`), userUpdateDto);
  }

  static async updateChild(userId, childId, userChildUpdateDto) {
    return Client.put(
      getApiUri(`v3/users/${userId}/children/${childId}`),
      userChildUpdateDto
    );
  }

  static async passwordReset(userName) {
    return Client.post(getApiUri('v3/password-resets'), {
      userName: userName,
      targetSite: 'EediFamily'
    });
  }

  static async eediHomeSignUp(user) {
    return Client.post(getApiUri('v4/users'), user);
  }

  static async eediHomeStudentSignUp(user) {
    return Client.post(getApiUri('/v4/users/students'), user);
  }

  static async getAccessToken(refreshToken) {
    return Client.get(
      getApiUri(`v3/tokens/access?refreshToken=${refreshToken}`)
    );
  }

  static async getRedirectToken() {
    return Client.get(getApiUri('v3/tokens/redirect'), null);
  }

  /* Guest */
  static async getGuestUserToken(userId) {
    return Client.post(getApiUri(`v3/tokens/users/${userId}/guest`));
  }

  static async createGuestDynamicQuizSession(
    userId,
    topicPathwayCollectionId,
    studentAbilityOverride,
    isCheckingAnswersFromSingleQuizSession = true,
    isCheckingForExistingQuizSession = false,
    userIdentifier = null,
    schoolIdentifier = null,
    locale = null
  ) {
    return Client.post(
      getApiUri(
        `v3/topic-pathway-collections/${topicPathwayCollectionId}/dynamic-quiz-sessions/${userId}/guest`
      ),
      {
        studentAbilityOverride,
        isCheckingAnswersFromSingleQuizSession,
        isCheckingForExistingQuizSession,
        userIdentifier,
        schoolIdentifier,
        locale
      }
    );
  }

  static async updateGuestDynamicQuizSession(
    quizSessionId,
    topicPathwayCollectionId,
    quizSessionUpdateDto,
    studentAbilityOverride
  ) {
    return Client.put(
      getApiUri(
        `v3/topic-pathway-collections/${topicPathwayCollectionId}/dynamic-quiz-sessions/${quizSessionId}?studentAbilityOverride=${studentAbilityOverride}&isCheckingAnswersFromSingleQuizSession=true`
      ),
      quizSessionUpdateDto
    );
  }

  static async getGuestUsers() {
    return Client.get(getApiUri(`v3/guests/users`));
  }

  static async getGuestDynamicQuizDetails(quizClaimCode) {
    return Client.get(getApiUri(`v3/guests/quiz/${quizClaimCode}`));
  }

  static async sendGuestDynamicQuizDetails(submitModel) {
    return Client.post(getApiUri('v3/guests/quiz'), submitModel);
  }

  /* Claim Code Dynamic Quizzes */
  static async createClaimCodeGuestDynamicQuizSession(
    userId,
    topicPathwayCollectionId,
    yearGroup,
    claimCode,
    isCheckingForExistingQuizSession = false,
    userIdentifier = null,
    schoolIdentifier = null,
    locale = null
  ) {
    return Client.post(
      getApiUri(
        `v3/topic-pathway-collections/${topicPathwayCollectionId}/guest-dynamic-quiz-sessions/${userId}`
      ),
      {
        studentAbilityOverride: yearGroup,
        claimCode,
        isCheckingForExistingQuizSession,
        userIdentifier,
        schoolIdentifier,
        locale
      }
    );
  }

  static async getClaimCodeGuestDynamicQuizSession(
    claimCode,
    isCreateNewQuizSession = true
  ) {
    return Client.get(
      getApiUri(
        `v3/guest-dynamic-quiz-sessions/${claimCode}?isCreateNewQuizSession=${isCreateNewQuizSession}`
      )
    );
  }

  static async updateClaimCodeGuestDynamicQuizSession(
    claimCode,
    quizSessionUpdateDto
  ) {
    return Client.put(
      getApiUri(`v3/guest-dynamic-quiz-sessions/${claimCode}`),
      quizSessionUpdateDto
    );
  }

  /**
   * Generates the parent and tutor reports and returns URIs linking to the PDFs in blob storage.
   * @param {*} claimCode - Required
   * @param {*} childFirstName - (Optional)
   * @param {*} sasTokenTTLHours - The PDFs are saved in a secure blob so a SAS token is required to acccess.
   * If you set to 0 this guarantees the PDF is regenerated each time.
   * @returns
   */
  static async generateClaimCodeGuestDynamicQuizReports(
    claimCode,
    childFirstName = null,
    sasTokenTTLHours = 0
  ) {
    return Client.get(
      getApiUri(
        `v3/guest-dynamic-quiz-report/${claimCode}?${
          childFirstName ? `childFirstName=${childFirstName}` : ''
        }&sasTokenTTLHours=${sasTokenTTLHours}`
      )
    );
  }

  /* User State */
  static async getUserState(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/state`));
  }

  static async updateUserState(userId, partialUserState) {
    return Client.put(getApiUri(`v3/users/${userId}/state`), {
      stateValues: partialUserState
    });
  }

  static async signInWithGoogle(googleToken) {
    const params = {
      googleToken
    };
    return Client.get(getApiUri(`v3/GoogleTokenSignIn`), params);
  }

  /*
    Quiz API
   */
  static async getQuiz(quizId) {
    return Client.get(getApiUri(`v3/quizzes/${quizId}`));
  }

  static async getQuizInsights(quizId, fromDaysAgo = null) {
    return Client.get(getApiUri(`v3/quizzes/${quizId}/quiz-insights`), {
      fromDaysAgo
    });
  }

  static async startTopicPathwayQuiz(topicPathwayQuizId) {
    return Client.post(
      getApiUri(`v3/topic-pathway-quizs/${topicPathwayQuizId}/quiz-sessions`)
    );
  }

  static async resumeQuiz(quizSessionId) {
    return Client.get(getApiUri(`v3/quiz-sessions/${quizSessionId}`));
  }

  /**
   * This EP does not target TopicPathwayQuizzes, of which Eedi-Family depends on
   */
  static async answerQuestion(quizSessionId, answer, isRetry = false) {
    const answerType = isRetry ? 'corrections' : 'answers';
    const params = {
      [answerType]: [answer]
    };

    return Client.put(getApiUri(`v3/quiz-sessions/${quizSessionId}`), params);
  }

  static async answerTopicPathwayQuizQuestion(
    quizSessionId,
    answer,
    isRetry = false
  ) {
    const answerType = isRetry ? 'corrections' : 'answers';
    const params = {
      [answerType]: [answer]
    };

    return Client.put(
      getApiUri(`v3/topic-pathway-quizs/quiz-sessions/${quizSessionId}`),
      params
    );
  }

  static async getQuestion(questionId) {
    return Client.get(getApiUri(`v3/questions/${questionId}`));
  }

  static async getQuestionResources(questionId) {
    return Client.get(getApiUri(`v3/questions/${questionId}/resources`));
  }

  static async getQuizReview(quizSessionId, includes = ['questions']) {
    return Client.get(getApiUri(`v3/quiz-sessions/${quizSessionId}`), {
      includes
    });
  }

  /*
    Classes API
   */
  static async joinClass(code) {
    return Client.post(getApiUri(`v3/invitations/${code}/students`));
  }

  static async getSchool(schoolId) {
    return Client.get(getApiUri(`v3/schools/${schoolId}`));
  }

  static async getStudentAndGroups(userId) {
    return this.getUsersStudents(userId, ['groups']);
  }

  static async getGroupStudents(
    studentId,
    groupId,
    includes = ['students', 'family-insights']
  ) {
    return Client.get(getApiUri(`v3/students/${studentId}/groups/${groupId}`), {
      includes
    });
  }

  static async getSchools(userId) {
    return this.getUsersStudents(userId, ['school']);
  }

  static async getChildren(userId, includes = []) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(getApiUri(`v3/users/${userId}/children?${queryParams}`));
  }

  static async addChild(childCode, parentId) {
    return Client.post(
      getApiUri(`v3/users/${parentId}/children?childCode=${childCode}`)
    );
  }

  static async getRCTExperiment(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/experiments/rct/latest`));
  }

  static async getQuizSession(quizSessionId) {
    return Client.get(getApiUri(`v3/quiz-sessions/${quizSessionId}`));
  }

  static async createQuizSessionForQuiz(quizId) {
    return Client.post(getApiUri(`v3/quizzes/${quizId}/quiz-sessions`));
  }

  static async getAssignment(assignmentId) {
    return Client.get(getApiUri(`v3/assignments/${assignmentId}`));
  }

  static async createQuizSessionForAssignment(
    assignmentId,
    isCreateNewQuizSession = true
  ) {
    return Client.post(
      getApiUri(
        `v3/assignments/${assignmentId}/quiz-sessions?isCreateNewQuizSession=${isCreateNewQuizSession}`
      )
    );
  }

  static async getStudentAssignmentInsightsForAssignment(
    studentId,
    assignmentId,
    includes = []
  ) {
    const queryParams = queryString.stringify({ includes });
    return Client.get(
      getApiUri(
        `v3/students/${studentId}/assignments/${assignmentId}/assignment-insights?${queryParams}`
      )
    );
  }

  /**
   * Fetches statistics and subject scores for a given student and topic pathway collection.
   * @param {*} studentId
   * @param {*} topicPathwayCollectionId
   * @param {*} fromDate
   * @param {*} includes
   * @returns
   */
  static async getTopicPathwayInsights(
    studentId,
    topicPathwayCollectionId,
    fromDate = null,
    includes = ['statistics', 'subject-scores']
  ) {
    return Client.get(
      getApiUri(
        `v3/students/${studentId}/topic-pathway-collections/${topicPathwayCollectionId}/topic-pathway-collection-insights`
      ),
      {
        includes,
        fromDate
      }
    );
  }

  static async getStudentAnalytics({ schoolId, studentId }) {
    const includes = ['activities', 'performance', 'inter-school-performance'];

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/students/${studentId}/analytics`),
      { 'request.filter.includes': includes }
    );
  }

  /*
    Students API
  */

  static async updateStudent(studentId, studentUpdateDTO) {
    return Client.put(getApiUri(`v3/students/${studentId}`), studentUpdateDTO);
  }

  /**
   * @param {object} param
   * @param {number} param.schoolId - School ID
   * @param {string[]} [param.includes=[]] - data to include in the request
   * @param {number} [param.page=1] - which page of data to return
   * @param {number} [param.limit=10000] - size of pages to split data into
   * @param {object[]} [param.sort=[]] - array of parameters to define sort order before paging
   * @param {string} [param.searchTerm=''] - filters results to include the search term in the users name or email
   * @param {number[]} [param.groupIds=[]] - filters results to students in listed groups. Empty array is the same as all groups
   * @param {('active'|'archived'|'all')} [param.archiveStatus='active'] - filters students to the specified activity level
   */
  static async getAllStudentsInSchool({
    schoolId,
    includes = [],
    page = 1,
    limit = 10000,
    sort = [],
    searchTerm = '',
    groupIds = [],
    archiveStatus = 'active'
  }) {
    const query = Object.assign(
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes,
        'request.filter.search': searchTerm,
        'request.filter.groupIds': groupIds,
        'request.filter.archiveStatus': archiveStatus
      },
      this.parseSort(sort)
    );

    return Client.get(getApiUri(`v3/schools/${schoolId}/students`), query);
  }

  /**
   * @param {object} param
   * @param {number} param.schoolId - School ID
   * @param {string} [param.searchTerm=''] - filters results to include the search term in the users name or email
   * @param {number[]} [param.groupIds=[]] - filters results to students in listed groups. Empty array is the same as all groups
   */
  static async getAllStudentsInSchoolCsv({
    schoolId,
    searchTerm = '',
    groupIds = []
  }) {
    const query = Object.assign({
      'filter.search': searchTerm,
      'filter.groupIds': groupIds
    });

    return Client.get(
      getApiUri(`v3/schools/${schoolId}/students/export`),
      query,
      { headers: { Accept: 'text/csv' }, readBodyAs: 'file' }
    );
  }

  static async deleteStudentsFromSchool(schoolId, studentIds = []) {
    const studentDeleteDTO = {
      schoolId,
      studentIds
    };

    return Client.delete(
      getApiUri(`v3/schools/${schoolId}/students`),
      studentDeleteDTO
    );
  }

  static async getAllGroupsInSchool(schoolId, includes = []) {
    return Client.get(getApiUri(`v3/schools/${schoolId}/groups`), includes);
  }

  /*
  Teacher Management API
  */
  /**
   * @param {object} param
   * @param {number} param.schoolId - School ID
   * @param {string[]} [param.includes=[]] - data to include in the request
   * @param {number} [param.page=1] - which page of data to return
   * @param {number} [param.limit=10000] - size of pages to split data into
   * @param {object[]} [param.sort=[]] - array of parameters to define sort order before paging
   * @param {string} [param.searchTerm=''] - filters results to include the search term in the users name or email
   * @param {number[]} [param.group=[]] - filters results to students in listed groups. Empty array is the same as all groups
   * @param {('all'|'pending'|'confirmed')} [param.membershipStatus='all'] - filters teachers based on school membership status
   */
  static async getAllTeachersInSchool({
    schoolId,
    includes = [],
    page = 1,
    limit = 10000,
    sort = [],
    searchTerm = '',
    groupIds = [],
    membershipStatus = 'all'
  }) {
    const query = Object.assign(
      {
        'request.skip': (page - 1) * limit,
        'request.take': limit,
        'request.filter.includes': includes,
        'request.filter.search': searchTerm,
        'request.filter.groupIds': groupIds,
        'request.filter.schoolMembershipStatus': membershipStatus
      },
      this.parseSort(sort)
    );

    return Client.get(getApiUri(`v3/schools/${schoolId}/teachers`), query);
  }

  /*
    Archive API
  */
  static async archiveStudents(schoolId, studentIds) {
    const params = {
      studentIds
    };

    return Client.put(
      getApiUri(`v3/schools/${schoolId}/students/archive-collection`),
      params
    );
  }

  static async unArchiveStudents(schoolId, studentIds) {
    const params = {
      studentIds
    };

    return Client.put(
      getApiUri(`v3/schools/${schoolId}/students/un-archive-collection`),
      params
    );
  }

  /*
    Assignments API
   */
  static async getNotifications(
    userId,
    status,
    schoolId,
    groupId = null,
    page = 1,
    limit = 30,
    notificationCategory = 'Assignment',
    schoolIds = []
  ) {
    let query = {
      'request.filter.notificationGroup': status,
      'request.filter.notificationCategory': notificationCategory,
      'request.skip': (page - 1) * limit,
      'request.take': limit,
      'request.filter.schoolId': schoolId,
      'request.sort[0].FieldName': 'RelevantDate',
      'request.sort[0].Direction': status === 'Upcoming' ? 'Asc' : 'Desc'
    };

    if (schoolIds.length) {
      schoolIds.forEach((id, index) => {
        query[`request.filter.schoolIds[${index}]`] = id;
      });
    }

    if (groupId) {
      query['request.filter.groupIds'] = groupId;
    }

    return Client.get(getApiUri(`v3/users/${userId}/notifications`), query);
  }

  static async dismissNotification(notificationId) {
    return Client.put(getApiUri(`v3/notifications/${notificationId}`));
  }

  static async dismissNotificationModal(notificationId) {
    return Client.put(
      getApiUri(`v3/notifications/${notificationId}/dismiss-modal`)
    );
  }

  static async getUserPreferences(userId) {
    return Client.get(getApiUri(`v3/user-preferences/${userId}`));
  }

  static async getChildPreferences(userId, childId) {
    return Client.get(
      getApiUri(`v3/users/${userId}/children/${childId}/user-preferences`)
    );
  }

  static async setUserPreferences(params) {
    return Client.put(getApiUri(`v3/user-preferences`), params);
  }

  /*
    Invitations
  */
  static async getUsersParentInvitations(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/parent-invitations`));
  }

  static async upsertParentMobileNumber(userId, mobile) {
    return Client.post(
      getApiUri(`v3/users/${userId}/invitations/parents-mobile`),
      { mobile }
    );
  }

  static async getParentInvitation(invitationCode, planId = null) {
    return Client.get(
      getApiUri(`v4/invitations/parents/${invitationCode}?planId=${planId}`)
    );
  }

  static async claimParentAccount(invitationCode, parentUserInvitationDTO) {
    return Client.post(
      getApiUri(`v4/invitations/parents/${invitationCode}`),
      parentUserInvitationDTO
    );
  }

  static async linkWithChild(parentId, invitationCode) {
    return Client.post(
      getApiUri(`/v4/invitations/parents/${parentId}/${invitationCode}`)
    );
  }

  static async linkWithChildLogin(parentId, username, password) {
    return Client.post(
      getApiUri(`/v4/invitations/parents/${parentId}/login-link`),
      {
        username,
        password
      }
    );
  }

  static async sendParentInvitation(userId, submitModel) {
    return Client.post(
      getApiUri(`v4/users/${userId}/parents/invitations`),
      submitModel
    );
  }

  static async sendInvitationReminder(userId) {
    return Client.post(
      getApiUri(`v4/users/${userId}/parents/invitation-reminder`)
    );
  }

  static async resetPasswordWithInvitationCode(invitationCode, password) {
    return Client.put(getApiUri(`v3/invitations/${invitationCode}/users`), {
      password
    });
  }

  /*
    Parents API
  */
  static async getStudentParents(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/parents`));
  }

  static async addNewChild(parentId, userChildInsertDTO) {
    return Client.post(
      getApiUri(`v4/users/${parentId}/children`),
      userChildInsertDTO
    );
  }

  static async addNewChildOnboarding(parentId, userChildOnboardingInsertDTO) {
    return Client.post(
      getApiUri(`v4/users/${parentId}/children/onboarding`),
      userChildOnboardingInsertDTO
    );
  }

  static async assignChildScheme(groupId, schemeId, schemeModuleAssignDTO) {
    return Client.post(
      getApiUri(`v4/groups/${groupId}/schemes/${schemeId}/scheme-module`),
      schemeModuleAssignDTO
    );
  }

  static async getEediHomeYearGroups() {
    return Client.get(getApiUri(`v3/year-groups/eedi-home`));
  }

  static async getEediHomeSchemes(yearGroupOrdinal = undefined) {
    return Client.get(getApiUri(`v3/schemes/eedi-home`), {
      yearGroup: yearGroupOrdinal
    });
  }

  static async getStudentGroup(studentId, isEediHomeGroups) {
    let param;
    if (isEediHomeGroups) {
      param = { isOnlyEediHomeGroups: true };
    }
    return Client.get(getApiUri(`v3/students/${studentId}/groups`), param);
  }

  static async getParentSchools(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/schools`));
  }

  static async searchSchoolLookups(searchText) {
    return Client.get(getApiUri(`v3/school-lookups`), {
      'filter.searchText': searchText
    });
  }

  static async getCollectionsList() {
    return Client.get(getApiUri(`v3/collections/collections-page`));
  }

  static async getCollectionsView(collectionId) {
    return Client.get(
      getApiUri(`v3/collections/collections-page/${collectionId}`)
    );
  }

  static async getExperiment(experimentId, targetId) {
    return Client.get(
      getFunctionsApiUri(`v3/experiments/${experimentId}/targets/${targetId}`)
    );
  }

  static async postExperiment(experimentId, targetId, pageId, payload) {
    return Client.post(
      getFunctionsApiUri(
        `v3/experiments/${experimentId}/targets/${targetId}/pages/${pageId}`
      ),
      payload
    );
  }

  /*
    Lessons API
   */
  /**
   * Get a lesson
   * @param {number} userId (required) The userId of this user.
   * @param {number} lessonId (required) The lessonId of the lesson this request is after.
   */
  static async getLesson(userId, lessonId) {
    return Client.get(getApiUri(`v3/users/${userId}/lessons/${lessonId}`));
  }

  /**
   * Get lessons belong to the current user, ordered by date DESC
   * @param {number} userId (required) The userId of this user.
   * @param {object} params
   * @param {number} [params.skip=0] Number of lessons to skip
   * @param {number} [params.take=10] Number of lessons to take
   * @param {?('active'|'done')} params.status The status of the lessons to get. `active` maps to `Ready`, `In Progress`, `Overdue`
   * @param {?('question'|'quiz'|'challengeWorksheet')} params.lessonType The type of the lessons to retrieve
   * @param {?number} params.quizId Filter to lessons for this quizId
   * @param {?number} params.questionId Filter to lessons for this questionId
   * @param {?quizSessionId} params.quizSessionId quizSessionId
   */
  static async getLessons(
    userId,
    params = {
      skip: 0,
      take: 10,
      status: undefined,
      lessonType: undefined,
      quizId: undefined,
      questionId: undefined,
      quizSessionId: undefined
    }
  ) {
    return Client.get(getApiUri(`v3/users/${userId}/lessons`), {
      'request.skip': params.skip,
      'request.take': params.take,
      'request.filter.status': params.status,
      'request.filter.lessonType': params.lessonType,
      'request.filter.quizId': params.quizId,
      'request.filter.questionId': params.questionId,
      'request.filter.quizSessionId': params.quizSessionId
    });
  }

  /**
   * Add a lesson for a user on a specific question.
   * @param {number} userId (required) The userId of this user.
   * @param {number} quizId (required) QuizId of the quiz this lesson is aimed at.
   * @param {number} questionId (required) QuestionId of the question this lesson is aimed at.
   * @param {number} quizSessionId (required) QuizSessionId of the quizSession this lesson is aimed at.
   * @param {date} date (required) Date of this lesson is for.
   */
  static async postQuestionLesson({
    userId,
    quizId,
    questionId,
    quizSessionId,
    date
  }) {
    const lessonInsertDTO = {
      quizId,
      questionId,
      quizSessionId,
      date,
      lessonType: 'Question'
    };
    return Client.post(
      getApiUri(`v3/users/${userId}/lessons`),
      lessonInsertDTO
    );
  }

  /**
   * Add a lesson for a user on the whole quiz
   * @param {object} params
   * @param {number} params.userId (required) The userId of this user.
   * @param {number} params.quizId (required) QuizId of the quiz this lesson is aimed at.
   * @param {date} params.date (required) Date of this lesson is for.
   * @param {('quiz','question','challengeWorksheet)} [params.lessonType='quiz'] Type of lesson to create
   */
  static async postQuizLesson({ userId, quizId, date, lessonType = 'quiz' }) {
    const lessonInsertDTO = {
      lessonType,
      quizId,
      date,
      questionId: null
    };
    return Client.post(
      getApiUri(`v3/users/${userId}/lessons`),
      lessonInsertDTO
    );
  }

  /**
   * Logs a single user event.
   */
  static async logUserEvent(userId, logInsertDTO) {
    try {
      Client.post(getApiUri(`v3/users/${userId}/events`), logInsertDTO);
    } catch (e) {
      console.error(e);
    }
    return;
  }

  /**
   * Get a list of user events.
   */
  static async getUserEvents(userId, userEventRequest) {
    return Client.get(getApiUri(`v3/users/${userId}/events`), userEventRequest);
  }

  /**
   * Stripe
   */

  static async getUserStripeCustomer(userId) {
    return Client.get(getApiUri(`v3/billing/customer/${userId}`));
  }

  static async getEediPlusPricingInfo() {
    return Client.get(getApiUri('v3/billing/info'));
  }

  /**
   * @param {object} params
   * @param {string} [params.priceId] Stripe price id to subscribe to in 'subscription' mode
   * @param {string} [params.successUrl] Url to be returned to on success
   * @param {string} [params.cancelUrl] Url to be returned to on cancellation
   * @param {'subscription'|'setup'} [params.mode='subscription'] Checkout session mode
   * @typedef SessionObject
   * @property {string} sessionId Id of the created session
   * @returns {SessionObject}
   */
  static async createStripeCheckoutSession({
    priceId = undefined,
    successUrl = undefined,
    cancelUrl = undefined,
    mode = 'subscription',
    settings = undefined,
    referral = undefined
  }) {
    return Client.post(
      getApiUri('v3/billing/session'),
      {
        priceId,
        successUrl,
        cancelUrl,
        mode,
        referral
      },
      settings
    );
  }

  static async getStripeBillingPortalUrl(userId, returnUrl = undefined) {
    return Client.get(getApiUri(`v3/billing/customer/${userId}/portal`), {
      returnUrl
    });
  }

  static async createStripeSetupIntent(userId, returnUrl = undefined) {
    const queryParams = queryString.stringify({ returnUrl });

    return Client.post(
      getApiUri(`v3/billing/customer/${userId}/payment-setup?${queryParams}`)
    );
  }

  static async createEediHomeTrialSubscription(
    userId,
    referralUuid = undefined,
    couponId = undefined,
    priceId = undefined,
    trialPeriodDays = undefined,
    settings = undefined
  ) {
    return Client.post(
      getApiUri(`v4/billing/customer/${userId}/trial`),
      {
        referralUuid,
        couponId,
        priceId,
        trialPeriodDays
      },
      settings
    );
  }

  static async createParentEediHomeTrialSubscription(userId, parentId) {
    return Client.post(
      getApiUri(`v4/billing/user/${userId}/parent/${parentId}/trial`)
    );
  }

  static async renewEediHomeSubscription(userId) {
    return Client.post(getApiUri(`v3/billing/customer/${userId}/renew`));
  }

  static async applyPromoCode(userId, promoCode) {
    const queryParams = queryString.stringify({ promoCode });
    return Client.post(
      getApiUri(`v3/billing/customer/${userId}/promoCode?${queryParams}`)
    );
  }

  static async getRewardfulAffiliateDashboardSSOLink(userId) {
    return Client.get(
      getApiUri(`v3/billing/customer/${userId}/affiliate-portal`)
    );
  }

  static async getReferrals(userId) {
    return Client.get(getApiUri(`v3/billing/customer/${userId}/affiliate`));
  }
  /**
   * Dynamic Quiz
   */

  static async createDynamicQuizSession(userId) {
    return Client.post(getApiUri(`v3/dynamic-quiz-sessions/${userId}`));
  }

  /**
   * Create a new dynamic quiz linked to a particular topic-pathway.
   * @param {*} userId - User who is taking the quiz-session
   * @param {*} topicPathwayCollectionId  - The topic pathway collection linked to the dynamic quiz
   * @param {*} isCheckingAnswersFromSingleQuizSession  - Set to True if you want to ignore all the users answers except for ones within the current quiz-session
   * @param {*} isCheckingForExistingQuizSession - Set to True if you want to search for an existing level booster session that is not completed and use that.
   * Otherwise if it's false each request will yield a new quiz-session.
   * @returns
   */
  static async createDynamicQuizSessionForTopicPathwayCollection(
    userId,
    topicPathwayCollectionId,
    isCheckingAnswersFromSingleQuizSession = true,
    isCheckingForExistingQuizSession = true
  ) {
    const queryParams = queryString.stringify({
      isCheckingAnswersFromSingleQuizSession,
      isCheckingForExistingQuizSession
    });
    return Client.post(
      getApiUri(
        `v3/topic-pathway-collections/${topicPathwayCollectionId}/dynamic-quiz-sessions/${userId}?${queryParams}`
      )
    );
  }

  static async updateDynamicQuizSession(quizSessionId, quizSessionUpdateDto) {
    return Client.put(
      getApiUri(`v3/dynamic-quiz-sessions/${quizSessionId}`),
      quizSessionUpdateDto
    );
  }

  /**
   * Updates a quiz-session linked to a dynamic quiz and topic pathway.
   * @param {*} quizSessionId - quiz-session for the dynamic quiz
   * @param {*} topicPathwayCollectionId  - The topic pathway collection linked to the dynamic quiz
   * @param {*} quizSessionUpdateDto - The payload to update the quiz-session with the users answers
   * @param {*} isCheckingAnswersFromSingleQuizSession  - Set to True if you want to ignore all the users answers except for ones within the current quiz-session
   * @returns
   */
  static async updateDynamicQuizSessionForTopicPathwayCollection(
    quizSessionId,
    topicPathwayCollectionId,
    quizSessionUpdateDto,
    isCheckingAnswersFromSingleQuizSession = true
  ) {
    const queryParams = queryString.stringify({
      isCheckingAnswersFromSingleQuizSession
    });
    return Client.put(
      getApiUri(
        `v3/topic-pathway-collections/${topicPathwayCollectionId}/dynamic-quiz-sessions/${quizSessionId}?${queryParams}`
      ),
      quizSessionUpdateDto
    );
  }

  static async fetchInProgressDynamicQuizSessionForTopicPathwayCollection(
    userId,
    topicPathwayCollectionId
  ) {
    return Client.get(
      getApiUri(
        `v3/topic-pathway-collections/${topicPathwayCollectionId}/dynamic-quiz-sessions/${userId}`
      )
    );
  }

  static async claimDynamicQuizSessionForStudent(studentId, quizClaimCode) {
    return Client.put(
      getApiUri(
        `v3/students/${studentId}/dynamic-quiz-sessions/claim-quiz/${quizClaimCode}`
      )
    );
  }

  /**
   * Achievements
   */
  static async getUserAchievements(userId, settings = null) {
    return Client.get(
      getApiUri(`v3/users/${userId}/achievements`),
      undefined,
      settings
    );
  }

  static async acknowledgeCompletedUserAchievements(
    userId,
    achievementIds = [],
    settings = null
  ) {
    return Client.put(
      getApiUri(`v3/users/${userId}/achievements/acknowledged`),
      achievementIds,
      {
        readBodyAs: 'none',
        ...settings
      }
    );
  }

  static parseSort(sort) {
    let params = {};
    if (Object.prototype.toString.call(sort) === '[object Array]') {
      sort.forEach((s, i) => {
        params[`request.sort[${i}].FieldName`] = s.fieldName;
        params[`request.sort[${i}].Direction`] = s.direction;
      });
    }
    return params;
  }

  static async sendUserFeedbackEmail(userId, feedbackType, message) {
    return Client.post(
      getFunctionsApiUri(`v1/users/${userId}/email-feedback`),
      {
        feedbackType,
        message
      }
    );
  }

  /**
   * Site Insights
   */
  static async getSiteInsights(minutes = 10) {
    return Client.get(getApiUri(`v3/site-insights`), {
      minutes
    });
  }

  /**
   * Topic Pathways
   */
  static async getAllTopicPathwayCollectionsForStudent(
    studentId,
    includes = []
  ) {
    return Client.get(
      getApiUri(`v4/students/${studentId}/topic-pathway-collections`),
      {
        includes
      }
    );
  }

  static async getTopicPathwayCollectionForStudent(
    studentId,
    topicPathwayCollectionId,
    includes = ['statistics', 'quiz']
  ) {
    return Client.get(
      getApiUri(
        `v4/students/${studentId}/topic-pathway-collections/${topicPathwayCollectionId}`
      ),
      {
        includes
      }
    );
  }

  static async getTopicPathwayCollectionForStudentAndLevel(
    studentId,
    topicPathwayCollectionId,
    includes = ['statistics', 'quiz', 'quiz-sessions'],
    level = 1
  ) {
    return Client.get(
      getApiUri(
        `v4/students/${studentId}/topic-pathway-collections/${topicPathwayCollectionId}/levels/${level}`
      ),
      {
        includes
      },
      {
        headers: {
          cache: 'no-cache',
          pragma: 'no-cache'
        }
      }
    );
  }

  static async getTopicPathwayQuizForStudent(
    studentId,
    topicPathwayQuizId,
    includes = ['statistics', 'quiz', 'quiz-sessions'],
    quizSessionId
  ) {
    return Client.get(
      getApiUri(
        `v4/students/${studentId}/topic-pathway-quizs/${topicPathwayQuizId}`
      ),
      {
        includes,
        quizSessionId
      }
    );
  }

  static async getTopicPathwayCollection(
    topicPathwayCollectionId,
    includes = ['statistics']
  ) {
    return Client.get(
      getApiUri(`v4/topic-pathway-collections/${topicPathwayCollectionId}`),
      {
        includes
      }
    );
  }

  static async getTopicPathwayQuizzesForStudent(
    studentId,
    topicPathwayCollectionId,
    topicPathwayQuizGroup,
    skip = 0,
    take = 10
  ) {
    const query = {
      topicPathwayQuizGroup,
      'request.skip': skip,
      'request.take': take,
      'request.membersToExpand[0]': 'statistics'
    };

    return Client.get(
      getApiUri(
        `v4/students/${studentId}/topic-pathway-collections/${topicPathwayCollectionId}/topic-pathway-quizzes`
      ),
      query
    );
  }

  /**
   * Fetches all topic pathway quizzes for a user, regardless of the pathway / assignment
   * status.
   * @param {*} userId
   * @param {*} topicPathwayQuizGroup
   * @param {*} skip
   * @param {*} take
   * @param {*} activityType
   * @returns
   */
  static async getTopicPathwayQuizzesForUser(
    userId,
    topicPathwayQuizGroup = 'Active',
    skip = 0,
    take = 10,
    activityType = null
  ) {
    const query = {
      'request.filter.topicPathwayQuizGroup': topicPathwayQuizGroup,
      'request.skip': skip,
      'request.take': take
    };

    if (activityType) {
      query['request.filter.activityType'] = activityType;
    }

    return Client.get(
      getApiUri(`v4/users/${userId}/topic-pathway-quizzes`),
      query
    );
  }

  static async getTopicPathwayRecommendedTopics(
    studentId,
    topicPathwayCollectionId,
    take = 1
  ) {
    return Client.get(
      getApiUri(
        `v4/students/${studentId}/topic-pathway-collections/${topicPathwayCollectionId}/recommended-topics?take=${take}`
      )
    );
  }

  static async getEediHomeTopicPathwayCollections() {
    return Client.get(getApiUri(`v3/topic-pathway-collections/eedi-home`));
  }

  static async upsertTopicPathwayCollectionStudent(
    studentId,
    topicPathwayCollectionId,
    startingLevel
  ) {
    const body = {};
    if (startingLevel) {
      body.startingLevel = startingLevel;
    }
    return Client.put(
      getApiUri(
        `v4/students/${studentId}/topic-pathway-collections/${topicPathwayCollectionId}`
      ),
      body
    );
  }

  static async deleteTopicPathwayCollectionStudent(
    studentId,
    topicPathwayCollectionStudentId
  ) {
    return Client.delete(
      getApiUri(
        `v4/students/${studentId}/topic-pathway-collection-students/${topicPathwayCollectionStudentId}`
      )
    );
  }

  static async createTopicPathwayQuizSession(topicPathwayQuizId) {
    return Client.post(
      getApiUri(`v3/topic-pathway-quizs/${topicPathwayQuizId}/quiz-sessions`)
    );
  }

  static async searchTopicsAndStatisticsForStudent(
    studentId,
    topicPathwayCollectionId,
    searchText,
    subjectIds,
    skip = 0,
    take = 10
  ) {
    const query = {
      'request.filter.searchText': searchText,
      'request.filter.subjectIds': subjectIds ?? [],
      'request.skip': skip,
      'request.take': take
    };
    const params = queryString.stringify(query);

    return Client.get(
      getApiUri(
        `/v5/students/${studentId}/topic-pathway-collection/${topicPathwayCollectionId}/topic-pathway-quizs/search?${params}`
      ),
      undefined,
      {
        headers: {
          cache: 'no-cache',
          pragma: 'no-cache'
        }
      }
    );
  }

  static async getUserRoutineState(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/routine-state`));
  }

  static async getUserTransactionsInsights(userId, dateFrom = null) {
    return Client.get(
      getApiUri(
        `v3/users/${userId}/user-transactions/insights${
          dateFrom ? `?${queryString.stringify({ dateFrom })}` : ''
        }`
      )
    );
  }

  // Teams
  static async getTeamsForSchool(schoolId) {
    return Client.get(getApiUri(`v3/schools/${schoolId}/teams`));
  }

  static async getTeamStudent(studentId) {
    return Client.get(getApiUri(`v3/students/${studentId}/team-student`));
  }

  static async getLeaderboardInsightForStudent(
    studentId,
    type = 'AllTime',
    geofiltertype = 'All'
  ) {
    const body = {
      type,
      geofiltertype
    };
    return Client.get(
      getApiUri(`v3/students/${studentId}/leaderboard-insight`),
      body
    );
  }

  static async getLeaderboardInsightForSchools(
    studentId,
    type = 'AllTime',
    geofiltertype = 'All'
  ) {
    const body = {
      type,
      geofiltertype
    };
    return Client.get(
      getApiUri(`v3/students/${studentId}/schools-leaderboard-insight`),
      body
    );
  }

  static async upsertTeamStudent(
    studentId,
    teamId,
    alias = null,
    avatar = null
  ) {
    return Client.put(
      getApiUri(`v3/students/${studentId}/teams/${teamId}/team-student`),
      {
        alias,
        avatar
      }
    );
  }

  static async getAvatar(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/avatar`));
  }

  static async updateAvatar(userId, file, avatarSettings) {
    const formData = new FormData();
    formData.append('ImageFile', file);
    formData.append('Json', JSON.stringify(avatarSettings));
    return Client.post(getApiUri(`v3/users/${userId}/avatar`), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static async getCountries() {
    return Client.get(getApiUri(`v3/countries`));
  }

  static async getSchoolsByCountry(countryId, input) {
    let query = {
      'request.filter.searchText': input,
      'request.skip': 0,
      'request.take': 20
    };
    return Client.get(getApiUri(`v3/countries/${countryId}/schools`), query);
  }

  static async joinTeamSchool(studentId, teamSchoolId) {
    return Client.put(
      getApiUri(
        `v3/students/${studentId}/team-school?teamschoolid=${teamSchoolId}`
      )
    );
  }

  static async addTeamSchool(studentId, countryId, name, url) {
    return Client.post(
      getApiUri(`v3/students/${studentId}/team-school/requests`),
      {
        countryId,
        name,
        url
      }
    );
  }

  // Emails
  static async emailUnsubscribe(email, messageId) {
    return Client.post(
      getApiUri(
        `v3/emails/unsubscribe?${queryString.stringify({ email, messageId })}`
      )
    );
  }

  static async sendChildCredentials(parentId, childId, email) {
    return Client.post(
      getApiUri(`v3/users/${parentId}/children/${childId}/email`),
      {
        email
      },
      { readBodyAs: 'none' }
    );
  }

  // Prize Draw
  static async getCurrentPrizeDraws() {
    return Client.get(getApiUri('v3/prize-draw/details'));
  }

  static async getPurchasedPrizeDrawEntries() {
    return Client.get(getApiUri('v3/competition-entry'));
  }

  // Tutors
  static async getOnDutyTutors() {
    return Client.get(getApiUri(`v3/tutors/active`));
  }

  static async getTutorByUserId(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/tutor`));
  }

  static async getTutorByTutorId(tutorId) {
    return Client.get(getApiUri(`v3/tutors/${tutorId}`));
  }

  // Shop
  static async getShopItems(itemType, page = 1, limit = 16) {
    let query = {
      'request.skip': (page - 1) * limit,
      'request.take': limit
    };
    return Client.get(getApiUri(`v3/shop-items/category/${itemType}`), query);
  }

  static async purchaseShopItem(itemId) {
    return Client.post(getApiUri(`v3/shop-items/${itemId}/purchase`));
  }

  static async purchasePrizeDrawEntry(prizeDrawRequirementId) {
    return Client.post(
      getApiUri(`v3/competition-entry/${prizeDrawRequirementId}`)
    );
  }

  static async equipShopItem(userId, itemId) {
    return Client.post(
      getApiUri(`v3/users/${userId}/user-items/${itemId}/equip`)
    );
  }

  static async getUserItems(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/user-items`));
  }

  // Live Classes
  static async getAllLiveClasses(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/live-classes`));
  }

  static async insertLiveClassEvent(userId, event) {
    return Client.post(
      getApiUri(`v3/users/${userId}/live-classes/events`),
      event
    );
  }

  // Eedi Plus
  static async getRecommendedYearGroups(userId, takeCount = 2) {
    const params = queryString.stringify({ takeCount });
    return Client.get(
      getApiUri(
        `v3/users/${userId}/eedi-plus/recommended-year-groups?${params}`
      )
    );
  }

  static async updateEediSchoolStudent(
    studentUserId,
    childUpdate = {
      password: null,
      firstName: null,
      dateOfBirth: null,
      yearGroupId: null
    }
  ) {
    return Client.put(
      getApiUri(`v3/users/${studentUserId}/eedi-plus/student`),
      childUpdate
    );
  }

  // Assignment Insights
  static async getStudentGroupAssignmentInsights({
    studentId,
    groupId,
    includes = ['quiz-session', 'students', 'challenges', 'statistics'],
    skip = null,
    take = null,
    assignmentGroup = 'AllActive'
  }) {
    const query = {
      'request.filter.assignmentGroup': assignmentGroup,
      'request.filter.includes': includes
    };

    if (skip) {
      query['request.skip'] = skip;
    }
    if (take) {
      query['request.take'] = take;
    }
    return Client.get(
      getApiUri(
        `v3/students/${studentId}/groups/${groupId}/assignment-insights`
      ),
      query
    );
  }

  static async getTopicPathwayQuizAssignmentInsights(
    userId,
    skip = 0,
    take = 10,
    assignmentGroup,
    tags = [],
    groupId = null
  ) {
    let query = {
      'request.filter.assignmentGroup': assignmentGroup,
      'request.filter.tags': tags,
      'request.filter.groupId': groupId,
      'request.skip': skip,
      'request.take': take
    };

    const params = queryString.stringify(query);

    return Client.get(
      getApiUri(
        `v4/users/${userId}/topic-pathway-quiz-assignment-insights?${params}`
      )
    );
  }

  static async upsertRetrievalPracticeTopic(userId, assignmentId) {
    return Client.post(
      getApiUri(
        `v3/users/${userId}/retrieval-practice-assignments/${assignmentId}/quiz`
      )
    );
  }

  static async getRetrievalPracticeAssignmentInsights(
    userId,
    skip = 0,
    take = 10,
    assignmentGroup,
    groupId = null
  ) {
    let query = {
      'request.filter.assignmentGroup': assignmentGroup,
      'request.filter.groupId': groupId,
      'request.skip': skip,
      'request.take': take
    };

    const params = queryString.stringify(query);

    return Client.get(
      getApiUri(
        `v3/users/${userId}/retrieval-practice-assignment-insights?${params}`
      )
    );
  }

  // Students
  static async getUsersStudents(userId, includes = []) {
    return Client.get(
      getApiUri(
        `v3/users/${userId}/students?${queryString.stringify({ includes })}`
      )
    );
  }

  static async acceptClassroomInvite(invitationCode) {
    return Client.post(getApiUri(`v3/invitations/${invitationCode}/students`));
  }

  /* Hybrid Schools */
  static async getUsersTeachers(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/teachers?includes=school`));
  }

  static async getAndOrEnrollHybridSchool(schoolId) {
    return Client.get(getApiUri(`v3/hybrid-schools/${schoolId}/enroll`));
  }

  /* Heartbeats */
  static async heartbeat(userId, body = { source: 'EediFamily' }) {
    return Client.post(
      getFunctionsApiUri(`v3/users/${userId}/heartbeat`),
      body
    );
  }

  /* Games */
  static async getWordEediDictionaries(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/wordeedi-dictionaries`));
  }

  static async getNumberEediDictionaries(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/numbereedi-dictionaries`));
  }

  /* Challenges */

  /// <summary>
  /// Gets the challenges for a user.
  /// </summary>
  static async getStudentChallenges({
    studentId,
    skip = null,
    take = null,
    includes = [],
    challengeId = null,
    isCompleted = null,
    isExpired = null,
    isProcessed = null,
    sort = null
  }) {
    const query = {
      'request.filter.includes': includes,
      'request.filter.challengeId': challengeId,
      'request.filter.isCompleted': isCompleted,
      'request.filter.isExpired': isExpired,
      'request.filter.isProcessed': isProcessed
    };

    if (!!skip) {
      query['request.skip'] = skip;
    }

    if (!!take) {
      query['request.take'] = take;
    }

    if (!!sort) {
      query['request.sort'] = this.parseSort(sort);
    }

    return Client.get(
      getApiUri(`v3/students/${studentId}/student-challenges`),
      query
    );
  }

  /* Qotd */
  /**
   * Add a QuestionOfTheDay lesson for a user
   * @param {object} params
   * @param {number} params.userId (required) The userId of this user.
   */
  static async postQotdLesson(userId) {
    const lessonInsertDTO = {
      lessonType: 'QuestionOfTheDay'
    };
    return Client.post(
      getApiUri(`v3/users/${userId}/lessons`),
      lessonInsertDTO
    );
  }

  static async getQotdInsights(userId) {
    return Client.get(getApiUri(`v3/users/${userId}/qotd/insights`));
  }

  static async updateQotdLesson(
    userId,
    lessonId,
    questionOfTheDayUpdateDTO = { isSkipped: null }
  ) {
    return Client.put(
      getApiUri(`v3/users/${userId}/qotd/${lessonId}`),
      questionOfTheDayUpdateDTO
    );
  }

  static async getBookings(studentId) {
    return Client.get(getApiUri(`v3/students/${studentId}/lesson-bookings`));
  }

  static async getIndependentStudyInsights({
    schoolId,
    quizId,
    studentId,
    includes = []
  }) {
    let query = {
      'request.filter.includes': includes
    };
    return Client.get(
      getApiUri(
        `v3/schools/${schoolId}/students/${studentId}/quiz/${quizId}/topic-insights`
      ),
      query
    );
  }

  static async getMisconceptions({ childId, page = 1, limit = 30 }) {
    const query = Object.assign({
      'request.skip': (page - 1) * limit,
      'request.take': limit
    });

    return Client.get(getApiUri(`/v3/users/${childId}/misconceptions`), query);
  }

  // Student quiz inference
  static async getUserQuizInferences(userId, quizId) {
    return Client.get(
      getApiUri(`v3/users/${userId}/quizzes/${quizId}/question-inferences`)
    );
  }
}

export default Api;
