import { FLOW_API_URL } from 'config/settings';
import Client from './Client';

const getApiUri = uri => getUri(FLOW_API_URL, uri);

const getUri = (endpoint = null, uri = null) => {
  const formattedUri = `/${uri}`.replace(/\/+/g, '/');
  return `${endpoint}${formattedUri}`;
};

class FlowApi {
  /**
   * The LessonHub is the primary SignalR hub for the flows API.
   * @param {*} hubName
   * @returns
   */
  static getLessonHubUrl(hubName) {
    return getApiUri(`/${hubName}`);
  }

  /**
   * Fetches the SignalR connection info.
   * @param {*} userId
   * @param {*} hubName
   * @param {*} userRole
   * @returns
   */
  static async getSignalRConnectionInfo(userId, hubName, userRole) {
    return Client.get(
      getApiUri(`/${hubName}/negotiate`),
      {},
      {
        headers: {
          'x-ms-signalr-userid': userId,
          userRole: userRole || 'Student'
        }
      }
    );
  }

  /**
   * Fetches the flow sesion by ID.
   * @param {*} flowGeneratorSessionId
   * @param {integer} take - How many "flowItem" (message) rows you want to take. If null it returns all rows for a session.
   * @param {string} nextFlowItemRowKey - The flowSession has a property "nextFlowItemRowKey" that gets returned if you pass
   * in a "take" value. If it's null and you pass in a take value, this means there are no more rows to fetch.
   * @returns
   */
  static async getFlowGeneratorSession({
    flowGeneratorSessionId,
    take = null,
    nextFlowItemRowKey = '',
    isIncludeInterventions = true,
    isIncludeMetaData = true
  }) {
    let queryParams = '';
    if (take) {
      queryParams = `?take=${take}&nextFlowItemRowKey=${nextFlowItemRowKey}&isIncludeMetaData=${isIncludeMetaData}`;
    } else {
      queryParams = `?isIncludeInterventions=${isIncludeInterventions}&isIncludeMetaData=${isIncludeMetaData}`;
    }
    return Client.get(
      getApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}${queryParams}`
      )
    );
  }

  /**
   * Updates a flowGeneratorSession by passing in a flowItemGroup and an input.
   * @param {*} flowGeneratorSessionId
   * @param {*} flowItemGroup - Each flowItem is dispatched with a flowItemGroup.
   * If a flowItem is linked to an expected input, we need to pass this back up
   * so we can record the user input and move the user forward.
   * @param {*} input - If a flowItem is expecting an input this value should not be
   * null or empty string.
   * @returns
   */
  static async updateFlowGeneratorSession(
    flowGeneratorSessionId,
    flowItemGroup,
    input
  ) {
    return Client.post(
      getApiUri(`/v1/flow-generator-sessions/${flowGeneratorSessionId}`),
      {
        flowItemGroup,
        input
      }
    );
  }

  /**
   * Send a direct message to a flow session without having to attempt to process the input.
   * @param {*} flowGeneratorSessionId
   * @param {*} input
   * @returns
   */
  static async sendMessage(flowGeneratorSessionId, input) {
    return Client.post(
      getApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/message`
      ),
      {
        input
      }
    );
  }

  /**
   * Flow session users and tutors can upload files which get send as messages.
   * Example usage (note the property names noted below are required for object mapping)
   * const formData = new FormData();
   * formData.append('FormFile', file);
   * formData.append('FileName', file.name);
   * formData.append('Message', message ?? '');
   * formData.append('IsPinned', isPinned);
   * @param {*} flowGeneratorSessionId
   * @param {*} formData
   * @returns
   */
  static async uploadFilesToFlowGeneratorSession(
    flowGeneratorSessionId,
    formData,
    isOCR = false
  ) {
    // NB: The file-upload endpoint returns all items, as required in Eedi-Admin
    // We only need a single "recently uploaded" file message for this project,
    //   hence this field
    formData.append('FetchLatestFlowItem', true);
    formData.append('IsOCR', isOCR);

    return Client.put(
      getApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/file-upload`
      ),
      formData,
      {
        headers: {
          isFormData: true
        }
      }
    );
  }

  static async requestTopicPathwayQuizLessonHelp(
    flowGeneratorSessionId,
    askForHelp = true
  ) {
    return Client.post(
      getApiUri(
        `/v1/flow-generator-sessions/${flowGeneratorSessionId}/help-requested?isHelpRequested=${
          askForHelp ? 'true' : 'false'
        }`
      ),
      {}
    );
  }

  /**
   * This creates a flowGeneratorSession for a particular templateSlug.
   * These templates are agnostic of the rest of the domain e.g. Quizzes, and live within themselves
   * for purposes such as onboarding or surveys.
   * @param {*} userId
   * @param {*} templateSlug
   * @param {*} isFetchExistingSession
   * @returns
   */
  static async createFlowGeneratorSessionForTemplate(
    userId,
    templateSlug,
    isFetchExistingSession = false
  ) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/flow-templates/${templateSlug}/` +
          `flow-generator-sessions?isFetchExistingSession=${isFetchExistingSession}`
      ),
      {}
    );
  }

  /**
   * Creates a flowGeneratorSession and the related entities for a topicPathwayQuiz.
   * @param {*} userId
   * @param {*} topicPathwayQuizId
   * @param {*} templateSlug
   * @param {*} isFetchExistingSession
   * @returns
   */
  static async createFlowGeneratorSessionForTopicPathwayQuiz(
    userId,
    topicPathwayQuizId,
    templateSlug = null,
    isFetchExistingSession = true,
    assignmentId = null
  ) {
    const request = {
      templateSlug,
      isFetchExistingSession
    };

    if (assignmentId) {
      request.assignmentId = assignmentId;
    }

    return Client.post(
      getApiUri(
        `/v1/users/${userId}/topic-pathway-quizs/${topicPathwayQuizId}/flow-generator-sessions`
      ),
      request
    );
  }

  static async createFlowGeneratorSessionForNeuripsQuiz(
    userId,
    quizId,
    templateSlug = 'NEURIPS_Treatment_v2',
    isFetchExistingSession = true
  ) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/experiments/${quizId}/flow-generator-sessions`
      ),
      {
        templateSlug,
        isFetchExistingSession
      }
    );
  }

  /**
   * Creates a flowGeneratorSession and the related entities for a topicPathwayQuiz.
   * @param {*} userId
   * @param {*} topicPathwayQuizId
   * @param {*} templateSlug
   * @param {*} isFetchExistingSession
   * @returns
   */
  static async createFlowGeneratorSessionForChallenge(
    userId,
    topicPathwayQuizId,
    templateSlug = null,
    isFetchExistingSession = true,
    quizSessionId = null
  ) {
    let queryParams = quizSessionId ? `?quizSessionId=${quizSessionId}` : '';
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/topic-pathway-quizs/${topicPathwayQuizId}/flow-generator-sessions/challenge-worksheet${queryParams}`
      ),
      {
        templateSlug,
        isFetchExistingSession
      }
    );
  }

  static async createFlowGeneratorSessionForStretchTask(
    userId,
    quizId = null,
    quizSessionId = null,
    isFetchExistingSession = true,
    templateSlug = null
  ) {
    let queryParams = quizSessionId ? `?quizSessionId=${quizSessionId}` : '';
    queryParams += quizId ? `${quizSessionId ? '&' : '?'}quizId=${quizId}` : '';
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/flow-generator-sessions/stretch-task${queryParams}`
      ),
      {
        templateSlug,
        isFetchExistingSession
      }
    );
  }

  static async createFlowGeneratorSessionForChatWithTutor(
    userId,
    templateSlug = 'ChatWithTutor_v1'
  ) {
    return Client.post(
      getApiUri(`/v1/users/${userId}/chat-with-tutor/flow-generator-sessions`),
      {
        templateSlug
      }
    );
  }

  static async createFlowGeneratorSessionForOnDemand(
    userId,
    templateSlug = 'ChatWithTutor_v1'
  ) {
    return Client.post(
      getApiUri(`/v1/users/${userId}/on-demand/flow-generator-sessions`),
      {
        templateSlug
      }
    );
  }

  static async createFlowGeneratorSessionForTopicPathwayQuizQuestion(
    userId,
    topicPathwayQuizId,
    sequence,
    quizSessionId = null,
    templateSlug = 'TopicQuizConstructLesson_2022-06-09',
    isFetchExistingSession = true
  ) {
    let queryParams = quizSessionId ? `?quizSessionId=${quizSessionId}` : '';

    return Client.post(
      getApiUri(
        `/v1/users/${userId}/topicPathwayQuizs/${topicPathwayQuizId}/sequence/${sequence}${queryParams}`
      ),
      {
        templateSlug,
        isFetchExistingSession
      }
    );
  }

  static async createFlowGeneratorSessionForQuestionOfTheDay(
    userId,
    lessonId,
    templateSlug = '',
    isFetchExistingSession = true
  ) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/question-of-the-day/${lessonId}/flow-generator-sessions`
      ),
      { templateSlug, isFetchExistingSession }
    );
  }

  static async createFlowGeneratorSessionForRetrievalPracticeTopic(
    userId,
    topicPathwayQuizId,
    assignmentId,
    templateSlug = '',
    isFetchExistingSession = true
  ) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/retrieval-practice-assignment/${assignmentId}/topics-pathway-quizs/${topicPathwayQuizId}/flow-generator-sessions`
      ),
      { templateSlug, isFetchExistingSession }
    );
  }

  static async dispatchIsTyping(flowGeneratorSessionId, userId) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/flow-generator-sessions/${flowGeneratorSessionId}/typing`
      ),
      {}
    );
  }

  static async getRecommendedQuizId(userId) {
    return Client.get(
      getApiUri(`/v1/users/${userId}/experiments/get-recommended-quiz`)
    );
  }

  static async setNeuripsYearGroup(userId, yearGroup) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/experiments/neurips-year-group/${yearGroup}`
      ),
      {}
    );
  }
}

export default FlowApi;
