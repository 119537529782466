import { Page } from 'components/common';
import { Outlet, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { media } from 'styles';

const Stepper = ({ steps, pageTitle = '' }) => {
  return (
    <Routes>
      <Route
        element={
          <Page title={pageTitle}>
            <Outlet />
          </Page>
        }>
        {steps.map((step, index) => (
          <Route
            key={index}
            index={step.index}
            path={step.path}
            element={
              <>
                {step.top ? <step.top /> : null}
                {step.content ? <step.content /> : null}
                {step.bottom ? <step.bottom /> : null}
              </>
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

Stepper.Content = styled.div`
  margin: 0 auto;
  padding: 9rem 1rem;
  max-width: 30rem;
  width: 100%;
  ${media.md`
    padding: 11rem 0;
  `};
`;

export default Stepper;
