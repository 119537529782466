export const themes = [
  {
    name: 'eedi',
    company: 'Eedi',
    orgId: 'eedi',
    locale: 'en-GB',
    logo: null,
    showWatermark: false,
    showEndConfetti: true,
    homeLinkDisabled: false,
    theme: `
    background: linear-gradient(308.66deg, #8481df 3.96%, #b684e9 100%)
    `
  },
  {
    name: 'anet',
    company: 'ANET',
    locale: 'en-US',
    orgId: 'anet',
    logo: null,
    showWatermark: false,
    showEndConfetti: false,
    homeLinkDisabled: true,
    theme: `
    background: linear-gradient(308.66deg, #8481df 3.96%, #b684e9 100%)
    `
  }
];

export const getTheme = ({ orgId, locale }) => {
  return (
    themes.find(t => t.orgId === orgId) ||
    themes.find(t => t.locale === locale) ||
    themes[0]
  );
};
