import { Logo, Navbar, Nav, Avatar } from 'components/common';
import NavbarLinks from './NavbarLinks';
import { useSelector } from 'react-redux';
import BillingErrorBanner from 'components/Billing/BillingErrorBanner';
import { hasValidSubscription } from 'helpers/validateSubscription';
import { EediPlusLogo } from 'components/common/EediPlusLogo';
import { Link } from 'react-router-dom';
import { RoutineUserPointsIndicator } from 'components/Routine/Indicators/RoutineUserPointsIndicator';
import { CoinsIndicator } from 'components/Coins/Indicators/CoinsIndicator';
import SwitchToParentModal from 'components/Auth/SwitchToParentModal';
import { useState } from 'react';
import NotificationsButton from 'components/Notifications/NotificationsButton';
import styled from 'styled-components';
import NotificationBanner from './NotificationBanner';

const ActivityIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    fill="none"
    viewBox="0 0 22 20">
    <path
      fill="currentColor"
      d="M6.055.115L3.142 3.013l-1.137-1.13L.727 3.152l1.776 1.765.64.606.639-.607 3.552-3.53L6.055.113v.001zm4.192 1.518V3.4h11.546V1.633H10.247zM6.055 7.177l-2.913 2.899-1.137-1.134-1.278 1.271 1.776 1.766.64.605.639-.606 3.552-3.53-1.279-1.272zm4.192 1.518v1.766h11.546V8.695H10.247zM6.055 14.24l-2.913 2.898-1.137-1.13-1.278 1.268 1.776 1.766.64.605.639-.606 3.552-3.531-1.279-1.271zm4.192 1.518v1.765h11.546v-1.765H10.247z"></path>
  </svg>
);

const ShopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="21"
    fill="none"
    viewBox="0 0 22 21">
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M19.918 1.414h-3.992l.5 5s1 1 2.5 1a3.23 3.23 0 002.139-.806.503.503 0 00.15-.465l-.706-4.229a.6.6 0 00-.591-.5v0z"></path>
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M15.926 1.414l.5 5s-1 1-2.5 1-2.5-1-2.5-1v-5h4.5zM11.426 1.414v5s-1 1-2.5 1-2.5-1-2.5-1l.5-5h4.5zM6.925 1.414H2.934a.6.6 0 00-.592.501l-.705 4.23a.504.504 0 00.15.464c.328.29 1.061.806 2.138.806 1.5 0 2.5-1 2.5-1l.5-5v0z"></path>
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M2.434 7.414v10a2 2 0 002 2h14a2 2 0 002-2v-10"></path>
    <path
      stroke="currentColor"
      strokeMiterlimit="16"
      strokeWidth="1.5"
      d="M14.266 19.414v-6a2 2 0 00-2-2h-2a2 2 0 00-2 2v6"></path>
  </svg>
);

const ChallengeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    fill="none"
    viewBox="0 0 20 22">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11.149 20.311l1.67-1.168A13.393 13.393 0 0018.266 5.52a.837.837 0 00-.453-.586l-7.811-3.819-7.81 3.82a.837.837 0 00-.454.585 13.393 13.393 0 005.448 13.624l1.67 1.168a2 2 0 002.293 0v0z"></path>
  </svg>
);

const LearnIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    fill="none"
    viewBox="0 0 17 19">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1.328 1.721v16M15.328 9.721l-14 8M15.328 9.721l-14-8"></path>
  </svg>
);

const HelpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 23">
    <path
      fill="currentColor"
      d="M11.792 5.066a4.477 4.477 0 00-3.09 1.23 4.11 4.11 0 00-1.283 2.961h2.224c0-1.136.964-2.06 2.15-2.06 1.184 0 2.15.924 2.15 2.06 0 .638-.535 1.1-1.352 1.733a10.132 10.132 0 00-.769.638c-1.109 1.062-1.141 2.19-1.141 2.316v.71h2.223l-.001-.674c.001-.017.037-.411.49-.845.167-.16.377-.32.595-.488.866-.672 2.176-1.687 2.176-3.39a4.108 4.108 0 00-1.281-2.962 4.475 4.475 0 00-3.09-1.229zM10.681 15.72h2.223v2.13h-2.223v-2.13z"></path>
    <path
      fill="currentColor"
      d="M11.792.805C5.662.805.676 5.585.676 11.46s4.987 10.653 11.116 10.653 11.116-4.78 11.116-10.653C22.908 5.583 17.92.804 11.792.804zm0 19.176c-4.903 0-8.893-3.823-8.893-8.523s3.99-8.522 8.893-8.522 8.893 3.823 8.893 8.522-3.99 8.523-8.893 8.523z"></path>
  </svg>
);

const Menu = [
  {
    label: 'Learn',
    path: null,
    icon: <LearnIcon />
  },
  {
    label: 'Challenge',
    path: 'challenge',
    icon: <ChallengeIcon />
  },
  {
    label: 'Shop',
    path: 'shop',
    icon: <ShopIcon />
  },
  {
    label: 'Activity',
    path: 'activity',
    icon: <ActivityIcon />
  },
  {
    label: 'Help',
    onClick: () => {
      window.Intercom('show');
    },
    icon: <HelpIcon />
  }
];

const ChildNavbar = () => {
  const { schoolId } = useSelector(store => store.school);
  const { userId } = useSelector(store => store.user);
  const hasEediPlus = hasValidSubscription();
  const [isParentLoginModalOpen, setIsParentLoginModalOpen] = useState(false);

  return (
    <>
      <NotificationBanner />
      <BillingErrorBanner
        loginAsParent={() => setIsParentLoginModalOpen(true)}
      />
      <SwitchToParentModal
        isOpen={isParentLoginModalOpen}
        closeModal={() => setIsParentLoginModalOpen(false)}
      />
      <Navbar expand="md" style={{ zIndex: 999 }}>
        <Nav.Left>
          <Nav.Brand to="/">
            <Logo loading={schoolId === null} />
            {hasEediPlus && <EediPlusLogo className="ml-3" />}
          </Nav.Brand>
        </Nav.Left>
        <Nav.Center>
          {schoolId && <NavbarLinks navigationOptions={Menu} />}
        </Nav.Center>
        <Nav.Right>
          <RoutineUserPointsIndicator className="mr-2" />
          <CoinsIndicator id="coinIndicator" className="mr-2" />
          <NotificationsWrapper>
            <NotificationsButton />
          </NotificationsWrapper>
          <Link to="/account">
            <Avatar userId={userId} />
          </Link>
        </Nav.Right>
      </Navbar>
    </>
  );
};

const NotificationsWrapper = styled.div`
  margin: 0 0.5rem 0 0;
`;

export default ChildNavbar;
