import {
  dismissCompletedAchievement,
  dismissCompletedAchievements,
  setAchievementsAndMarkAsAcknowledged
} from 'actions/achievements';
import { Api } from 'api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AchievementCompletedModal } from 'components/Achievements/AchievementCompletedModal';
import { useSignalROn } from 'hooks/useSignalROn';
import { useLocation } from 'react-router';
import matchPathname from 'helpers/matchPathname';

export const AchievementsProvider = () => {
  const { userId, newAchievements, userRole } = useSelector(
    ({
      user: {
        userId,
        achievements: { newAchievements, isLoaded: isAchievementsLoaded },
        userRole
      }
    }) => ({
      userId,
      newAchievements,
      isAchievementsLoaded,
      userRole
    })
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // List of URIs to not display Achievements
  const matchArg = matchPathname(
    [
      '/level-booster/:dynamicQuizSessionId?',
      '/topic/:topicPathwayQuizId/challenge',
      '/topic/:topicPathwayQuizId',
      '/topic/:topicPathwayQuizId/:flowGeneratorSessionId?'
    ],
    pathname,
    { exact: true }
  );

  const getUserAchievements = useCallback(async () => {
    let lastResponse = null;
    if (!!userId && userRole === 'Student') {
      try {
        const response = await Api.getUserAchievements(userId, {
          readBodyAs: 'text'
        });
        if (lastResponse !== response) {
          const userAchievements = JSON.parse(response);
          lastResponse = response;
          dispatch(setAchievementsAndMarkAsAcknowledged(userAchievements));
        }
      } catch (e) {
        console.error('Error retrieving achievements', e);
      }
    }
  }, [dispatch, userId, userRole]);

  useSignalROn('AchievementsUpdated', getUserAchievements);

  if (newAchievements?.length > 0) {
    if (matchArg) {
      return null;
    }
    return (
      <AchievementCompletedModal
        key={newAchievements[0].achievement.achievementId}
        achievement={newAchievements[0]}
        onDismiss={dismissAll => {
          if (dismissAll) {
            dispatch(
              dismissCompletedAchievements(
                newAchievements.map(ua => ua.achievement.achievementId)
              )
            );
            return;
          }

          dispatch(
            dismissCompletedAchievement(
              newAchievements[0].achievement.achievementId
            )
          );
        }}
      />
    );
  }

  return null;
};
