import { Button, Card, Col, Container, Row } from 'components/common';
import styled from 'styled-components';

const DynamicQuizStepOutro = ({
  title = "Great job! We're almost done.",
  body = (
    <p>
      We’re reviewing your answers and creating the best learning plan for you.'
    </p>
  ),
  onFinish,
  onFinishCopy = 'Finish'
}) => {
  return (
    <Card.Body
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '3rem',
        paddingRight: '3rem'
      }}>
      <Container>
        <Row noGutters={true}>
          <Col md="8">
            <TextWrapper>
              <h2>{title}</h2>
              {body}
            </TextWrapper>
          </Col>
          {onFinish ? (
            <Col md="4">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <Button className="mt-4" color="primary" onClick={onFinish}>
                  {onFinishCopy}
                </Button>
              </div>
            </Col>
          ) : null}
        </Row>
      </Container>
    </Card.Body>
  );
};

const TextWrapper = styled.div`
  p {
    margin: 0;
  }
`;

export default DynamicQuizStepOutro;
