import { FUNCTIONS_API_URL, getAuthToken } from 'config/settings';

export class FunctionsApi {
  init() {}
  identify(user) {}
  page(location) {}
  async track(event, data, option, userId) {
    if (!userId) return;

    try {
      const body = {
        category: data && data.category ? data.category : 'All',
        action: event,
        label: data && data.label ? data.label : undefined,
        value: data && data.value ? data.value : undefined,
        values: data && data.values ? data.values : undefined,
        source: 'EediFamily'
      };

      await fetch(`${FUNCTIONS_API_URL}/api/v3/users/${userId}/events`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
}
