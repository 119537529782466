// import LeagueIcon from 'pages/challenge/icons/AutumnLeagueIcon';
// import BannerBg from 'assets/images/school-league/autumn_banner_bg.svg';
// import Bg from 'assets/images/school-league/autumn_bg.svg';
// import PrizesBanner from 'assets/images/school-league/prizes_every_monday.svg';
// const color = '#EFCF90';

// import LeagueIcon from 'pages/challenge/icons/SpringLeagueIcon';
// import BannerBg from 'assets/images/school-league/spring_banner_bg.svg';
// import Bg from 'assets/images/school-league/spring_bg.svg';
// import PrizesBanner from 'assets/images/school-league/prizes_every_monday.svg';
// const color = '#DCF8C2';

// import LeagueIcon from 'pages/challenge/icons/SummerLeagueIcon';
// import BannerBg from 'assets/images/school-league/summer_banner_bg.svg';
// import Bg from 'assets/images/school-league/summer_bg.svg';
import PrizesBanner from 'assets/images/school-league/prizes_every_monday.svg';
// const color = '#EFCF90';

import LeagueIcon from 'pages/challenge/icons/SummerClubIcon';
import BannerBg from 'assets/images/school-league/summer_club_banner_bg.svg';
import Bg from 'assets/images/school-league/summer_club_bg.svg';
const color = '#FFCA0D';

const challengeCardBackgroundColor = color;
const ChallengeNavigationIcon = LeagueIcon;
const schoolLeagueBackground = BannerBg;
const schoolLeaguePageBackground = Bg;
const prizesBanner = PrizesBanner;

export {
  challengeCardBackgroundColor,
  ChallengeNavigationIcon,
  schoolLeagueBackground,
  schoolLeaguePageBackground,
  prizesBanner
};
