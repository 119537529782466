import { Api } from 'api';
import {
  Main,
  Button,
  Card,
  EmojiSpan,
  Form,
  toaster
} from 'components/common';
import TextInput from 'components/common/FormInputs/TextInput';
import useApiValidatedForm from 'hooks/useApiValidatedForm';
import is from 'is_js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Navigation from 'components/Navigation';
import styled from 'styled-components';
import ConfettiBackground from 'assets/images/quiz/quiz-confetti.svg';

const StyledMain = styled(Main)`
  padding-top: clamp(1rem, 5%, 8rem);
  background-color: var(--gray-lightest);
  background-image: url('${ConfettiBackground}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const DynamicQuizReportStep = () => {
  const navigate = useNavigate();
  const { claimCode } = useParams();
  const guest = useSelector(s => s.guest);
  const [state, setState] = useState({
    submitted: false,
    email: null,
    quizClaimCode: null
  });

  const {
    formState: { isSubmitting },
    control,
    errors,
    handleSubmit
  } = useApiValidatedForm({
    mode: 'onChange'
  });

  const handleSave = async data => {
    const submitModel = {
      childFirstName: data.childFirstName,
      email: data.email,
      quizSessionId: guest.quizSessionId,
      yearGroup: guest.yearGroup,
      claimCode: claimCode || guest.claimCodeParam
    };

    try {
      let guestDynamicQuiz = await Api.sendGuestDynamicQuizDetails(submitModel);
      setState({
        submitted: true,
        email: data.email,
        quizClaimCode: guestDynamicQuiz?.dynamicQuizClaimCode
      });
      navigate(
        `/signup/parent?quizClaimCode=${guestDynamicQuiz?.dynamicQuizClaimCode}`
      );
    } catch (ex) {
      console.error(ex);
      toaster.danger(
        `Something went wrong when trying to save your details - ${ex}`
      );
    }
  };

  return (
    <>
      <Navigation top />
      <StyledMain container="lg">
        <Card
          className="mx-auto fadeInAndDown"
          style={{ maxWidth: '75%', height: 'max-content' }}>
          <Card.Body>
            {!state?.submitted ? (
              <>
                <Card.Title>
                  <h2>
                    Thanks for completing the diagnostic quiz!{' '}
                    <EmojiSpan ariaLabel="rocket" emoji="🚀" />
                  </h2>
                  <p>
                    We will compile a report based on your answers.{' '}
                    <EmojiSpan ariaLabel="bicep flex" emoji="💪" />
                  </p>
                  <p>
                    Please enter your parent's email address below so we can
                    send the report!
                  </p>
                </Card.Title>
                <Form
                  onSubmit={handleSubmit(handleSave)}
                  loading={isSubmitting}>
                  <Form.Group>
                    <TextInput
                      control={control}
                      label="Your parent's email address"
                      name="email"
                      error={errors.email?.message}
                      placeholder="Parent's email address"
                      rules={{
                        required: 'Email cannot be blank',
                        validate: val =>
                          val && !is.email(val)
                            ? 'Please enter a valid email address'
                            : undefined
                      }}
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                  <Form.Group>
                    <TextInput
                      control={control}
                      label="Your first name"
                      name="childFirstName"
                      error={errors.childFirstName?.message}
                      placeholder="Your first name"
                      rules={{
                        required: 'First name cannot be blank'
                      }}
                      disabled={isSubmitting}
                    />
                  </Form.Group>
                  <Button type="submit" disabled={isSubmitting} color="primary">
                    Submit
                  </Button>
                </Form>
              </>
            ) : (
              <>
                <Card.Title>
                  <h2>The report will be sent to {state.email}</h2>
                  <p>
                    Sign up with Eedi to improve your confidence with Maths{' '}
                    <EmojiSpan ariaLabel="smile" emoji="😊" />
                  </p>
                </Card.Title>
                <Button
                  onClick={() =>
                    navigate(
                      state.quizClaimCode
                        ? `/signup/parent?quizClaimCode=${state.quizClaimCode}`
                        : '/signup/parent'
                    )
                  }>
                  Let's Go!
                </Button>
              </>
            )}
          </Card.Body>
        </Card>
      </StyledMain>
    </>
  );
};

export default DynamicQuizReportStep;
