import { cache } from 'store';
import types from './types';
import { ENVIRONMENT } from 'config/settings';

const validateProperties = ({ type, callback }) => {
  if (!type) throw new Error('type is required');
  if (!callback) throw new Error('callback is required');
  if (!types[type?.typename])
    throw new Error(`type ${type?.typename} is not defined`);
};

/**
 * Place an item back in the cache.
 * @param type - type of the cache item e.g. types.userPreferences
 * @param key - key of the cache item e.g. v3/user-preferences/{userId}
 * @param identifier - identifier of the cache item e.g. userId
 * @param properties - properties of the cache item e.g. { userId: '123' }
 * @param isUseSessionStorage - whether to use session storage or not
 * @param ttlMinutes - time to live in minutes
 * @returns
 */
function place({
  type,
  key,
  identifier,
  callback,
  properties = null,
  isUseSessionStorage = false,
  ttlMinutes = 0
}) {
  validateProperties({ type, callback });

  debugLog({
    func: 'place',
    key,
    identifier,
    type,
    properties
  });

  const result = cache.getOrAdd({
    key,
    callback,
    ttlMinutes,
    properties,
    typename: type.typename,
    isUseSessionStorage,
    isForceRefetch: true
  });

  const lookResults = look({ type, identifier });

  debugLog({
    func: 'place',
    key,
    identifier,
    type,
    properties
  });

  reCacheLookResults({
    lookResults,
    result,
    ttlMinutes,
    type,
    isUseSessionStorage
  });

  return result;
}

/**
 * Place an item back in the cache.
 * @param type - type of the cache item e.g. types.userPreferences
 * @param key - key of the cache item e.g. v3/user-preferences/{userId}
 * @param identifier - identifier of the cache item e.g. userId
 * @param properties - properties of the cache item e.g. { userId: '123' }
 * @param isUseSessionStorage - whether to use session storage or not
 * @param ttlMinutes - time to live in minutes
 * @returns
 */
async function placeAsync({
  type,
  key,
  identifier,
  callback,
  properties = null,
  isUseSessionStorage = false,
  ttlMinutes = 0
}) {
  validateProperties({ type, callback });

  debugLog({
    func: 'placeAsync',
    key,
    identifier,
    type,
    properties
  });

  const result = await cache.getOrAddAsync({
    key,
    callback,
    ttlMinutes,
    properties,
    typename: type.typename,
    isUseSessionStorage,
    isForceRefetch: true
  });

  const lookResults = look({ type, identifier });

  debugLog({
    func: 'lookResults',
    lookResults,
    type,
    identifier
  });

  reCacheLookResults({
    lookResults,
    result,
    ttlMinutes,
    type,
    isUseSessionStorage
  });

  return result;
}

function reCacheLookResults({
  lookResults,
  result,
  ttlMinutes,
  type,
  isUseSessionStorage
}) {
  lookResults.forEach(entry => {
    debugLog({
      fun: 'lookResultsSet',
      entry: entry?.entry?.value,
      key: entry.key,
      result
    });
    const isEntryObj = typeof entry?.entry?.value === 'object';
    const isResultObj = typeof result === 'object';
    const isEntryArray = Array.isArray(entry?.entry?.value);
    const isResultArray = Array.isArray(result);

    let value = null;

    if (isEntryObj && isResultObj) {
      value = { ...entry?.entry?.value, ...result };
    }

    if (isEntryArray && isResultArray) {
      value = [...new Set([...entry?.entry?.value, ...result])];
      debugLog({
        fun: 'lookResultsSet - array',
        value
      });
      const uniqueIds = new Set();
      value = value.filter(element => {
        const isDuplicate = type.keys.filter(key =>
          uniqueIds.has(element[key])
        ).length;

        if (!isDuplicate) {
          type.keys.forEach(key => uniqueIds.add(element[key]));
        }

        if (!isDuplicate) {
          return true;
        }

        return false;
      });
      debugLog({
        fun: 'lookResultsSet - filtered',
        value,
        uniqueIds
      });
    }

    debugLog({
      fun: 'lookResultsSet - output',
      value,
      isEntryArray,
      isResultArray,
      isEntryObj,
      isResultObj
    });

    if (value) {
      cache.set({
        key: entry.key,
        value: value,
        ttlMinutes,
        typename: type.typename,
        isUseSessionStorage
      });
    }
  });
}

/**
 * Attempts to find an item in cache via a deep search first. If not found the callback is called to get the data.
 * @param type - type of the cache item e.g. types.userPreferences
 * @param key - key of the cache item e.g. v3/user-preferences/{userId}
 * @param identifier - identifier of the cache item e.g. userId
 * @param callback - callback function to get the data
 * @param properties - properties of the cache item e.g. { userId: '123' }
 * @param isUseSessionStorage - whether to use session storage or not
 * @param filter - filter function to filter the data
 * @param ttlMinutes - time to live in minutes
 * @param isForceRefetch - whether to force the cache to be refreshed or not
 * @returns
 */
function pick({
  type,
  key,
  identifier,
  callback,
  properties = null,
  isUseSessionStorage = false,
  filter = x => x,
  ttlMinutes = 0,
  isForceRefetch = false
}) {
  validateProperties({ type, callback });

  if (!isForceRefetch) {
    const result = search({ type, identifier, filter });
    if (isValidSearchResult(result)) {
      debugLog({
        func: 'pickAsync',
        key,
        identifier,
        type,
        properties,
        isForceRefetch,
        status: 'Cache hit',
        result
      });
      return result?.entry?.value ?? result;
    }
  }

  debugLog({
    func: 'pickAsync',
    key,
    identifier,
    type,
    properties,
    isForceRefetch,
    status: isForceRefetch ? 'Refetch' : 'Cache miss'
  });

  return cache.getOrAdd({
    key,
    callback,
    ttlMinutes,
    properties,
    typename: type.typename,
    isUseSessionStorage,
    isForceRefetch
  });
}

/**
 * Attempts to find an item in cache via a deep search first. If not found the callback is called to get the data.
 * @param type - type of the cache item e.g. types.userPreferences
 * @param key - key of the cache item e.g. v3/user-preferences/{userId}
 * @param identifier - identifier of the cache item e.g. userId
 * @param callback - callback function to get the data
 * @param properties - properties of the cache item e.g. { userId: '123' }
 * @param isUseSessionStorage - whether to use session storage or not
 * @param filter - filter function to filter the data
 * @param ttlMinutes - time to live in minutes
 * @param isForceRefetch - whether to force the cache to be refreshed or not
 * @returns
 */
async function pickAsync({
  type,
  key,
  identifier,
  callback,
  properties = null,
  isUseSessionStorage = false,
  filter = x => x,
  ttlMinutes = 0,
  isForceRefetch = false
}) {
  debugLog({
    func: 'pickAsync',
    key,
    identifier,
    type,
    properties,
    isForceRefetch,
    status: 'Start'
  });
  validateProperties({ type, callback });

  if (!isForceRefetch) {
    const result = search({ type, identifier, filter });
    if (isValidSearchResult(result)) {
      debugLog({
        func: 'pickAsync',
        key,
        identifier,
        type,
        properties,
        isForceRefetch,
        status: 'Cache hit',
        result
      });
      return result?.entry?.value ?? result;
    }
  }

  debugLog({
    func: 'pickAsync',
    key,
    identifier,
    type,
    properties,
    isForceRefetch,
    status: isForceRefetch ? 'Refetch' : 'Cache miss'
  });

  return await cache.getOrAddAsync({
    key,
    callback,
    ttlMinutes,
    properties,
    typename: type.typename,
    isUseSessionStorage,
    isForceRefetch
  });
}

/**
 * Deep searches the cache for an item based on identifier and filter function.
 * @param type - type of the cache item e.g. types.userPreferences
 * @param identifier - identifier of the cache item e.g. userId
 * @param filter - filter function to filter the data
 * @returns
 */
function search({ type, identifier, filter = x => x }) {
  return cache.search({
    typename: type.typename,
    filter: x => {
      let matched = [];
      type.keys.forEach(key => {
        matched.push(identifier ? x[key] === identifier : true);
      });
      return !!matched.find(x => x) && !!filter(x);
    }
  });
}

/**
 * Tidy up the larder
 * @param type - type of the cache item e.g. types.userPreferences
 * @param identifier - identifier of the cache item e.g. userId
 * @param filter - filter function to filter the data
 */
function tidy({ type, identifier, filter = x => x }) {
  const entries = cache.getEntries({
    filter: (entry, key) => {
      let matched = [];
      debugLog({ func: 'tidy', entry, key, type, identifier });
      if (entry?.value) {
        type.keys.forEach(key => {
          matched.push(identifier ? entry?.value[key] === identifier : true);
        });
      }
      return (
        !!matched.find(x => x) &&
        !!filter(entry) &&
        entry.typename === type.typename
      );
    }
  });

  debugLog({ func: 'tidy', entries });

  cache.removeEntries({ entryKeys: entries.map(x => x.key) });
}

/**
 * Look in the larder for particular types of items.
 * @param type - type of the cache item e.g. types.userPreferences
 * @param identifier - identifier of the cache item e.g. userId
 * @param filter - filter function to filter the data
 * @returns
 */
function look({ type, identifier, filter = x => x }) {
  return cache.getEntries({
    filter: (entry, key) => {
      let matched = [];
      debugLog({ func: 'tidy', entry, key, type, identifier });
      if (entry?.value) {
        type.keys.forEach(key => {
          matched.push(identifier ? entry?.value[key] === identifier : true);
        });
      }
      return (
        !!matched.find(x => x) &&
        !!filter(entry) &&
        entry.typename === type.typename
      );
    }
  });
}

const isValidSearchResult = result =>
  (result !== undefined &&
    result !== null &&
    result !== false &&
    !Array.isArray(result)) ||
  (Array.isArray(result) && result?.length > 0);

const debugLog = (props = {}) => {
  if (ENVIRONMENT === 'prod') return;
  console.table('Cache Log', props);
};

const larder = {
  place,
  placeAsync,
  pick,
  pickAsync,
  search,
  look,
  tidy
};

export default larder;
