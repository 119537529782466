const StreakFullFlame = ({
  width = 22,
  height = 28,
  shadow = true,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{
        width,
        height,
        ...props.style
      }}>
      <g clipPath="url(#clip0)">
        <path
          d="M11.2056 27.7627C11.2056 27.7627 15.0472 27.3416 17.1755 25.7627C19.3038 24.1838 21.6388 21.1832 21.3793 17.1838C21.1199 13.1845 20.1334 12.2891 20.1334 12.2891C20.1334 12.2891 19.5104 14.6578 18.5241 14.2364C17.5378 13.8149 18.4724 8.76366 16.7074 6.2891C14.9424 3.81454 10.426 0.762695 10.426 0.762695C10.426 0.762695 11.6199 5.86782 10.426 6.6048C9.23201 7.34178 7.62273 7.18372 7.1037 8.28908C6.58466 9.39445 6.74116 15.7444 4.97518 15.7444C3.20921 15.7444 2.89883 11.9736 2.89883 11.9736C2.89883 11.9736 0.510696 15.3944 0.614624 17.8679C0.718553 20.3415 3.93791 24.2892 3.93791 24.2892L2.17294 23.9216C2.17294 23.9216 5.02715 27.7627 11.2056 27.7627Z"
          fill="url(#paint0_radial)"
        />
        <path
          d="M1.99512 11.391C1.99512 11.391 3.42212 9.84943 3.74961 8.94807C4.07711 8.04671 4.19413 6.07837 4.03038 6.2207C2.15886 7.78594 2.02593 9.26275 1.99512 11.391Z"
          fill="#FF6320"
        />
        <g filter={shadow ? 'url(#filter0_d)' : null}>
          <path
            d="M9.78983 26.6659C9.78983 26.6659 12.8215 27.4747 15.4144 25.2907C16.9439 24.0134 18.3229 22.5613 19.5238 20.9632L17.4493 21.4895C17.4493 21.4895 17.409 19.3453 17.9277 18.0513C18.4463 16.7572 18.486 15.7438 16.3981 17.2455C16.1299 17.4384 14.1778 16.8779 14.6565 13.6429C15.1353 10.4079 14.0581 7.29211 12.2632 5.43262C12.2632 7.21226 8.19466 9.43667 7.99486 12.6721C7.79506 15.9075 7.47944 22.0155 3.0498 17.5254C3.0498 21.853 6.51891 26.2613 9.78983 26.6659Z"
            fill="#FE9A3D"
          />
        </g>
        <path
          d="M10.5072 26.0185C12.1676 26.6405 15.8555 24.8118 16.0518 22.1762C16.3711 20.68 16.2913 19.79 15.3739 18.9812C12.8887 16.912 12.4029 11.2443 12.8609 8.80176C8.03828 13.4423 11.2921 15.6304 8.59277 18.9005C7.1563 19.952 4.80281 19.5474 4.60341 20.235C4.40402 20.9226 7.67494 25.8568 10.5072 26.0185Z"
          fill="#FFB22D"
        />
        <g filter="url(#filter1_d)">
          <path
            d="M11.2858 25.6961C13.2816 25.6626 14.8951 22.9863 14.5761 21.5712C14.2571 20.1561 12.0633 18.3748 11.9038 16.6762C11.7443 14.9776 11.8635 14.2627 11.8635 14.2627C11.8635 14.2627 10.7483 16.3527 10.4083 17.6872C10.0683 19.0217 9.7094 20.9228 8.91161 21.1654C8.11382 21.408 6.79679 20.9228 7.11643 21.6104C7.43607 22.298 8.87355 25.7358 11.2858 25.6961Z"
            fill="#FFE840"
          />
        </g>
        <g filter="url(#filter2_f)">
          <path
            d="M11.2864 25.2467C12.8588 25.2467 13.4163 23.0225 13.2733 22.0556C13.1303 21.0887 11.1597 19.4928 11.2864 19.0093C11.4131 18.5257 10.9366 21.2335 10.0779 21.6202C9.21932 22.007 8.36111 22.1512 8.69485 23.0223C9.02859 23.8935 9.86001 25.2467 11.2864 25.2467Z"
            fill="#FDFBE6"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-0.950195"
          y="5.43262"
          width="24.474"
          height="29.3797"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.443137 0 0 0 0 0.231373 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        {shadow && (
          <filter
            id="filter1_d"
            x="3.06738"
            y="10.2627"
            width="15.5491"
            height="19.4338"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.909804 0 0 0 0 0.25098 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        )}

        <filter
          id="filter2_f"
          x="5.62109"
          y="15.9517"
          width="10.6738"
          height="12.2951"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur" />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.0052 14.2627) rotate(-72.7667) scale(10.062 8.26024)">
          <stop stopColor="#F87A1F" />
          <stop offset="1" stopColor="#F83D1F" />
        </radialGradient>
        <clipPath id="clip0">
          <rect
            width="20.7879"
            height="27"
            fill="white"
            transform="translate(0.611328 0.762695)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StreakFullFlame;
