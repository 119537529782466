import { Container, Card } from 'components/common';
import DynamicQuizAnswerCard from './GuestQuiz/DynamicQuizAnswerCard';
import styled from 'styled-components';
import Navigation from 'components/Navigation';
import { media } from 'styles';
import { ProgressBar } from 'components/common';
import EediWatermark from './GuestQuiz/EediWatermark';
import Confetti from 'react-confetti';

export const DynamicQuizState = {
  Intro: 'intro',
  InProgress: 'inProgress',
  Outro: 'outro'
};

const defaultDynamicQuizOption = {
  theme: null,
  logo: null,
  showWatermark: false,
  showEndConfetti: false
};

export const DynamicQuiz = ({
  dynamicQuizSession = null,
  isLoading = false,
  quizState = undefined,
  Intro = null,
  Outro = null,
  onSubmitAnswer = () => {},
  options = defaultDynamicQuizOption
}) => {
  return (
    <Root
      theme={options.theme}
      isQuestionPage={isLoading || quizState === DynamicQuizState.InProgress}
      isOutro={quizState === DynamicQuizState.Outro}>
      <Navigation
        top
        isHomeLinkDisabled={true}
        logo={options.logo}
        rightComponent={
          <>
            {dynamicQuizSession && !(quizState === DynamicQuizState.Outro) ? (
              <ProgressBar
                style={{ width: '60%' }}
                currentIndex={dynamicQuizSession.position}
                total={10}
                infoText={
                  <InfoText>
                    {`Progress ${dynamicQuizSession.position}/${10}`}
                  </InfoText>
                }
              />
            ) : null}
          </>
        }
      />
      <Main className="main">
        <Container
          className={
            isLoading || quizState === DynamicQuizState.InProgress
              ? 'container-lg'
              : 'container-md'
          }>
          {quizState === DynamicQuizState.Outro && options.showEndConfetti && (
            <Confetti
              style={{ zIndex: '1' }}
              recycle={true}
              height={document.documentElement.scrollHeight}
            />
          )}
          <Wrapper>
            {quizState === DynamicQuizState.Intro && Intro}
            {quizState === DynamicQuizState.Outro && Outro}
            {(isLoading || quizState === DynamicQuizState.InProgress) && (
              <DynamicQuizAnswerCard
                isLoading={isLoading}
                quizSession={dynamicQuizSession}
                submitAnswer={onSubmitAnswer}
              />
            )}
          </Wrapper>
        </Container>
      </Main>
      {options.showWatermark && <EediWatermark />}
    </Root>
  );
};

const Root = styled.div`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .answer-card {
    padding: 0rem;
    min-height: 400px;
    ${media.md`
      padding: 0rem;
    `}
  }
  .answer-form {
    padding: 3rem;
  }
  .question-pane {
    padding: 3rem 2rem;
    border-radius: 0.5rem 0.5rem 0 0;
    height: auto;
    min-height: 150px;
    ${media.md`
    min-height: 400px;
    `}
  }
  .next-button {
    width: 100%;
  }
  .container-lg {
    max-width: 1272px;
  }
  .image {
    margin: -6.7% 0 -4.8% 0;
  }
  .progress-bar {
    background-color: var(--green);
  }
  ${media.md`
    overflow-y: hidden;
    .next-button {
      width: auto;
    }
    .question-pane {
      border-radius: 0.5rem 0 0 0.5rem;
      min-height: 516px;
    }
    .left-col {
      padding-right: 0;
    }
    .right-col {
      padding-left: 0;
    }
  `}

  ${({ theme }) => theme}
`;

const Wrapper = styled(Card)`
  z-index: 999;
  min-height: 350px;
`;

const Main = styled.div`
  z-index: 999;
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  ${media.md`
    padding-top: 7rem;
  `};
`;

const InfoText = styled.div`
  color: #555978;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0.25rem;
`;
