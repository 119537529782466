import { Row, Col, EmojiSpan, Button, Loading } from 'components/common';
import { useEffect, useRef, useState } from 'react';
import { media } from 'styles';
import styled from 'styled-components';
import QuizAnswers from 'components/Quiz/QuizAnswers';
import BotMessage from 'components/Chat/BotMessage';
import { CSSTransition } from 'react-transition-group';

const motivationalMessages = [
  <>
    Every answer you give, helps us create the perfect lessons for you{' '}
    <EmojiSpan style={{ fontSize: '1rem' }} ariaLabel="smile" emoji="😊" />
  </>,
  <>
    Don’t worry if you don’t know the answers yet, we’ll be working on your
    knowledge gaps together{' '}
    <EmojiSpan style={{ fontSize: '1rem' }} ariaLabel="arm" emoji="💪" />
  </>,
  <>
    You’ve made a great start! Keep going!{' '}
    <EmojiSpan
      style={{ fontSize: '1rem' }}
      ariaLabel="running-man"
      emoji="🏃‍♂️"
    />
  </>,
  <>
    Soon you’ll be growing in confidence with every lesson{' '}
    <EmojiSpan style={{ fontSize: '1rem' }} ariaLabel="tree" emoji="🌳" />
  </>,
  <>
    Looks like you’re on a roll! Keep up the great work{' '}
    <EmojiSpan
      style={{ fontSize: '1rem' }}
      ariaLabel="dancing-man"
      emoji="🕺"
    />
  </>,
  <>
    Great perseverance! You’re halfway there{' '}
    <EmojiSpan style={{ fontSize: '1rem' }} ariaLabel="star" emoji="🌟" />
  </>,
  <>
    Don’t worry if you’re finding these difficult now. Each question helps us
    create the perfect learning journey for you{' '}
    <EmojiSpan style={{ fontSize: '1rem' }} ariaLabel="feet" emoji="👣" />
  </>,
  <>
    Just a few more questions. You’ve got this{' '}
    <EmojiSpan style={{ fontSize: '1rem' }} ariaLabel="trophy" emoji="🏆" />
  </>,
  <>
    Nearly there! You’ll soon find out what lessons we have hand-picked for you{' '}
    <EmojiSpan style={{ fontSize: '1rem' }} ariaLabel="sunflower" emoji="🌻" />
  </>,
  <>Outstanding! You are on the last question! Answer to complete the quiz!</>
];

const initialAnswerSubmitState = {
  questionId: -1,
  reason: '',
  confidence: null,
  isGuess: false,
  dateAnswered: ''
};

const DynamicQuizAnswerCard = ({ isLoading, quizSession, submitAnswer }) => {
  const [answerSubmitState, setAnswerSubmitState] = useState(
    initialAnswerSubmitState
  );
  const componentIsMounted = useRef(true);

  useEffect(() => () => (componentIsMounted.current = false), []);

  const onSubmit = async () => {
    await Promise.resolve(
      submitAnswer({
        ...answerSubmitState,
        questionId: quizSession.questions[quizSession.position - 1].questionId,
        dateAnswered: new Date().toISOString()
      })
    );

    if (componentIsMounted) {
      setAnswerSubmitState(initialAnswerSubmitState);
    }
  };

  const onAnswerChange = answer =>
    setAnswerSubmitState({ ...answerSubmitState, answerValue: answer });

  const isShowQuestion =
    !isLoading && quizSession !== undefined && quizSession !== null;

  return (
    <>
      <AnswerCardWrapper className="answer-card">
        <Row className={'d-flex'}>
          <Col md="7" className="left-col">
            <QuestionPane className="question-pane">
              {(isLoading || !quizSession) && (
                <LoadingWrapper>
                  <h4>Loading Question</h4>
                  <Loading color="#fff" />
                </LoadingWrapper>
              )}
              <CSSTransition
                classNames="image-transition"
                in={isShowQuestion}
                timeout={300}
                unmountOnExit>
                <QuestionImageWrapper className="question-wrapper">
                  {/* If you decided to add <Image /> try fixing the jumping content because of the <Loading /> scale. */}
                  <img
                    className="image"
                    src={
                      quizSession?.questions[quizSession.position - 1]?.imageURL
                    }
                    alt="test"
                  />
                </QuestionImageWrapper>
              </CSSTransition>
            </QuestionPane>
          </Col>
          <Col md="5" className="right-col">
            <AnswerForm className="answer-form">
              <h3>Choose your answer below:</h3>
              <QuizAnswers
                onAnswerChange={val =>
                  onAnswerChange(val, quizSession?.position)
                }
                answerValue={answerSubmitState?.answerValue}
                questionSubmitted={false}
                isCorrectAnswer={null}
                disabled={isLoading || !quizSession}
              />
              <BotWrapper>
                <CSSTransition
                  classNames="image-transition"
                  in={isShowQuestion}
                  timeout={300}
                  unmountOnExit>
                  <StyledBotMessage
                    size="small"
                    message={
                      <p>{motivationalMessages[quizSession?.position - 1]}</p>
                    }
                  />
                </CSSTransition>
              </BotWrapper>
              <ButtonWrapper>
                <StyledButton
                  color="primary"
                  loading={isLoading || !quizSession}
                  className="next-button"
                  onClick={onSubmit}
                  disabled={!answerSubmitState.answerValue}>
                  {quizSession?.position === 10
                    ? 'Finish Quiz'
                    : 'Next Question'}
                </StyledButton>
              </ButtonWrapper>
            </AnswerForm>
          </Col>
        </Row>
      </AnswerCardWrapper>
    </>
  );
};

const ButtonWrapper = styled.div``;

const BotWrapper = styled.div`
  flex-grow: 1;
  margin: 30px 0 1rem;
`;

const StyledBotMessage = styled(BotMessage)`
  flex-grow: 1;
  margin: 0 0 1rem;
`;

const AnswerCardWrapper = styled.div`
  overflow: hidden;
  .answer-card {
    padding: 0rem;
    min-height: 400px;
    ${media.md`
      padding: 0rem;
    `}
  }
  .answer-form {
    padding: 3rem;
  }
  ${media.md`
    padding: 3rem;
  `}
  .image-transition-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .image-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 600ms, transform 600ms;
  }
  .image-transition-exit {
    opacity: 1;
  }
  .image-transition-exit-active {
    opacity: 0;
    transform: scale(1);
    transition: opacity 600ms, transform 600ms;
  }
`;

const QuestionPane = styled.div`
  background: var(--secondary);
`;

const LoadingWrapper = styled.div`
  font-weight: 700;
  text-align: center;

  ${media.md`
    position: absolute;
    top: 200px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  `}
`;

const AnswerForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin: 0 auto;
  padding: 2rem;
`;

const QuestionImageWrapper = styled.div`
  .image {
    width: 100%;
    height: auto;
  }
  border-radius: 0.5rem;
  border: 1px solid var(--gray-lighter);
  overflow: hidden;
`;

const StyledButton = styled(Button)`
  flex-grow: 1;
  float: right;
`;

export default DynamicQuizAnswerCard;
