import { useSelector } from 'react-redux';

export default function useParentNeedsOnboarding() {
  const { hasLoadedChildren, subscription } = useSelector(
    ({ user: { subscription }, school: { allChildrenLoaded } }) => ({
      hasLoadedChildren: allChildrenLoaded,
      subscription
    })
  );

  if (!hasLoadedChildren) return null;

  return subscription?.stripeCustomerId === null;
}
