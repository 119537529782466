export const SetTeamsActionType = 'SET_TEAMS';
export const setTeams = teams => ({
  type: SetTeamsActionType,
  teams
});

export const SetTeamStudentActionType = 'SET_TEAM_STUDENT';
export const setTeamStudent = teamStudent => ({
  type: SetTeamStudentActionType,
  teamStudent
});
