import { Loading } from 'components/common';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import useGoogleauth from './useGoogleAuth';

// https://www.dolthub.com/blog/2022-05-04-google-signin-migration/
const GoogleLoginButton = ({
  callback = r => console.log(r),
  disabled = false
}) => {
  const buttonRef = useRef(null);
  const [state, setState] = useState({ error: null });
  const { isLoaded } = useGoogleauth({ callback });

  useEffect(() => {
    try {
      if (!window?.google || !buttonRef.current || !isLoaded) {
        return;
      }
      window.google.accounts.id.renderButton(buttonRef.current, {
        theme: 'outline',
        size: 'large',
        type: 'standard',
        shape: 'rectangular',
        text: 'Sign in with Google',
        logo_alignment: 'center',
        width: 400,
        longtitle: true
      });
    } catch (e) {
      console.error(e);
      setState(() => ({ error: e }));
    }
  }, [buttonRef, isLoaded, state]);

  return (
    <StyledLoginButton disabled={disabled}>
      {(!window?.google || !buttonRef.current || !isLoaded) && <Loading />}
      <div ref={buttonRef} />
    </StyledLoginButton>
  );
};

const StyledLoginButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  ${props => props.disabled && 'opacity: 0.5; cursor: none;'}
`;

export default GoogleLoginButton;
