import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { Nav } from 'components/common';
import styled from 'styled-components';
import { media } from 'styles';
import analytics from 'analytics';
import { Link } from 'react-router-dom';
import matchPathname from 'helpers/matchPathname';

const NavbarLinks = ({ navigationOptions }) => {
  const { pathname } = useLocation();

  const isPathActive = useCallback(
    link => {
      const matchArg = matchPathname(['/:arg'], pathname, {
        exact: true
      });

      const isDashboard =
        pathname === '/' ||
        (matchArg?.isExact && parseInt(matchArg.params.arg));

      return (
        (isDashboard && link.path === null) || pathname.includes(link.path)
      );
    },
    [pathname]
  );

  return (
    <StyledNav>
      <Nav className="w-100">
        {navigationOptions.map(link => {
          const { label, path, icon, onClick } = link;
          const pathSection = path === null ? '' : `/${path}`;
          const to = pathSection;
          const isActive = isPathActive(link);

          return (
            <StyledNavItem key={label}>
              <StyledNavLink
                to={path ? to : '/'}
                id={label}
                active={isActive}
                onClick={() => {
                  analytics.track('Navbar Clicked', {
                    category: 'Navigation',
                    label: label
                  });
                  typeof onClick === 'function' && onClick();
                }}
                tag={Link}>
                {icon}
                <span>{label}</span>
              </StyledNavLink>
            </StyledNavItem>
          );
        })}
      </Nav>
    </StyledNav>
  );
};

const StyledNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--white);
  display: flex;
  z-index: 999;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.1);

  ${media.md`
    box-shadow: none;
    position: relative;
  `};
`;

const StyledNavLink = styled(Nav.Link)`
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: normal;
  color: var(--gray);
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 0.75rem 1rem;
  border-top: 0.25rem solid transparent;
  height: 5rem;

  svg {
    color: var(--gray-dark);
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.125rem;
  }

  &.active {
    color: var(--primary);
    font-weight: 500;
    border-top: 0.25rem solid var(--primary);
    svg {
      color: var(--primary);
    }
  }
  &:hover {
    border-color: var(--highlight-color);
  }

  ${media.md`
  width: auto;
  border-bottom: 0.25rem solid transparent;
  border-top: 0;
  &.active {
    border-top: 0;
    border-bottom: 0.25rem solid var(--primary);
  }
  `}
`;

const StyledNavItem = styled(Nav.Item)`
  display: flex;
  flex: 1;
  align-items: center;
  ${media.md`
    flex: 0;
  `};
`;

export default NavbarLinks;
