import { API_URL, FUNCTIONS_API_URL } from 'config/settings';
import { cache } from 'store';

const getFunctionsApiUri = uri => getUri(FUNCTIONS_API_URL, `/api/${uri}`);

const getApiUri = uri => getUri(API_URL, uri);

const getUri = (endpoint = null, uri = null) => {
  const formattedUri = `/${uri}`.replace(/\/+/g, '/');
  return `${endpoint}${formattedUri}`;
};

const imageExists = url => {
  return new Promise(resolve => {
    let img = new Image();
    img.addEventListener('load', () => resolve(true));
    img.addEventListener('error', () => resolve(false));
    img.src = url;
  });
};

const videoExists = async videoUrl => {
  return await cache.getOrAddAsync({
    key: `video-exists-${videoUrl}`,
    callback: async () => {
      try {
        const response = await fetch(videoUrl, { method: 'HEAD' });
        return response.ok;
      } catch (e) {
        return false;
      }
    },
    ttlMinutes: 60 * 60 * 24,
    isUseSessionStorage: true,
    typename: 'videoExists'
  });
};

export { getApiUri, getFunctionsApiUri, getUri, imageExists, videoExists };
