import { Logo, Navbar, Nav, Avatar, Button } from 'components/common';
import styled from 'styled-components';
import NavbarLinks from './NavbarLinks';
import { useSelector } from 'react-redux';
import BillingErrorBanner from 'components/Billing/BillingErrorBanner';
import { hasValidSubscription } from 'helpers/validateSubscription';
import { EediPlusLogo } from 'components/common/EediPlusLogo';
import LoginTogetherComponent from 'components/ParentDashboard/LoginTogetherComponent';
import { Link, useNavigate } from 'react-router-dom';
import useParentNeedsOnboarding from 'hooks/useParentNeedsOnboarding';

const StatsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20">
    <path
      fill="currentColor"
      d="M14.094 8.63L12.937 8.1a.545.545 0 01-.233-.2.54.54 0 01-.087-.294.54.54 0 01.087-.293.545.545 0 01.233-.2l1.157-.532.535-1.14a.544.544 0 01.201-.23.548.548 0 01.591 0 .543.543 0 01.201.23l.535 1.15 1.146.533a.545.545 0 01.233.2.54.54 0 01.087.293.54.54 0 01-.087.294.545.545 0 01-.233.2l-1.157.532-.535 1.139a.544.544 0 01-.2.231.548.548 0 01-.591 0 .543.543 0 01-.202-.231l-.524-1.15zm-10.49 3.32l.535-1.15 1.157-.531a.545.545 0 00.232-.2.54.54 0 000-.587.545.545 0 00-.232-.2L4.139 8.75l-.535-1.14a.535.535 0 00-.198-.23.54.54 0 00-.587 0 .535.535 0 00-.198.23l-.535 1.15-1.157.532a.545.545 0 00-.232.2.54.54 0 000 .587.545.545 0 00.232.2l1.157.532.535 1.14a.539.539 0 00.983 0zm4.923-5.435l.688-1.52 1.528-.683a.544.544 0 00.233-.2.54.54 0 000-.587.544.544 0 00-.233-.2l-1.528-.683-.688-1.52a.543.543 0 00-.201-.23.548.548 0 00-.59 0 .543.543 0 00-.202.23l-.688 1.52-1.528.683a.545.545 0 00-.233.2.54.54 0 000 .587.545.545 0 00.233.2l1.528.684.688 1.519c.185.423.797.423.993 0zm14.606.304a1.093 1.093 0 00-.368-.24 1.098 1.098 0 00-.861.023 1.092 1.092 0 00-.354.26l-6.975 7.79-3.592-3.57a1.093 1.093 0 00-1.539 0l-6.593 6.564a1.154 1.154 0 000 1.628c.447.445 1.19.445 1.637 0l5.731-5.707 3.548 3.526a1.087 1.087 0 001.583-.043l7.826-8.756a1.071 1.071 0 00-.043-1.475z"></path>
  </svg>
);

const ActivityIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 21">
    <path
      fill="currentColor"
      d="M5.498.806L2.585 3.704l-1.137-1.13L.17 3.843l1.777 1.765.64.606.639-.607 3.552-3.53L5.498.804v.001zM9.69 2.324V4.09h11.546V2.324H9.69zM5.498 7.868l-2.913 2.899-1.137-1.134L.17 10.904l1.777 1.766.64.605.639-.606 3.552-3.531-1.279-1.271zM9.69 9.386v1.765h11.546V9.386H9.69zM5.498 14.93l-2.913 2.898-1.137-1.13-1.279 1.27 1.777 1.765.64.605.639-.606L6.777 16.2l-1.279-1.271zm4.192 1.519v1.765h11.546v-1.765H9.69z"></path>
  </svg>
);

const AccountIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 23">
    <path
      fill="currentColor"
      d="M12.833 22.112H8.93a1.076 1.076 0 01-.672-.235 1.064 1.064 0 01-.375-.602l-.436-2.007a8.593 8.593 0 01-1.646-.946l-1.969.624a1.078 1.078 0 01-.713-.022 1.07 1.07 0 01-.54-.46L.623 15.105a1.066 1.066 0 01-.129-.696 1.07 1.07 0 01.336-.624l1.527-1.385a8.577 8.577 0 010-1.887L.831 9.132a1.07 1.07 0 01-.336-.625c-.038-.24.008-.485.129-.696l1.95-3.36a1.07 1.07 0 01.542-.46 1.078 1.078 0 01.713-.022l1.969.624a8.896 8.896 0 01.815-.533 9.043 9.043 0 01.835-.411l.437-2.005c.052-.238.184-.45.374-.603a1.076 1.076 0 01.672-.236h3.902a1.075 1.075 0 011.046.839l.441 2.006a8.583 8.583 0 011.645.945l1.97-.624a1.078 1.078 0 01.712.022c.228.088.419.25.54.461l1.952 3.359a1.068 1.068 0 01-.207 1.32l-1.528 1.385a8.584 8.584 0 010 1.888l1.528 1.385c.37.338.455.886.207 1.32l-1.951 3.359a1.07 1.07 0 01-.542.46 1.078 1.078 0 01-.712.022l-1.97-.623a8.57 8.57 0 01-1.644.944l-.441 2.002a1.065 1.065 0 01-.375.601 1.076 1.076 0 01-.671.236zm-6.646-6.148l.879.64c.198.144.404.277.619.399.201.116.407.22.62.315l1 .436.49 2.227h2.176l.49-2.228 1-.436a6.633 6.633 0 001.236-.71l.879-.639 2.189.693 1.087-1.873-1.696-1.538.12-1.078a6.281 6.281 0 000-1.419l-.12-1.078 1.698-1.54-1.09-1.874-2.187.692-.88-.639a6.684 6.684 0 00-1.236-.715l-1-.436-.49-2.227H9.795l-.493 2.228-.997.435a6.452 6.452 0 00-1.236.71l-.88.639-2.186-.693-1.09 1.88L4.608 9.67l-.12 1.079a6.283 6.283 0 000 1.42l.12 1.077-1.697 1.538L4 16.656l2.187-.692zm4.69-.244a4.301 4.301 0 01-3.031-1.248 4.248 4.248 0 01-1.256-3.013c0-1.13.452-2.214 1.256-3.014a4.3 4.3 0 013.031-1.248c1.138 0 2.228.45 3.032 1.248a4.248 4.248 0 011.256 3.014 4.249 4.249 0 01-1.256 3.013 4.301 4.301 0 01-3.032 1.248zm0-6.392a2.153 2.153 0 00-1.18.353 2.134 2.134 0 00-.79.942 2.118 2.118 0 00-.136 1.217c.075.41.27.79.56 1.092a2.147 2.147 0 001.072.604c.41.093.837.064 1.23-.083a2.141 2.141 0 00.981-.742 2.122 2.122 0 00.407-1.157v.427-.523c0-.565-.226-1.107-.628-1.506a2.15 2.15 0 00-1.515-.624z"></path>
  </svg>
);

const TutorsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="14"
    fill="none"
    viewBox="0 0 21 14">
    <path
      fill="currentColor"
      d="M14.136 5.857a2.716 2.716 0 002.719-2.727A2.716 2.716 0 0014.136.402 2.724 2.724 0 0011.41 3.13a2.724 2.724 0 002.727 2.727zm-7.272 0A2.716 2.716 0 009.582 3.13c0-1.51-1.21-2.728-2.718-2.728A2.724 2.724 0 004.136 3.13a2.724 2.724 0 002.728 2.727zm0 1.818C4.745 7.675.5 8.74.5 10.857v1.364c0 .5.41.909.91.909h10.908c.5 0 .91-.41.91-.91v-1.363c0-2.118-4.246-3.182-6.364-3.182zm7.272 0c-.263 0-.563.018-.881.046.018.009.027.027.036.036 1.036.754 1.755 1.764 1.755 3.1v1.364c0 .318-.064.627-.164.909h4.709c.5 0 .909-.41.909-.91v-1.363c0-2.118-4.245-3.182-6.364-3.182z"></path>
  </svg>
);

const HelpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 23">
    <path
      fill="currentColor"
      d="M11.792 5.066a4.477 4.477 0 00-3.09 1.23 4.11 4.11 0 00-1.283 2.961h2.224c0-1.136.964-2.06 2.15-2.06 1.184 0 2.15.924 2.15 2.06 0 .638-.535 1.1-1.352 1.733a10.132 10.132 0 00-.769.638c-1.109 1.062-1.141 2.19-1.141 2.316v.71h2.223l-.001-.674c.001-.017.037-.411.49-.845.167-.16.377-.32.595-.488.866-.672 2.176-1.687 2.176-3.39a4.108 4.108 0 00-1.281-2.962 4.475 4.475 0 00-3.09-1.229zM10.681 15.72h2.223v2.13h-2.223v-2.13z"></path>
    <path
      fill="currentColor"
      d="M11.792.805C5.662.805.676 5.585.676 11.46s4.987 10.653 11.116 10.653 11.116-4.78 11.116-10.653C22.908 5.583 17.92.804 11.792.804zm0 19.176c-4.903 0-8.893-3.823-8.893-8.523s3.99-8.522 8.893-8.522 8.893 3.823 8.893 8.522-3.99 8.523-8.893 8.523z"></path>
  </svg>
);

const MotivationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 21">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.33 16.035c1.51 2.076 3.422 3.405 5.739 3.988 2.316-.582 4.228-1.911 5.737-3.987 1.508-2.075 2.263-4.38 2.263-6.913v-6.1l-8-3-8 3v6.1c0 2.533.753 4.837 2.262 6.912zM7.07 5.023h1.687v9H7.07v-9zm9 1.97V8.68h-5.063V6.992h5.063zm0 3.374v1.688h-5.063v-1.688h5.063z"
      clipRule="evenodd"></path>
  </svg>
);

const Menu = [
  {
    label: 'Activity',
    path: null,
    icon: <ActivityIcon />
  },
  {
    label: 'Stats',
    path: 'stats',
    icon: <StatsIcon />
  },
  {
    label: 'Tutors',
    path: 'tutors',
    icon: <TutorsIcon />
  },
  {
    label: 'Motivation',
    path: 'motivation',
    icon: <MotivationIcon />
  },
  {
    label: 'Account',
    path: 'account',
    icon: <AccountIcon />
  },
  {
    label: 'Help',
    onClick: () => {
      window.Intercom('show');
    },
    icon: <HelpIcon />
  }
];

const ParentNavbar = () => {
  const { child } = useSelector(store => store.school);
  const hasEediPlus = hasValidSubscription();
  const isOnboardingRequired = useParentNeedsOnboarding();
  const navigate = useNavigate();
  return (
    <>
      <BillingErrorBanner />
      <Navbar expand="md">
        <Nav.Left>
          <Nav.Brand to="/">
            <Logo />
            {hasEediPlus && <EediPlusLogo className="ml-3" />}
          </Nav.Brand>
        </Nav.Left>
        <Nav.Center>
          {!isOnboardingRequired && <NavbarLinks navigationOptions={Menu} />}
        </Nav.Center>
        <Nav.Right>
          {child && (
            <>
              <StyledChild to="/children">
                <Avatar userId={child.childId} className="mr-2" />
                {child.firstName}
              </StyledChild>
              <LoginTogetherComponent child={child} />
            </>
          )}
          {!child && (
            <Button outline onClick={() => navigate('/child/create')}>
              Setup child account
            </Button>
          )}
        </Nav.Right>
      </Navbar>
    </>
  );
};

const StyledChild = styled(Link)`
  display: flex;
  margin-right: 1rem;
  color: var(--black);
  align-items: center;
`;

export default ParentNavbar;
