import tracking from 'analytics/tracking';
import InviteParentModal from 'components/Child/InviteParentModal';
import { createContext, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export const ParentInvitationContext = createContext({
  invitationPrecheck: () => {},
  openParentInvitationModal: () => {}
});

export const ParentInvitationProvider = ({ children }) => {
  const { parentInvitationStatus } = useSelector(store => store.user);

  const [isInviteParentModalOpen, setIsInviteParentModalOpen] = useState(false);

  const openParentInviteModalAction = useCallback(async isOpen => {
    setIsInviteParentModalOpen(isOpen);
    isOpen &&
      (await tracking.track(tracking.eventNames.ParentInviteStarted, true));
  }, []);

  const invitationPrecheck = useCallback(
    async action => {
      if (parentInvitationStatus === 'Accepted') {
        action();
        return;
      }
      await openParentInviteModalAction(true);
    },
    [openParentInviteModalAction, parentInvitationStatus]
  );

  return (
    <ParentInvitationContext.Provider
      value={{
        invitationPrecheck,
        openParentInvitationModal: () => openParentInviteModalAction(true)
      }}>
      <InviteParentModal
        isOpen={isInviteParentModalOpen}
        onComplete={() => setIsInviteParentModalOpen(false)}
      />
      {children}
    </ParentInvitationContext.Provider>
  );
};
