import { colors } from 'styles';

const colorList = [
  'blue',
  'indigo',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'cyan',
  'bronze',
  'lavender',
  'darkBlue'
];

const hashStr = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return Math.abs(hash);
};

// Grab a deterministically "random" colour based for a given input string
const stringToColor = string => {
  if (!string) {
    return 'gray4';
  }
  const hash = hashStr(string);
  const index = hash % colorList.length;
  return colors[colorList[index]];
};

export default stringToColor;
