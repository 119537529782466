import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FormFeedback } from 'reactstrap';
import { SelectableTile } from './SelectableTile';

const TileInputArray = ({
  control,
  id,
  name,
  multiSelect = true,
  error = undefined,
  rules = undefined,
  disabled = false,
  isDesktopView = false,
  options = [
    { label: 'Option 1', value: 'op1', isSelected: true },
    { label: 'Option 2', value: 'op2', isSelected: false },
    { label: 'Option 3', value: 'op3', isSelected: false }
  ],
  renderItem: Item = SelectableTile,
  ...props
}) => {
  const defaultValue = useMemo(
    () => options.filter(o => o.isSelected).map(o => o.value),
    [options]
  );

  const calculateNewValue = useCallback(
    (allValues, value) => {
      if (!multiSelect) {
        if (allValues[0] === value) {
          return [];
        } else {
          return [value];
        }
      } else {
        if (!allValues.includes(value)) {
          return allValues.concat(value);
        } else if (allValues.includes(value)) {
          return allValues.filter(v => v !== value);
        }

        return allValues;
      }
    },
    [multiSelect]
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onChange, onBlur, value }) => (
        <>
          {options.map(o => {
            const uniqueId = `${name}-${o.value}`;

            return (
              <Item
                key={uniqueId}
                id={uniqueId}
                className="tile"
                option={o}
                isSelected={value.includes(o.value)}
                onSelect={() => onChange(calculateNewValue(value, o.value))}
                onBlur={onBlur}
                isDesktopView={isDesktopView}
                disabled={disabled || o.disabled}
              />
            );
          })}
          {error && <FormFeedback className="ml-2">{error}</FormFeedback>}
        </>
      )}
      rules={rules}
    />
  );
};
export default TileInputArray;
