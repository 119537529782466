import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import NotificationsDropdown from './NotificationsDropdown';
import { Dropdown } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'api';
import NotificationsIcon from 'assets/icons/NotificationsIcon';
import tracking from 'analytics/tracking';
import analytics from 'analytics';
import { useSignalROn } from 'hooks/useSignalROn';
import NewNotification from 'assets/sounds/new_notification.wav';
import { parseObjectKeys } from 'helpers/objectHelpers';
import NotificationModal from './NotificationModal';
import { asyncForEach } from 'helpers/asyncHelpers';
import {
  AllDashboardNotificationTypes,
  nonDismissibleTypes
} from './notificationHelpers';
import { setNotifications } from 'actions/user';
import { cache } from 'store';
import types from 'cache/types';

const NotificationsButton = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState();
  const [loading, setLoading] = useState(false);

  const { userId, schoolId, schoolIds, notifications } = useSelector(s => ({
    userId: s.user.userId,
    schoolId: s.school.schoolId,
    schoolIds: s.user?.students?.map(s => s.schoolId) ?? [],
    notifications: s.user.notifications
  }));

  const fetchNotifications = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    let notifications = { data: [], total: 0 };
    try {
      let schoolIdsToFetch = schoolIds;
      if (!schoolIds?.length) {
        schoolIdsToFetch = [schoolId];
      }
      notifications = await Api.getNotifications(
        userId,
        'DashboardMessage',
        schoolId,
        null,
        1,
        999,
        '',
        schoolIdsToFetch
      );
    } catch (e) {
      console.error(e);
    }
    dispatch(setNotifications(notifications));
    cache.bustTypeCache({
      typenames: [types.userRoleContext.typename]
    });
    setLoading(false);
  }, [dispatch, loading, schoolId, schoolIds, userId]);

  useSignalROn('NotificationRecieved', async notification => {
    try {
      if (typeof notification !== 'object') return;

      notification = parseObjectKeys(notification);

      if (notification.userId !== userId) {
        fetchNotifications();
        return;
      }

      setLoading(true);
      dispatch(
        setNotifications({
          total: notifications.total + 1,
          data: [notification, ...notifications.data]
        })
      );
      cache.bustTypeCache({
        typenames: [types.userRoleContext.typename]
      });
      new Audio(NewNotification).play();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  });

  useEffect(() => {
    const runEffect = async () => {
      if (!notifications && userId && !loading) {
        await fetchNotifications();
      }
    };
    runEffect();
  }, [fetchNotifications, loading, notifications, userId]);

  const handleDismiss = useCallback(
    async notificationId => {
      if (!notificationId) return;

      try {
        analytics.track('Notification Dismissed', {
          category: 'Notifications'
        });
        await Api.dismissNotification(notificationId);
        cache.bustTypeCache({
          typenames: [types.userRoleContext.typename]
        });
        fetchNotifications();
      } catch (e) {
        console.error(e);
      }
    },
    [fetchNotifications]
  );

  const handleModalDismiss = useCallback(async notificationId => {
    if (!notificationId) return;

    try {
      analytics.track('Notification Modal Dismissed', {
        category: 'Notifications'
      });
      await Api.dismissNotificationModal(notificationId);
      cache.bustTypeCache({
        typenames: [types.userRoleContext.typename]
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const trackClick = type => {
    tracking.track('Interacted With A Notification', {
      type
    });
  };

  const toggle = useCallback(() => {
    if (!isOpen) {
      tracking.track('Clicked Notifications Dropdown');

      const data = notifications?.data || [];
      const dismissibleData = data.filter(
        n =>
          !n.dateDismissed &&
          nonDismissibleTypes.indexOf(n.notificationType) !== -1
      );

      if (!!dismissibleData.length) {
        asyncForEach(dismissibleData, async n => {
          await handleDismiss(n.notificationId);
        });
        dispatch(
          setNotifications(() => ({
            total: 0,
            data: data.map(n => ({
              ...n,
              dateDismissed: dismissibleData.find(
                d => d.notificationId === n.notificationId
              )
                ? new Date().toISOString()
                : n.dateDismissed
            }))
          }))
        );
      }
    }
    setIsOpen(!isOpen);
  }, [dispatch, handleDismiss, isOpen, notifications?.data]);

  const data = (notifications?.data || [])
    .filter(n => AllDashboardNotificationTypes.includes(n.notificationType))
    .sort((a, b) => b.notificationId - a.notificationId);

  const modalNotification = data.find(
    notification =>
      !notification?.dateDismissed && notification?.data?.isShowInModal
  );

  const unreadCount = data.filter(
    n =>
      !n.dateDismissed &&
      AllDashboardNotificationTypes.includes(n.notificationType)
  ).length;

  return (
    <Root>
      <Dropdown
        id="notifications"
        key={notifications?.data?.length || -1}
        isOpen={isOpen}
        toggle={toggle}>
        <StyledDropdownToggle active={isOpen} outline>
          {unreadCount > 0 && <UnreadIndicator>{unreadCount}</UnreadIndicator>}
          <NotificationsIcon size="21" />
        </StyledDropdownToggle>
        <StyledDropdownMenu right>
          {isOpen && (
            <NotificationsDropdown
              data={data}
              onDismiss={handleDismiss}
              onClick={trackClick}
              loading={loading}
            />
          )}
        </StyledDropdownMenu>
      </Dropdown>
      {!!modalNotification ? (
        <NotificationModal
          notification={modalNotification}
          onDismiss={handleDismiss}
          onDismissModal={handleModalDismiss}
        />
      ) : null}
    </Root>
  );
};

const Root = styled.div`
  z-index: 999;
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  border-radius: 99rem;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  width: 500px;
  max-height: 80vh;
  overflow-y: scroll;
  border: 1px solid var(--gray-lighter);
  padding: 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  z-index: 1000;
`;

const UnreadIndicator = styled.span`
  background-color: var(--red);
  height: 1rem;
  min-width: 1rem;
  padding: 0 0.125rem;
  border-radius: 1rem;
  position: absolute;
  top: -0.0625rem;
  right: -0.25rem;
  color: var(--white);
  font-size: 0.75rem;
  line-height: 1rem;
`;

export default NotificationsButton;
