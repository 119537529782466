import { useState } from 'react';
import { Button, Row, Col, EediPlusLogo } from 'components/common';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useEediPlusPricingInfo } from 'hooks/useEediPlusPricingInfo';
import { penniesToPoundsGbp } from 'constants/plans';

const steps = [
  {
    title: 'Unlimited, Human, 1-to-1 tuition',
    subtitle: (
      <p>
        UK based, DBS-checked.
        <br />
        Available 9am-8pm, 7 Days a week
      </p>
    ),
    color: 'var(--secondary)',
    image: 'https://images.diagnosticquestions.com/eedi/images/plus/tutors.png'
  },
  {
    title: 'Plus members Earn more coins',
    subtitle: (
      <p>
        {' '}
        Double coins and higher daily limit means you can purchase more items
        from our shop!
      </p>
    ),
    color: 'var(--yellow-lighter)',
    image:
      'https://images.diagnosticquestions.com/eedi/images/plus/double-coins_2.png'
  }
];

export const FeatureFocusModalPage = ({ onJoinEediPlus = () => {} }) => {
  const userRole = useSelector(({ user: { userRole } }) => userRole);
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const totalSteps = steps.length;
  const currentStep = steps[currentStepIdx];

  const { priceOptions } = useEediPlusPricingInfo();
  const lowestMonthlyCost = priceOptions.reduce(
    (prev, curr) =>
      curr.equivalentMonthlyPrice < prev ? curr.equivalentMonthlyPrice : prev,
    Number.MAX_SAFE_INTEGER
  );

  return (
    <Row className="align-items-center">
      <Col sm="5">
        <div className="p-2">
          <h1 className="display-4 mb-4">
            Eedi{' '}
            <EediPlusLogo
              style={{
                fontSize: '1.125rem'
              }}
              shinePeriodMs={[1000, 5000]}
            />
          </h1>
          {userRole === 'Parent' ? (
            <>
              <h2>
                Our live tutors can help students to figure things out in
                minutes.
              </h2>
              <p className="text-lg mb-4">
                Provide all the help your child needs, and double coins, all
                from £{penniesToPoundsGbp(lowestMonthlyCost)} / month
              </p>
            </>
          ) : (
            <>
              <h2>
                Stuck? Our live tutors can help you figure things out in minutes
              </h2>
              <p className="text-lg mb-4">
                Get all the help you need, and double coins, all from £
                {penniesToPoundsGbp(lowestMonthlyCost)} / month.
              </p>
            </>
          )}

          <Button
            color="primary"
            className="mb-3"
            block
            size="large"
            onClick={onJoinEediPlus}>
            Sign up to Eedi Plus
          </Button>
        </div>
      </Col>
      <Col sm="7">
        <Carousel>
          <CarouselItem
            style={{
              background: `linear-gradient(to bottom, ${currentStep.color} 0%, var(--white) 100%)`
            }}>
            <CarouselImage>
              <img src={currentStep.image} alt={currentStep.title} />
            </CarouselImage>
            <CarouselCaption>
              <h2>{currentStep.title}</h2>
              <p>{currentStep.subtitle}</p>
            </CarouselCaption>
          </CarouselItem>
          <CarouselDots>
            {Array.from(Array(totalSteps), (e, i) => (
              <Dot
                key={i}
                isCurrent={i === currentStepIdx}
                onClick={() => setCurrentStepIdx(i)}
              />
            ))}
          </CarouselDots>
        </Carousel>
      </Col>
    </Row>
  );
};

const Carousel = styled.div`
  position: relative;
`;

const CarouselItem = styled.div`
  position: relative;
  border-radius: 0.5rem;
  height: 34rem;
`;

const CarouselImage = styled.div`
  padding: 2rem;
  text-align: center;
  img {
    height: 20rem;
  }
`;

const CarouselCaption = styled.div`
  position: absolute;
  width: 100%;
  bottom: 2rem;
  text-align: center;
  padding: 2rem;
`;

const CarouselDots = styled.ul`
  position: absolute;
  width: 100%;
  bottom: 1rem;
  text-align: center;
`;

const Dot = styled.li`
  height: 0.75rem;
  width: 0.75rem;
  margin: 0 0.25rem;
  background-color: ${props =>
    props.isCurrent ? 'var(--primary)' : 'var(--gray)'};
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
`;
