import { createRoot } from 'react-dom/client';
// Compile these into one css file
import 'styles/index.css';
// Import polyfill for fetch on IE11
import 'whatwg-fetch';
import 'react-vis/dist/style.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

// import smoothscroll polyfill
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
