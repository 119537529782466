import { Api } from 'api';
import GoogleLoginButton from 'components/Auth/GoogleLoginButton';
import { Button, Container, Form, toaster } from 'components/common';
import TextInput from 'components/common/FormInputs/TextInput';
import useApiValidatedForm from 'hooks/useApiValidatedForm';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const ParentLoginModalPage = ({ onSuccess = () => {}, ...props }) => {
  const validParentId = useSelector(({ user: { parentId } }) => parentId);

  const {
    control,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting }
  } = useApiValidatedForm({ mode: 'onChange' });

  const onLogin = useCallback(
    async ({ email, password }) => {
      try {
        const token = await Api.signIn(email.trim(), password);
        if (token) {
          // Validate the token
          const encodedBody = token.split('.')[1];
          const decodedBody = Buffer.from(encodedBody, 'base64').toString(
            'utf-8'
          );
          const jwtBody = JSON.parse(decodedBody);
          const userIdClaim = +jwtBody.UserId;

          if (userIdClaim !== validParentId) {
            throw new Error(`${email.trim()} is not this users parent.`);
          }

          onSuccess(token);
        } else {
          throw new Error('Unable to log in. Please try again.');
        }
      } catch (e) {
        console.error(e);
        // Reset form and prompt user to try again
        toaster.danger(e.message);
        reset();
      }
    },
    [onSuccess, reset, validParentId]
  );

  const onGoogleLogin = useCallback(
    async response => {
      try {
        const googleToken = response.getAuthResponse().id_token;
        const token = await Api.signInWithGoogle(googleToken);
        if (token) {
          onSuccess(token);
        } else {
          throw new Error('Unable to log in. Please try again.');
        }
      } catch (e) {
        console.error(e);
        // Reset form and prompt user to try again
        toaster.danger(e.message);
        reset();
      }
    },
    [onSuccess, reset]
  );

  return (
    <Container className="container-xs">
      <div style={{ padding: '4rem' }}>
        <h1 className="text-center mb-5">Sign into your parent hub</h1>
        <form onSubmit={handleSubmit(onLogin)}>
          <Form.Group>
            <TextInput
              control={control}
              name="email"
              label="Username"
              placeholder=""
              disabled={isSubmitting}
              rules={{
                required: 'Please enter your username or email'
              }}
              error={errors.email?.message}
            />
          </Form.Group>
          <Form.Group>
            <TextInput
              control={control}
              name="password"
              type="password"
              disabled={isSubmitting}
              label={
                <div className="d-flex" style={{ width: '22rem' }}>
                  <div>Password</div>
                  <Link
                    to={'/login/forgot-password'}
                    target="_blank"
                    rel="noreferrer noopener"
                    tabIndex="-1"
                    className="ml-auto">
                    Forgot password
                  </Link>
                </div>
              }
              rules={{ required: 'Please enter your password' }}
            />
          </Form.Group>

          <Button
            className="mt-3"
            type="submit"
            color="primary"
            disabled={isSubmitting}
            block>
            Log in
          </Button>

          <span className="text-muted my-3 d-block text-center">or</span>
          <GoogleLoginButton callback={onGoogleLogin} disabled={isSubmitting} />
        </form>
      </div>
    </Container>
  );
};
