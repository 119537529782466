import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Loading, Button, toaster } from 'components/common';
import React, { useCallback } from 'react';
import ActivityCard from './ActivityCard';
import { useEffect } from 'react';
import { assignmentGroups } from '../helpers/learningActivityHelpers';
import CachedApi from 'cache/CachedApi';
import useStateReducer from 'hooks/useCustomReducer';

const ActivityContainer = ({
  userId,
  count,
  take,
  isCountSet,
  countfunction,
  emptyStateComponent,
  assignmentGroup,
  activityType,
  topicPathwayQuizGroup,
  ...props
}) => {
  const { state: pageState, setState: setPageState } = useStateReducer({
    stateName: 'activity',
    initialState: { isLoading: false, skip: 0, insights: [], total: 0 }
  });

  const fetchAssignments = useCallback(
    async (
      skip = 0,
      assignmentGroup = assignmentGroups.Active,
      activityType = 'All'
    ) => {
      if (pageState.isLoading) return;

      if (!userId || !take || !count || !activityType) {
        setPageState({
          isLoading: false
        });
        return;
      }

      try {
        setPageState({
          isLoading: true
        });

        const data = await CachedApi.getTopicPathwayQuizzesForUser(
          userId,
          assignmentGroup,
          skip ?? pageState.skip,
          take,
          activityType
        );

        const insights =
          assignmentGroup === pageState.assignmentGroup &&
          activityType === pageState.activityType &&
          skip > 0
            ? [...new Set([...pageState.insights, ...data.data])]
            : data.data;

        setPageState({
          insights,
          assignmentGroup,
          activityType,
          skip,
          total: data.total,
          isLoading: false
        });

        return;
      } catch (error) {
        console.error(error);
        toaster.danger('Error fetching assignments');
      }

      setPageState({
        isLoading: false
      });
    },
    [count, pageState, setPageState, take, userId]
  );

  useEffect(() => {
    const runEffect = async () => {
      if (
        (assignmentGroup !== pageState.assignmentGroup ||
          activityType !== pageState.activityType) &&
        !pageState.isLoading
      ) {
        try {
          setPageState({
            isLoading: true,
            activityType,
            assignmentGroup,
            total: 0
          });
          const data = await CachedApi.getTopicPathwayQuizzesForUser(
            userId,
            assignmentGroup,
            pageState.skip,
            take,
            activityType
          );
          setPageState({
            isLoading: false,
            insights: data.data,
            total: data.total
          });
        } catch (error) {
          console.error(error);
          toaster.danger('Error fetching assignments');
          setPageState({
            isLoading: false
          });
        }
      }
    };
    runEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentGroup, activityType, pageState.isLoading]);

  if (
    (!isCountSet && typeof countfunction === 'function') ||
    (pageState.isLoading && !pageState.insights.length)
  )
    return <Loading />;

  const hasMoreResults = pageState.total > pageState.skip + take;
  const isLoadingMore = pageState.isLoading && pageState.skip > 0;

  return (
    <Container {...props}>
      <AnimatePresence>
        <Wrapper
          key={pageState.activityType}
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1,
                delayChildren: 0.1
              }
            }
          }}
          initial="hidden"
          animate="show">
          {!pageState.insights?.length
            ? React.createElement(emptyStateComponent)
            : pageState.insights.map((a, i) => (
                <motion.div
                  key={i}
                  variants={{
                    hidden: {
                      opacity: 0,
                      scale: 0.5,
                      y: -50
                    },
                    show: { opacity: 1, scale: 1, y: 0 }
                  }}>
                  <ActivityCard
                    key={i}
                    activity={{
                      name: a.name,
                      topicId: a.topicPathwayQuizId,
                      type: a.assignmentId ? a.activityType : 'topic',
                      data: a
                    }}
                    isLastChild={i === pageState.insights.length - 1}
                    iconSize="4.5rem"
                  />
                </motion.div>
              ))}
          {hasMoreResults &&
            (isLoadingMore ? (
              <Loading />
            ) : (
              <LoadMoreButton
                onClick={() =>
                  fetchAssignments(
                    pageState.skip + take,
                    assignmentGroup,
                    activityType
                  )
                }>
                Load more results
              </LoadMoreButton>
            ))}
        </Wrapper>
      </AnimatePresence>
    </Container>
  );
};

const Container = styled.div``;

const Wrapper = styled(motion.div)``;

const LoadMoreButton = styled(Button)`
  width: 100%;
`;

export default ActivityContainer;
