import styled from 'styled-components';
import { media } from 'styles';
import { Card } from '..';

const StyledCard = styled(Card)`
  position: relative;
  cursor: pointer;

  width: 9.2rem;
  margin: 0.5rem;
  text-align: center;
  transition: all ease-in 0.15s;

  ${media.sm`
  width: 12rem;
  `} &.selected {
    border: 1px solid var(--primary);
    color: var(--primary);
    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: var(--primary);
      opacity: 0.15;
    }
  }
`;

export const SelectableTile = ({
  id,
  option,
  isSelected,
  onSelect = () => {},
  disabled = false,
  ...props
}) => {
  return (
    <StyledCard
      {...props}
      onClick={onSelect}
      className={
        isSelected ? `selected ${props.className ?? ''}` : props.className ?? ''
      }>
      <Card.Body className="d-flex flex-column justify-content-center">
        {option?.icon}
        <span
          style={{ fontWeight: 500, marginTop: !option?.icon ? '0' : '1rem' }}>
          {option?.label}
        </span>
      </Card.Body>
    </StyledCard>
  );
};
