export const login = refreshToken => async dispatch => {
  dispatch({ type: 'LOGIN', refreshToken });
};

export const setGoogleLoginData = ({
  googleToken,
  firstName,
  lastName,
  email,
  title
}) => ({
  type: 'SET_GOOGLE_DATA',
  data: { googleToken, firstName, lastName, email, title }
});

export const signupStart = () => ({ type: 'SIGNUP_START' });

export const signupError = message => ({
  type: 'SIGNUP_ERROR',
  message
});

export const logout = () => ({ type: 'LOGOUT' });
