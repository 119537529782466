import { useSelector } from 'react-redux';
import { useEediPlusAction } from 'hooks/useEediPlusAction';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import useQuery from 'hooks/useQuery';

const TopicChatWithTutorBox = ({ hidden = false }) => {
  const search = useQuery().get('search');
  const navigate = useNavigate();
  const subscription = useSelector(({ user }) => user.subscription);
  const action = useEediPlusAction(() => {});

  if (hidden) return null;

  return (
    <ChatWithTutorBox>
      <p>
        Can't find what you're looking for?{' '}
        <LinkButton
          onClick={() => {
            const status = subscription?.subscriptionStatus;
            if (status !== 'active' && status !== 'trialing') {
              navigate('/topics');
              action();
              return;
            }
            navigate(`topics/request?topicQuery=${search}`);
          }}>
          Chat with one of our tutors
        </LinkButton>
      </p>
    </ChatWithTutorBox>
  );
};

const ChatWithTutorBox = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0 0;
`;

const LinkButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  font-weight: 500;
  outline: none;
`;

export default TopicChatWithTutorBox;
