import { EediPlusWelcomeBanner } from 'components/Billing/EediPlusWelcomeBanner';
import ChildNavbar from 'components/Navigation/ChildNavbar';
import ParentNavbar from 'components/Navigation/ParentNavbar';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useSelector } from 'react-redux';

export const NavTypes = {
  None: 'none',
  Main: 'main'
};

export const NavBarContext = createContext({
  setNavType: () => {},
  currentNavType: NavTypes.None
});

export const NavBarProvider = ({ children }) => {
  const { isAuthenticated, userRole } = useSelector(store => store.user);
  const [currentNavType, setCurrentNavType] = useState(NavTypes.Main);

  const setNavType = useCallback(navType => {
    switch (navType) {
      case NavTypes.None:
        setCurrentNavType(NavTypes.None);
        break;

      default:
        setCurrentNavType(NavTypes.Main);
    }
  }, []);

  const [navState, setNavState] = useState({
    setNavType,
    currentNavType
  });

  useEffect(() => {
    setNavState({
      setNavType,
      currentNavType
    });
  }, [currentNavType, setNavType]);

  const getNavbar = () => {
    if (currentNavType !== NavTypes.Main || !isAuthenticated || !userRole) {
      return;
    }

    return userRole === 'Parent' ? <ParentNavbar /> : <ChildNavbar />;
  };

  return (
    <NavBarContext.Provider value={navState}>
      {isAuthenticated && <EediPlusWelcomeBanner />}
      {getNavbar()}
      {children}
    </NavBarContext.Provider>
  );
};

export const useNavType = (navType = NavTypes.Main) => {
  const { setNavType, currentNavType } = useContext(NavBarContext);
  const initialNavType = useRef(currentNavType);

  useEffect(() => {
    if (!navType) {
      return;
    }
    const initialType = initialNavType.current;

    setNavType(navType);
    return () => setNavType(initialType);
  }, [navType, setNavType]);
};
