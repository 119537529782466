import { loadStripe } from '@stripe/stripe-js';
import { Api } from 'api';
import { STRIPE_API_KEY } from 'config/settings';

export const stripePromise = loadStripe(STRIPE_API_KEY);

export const navigateToStripeBillingPortal = async userId => {
  const currentUrl = window.location.href;
  const { sessionUrl } = await Api.getStripeBillingPortalUrl(
    userId,
    currentUrl
  );
  window.location.assign(sessionUrl);
};

export const navigateToStripeCheckout = async ({
  successUrl,
  cancelUrl = window.location.href,
  priceId = undefined,
  checkoutMode = 'subscription',
  settings = undefined,
  referral = undefined
}) => {
  const { sessionId } = await Api.createStripeCheckoutSession({
    priceId,
    successUrl,
    cancelUrl,
    mode: checkoutMode,
    settings,
    referral: !!referral
      ? { referralUuid: referral.id, couponId: referral.coupon }
      : undefined
  });

  const stripe = await stripePromise;
  const { error } = await stripe.redirectToCheckout({ sessionId });

  if (error) {
    throw error;
  }
};
