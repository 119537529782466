const AbstractCircle2x2 = ({
  width = '24px',
  height = '24px',
  disabled = false,
  ...props
}) => {
  const backgroundColor = !disabled ? 'var(--indigo)' : 'var(--gray-lighter)';
  const foregroundColor = !disabled ? 'var(--red-lighter)' : 'var(--gray)';

  return (
    <svg
      style={{ width, height }}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        width="60"
        height="60"
        rx="30"
        className="topic-circle-background"
        fill={backgroundColor}
      />
      <circle
        cx="20"
        cy="20"
        r="8"
        className="topic-circle-foreground"
        fill={foregroundColor}
      />
      <circle
        cx="40"
        cy="20"
        r="8"
        className="topic-circle-foreground"
        fill={foregroundColor}
      />
      <circle
        cx="20"
        cy="40"
        r="8"
        className="topic-circle-foreground"
        fill={foregroundColor}
      />
      <circle
        cx="40"
        cy="40"
        r="8"
        className="topic-circle-foreground"
        fill={foregroundColor}
      />
    </svg>
  );
};

export default AbstractCircle2x2;
