import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Loading, Dropdown, Button } from 'components/common';
import React, { useCallback, useState } from 'react';
import ActivityCard from './ActivityCard';
import { useEffect } from 'react';
import { assignmentGroups } from '../helpers/learningActivityHelpers';
import CachedApi from 'cache/CachedApi';
import { useSelector } from 'react-redux';

const AssignmentsContainer = ({
  count,
  take,
  isCountSet,
  countfunction,
  emptyStateComponent,
  assignmentGroup,
  classroomStudents,
  userId,
  ...props
}) => {
  const userRole = useSelector(s => s.user.userRole);
  const [pageState, setPageState] = useState({
    isLoading: false,
    skip: 0,
    assignments: [],
    groups: classroomStudents?.length
      ? classroomStudents.flatMap(s =>
          s.groups.map(g => ({ ...g, studentId: s.studentId }))
        )
      : [],
    groupId: null,
    total: 0
  });

  const fetchAssignments = useCallback(
    async (
      skip = 0,
      groupId = null,
      assignmentGroup = assignmentGroups.Active
    ) => {
      if (!userId || !take || !assignmentGroup || !count) {
        setPageState(() => ({
          ...pageState,
          isLoading: false
        }));
      }

      setPageState(() => ({
        ...pageState,
        isLoading: true
      }));

      const insights = await CachedApi.getAssignmentInsights(
        userId,
        skip ?? pageState.skip,
        take,
        assignmentGroup,
        [],
        groupId
      );

      const assignments =
        assignmentGroup === pageState.assignmentGroup && skip > 0
          ? [...new Set([...pageState.assignments, ...insights.data])]
          : insights.data;

      setPageState(() => ({
        ...pageState,
        assignments: assignments.filter(a => !a.isActivityDisabled),
        assignmentGroup,
        skip,
        total: insights.total,
        groupId,
        isLoading: false
      }));
    },
    [count, pageState, take, userId]
  );

  useEffect(() => {
    if (assignmentGroup !== pageState.assignmentGroup && !pageState.isLoading) {
      fetchAssignments(0, null, assignmentGroup);
    }
  }, [
    assignmentGroup,
    fetchAssignments,
    pageState.assignmentGroup,
    pageState.isLoading
  ]);

  if (
    (!isCountSet && typeof countfunction === 'function') ||
    (pageState.isLoading && !pageState.assignments.length)
  )
    return <Loading />;

  const hasMoreResults = pageState.total > pageState.skip + take;
  const isLoadingMore = pageState.isLoading && pageState.skip > 0;

  return (
    <div {...props}>
      <ClassPickerWrapper>
        <p className="mb-0">
          {userRole === 'Student'
            ? 'Assignments set by your teachers will appear here'
            : "Assignments set by your child's school will appear here"}
        </p>
        <Dropdown>
          <Dropdown.Toggle outline caret style={{ fontSize: '15px' }}>
            {pageState.groups.find(g => g.groupId === pageState.groupId)
              ?.code || 'All Classes'}
          </Dropdown.Toggle>
          {
            <Dropdown.Menu right>
              {pageState.groups.map((g, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() =>
                    fetchAssignments(0, g.groupId, assignmentGroup)
                  }>
                  <span>{g.code}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          }
        </Dropdown>
      </ClassPickerWrapper>
      <AnimatePresence>
        <motion.div
          key={pageState.assignmentGroup}
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1,
                delayChildren: 0.1
              }
            }
          }}
          initial="hidden"
          animate="show">
          {!pageState.assignments?.length
            ? React.createElement(emptyStateComponent)
            : pageState.assignments.map((a, i) => (
                <motion.div
                  key={i}
                  variants={{
                    hidden: {
                      opacity: 0,
                      scale: 0.5,
                      y: -50
                    },
                    show: { opacity: 1, scale: 1, y: 0 }
                  }}>
                  <ActivityCard
                    key={i}
                    activity={{
                      name: a.name,
                      topicId: a.topicPathwayQuizId,
                      type: a.type,
                      isActivityDisabled: a.isActivityDisabled,
                      data: a
                    }}
                    isLastChild={i === pageState.assignments.length - 1}
                    iconSize="4.5rem"
                  />
                </motion.div>
              ))}
          {hasMoreResults &&
            (isLoadingMore ? (
              <Loading />
            ) : (
              <Button
                onClick={() =>
                  fetchAssignments(
                    pageState.skip + take,
                    pageState.groupId,
                    assignmentGroup
                  )
                }
                className="btn-block">
                Load more results
              </Button>
            ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const ClassPickerWrapper = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 1rem;
  background: var(--indigo-lightest);
`;

export default AssignmentsContainer;
