import styled from 'styled-components';
import moment from 'moment';
import { CloseButton, Loading } from 'components/common';
import EmptyState from 'components/EmptyState';
import { processNotification } from './notificationHelpers';

const NotificationItem = ({
  notification,
  onClick = () => {},
  onDismiss = () => {}
}) => {
  const { body, title, dismissible } = processNotification(notification);

  if (!body && !title) {
    return null;
  }

  return (
    <>
      <Notification
        key={notification.notificationId}
        onClick={() => onClick(notification.notificationType)}>
        {!!title ? <h3>{title}</h3> : null}
        {!!body ? <span>{body}</span> : null}
        <span className="mt-2 text-muted d-block text-sm">
          {moment(notification.dateCreated).fromNow()}
        </span>
        {dismissible && (
          <CloseButton
            onClick={() => onDismiss(notification.notificationId)}
            size="small"
            style={{
              position: 'absolute',
              right: '1rem',
              top: '50%',
              marginTop: '-1rem'
            }}
          />
        )}
      </Notification>
    </>
  );
};

const NotificationsDropdown = ({ data, loading, onDismiss, onClick }) => {
  if (loading) {
    return (
      <NotificationLoading>
        <Loading style={{ height: '2rem' }} />
      </NotificationLoading>
    );
  }

  const notifications = data;

  if (!notifications?.length) {
    return (
      <EmptyState
        title="No notifications"
        imgSource={require('assets/images/eedi_empty.svg')}
        imgAlt="No new notifications"
      />
    );
  }

  return (
    <>
      {notifications.map(notification => (
        <NotificationItem
          notification={notification}
          onClick={onClick}
          onDismiss={onDismiss}
        />
      ))}
      {loading && <Loading style={{ margin: '2rem' }} />}
    </>
  );
};

const Notification = styled.div`
  padding: 1.25rem 3rem 1.25rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
  position: relative;
`;

const NotificationLoading = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
`;

export default NotificationsDropdown;
