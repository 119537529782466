import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTeamStudent } from 'actions/teams';
import { selectUserState } from 'reducers/selectors/selectUserState';
import { userStates } from 'constants/userStates';
import SchoolSelectionModal from 'components/Leaderboard/SchoolSelectionModal';
import { useLocation } from 'react-router';
import matchPathname from 'helpers/matchPathname';
import CachedApi from 'cache/CachedApi';
import { cache } from 'store';
import types from 'cache/types';
import { HOME_SCHOOL_IDS } from 'config/settings';

export const TeamsContext = createContext({
  launchTeamsSelectionModal: isForced => {}
});

export const TeamsProvider = ({ children }) => {
  const location = useLocation();
  const {
    schoolId,
    studentId,
    acceptTermsAndConditions,
    isFirstQuizCompleted,
    isTeamSchoolModalSkipped,
    allStudents
  } = useSelector(store => ({
    schoolId: store.school.schoolId,
    studentId: store.school.studentId,
    teamStudent: store.teams.teamStudent,
    allStudents: store.school.allStudents,
    acceptTermsAndConditions: store.user.acceptTermsAndConditions,
    ...selectUserState(
      userStates.isFirstQuizCompleted,
      userStates.isTeamSchoolModalSkipped
    )(store)
  }));

  const [isTeamsModalOpen, setIsTeamsModalOpen] = useState({
    isOpen: false,
    isForced: false
  });

  const dispatch = useDispatch();

  // List of URIs to not display Modal
  const matchArg = matchPathname(
    [
      '/level-booster/:dynamicQuizSessionId?',
      '/topic/:topicPathwayQuizId/challenge',
      '/topic/:topicPathwayQuizId',
      '/:schoolId/topicpathwayquiz/:topicPathwayQuizId/fact',
      '/:schoolId/topicpathwayquiz/:topicPathwayQuizId/:questionIdx?'
    ],
    location?.pathname,
    { exact: true }
  );

  const onDismissTeamSelection = () => {
    setIsTeamsModalOpen({ isOpen: false, isForced: false });
  };

  useEffect(() => {
    let wasCancelled = false;

    const fetchTeams = async () => {
      if (!schoolId || !studentId) {
        return;
      }

      try {
        // Fetch team information for current student
        const classroomStudents = (allStudents ?? []).filter(
          s => !HOME_SCHOOL_IDS.includes(s.schoolId)
        );
        if (
          acceptTermsAndConditions &&
          (isFirstQuizCompleted || classroomStudents.length > 0)
        ) {
          const teamStudent = await CachedApi.getTeamStudent(studentId);
          if (!wasCancelled) {
            if (
              !teamStudent ||
              (!teamStudent.teamSchoolId && !isTeamSchoolModalSkipped)
            ) {
              // If the student hasn't selected a team yet (or got an avatar)
              // open the selection modal
              setIsTeamsModalOpen({ isOpen: true });
              cache.bustTypeCache({ typenames: [types.teamStudent.typename] });
            } else {
              dispatch(setTeamStudent(teamStudent));
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchTeams();
    return () => (wasCancelled = true);
  }, [
    acceptTermsAndConditions,
    allStudents,
    dispatch,
    isFirstQuizCompleted,
    isTeamSchoolModalSkipped,
    schoolId,
    studentId
  ]);

  return (
    <TeamsContext.Provider
      value={{
        launchTeamsSelectionModal: isForced => {
          setIsTeamsModalOpen({ isOpen: true, isForced: isForced });
        }
      }}>
      {((isFirstQuizCompleted && !matchArg) || isTeamsModalOpen.isForced) &&
      isTeamsModalOpen.isOpen ? (
        <SchoolSelectionModal onDismiss={onDismissTeamSelection} />
      ) : null}
      {children}
    </TeamsContext.Provider>
  );
};
