import { Button, EmojiSpan, Modal, toaster } from 'components/common';
import InviteParentForm from './InviteParentForm';
import styled from 'styled-components';
import CoinLock from 'assets/images/coin_lock.svg';
import { useSelector } from 'react-redux';
import { Api } from 'api';
import { useState } from 'react';
import WhatsappIcon from 'assets/icons/WhatsAppIcon';

const InviteParentModal = ({
  isOpen,
  onComplete = () => {},
  focus = 'coins'
}) => {
  const { userId } = useSelector(store => store.user);
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [invitationSource, setInvitationSource] = useState('');

  const handleSendReminder = async () => {
    try {
      if (isSent) return;
      setIsSending(true);
      await Api.sendInvitationReminder(userId);
      toaster.success('A reminder has been submitted successfully');
      setIsSent(true);
      setIsSending(false);
    } catch (e) {
      toaster.danger(
        'A reminder has already been sent, please try again later.'
      );
    }
  };

  const onInviteCompleted = source => {
    setIsSent(true);
    setIsSending(false);
    setInvitationSource(source);
  };

  return (
    <Modal isOpen={isOpen} size="md">
      <Modal.Header toggle={onComplete}></Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {focus === 'tutors' ? (
            <img
              src="https://images.diagnosticquestions.com/eedi/images/plus/tutor-locked.png"
              alt="Coin lock"
              style={{ marginTop: '-3rem', width: '65%', marginBottom: '2rem' }}
            />
          ) : (
            <img
              src={CoinLock}
              alt="Coin lock"
              style={{ marginTop: '-3rem' }}
            />
          )}
        </div>
        <div className="px-4">
          <h1 className="display-4 text-center">Almost there!</h1>
          {focus === 'tutors' ? (
            <p className="text-lg">
              Before you can speak to a tutor your parent will need to create an
              account on Eedi.
            </p>
          ) : (
            <p className="text-lg">
              Before you can spend your coins on cool things we need your parent
              to sign in:
            </p>
          )}

          {isSent && invitationSource.invitationSource !== 'whatsapp' ? (
            <Well>
              <h3>
                We've sent your parent an email{' '}
                <EmojiSpan ariaLabel="email" emoji="📧" />
              </h3>
              <p>
                Your coins will be unlocked once they have confirmed their
                address
              </p>
              <Button
                disabled={isSent || isSending}
                loading={isSending}
                color="link"
                onClick={handleSendReminder}>
                Send another email
              </Button>
            </Well>
          ) : isSent && invitationSource.invitationSource === 'whatsapp' ? (
            <Well>
              <h3>
                Thanks for sending the WhatsApp message{'  '}
                <WhatsappIcon />
              </h3>
              <p>
                Your coins will be unlocked once they have confirmed their
                details via the invitation link
              </p>
              <Button
                disabled={isSent || isSending}
                loading={isSending}
                color="link"
                onClick={invitationSource.send}>
                Send another message
              </Button>
            </Well>
          ) : (
            <InviteParentForm onComplete={onInviteCompleted} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Well = styled.div`
  text-align: center;
  background-color: var(--blue-lighter);
  padding: 1.5rem;
  border-radius: 1rem;
`;

export default InviteParentModal;
