import { LoadPricingInfo } from 'actions/pricingActions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useEediPlusPricingInfo = () => {
  const dispatch = useDispatch();
  const pricingInfo = useSelector(({ pricing }) => pricing);

  useEffect(() => {
    if (!pricingInfo.hasLoaded && !pricingInfo.isLoading) {
      dispatch(LoadPricingInfo());
    }
  }, [dispatch, pricingInfo.hasLoaded, pricingInfo.isLoading]);

  const data = useMemo(
    () => ({
      ...pricingInfo,
      priceOptions: withEquivalentPrices(pricingInfo.priceOptions)
    }),
    [pricingInfo]
  );

  return data;
};

const withEquivalentPrices = (priceOptions = []) => {
  return priceOptions.map(o => ({
    ...o,
    equivalentYearlyPrice: toYearlyPrice(
      o.unitAmount,
      o.interval,
      o.intervalCount
    ),
    equivalentMonthlyPrice: toMonthlyPrice(
      o.unitAmount,
      o.interval,
      o.intervalCount
    ),
    equivalentWeeklyPrice: toWeeklyPrice(
      o.unitAmount,
      o.interval,
      o.intervalCount
    )
  }));
};

const toYearlyPrice = (unitAmount, interval, intervalCount) => {
  switch (interval) {
    case 'day':
      return Math.round((365.25 / intervalCount) * unitAmount);

    case 'week':
      return Math.round((52.17857 / intervalCount) * unitAmount);

    case 'month':
      return Math.round((12 / intervalCount) * unitAmount);

    case 'year':
    default:
      return unitAmount;
  }
};

const toMonthlyPrice = (unitAmount, interval, intervalCount) => {
  switch (interval) {
    case 'day':
      // assumes 30 day month
      return unitAmount * (30 * intervalCount);

    case 'week':
      // assumes 4 week month
      return unitAmount * (4 * intervalCount);

    case 'year':
      return Math.round(unitAmount / (12 * intervalCount));

    case 'month':
    default:
      return unitAmount;
  }
};

const toWeeklyPrice = (unitAmount, interval, intervalCount) => {
  switch (interval) {
    case 'day':
      return unitAmount * (7 * intervalCount);

    case 'month':
      // assumes 4 week month
      return Math.round(unitAmount / (4 * intervalCount));

    case 'year':
      return Math.round(unitAmount / (52 * intervalCount));

    case 'week':
    default:
      return unitAmount;
  }
};
