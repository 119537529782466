import { Button } from 'components/common';
import { useState } from 'react';
import styled from 'styled-components';
import LoginTogetherModal from './LoginTogetherModal';

const LoginTogetherComponent = ({ child, ...props }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div {...props}>
      <LoginTogetherModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        child={child}
      />

      <StyledLoginButton outline onClick={() => setShowModal(true)}>
        Log in as child
      </StyledLoginButton>
    </div>
  );
};

const StyledLoginButton = styled(Button)`
  display: inline;
  height: min-content;
  white-space: break-spaces;
  width: auto;
`;

export default LoginTogetherComponent;
