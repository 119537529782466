import { EmojiSpan, Modal } from 'components/common';
import Coin from 'assets/icons/coin';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const CoinsModal = ({ open, onDismiss, ...props }) => {
  const { currentBalance } = useSelector(s => s.user.coins);

  return (
    <Modal isOpen={open} size="md" toggle={onDismiss} {...props}>
      <Modal.Title toggle={onDismiss}>
        <HeaderContent>
          <div className="mr-3">
            <Coin id="modalCoin" height="4rem" />
          </div>
          <h2 className="mb-0">You have {currentBalance} Eedi coins</h2>
        </HeaderContent>
      </Modal.Title>
      <Modal.Body>
        <h3>How you can earn coins</h3>
        <ul className="mb-4">
          <li className="mb-1">
            <EmojiSpan emoji="✅" ariaLabel="tada" /> Answering questions
            correctly
          </li>
          <li className="mb-1">
            <EmojiSpan emoji="📺" ariaLabel="television" /> Watching lesson
            videos
          </li>
          <li className="mb-1">
            <EmojiSpan emoji="🏆" ariaLabel="trophy" /> Completing class
            challenges
          </li>
        </ul>
        <h3>What you can spend coins on</h3>
        <ul className="mb-4">
          <li className="mb-1">
            <EmojiSpan emoji="🤓" ariaLabel="glasses face" />{' '}
            <Link to="/shop/avatars" onClick={onDismiss}>
              Avatars
            </Link>
          </li>
          <li className="mb-1">
            <EmojiSpan emoji="🎁" ariaLabel="gift" />{' '}
            <Link to="/shop/prize" onClick={onDismiss}>
              Prize draw entries
            </Link>
          </li>
        </ul>
        <NewBox>
          <NewBoxHeader>
            <NewPill>NEW</NewPill>
            <h2 className="mb-0">
              We've removed coin limits{' '}
              <EmojiSpan emoji="🎉" ariaLabel="tada" />
            </h2>
          </NewBoxHeader>
          <p className="mb-0">
            To level the playing field, we've removed daily coin limits and
            double coin bonuses.
          </p>
        </NewBox>
      </Modal.Body>
    </Modal>
  );
};

const HeaderContent = styled.div`
  background: linear-gradient(
    180deg,
    #ffe9a8 0%,
    rgba(255, 233, 168, 0.41) 100%
  );
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

const NewBox = styled.div`
  padding: 1.5rem;
  background: var(--pink-lighter);
  border-radius: 0.75rem;
  h1 {
    margin: 0;
  }
`;

const NewPill = styled.div`
  display: flex;
  align-items: center;
  background: #d944ca;
  border-radius: 0.25rem;
  color: white;
  font-weight: bold;
  padding: 0.1rem 0.45rem 0.1rem 0.45rem;
  margin: 0 0.5rem 0 0;
`;

const NewBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  align-items: center;
`;
