import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import { useEffect, useState } from 'react';

// Tooltip component that automatically hides after a certain amount of time
const Tooltip = ({ initialVisibleMs = 5000, children, ...props }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Show the tooltip automatically after the component mounts
    setVisible(true);
    const timer = setTimeout(() => setVisible(false), initialVisibleMs); // Auto-hide after 3 seconds

    return () => clearTimeout(timer);
  }, [initialVisibleMs]);

  if (!initialVisibleMs || !visible) {
    return <StyledTooltip {...props}>{children}</StyledTooltip>;
  }

  return (
    <StyledTooltip isOpen={true} {...props}>
      {children}
    </StyledTooltip>
  );
};

const StyledTooltip = styled(UncontrolledTooltip)`
  z-index: 999;
`;

export default Tooltip;
