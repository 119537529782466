import { Api } from 'api';
import { Button, Input, Modal, Form, toaster } from 'components/common';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LoginTogetherModal = ({ isOpen, closeModal }) => {
  const navigate = useNavigate();
  const child = useSelector(store => store.school.child);

  const [hidePassword, setHidePassword] = useState(true);
  const [inputField, setInputField] = useState({
    enteredPassword: ''
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const inputsHandler = e => {
    setInputField({ [e.target.name]: e.target.value });
  };

  const loginAsChild = async () => {
    const password = child.givenPassword ?? inputField.enteredPassword;

    if (password) {
      try {
        setIsLoggingIn(true);
        const token = await Api.signInTogether(child.userName, password);

        if (token) {
          navigate(`/loginredirect?token=${token}`);
        } else {
          // If we can't login automatically, inform user to try again
          toaster.danger(
            `Unable to log in as ${child.userName}. Please try again.`
          );
        }
      } catch (e) {
        console.error(e);
        setIsLoggingIn(false);
      }
    }
  };

  return (
    child && (
      <Modal isOpen={isOpen}>
        <Modal.Header toggle={closeModal}>
          Log in with {child.firstName}
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <p>
            We recommend logging in to Eedi with your child together for the
            first time so you can explore what Eedi has to offer!
          </p>
          <Form.Group>
            <Input
              label={`${child.firstName}'s Username`}
              readOnly
              value={child.userName}
            />
          </Form.Group>

          {!!child.givenPassword && (
            <>
              <Form.Group>
                <Input
                  label={`${child.firstName}'s Password`}
                  type={hidePassword ? 'password' : 'text'}
                  readOnly
                  value={child.givenPassword}
                />
                <RevealLink onClick={() => setHidePassword(!hidePassword)}>
                  {hidePassword ? 'Show' : 'Hide'}
                </RevealLink>
              </Form.Group>
            </>
          )}

          {!child.givenPassword && (
            <>
              <Form.Group>
                <Input
                  name="enteredPassword"
                  label={`${child.firstName}'s Password`}
                  value={inputField.enteredPassword}
                  onChange={inputsHandler}
                  type="text"
                  placeholder="Enter password"
                />
              </Form.Group>
              <p>
                Note: The current password is not shown as it was chosen by the
                child themselves or a parent. If it has been forgotten&nbsp;
                <Link
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline',
                    fontWeight: 'normal'
                  }}
                  to={`/children/${child.childId}/edit`}
                  onClick={closeModal}>
                  set a new one
                </Link>
                .
              </p>
            </>
          )}

          <StyledButton
            color="primary"
            size="lg"
            onClick={loginAsChild}
            loading={isLoggingIn}
            className="mb-4"
            block>
            Log in as {child.firstName}
          </StyledButton>
          <span className="text-gray">
            Please note that logging in as {child.firstName} will log you out of
            your account
          </span>
        </Modal.Body>
      </Modal>
    )
  );
};

const StyledButton = styled(Button)`
  white-space: break-spaces;
  width: auto;
`;

const RevealLink = styled.span`
  cursor: pointer;
  text-align: middle;
  color: var(--primary);
  font-weight: 500;
  float: right;
  &:hover {
    color: var(--primary);
  }
`;

export default LoginTogetherModal;
