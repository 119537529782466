import analytics, { FAMILY_PROJECT_KEY } from 'analytics';
import { Api } from 'api';
import SelectInput from 'components/common/FormInputs/SelectInput';
import { useEffect, useState } from 'react';

export const FamilyYearGroupSelector = ({
  control,
  disabled = false,
  locale = 'en-GB',
  ...props
}) => {
  const [yearGroupOptions, setYearGroupOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const yearGroups = await Api.getEediHomeYearGroups();

      if (isMounted) {
        const yearGroupOptions = yearGroups
          .map(yg => ({
            label: yg.name,
            value: { name: yg.name, yearGroupId: yg.yearGroupId }
          }))
          .sort((a, b) => {
            // N.B. This will break if it ever changes to something like
            // 'Year Seven'. Lets not do that, or if we do, also return an
            // ordinal that we can sort on.
            const aName = a.label.replace('Year ', '');
            const bName = b.label.replace('Year ', '');

            return +aName - bName;
          });

        if (locale === 'en-US') {
          yearGroupOptions.forEach(opt => {
            opt.label = opt.label.replace('Year', 'Grade');
          });
          // Remove first element as it is not needed in US
          yearGroupOptions.shift();
          // // Minus 1 from ordinal
          yearGroupOptions.forEach(opt => {
            opt.label = opt.label.replace(/\d+/, match => +match - 1);
          });
        }

        const currentValue = control.getValues('yearGroup');

        if (!currentValue) {
          control.setValue(
            'yearGroup',
            yearGroupOptions.find(opt => opt.label === 'Year 7')?.value
          );
        } else if (currentValue?.name && !currentValue?.yearGroupId) {
          currentValue.yearGroupId = yearGroups.find(
            y => y.name === currentValue.name
          )?.yearGroupId;
        }

        setYearGroupOptions(yearGroupOptions);
      }
    }

    let isMounted = true;
    fetchData();
    return () => (isMounted = false);
  }, [control, locale]);

  const trackSchoolYearChange = val => {
    const selectedSchoolYear = yearGroupOptions.find(
      opt => opt.value === val
    )?.label;
    analytics.track(
      'Child School Year Changed',
      {
        selected_school_year: selectedSchoolYear
      },
      {
        project: FAMILY_PROJECT_KEY
      }
    );
  };

  // N.B. This is slightly hacky. We're relying on internal implementation
  //      details in order to get the error state from the `control` object
  //      passed in. This might break in future versions and we'll have to
  //      rework how we get the error out.
  const errorMessage = control.formStateRef.current.errors?.yearGroup?.message;

  return (
    <SelectInput
      {...props}
      control={control}
      name="yearGroup"
      options={yearGroupOptions}
      label="School year"
      error={errorMessage}
      disabled={disabled}
      onChange={val => trackSchoolYearChange(val)}
    />
  );
};
