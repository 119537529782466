import { Button, Modal } from 'components/common';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Confetti from 'react-confetti';
import AchievementCard from './AchievementCard';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';

const StyledModalBody = styled(Modal.Body)`
  padding-top: 0;
  display: flex;
  justify-content: center;
`;

export const AchievementCompletedModal = ({ achievement, onDismiss }) => {
  const [confettiActive, setConfettiActive] = useState(false);
  const [celebrationCount, setCelebrationCount] = useState(0);
  const { width, height } = useWindowSize();

  const reset = useCallback(() => {
    setConfettiActive(false);
    setCelebrationCount(0);
  }, []);

  const celebrateButtonText = useMemo(() => {
    if (celebrationCount === 0) {
      return 'Celebrate';
    }
    if (celebrationCount < 2) {
      return 'Celebrate (again)';
    }
    if (celebrationCount < 5) {
      return 'Celebrate (even more)';
    }

    return confettiActive ? 'Stop celebrating 😢' : 'Celebrate forever 🎉';
  }, [celebrationCount, confettiActive]);

  return (
    <>
      {confettiActive && (
        <Confetti
          width={width}
          height={height}
          style={{ zIndex: 9999 }}
          recycle={celebrationCount >= 5}
          onConfettiComplete={() => {
            if (celebrationCount < 5) {
              setConfettiActive(false);
            }
            setCelebrationCount(c => c + 1);
          }}
        />
      )}
      <Modal
        isOpen={true}
        toggle={() => {
          reset();
          onDismiss();
        }}>
        <Modal.Header
          toggle={() => {
            reset();
            onDismiss();
          }}>
          Achievement unlocked!
        </Modal.Header>
        <StyledModalBody>
          <AchievementCard
            userAchievement={achievement}
            playUnlockAnimation={true}
          />
        </StyledModalBody>
        <Modal.Footer>
          <Link
            style={{ marginRight: '2rem' }}
            to="/challenge/achievements"
            onClick={() => {
              onDismiss(true);
            }}>
            View Achievements
          </Link>
          <Button
            color="primary"
            onClick={() => {
              if (celebrationCount < 5) {
                setConfettiActive(true);
                return;
              }
              setConfettiActive(isActive => !isActive);
            }}
            disabled={confettiActive && celebrationCount < 5}>
            {celebrateButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
