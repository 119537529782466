import { parseHtmlContent } from 'hooks/useHtmlProcessor';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const NotificationTypeEnum = {
  PlainTextNotification: 72,
  PlainTextNotificationDismissible: 70,
  MarkDownNotification: 73,
  MarkDownNotificationDismissible: 71,
  HtmlNotification: 74,
  HtmlNotificationDismissible: 75,
  HtmlBannerNotification: 76,
  HtmlBannerNotificationDismissible: 77
};

export const NotificationType = {
  PlainTextNotification: 'PlainTextNotification',
  PlainTextNotificationDismissible: 'PlainTextNotificationDismissible',
  MarkDownNotification: 'MarkDownNotification',
  MarkDownNotificationDismissible: 'MarkDownNotificationDismissible',
  HtmlNotification: 'HtmlNotification',
  HtmlNotificationDismissible: 'HtmlNotificationDismissible',
  HtmlBannerNotification: 'HtmlBannerNotification',
  HtmlBannerNotificationDismissible: 'HtmlBannerNotificationDismissible'
};

export const BannerNotificationTypes = [
  NotificationTypeEnum.HtmlBannerNotification,
  NotificationTypeEnum.HtmlBannerNotificationDismissible,
  NotificationType.HtmlBannerNotification,
  NotificationType.HtmlBannerNotificationDismissible
];

export const AllDashboardNotificationTypes = [
  NotificationTypeEnum.PlainTextNotification,
  NotificationTypeEnum.PlainTextNotificationDismissible,
  NotificationTypeEnum.MarkDownNotification,
  NotificationTypeEnum.MarkDownNotificationDismissible,
  NotificationTypeEnum.HtmlNotification,
  NotificationTypeEnum.HtmlNotificationDismissible,
  NotificationType.PlainTextNotification,
  NotificationType.PlainTextNotificationDismissible,
  NotificationType.MarkDownNotification,
  NotificationType.MarkDownNotificationDismissible,
  NotificationType.HtmlNotification,
  NotificationType.HtmlNotificationDismissible
];

export const nonDismissibleTypes = [
  NotificationTypeEnum.PlainTextNotification,
  NotificationTypeEnum.MarkDownNotification,
  NotificationTypeEnum.HtmlNotification,
  NotificationType.PlainTextNotification,
  NotificationType.MarkDownNotification,
  NotificationType.HtmlNotification
];

export const processNotification = notification => {
  const { data, notificationType } = notification;

  switch (notificationType) {
    // Plain Text
    case NotificationTypeEnum.PlainTextNotification:
    case NotificationType.PlainTextNotification:
    case NotificationTypeEnum.PlainTextNotificationDismissible:
    case NotificationType.PlainTextNotificationDismissible:
      return data;
    // Markdown
    case NotificationTypeEnum.MarkDownNotification:
    case NotificationType.MarkDownNotification:
    case NotificationTypeEnum.MarkDownNotificationDismissible:
    case NotificationType.MarkDownNotificationDismissible:
      return {
        ...data,
        body: data.body ? (
          <Markdown remarkPlugins={[remarkGfm]}>{data.body}</Markdown>
        ) : null
      };
    // HTML
    case NotificationTypeEnum.HtmlNotification:
    case NotificationType.HtmlNotification:
    case NotificationTypeEnum.HtmlNotificationDismissible:
    case NotificationType.HtmlNotificationDismissible:
    case NotificationTypeEnum.HtmlBannerNotification:
    case NotificationType.HtmlBannerNotification:
    case NotificationTypeEnum.HtmlBannerNotificationDismissible:
    case NotificationType.HtmlBannerNotificationDismissible:
      return {
        ...data,
        body: data.body ? parseHtmlContent(data.body) : null
      };
    default:
      return false;
  }
};
