export const toCamelCase = key => {
  return key
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()) // Convert to CamelCase
    .replace(/^./, m => m.toLowerCase()); // Make first character lowercase
};

export const parseObjectKeys = obj => {
  return Object.keys(obj).reduce((newObj, key) => {
    const newKey = toCamelCase(key);
    newObj[newKey] = obj[key];
    return newObj;
  }, {});
};
