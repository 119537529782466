import Stepper from 'components/Stepper/Stepper';
import DynamicQuizReportStep from './DynamicQuizReportStep';
import DynamicQuizStep from './DynamicQuizStep';
import DynamicQuizSignupStep from './DynamicQuizSignupStep';

const StepperSteps = [
  {
    stepKey: 'about',
    label: 'Level Booster - About you',
    path: '',
    index: true,
    redirectPath: '',
    content: DynamicQuizSignupStep
  },
  {
    stepKey: 'quiz',
    label: 'Level Booster - Quiz',
    path: 'quiz/:claimCode?',
    redirectPath: 'quiz',
    content: DynamicQuizStep
  },
  {
    stepKey: 'report',
    label: 'Level Booster - Report',
    path: 'report/:claimCode?',
    redirectPath: 'report',
    content: DynamicQuizReportStep
  }
];

const DynamicQuizStepper = () => {
  return <Stepper steps={StepperSteps} pageTitle="Level-booster" />;
};

export default DynamicQuizStepper;
