import { Button, Col, Row } from 'components/common';
import Mac from 'assets/images/quiz/quiz-mac.svg';
import DiagonalBG from 'assets/images/quiz/quiz-diagonal-background.svg';
import styled from 'styled-components';
import { media } from 'styles';

const DynamicQuizStepIntro = ({ createDynamicQuizSession }) => {
  return (
    <Wrapper>
      <Row>
        <Col md="7">
          <TextWrapper>
            <h2
              style={{
                marginTop: '1rem',
                fontSize: '2rem',
                fontWeight: '500'
              }}>
              Welcome to our maths assessment
            </h2>
            <p>
              We’ll ask you 10 questions to help us design the perfect learning
              plan for you.
            </p>
            <p>
              You may not have learned how to do some questions yet. It’s ok to
              make mistakes, just try your best.
            </p>
            <p>Good luck!</p>
          </TextWrapper>
        </Col>
        <Col md="5" className="text-center">
          <Img src={Mac} alt="laptop" />
          <Button
            className="m-4"
            color="primary"
            onClick={createDynamicQuizSession}>
            Start first question
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};

const Img = styled.img`
  display: none;
  float: right;

  ${media.md`
    display: block;
  `}
`;

const Wrapper = styled.div`
  background-image: unset;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 1rem;
  padding: 2rem 0;

  ${media.md`
    background-image: url('${DiagonalBG}');
  `}
`;

const TextWrapper = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;

  ${media.md`
    margin-left: 4rem;
  `}
`;

export default DynamicQuizStepIntro;
