const tryParseBool = value => {
  const lower = value.trim().toLowerCase();

  if (lower === 'true') {
    return true;
  }
  if (lower === 'false') {
    return false;
  }

  return value;
};

/**
 * Selects the provided keys from the `userState` in the store. If the values
 * can be case to `true` or `false`, they are.
 * @param  {...string} keys
 */
export const selectUserState =
  (...keys) =>
  rootStore => {
    const userState = rootStore?.user?.userState;

    if (!userState?.stateValues?.length) return {};

    return userState.stateValues.reduce((prev, curr) => {
      if (keys.includes(curr.key)) {
        const transformedValue = tryParseBool(curr.value);
        prev[curr.key] = transformedValue;
      }
      return prev;
    }, {});
  };

export const selectUserStateItem = key => rootStore =>
  rootStore.user.userState?.stateValues?.find(kvp => kvp.key === key);

export const selectStateValue = (userState, key) => {
  const state = userState?.stateValues?.find(kvp => kvp.key === key);
  if (state?.value) {
    return tryParseBool(state.value);
  }
  return null;
};
