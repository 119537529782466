import { useState } from 'react';
import { processNotification } from './notificationHelpers';
import { Modal } from 'components/common';

const NotificationModal = ({
  notification,
  onDismiss = () => {},
  onDismissModal = () => {}
}) => {
  const { body, title, isShowInModal, dismissable } =
    processNotification(notification);
  const [isModalOpen, setIsModalOpen] = useState(true);

  if ((!body && !title) || !isShowInModal) return null;

  const onCloseModal = async () => {
    if (dismissable) {
      onDismiss(notification.notificationId);
    }
    onDismissModal(notification.notificationId);
    setIsModalOpen(false);
  };

  return (
    <Modal isOpen={isModalOpen} size="md" toggle={onCloseModal}>
      {!!title ? (
        <Modal.Title toggle={onCloseModal}>
          <h3>{title}</h3>
        </Modal.Title>
      ) : null}
      <Modal.Body>
        <span>{body}</span>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationModal;
