import { setQuizInsightStateActionType } from 'actions/quizInsight';
const initialState = {
  quizId: null,
  questions: []
};

const quizInsight = (state = initialState, action) => {
  switch (action.type) {
    case setQuizInsightStateActionType:
      return action.quizInsight;

    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default quizInsight;
