import { Button, Loading } from 'components/common';
import TileInputArray from 'components/common/FormInputs/TileInputArray';
import { penniesToPoundsGbp } from 'constants/plans';
import { useEediPlusPricingInfo } from 'hooks/useEediPlusPricingInfo';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { PricePlanTile } from './PricePlanTile';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 60%;
`;

const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const PricePlanChooser = ({ onConfirm = priceId => {} }) => {
  const { hasLoaded, priceOptions } = useEediPlusPricingInfo();

  const tileOptions = useMemo(() => {
    const pricesAsc = sortBy(priceOptions, p => p.equivalentMonthlyPrice);
    const mostExpensivePlan = pricesAsc[pricesAsc.length - 1];

    return pricesAsc.map((price, index) => {
      const formattedInterval = (
        price.interval === 'day' ? 'daily' : `${price.interval}ly`
      ).toUpperCase();

      return {
        value: price.stripePriceId,
        interval: formattedInterval,
        pricePerMonth: penniesToPoundsGbp(price.equivalentMonthlyPrice),
        subText:
          price.interval === 'year'
            ? `1 payment of £${penniesToPoundsGbp(price.unitAmount)}`
            : `£${penniesToPoundsGbp(price.equivalentYearlyPrice)} / year`,
        yearlySaveAmount:
          price.stripePriceId !== mostExpensivePlan.stripePriceId
            ? mostExpensivePlan.equivalentYearlyPrice -
              price.equivalentYearlyPrice
            : undefined,
        isSelected: index === 0
      };
    });
  }, [priceOptions]);

  const {
    formState: { isSubmitting },
    control,
    errors,
    handleSubmit
  } = useForm({
    mode: 'onChange'
  });

  if (!hasLoaded) {
    return <Loading />;
  }
  return (
    <form onSubmit={handleSubmit(({ priceId }) => onConfirm(priceId[0]))}>
      <Body>
        <h1 className="h2 mb-4">Choose how you’d like to pay for Eedi Plus</h1>

        <TileContainer>
          <TileInputArray
            control={control}
            name="priceId"
            renderItem={PricePlanTile}
            options={tileOptions}
            multiSelect={false}
            error={errors.priceId?.message}
            rules={{ required: 'You must choose a payment plan' }}
          />
        </TileContainer>

        <StyledButton color="primary" className="mt-4" loading={isSubmitting}>
          Next
        </StyledButton>
      </Body>
    </form>
  );
};
