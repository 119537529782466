import { FormError } from 'api';
import { useForm } from 'react-hook-form';

/**
 * A wrapper for react-hook-form
 * @param {object} formOptions - options that react-hook-form expects
 */
export default function useApiValidatedForm(formOptions) {
  const form = useForm(formOptions);

  /**
   * Wrapper on `handleSubmit` of react-hook-form (RHF), that maps `modelState` from FormError
   * and RHF's errors.
   *
   * IMPORTANT!! If there is a 'try...catch' block in the `onSubmit` function, the catch block
   * must re-throw the exception in order for this wrapper function to perform the map
   *
   * @param {function} onSubmit - submit function (same as one required by react-hook-form) that calls API
   * @param  {...any} args
   */
  const handleSubmit = (onSubmit, ...args) =>
    form.handleSubmit(async data => {
      try {
        await Promise.resolve(onSubmit(data));
      } catch (e) {
        if (e instanceof FormError) {
          Object.entries(e.modelState).map(([k, v]) =>
            form.setError(k, { type: 'manual', message: v.message })
          );
        }
      }
    }, ...args);

  return {
    ...form,
    handleSubmit: handleSubmit
  };
}
