import { hasValidSubscription } from 'helpers/validateSubscription';
import {
  EediPlusContext,
  EediPlusFeatureFocus
} from 'providers/EediPlus/EediPlusProvider';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useEediPlusAction = (
  action,
  modalFeatureFocus = EediPlusFeatureFocus.LiveChat
) => {
  const subscription = useSelector(({ user }) => user.subscription);
  const { launchModal } = useContext(EediPlusContext);

  const wrappedAction = useMemo(
    () => () => {
      if (hasValidSubscription(subscription)) {
        return action();
      }

      launchModal(modalFeatureFocus);
    },
    [action, launchModal, modalFeatureFocus, subscription]
  );

  return wrappedAction;
};
