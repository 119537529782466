import { Helmet } from 'react-helmet';
import { Container } from 'components/common';
import styled from 'styled-components';

const Page = ({ title = '', container, children, ...props }) => (
  <>
    <Helmet>
      <title>{title && title !== '' ? title + ' - Eedi' : 'Eedi'}</title>
    </Helmet>
    {container ? (
      <StyledMain {...props}>
        <Container className={`container-${container}`}>{children}</Container>
      </StyledMain>
    ) : (
      <>{children}</>
    )}
  </>
);

Page.Main = ({ container, children, ...props }) => (
  <StyledMain {...props}>
    {container ? (
      <Container className={`container-${container}`}>{children}</Container>
    ) : (
      <>{children}</>
    )}
  </StyledMain>
);

const StyledMain = styled.main`
  margin-bottom: 5rem;
  padding: clamp(1.5rem, 2%, 5rem) 0;
  position: relative;
`;

export default Page;
