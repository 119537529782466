import { EmojiSpan } from 'components/common';
import TopicIcon from 'components/TopicPathway/TopicIcon';
import styled from 'styled-components';
import TopicCalendarIcon from './TopicCalendarIcon';

export const UpcomingAssignmentsEmptyState = () => {
  return (
    <EmptyStateWrapper>
      <div style={{ display: 'flex', margin: '0 0 1rem 0' }}>
        <TopicCalendarIcon size="5rem" />
      </div>
      <h3>
        Nothing to see yet <EmojiSpan ariaLabel="googlyEyes" emoji="👀" />{' '}
      </h3>
      <p>Upcoming assignments set by your teacher will appear here!</p>
    </EmptyStateWrapper>
  );
};

export const OverdueAssignmentsEmptyState = () => {
  return (
    <EmptyStateWrapper>
      <div style={{ display: 'flex', margin: '0 0 1rem 0' }}>
        <TopicCalendarIcon size="5rem" />
      </div>
      <h3>
        Nothing to see yet <EmojiSpan ariaLabel="googlyEyes" emoji="👀" />{' '}
      </h3>
      <p>Overdue assignments will appear here!</p>
    </EmptyStateWrapper>
  );
};

export const StartedAssignmentsEmptyState = () => {
  return (
    <EmptyStateWrapper>
      <div style={{ display: 'flex', margin: '0 0 1rem 0' }}>
        <TopicCalendarIcon size="5rem" />
      </div>
      <h3>
        Nothing to see yet <EmojiSpan ariaLabel="googlyEyes" emoji="👀" />{' '}
      </h3>
      <p>Started and completed assignments will appear here!</p>
    </EmptyStateWrapper>
  );
};

export const ActivityEmptyState = () => {
  return (
    <EmptyStateWrapper>
      <div style={{ display: 'flex', margin: '0 0 1rem 0' }}>
        <TopicIcon size="5rem" />
      </div>
      <h3>
        Nothing to see yet <EmojiSpan ariaLabel="googlyEyes" emoji="👀" />{' '}
      </h3>
      <p>There's no recent activity to show!</p>
    </EmptyStateWrapper>
  );
};

export const ParentActivityEmptyState = () => {
  return (
    <EmptyStateWrapper>
      <div style={{ display: 'flex', margin: '0 0 1rem 0' }}>
        <TopicIcon size="5rem" />
      </div>
      <h3>
        Nothing to see yet <EmojiSpan ariaLabel="googlyEyes" emoji="👀" />{' '}
      </h3>
      <p>
        There's no recent activity to show! Ask your child to take a topic to
        view their progress here.
      </p>
    </EmptyStateWrapper>
  );
};

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 20px 0 0 0;
`;
