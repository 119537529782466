import styled from 'styled-components';

const Wrapper = styled.div`
  animation: bulge 2s ease-out infinite;
  display: flex;

  span {
    height: 0.75rem;
    width: 0.75rem;
    margin: 0 0.25rem;
    background-color: var(--gray);
    display: block;
    border-radius: 50%;
    opacity: 0.4;
  }

  span:nth-of-type(1) {
    animation: blink 1s 0.3333s infinite, bounce 1s 0.3333s infinite;
  }

  span:nth-of-type(2) {
    animation: blink 1s 0.6666s infinite, bounce 1s 0.6666s infinite;
  }

  span:nth-of-type(3) {
    animation: blink 1s 0.9999s infinite, bounce 1s 0.9999s infinite;
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }

  @keyframes bounce {
    50% {
      transform: translateY(0.3rem);
    }
  }
`;

export const TypingIndicator = props => {
  return (
    <Wrapper {...props}>
      <span />
      <span />
      <span />
    </Wrapper>
  );
};
