export const SetAllStudentsType = 'SET_ALL_STUDENTS';
export const setAllStudents = students => ({
  type: SetAllStudentsType,
  students
});

export const SwitchSchoolType = 'SWITCH_SCHOOL';
export const switchSchool = (schoolId, studentId = null) => ({
  type: SwitchSchoolType,
  schoolId,
  studentId
});

export const SetChildType = 'SET_CHILD';
const setChild = (schoolId, child) => ({
  type: SetChildType,
  schoolId,
  child
});

export const SetChildrenType = 'SET_CHILDREN';
export const setChildren = children => ({
  type: SetChildrenType,
  children
});

export const SetChildPreferencesType = 'SET_CHILD_PREFERENCES';
export const setChildPreferences = preferences => ({
  type: SetChildPreferencesType,
  preferences
});

export const updateChild = childId => {
  return async (dispatch, _) => {
    // Switch back to the child, forcing a refresh
    await dispatch(switchChild(childId, true));
  };
};

export const switchChild = (childId, forceReload = false) => {
  return async (dispatch, getState) => {
    let {
      school: { schoolId, childId: currentChildId, allChildren }
    } = getState();

    // We're switching to the current child, do nothing
    if (childId === currentChildId && !forceReload) {
      return Promise.resolve();
    }

    let selectedChild = allChildren.find(x => x.childId === +childId);

    // We do not have this child in the store
    if (!selectedChild || forceReload) {
      let {
        school: { allChildren }
      } = getState();

      selectedChild = allChildren.find(x => x.childId === +childId);
      // If we still don't have the child, throw
      if (!selectedChild) {
        throw new Error(`Could not switch to non-existent child #${childId}`);
      }
    }

    dispatch(setChildPreferences(selectedChild.userPreferences));
    dispatch(setChild(schoolId, selectedChild));
  };
};
