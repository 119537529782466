import { activityTypes } from '../helpers/learningActivityHelpers';

export const ActivityStatuses = {
  AllCompleted: 'AllCompleted',
  EnableStretchChallenge: 'EnableStretchChallenge',
  StartTopic: 'StartTopic',
  ResumeTopic: 'ResumeTopic'
};

export const ActivityStatus = (activity, type) => {
  const quizSession = activity.quizSessions?.[0];

  if (!activity || !quizSession || !activity.topicPathwayQuizStatistics)
    return ActivityStatuses.StartTopic;

  const {
    answersCount,
    questionsCount,
    incompleteQuestionLessonsCount,
    stretchLessonsCount
  } = activity.topicPathwayQuizStatistics;

  if (
    quizSession?.dateCompleted == null ||
    answersCount < questionsCount ||
    !!incompleteQuestionLessonsCount
  )
    return ActivityStatuses.ResumeTopic;

  if (
    !!quizSession?.dateCompleted &&
    !stretchLessonsCount &&
    type !== activityTypes.retrievalpractice
  )
    return ActivityStatuses.EnableStretchChallenge;

  if (
    (!!quizSession?.dateCompleted && !!stretchLessonsCount) ||
    (type === activityTypes.retrievalpractice && !!quizSession?.dateCompleted)
  )
    return ActivityStatuses.AllCompleted;

  return ActivityStatuses.StartTopic;
};
