import { SKIP_SUBSCRIPTION_VALIDATION } from 'config/settings';
import { store } from 'store';
import { utc } from 'moment';

const checkSubscriptionStatus = (subscription, statuses) => {
  if (SKIP_SUBSCRIPTION_VALIDATION) {
    return true;
  }

  // No subscription? Double check the store
  if (!subscription) {
    const state = store.getState();
    subscription = state.user?.subscription;
  }

  if (
    subscription?.subscriptionStatus === 'trialing' &&
    !!subscription?.stripeSubscriptionId
  ) {
    return true;
  }

  // Return false if there's no subscription or the subscription period has ended
  if (
    !subscription ||
    !subscription.currentPeriodEndUTC ||
    utc(subscription.currentPeriodEndUTC).isBefore()
  ) {
    return false;
  }

  // Is this the subscription status you were looking for?
  if (statuses.indexOf(subscription.subscriptionStatus) !== -1) {
    return true;
  }

  return false;
};

export const hasValidSubscription = subscription => {
  return checkSubscriptionStatus(subscription, ['trialing', 'active']);
};

export const hasActiveSubscription = subscription => {
  return checkSubscriptionStatus(subscription, ['active']);
};

export const isTrialing = subscription => {
  return checkSubscriptionStatus(subscription, ['trialing']);
};

export const hasCancelled = subscription => {
  if (!subscription) {
    return false;
  }

  return (
    !subscription.currentPeriodEndUTC || subscription.hasHadActiveSubscription
  );
};
