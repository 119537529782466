import { EediPlusLogo, EmojiSpan } from 'components/common';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { Banner } from './Banner';
import queryString from 'querystring-es3';
import styled from 'styled-components';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router';

const StyledBanner = styled(Banner)`
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 0;
  padding: 0;
  overflow: hidden;

  &.show {
    height: 2.5rem;
    padding: 0.5rem;
  }

  transition: all 500ms linear;
`;

export const EediPlusWelcomeBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const [confettiState, setConfettiState] = useState({
    active: false,
    ending: false
  });

  useEffect(() => {
    let wasCancelled = false;

    const query = queryString.parse(location.search);

    if (
      query.trialSuccess !== undefined ||
      query.subscriptionSuccess !== undefined
    ) {
      query.trialSuccess = undefined;
      query.subscriptionSuccess = undefined;
      navigate({
        pathname: location.pathname,
        search: queryString.stringify(query),
        replace: true
      });

      setShowBanner(true);
      setConfettiState({ active: true, ending: false });
    }

    setTimeout(() => {
      if (wasCancelled) {
        return;
      }
      setShowBanner(false);
      setConfettiState(s => {
        if (s.active) {
          return { active: true, ending: true };
        }
        return s;
      });
    }, 6000);

    return () => (wasCancelled = true);
  }, [navigate, location.pathname, location.search]);

  const documentHeight = document.documentElement.scrollHeight;

  return (
    <>
      {confettiState.active && (
        <Confetti
          recycle={!confettiState.ending}
          height={documentHeight}
          onConfettiComplete={() =>
            setConfettiState({
              active: false,
              ending: false
            })
          }
        />
      )}
      <StyledBanner
        showInviteLink={false}
        color="var(--primary)"
        className={showBanner ? 'show' : ''}>
        <span style={{ color: 'white', margin: '0 auto' }}>
          Welcome to Eedi <EediPlusLogo lightTheme />{' '}
          <EmojiSpan emoji="🎉" ariaLabel="tada" />
        </span>
      </StyledBanner>
    </>
  );
};
