import { CustomInput } from 'reactstrap';

const Checkbox = ({ id, label, name, ...props }) => {
  return (
    <CustomInput
      id={id}
      name={name}
      type="checkbox"
      label={label}
      {...props}
      value={props.value || ''}
    />
  );
};

export default Checkbox;
