import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { hasClassroomSchoolFeature } from 'helpers/featureFlags';
import CachedApi from 'cache/CachedApi';

const useRCTRedirect = () => {
  const experiment = useRef(null);
  const isExperiment = hasClassroomSchoolFeature('RCTExperiment');
  const { userId, userRole, acceptTermsAndConditions } = useSelector(s => ({
    userId: s.user.userId,
    userRole: s.user.userRole,
    acceptTermsAndConditions: s.user.acceptTermsAndConditions
  }));
  const location = useLocation();
  const navigate = useNavigate();
  const isExperimentPage =
    location.pathname.includes('rct') ||
    location.pathname.includes('quiz-session') ||
    location.pathname.includes('logout');

  const fetchExperiment = useCallback(async () => {
    try {
      const isRctAble =
        (!experiment.current ||
          (!!experiment.current?.isControl && !isExperimentPage)) &&
        isExperiment &&
        !!userId &&
        userRole === 'Student' &&
        acceptTermsAndConditions;

      if (isRctAble) {
        experiment.current = await CachedApi.getRCTExperiment(userId);

        if (
          acceptTermsAndConditions &&
          !isExperimentPage &&
          experiment.current?.isControl
        ) {
          navigate('/rct');
        }
      }
    } catch (e) {
      experiment.current = {};
      console.error(e);
    }
  }, [
    acceptTermsAndConditions,
    isExperiment,
    isExperimentPage,
    navigate,
    userId,
    userRole
  ]);

  useEffect(() => {
    const runEffect = async () => {
      await fetchExperiment();
    };
    runEffect();
  }, [fetchExperiment]);

  return {
    experiment: experiment.current,
    isExperimentPage,
    isExperiment,
    isControl: experiment.current?.isControl
  };
};

export default useRCTRedirect;
