import { Loading } from 'components/common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LevelControls } from './LevelControls';
import { TopicSearchContainer } from './TopicSearch/TopicSearchContainer';
import subjects from 'constants/subjects';
import TopicPathwayGrid from './TopicPathwayGrid';
import TopicPathwayFactory from 'factories/topicPathwayFactory';
import { useSelector } from 'react-redux';
import useGuestDynamicQuiz from 'hooks/useGuestDynamicQuiz';

export const TopicPathwayContainer = () => {
  const { guestDynamicQuiz } = useGuestDynamicQuiz();

  const { childStudentId, userStudentId, schoolId } = useSelector(s => ({
    childStudentId: s.school.child?.studentId,
    userStudentId: s.school.studentId,
    schoolId: s.school.schoolId
  }));
  const studentId = childStudentId || userStudentId;
  const [showPathway, setShowPathway] = useState(true);
  const [states, setStates] = useState({
    isLoaded: false,
    factory: {},
    data: {}
  });

  const reload = useCallback(async () => {
    if (studentId) {
      setStates({ isLoaded: false });
      const factory = TopicPathwayFactory(
        studentId,
        schoolId,
        guestDynamicQuiz
      );
      const topicPathwayData = await factory.fetchTopicPathway();
      setStates(s => ({
        ...s,
        data: topicPathwayData,
        isLoaded: true,
        factory
      }));
    }
  }, [studentId, schoolId, guestDynamicQuiz]);

  const setLevel = useCallback(
    async targetLevel => {
      const {
        data: { levelProgress, topicPathwayCollectionStudent },
        factory
      } = states;

      if (!topicPathwayCollectionStudent) return;

      // Do not update if level is unchanged to save bandwidth
      if (levelProgress.level === targetLevel) return;

      setStates(s => ({ ...s, isLoaded: false }));

      const topicPathwayData = await factory.fetchTopicPathway(targetLevel);

      setStates(s => ({ ...s, data: topicPathwayData, isLoaded: true }));
    },
    [states]
  );

  let {
    levelProgress: currentLevelProgress,
    topicPathwayCollectionStudent,
    topicPathwayCollectionGroups
  } = states.data ?? {};

  const [currentViewLevel, setCurrentViewLevel] = useState();

  useEffect(() => {
    if (!currentViewLevel) {
      const groupInView = topicPathwayCollectionGroups?.find(
        t => t.topicPathwayCollectionGroupId === -1
      );

      setCurrentViewLevel(groupInView?.level);
    }
  }, [currentViewLevel, topicPathwayCollectionGroups]);

  const studentTopicPathwayLevels = useMemo(
    () => topicPathwayCollectionStudent?.progress.levelProgress ?? [],
    [topicPathwayCollectionStudent?.progress.levelProgress]
  );

  const { minLevel, maxLevel } = useMemo(() => {
    const maxLevel = studentTopicPathwayLevels.sort(
      (a, b) => b.level - a.level
    )[0]?.level;

    return {
      minLevel: 1,
      maxLevel
    };
  }, [studentTopicPathwayLevels]);

  const goToNextLevel = useCallback(() => {
    if (studentTopicPathwayLevels.length === 0) return;

    const nextLevel = studentTopicPathwayLevels
      .sort((a, b) => a.level - b.level)
      .filter(a => a.level > currentViewLevel)[0];

    if (!nextLevel)
      throw new Error(
        `Level ${currentViewLevel + 1} is not available as next level`
      );

    setCurrentViewLevel(nextLevel.level);
    setLevel(nextLevel.level);
  }, [studentTopicPathwayLevels, currentViewLevel, setLevel]);

  const goToPreviousLevel = useCallback(() => {
    if (studentTopicPathwayLevels.length === 0) return;

    const prevLevel = studentTopicPathwayLevels
      .sort((a, b) => b.level - a.level)
      .filter(a => a.level < currentViewLevel)[0];

    if (!prevLevel)
      throw new Error(
        `Level ${currentViewLevel + 1} is not available as previous level`
      );

    setCurrentViewLevel(prevLevel.level);
    setLevel(prevLevel.level);
  }, [studentTopicPathwayLevels, currentViewLevel, setLevel]);

  useEffect(() => {
    if (studentId) {
      reload();
    }
  }, [reload, studentId]);

  // Subject filters
  const [searchSubjects, setSearchSubjects] = useState([]);
  const [currentSubject, setCurrentSubject] = useState(1);

  useEffect(() => {
    if (!states.isLoaded) return;
    const newSearchSubjects = [
      ...new Set(
        topicPathwayCollectionGroups.map(tpcg => {
          return {
            id: tpcg.topicPathwayCollectionGroupId,
            value: tpcg.name
          };
        })
      )
    ];
    setSearchSubjects(newSearchSubjects);
    setCurrentSubject(newSearchSubjects[0]?.id);
  }, [states.isLoaded, topicPathwayCollectionGroups]);

  if (!states.isLoaded) return <Loading />;

  return (
    <>
      <TopicSearchContainer
        topicPathwayCollectionId={
          topicPathwayCollectionStudent.topicPathwayCollectionId
        }
        onSearchTextChange={query => setShowPathway(query.length < 1)}
        subjectFilters={subjects}
        searchSubjects={searchSubjects}
        setCurrentSubject={setCurrentSubject}
        currentSubject={
          searchSubjects?.find(s => s.id === currentSubject)?.value
        }
      />

      {showPathway && (
        <>
          <TopicPathwayGrid
            currentTab={currentSubject}
            topicPathwayCollectionGroups={topicPathwayCollectionGroups}
          />
          <LevelControls
            currentLevel={topicPathwayCollectionStudent.currentLevel}
            nextLevel={topicPathwayCollectionStudent.progress.currentLevel}
            levelAfterNext={topicPathwayCollectionStudent.progress.nextLevel}
            canProgress={currentViewLevel < maxLevel}
            canBack={currentViewLevel > minLevel}
            topicsToComplete={
              currentLevelProgress.quizCount -
              currentLevelProgress.completedQuizCount
            }
            onProgressLevel={goToNextLevel}
            onPreviousLevel={goToPreviousLevel}
          />
        </>
      )}
    </>
  );
};
