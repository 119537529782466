import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Api from 'api/Api';
import { setGuestDynamicQuiz } from 'actions/guest';
import { useLocation } from 'react-router';

const useGuestDynamicQuiz = quizClaimCode => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { guestDynamicQuiz } = useSelector(s => s.guest);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const runEffect = async () => {
      if (location.pathname.includes('signup')) {
        const localQuizClaimCode = localStorage.getItem('quizClaimCode');
        if (
          (quizClaimCode &&
            quizClaimCode !== guestDynamicQuiz?.dynamicQuizClaimCode) ||
          (!quizClaimCode && localQuizClaimCode && !guestDynamicQuiz)
        ) {
          const guestDynamicQuizResult = await Api.getGuestDynamicQuizDetails(
            quizClaimCode ?? localQuizClaimCode
          );
          dispatch(setGuestDynamicQuiz(guestDynamicQuizResult));
          localStorage.setItem(
            'quizClaimCode',
            quizClaimCode ?? localQuizClaimCode
          );
        }
      } else if (location.pathname !== '/') {
        dispatch(setGuestDynamicQuiz(null));
        localStorage.removeItem('quizClaimCode');
      }
      setIsLoading(false);
    };
    runEffect();
  }, [
    dispatch,
    guestDynamicQuiz,
    guestDynamicQuiz?.dynamicQuizClaimCode,
    location.pathname,
    quizClaimCode
  ]);

  return { guestDynamicQuiz, isLoading };
};

export default useGuestDynamicQuiz;
