/* eslint-disable no-unused-vars */
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import Circle from 'assets/icons/circle';
import { TransitionGroup } from 'react-transition-group';
import useInterval from '../../hooks/useInterval';
import ThinChevron from 'assets/icons/ThinChevron';
import { Fade } from './Transitions';

const shuffleArray = array => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

const Carousel = ({
  slides = [],
  isBottomNavigation = true,
  duration = 300,
  transition = 5000,
  shuffle = false,
  disableNavigation = false,
  ...props
}) => {
  const carouselRef = useRef(null);
  const [carouselState, setCarouselState] = useState({
    currentIndex: 0,
    transition
  });

  const incrementCarousel = useCallback(
    (incrementAmount = 1, transition = carouselState.transition) => {
      setCarouselState(() => ({
        ...carouselState,
        currentIndex:
          carouselState.currentIndex + incrementAmount > slides?.length - 1
            ? 0
            : carouselState.currentIndex + incrementAmount < 0
            ? slides?.length - 1
            : carouselState.currentIndex + incrementAmount,
        transition
      }));
    },
    [carouselState, slides]
  );

  useInterval(() => incrementCarousel(), carouselState.transition);

  slides = shuffle ? shuffleArray(slides) : slides;

  return (
    <CarouselWrapper duration={duration} ref={carouselRef} {...props}>
      <TransitionGroup>
        {slides
          .filter((s, i) => i === carouselState.currentIndex)
          .map((slide, i) => (
            <Fade key={Math.random() + i} timeout={duration}>
              {slide}
            </Fade>
          ))}
      </TransitionGroup>
      {!disableNavigation && (
        <>
          <StyledChevron
            direction="left"
            color="var(--gray-light)"
            onClick={() => incrementCarousel(-1, null)}
          />
          <StyledChevron
            direction="right"
            color="var(--gray-light)"
            style={{ left: '97.8%' }}
            onClick={() => incrementCarousel(1, null)}
          />
        </>
      )}
      {isBottomNavigation && (
        <BottomNavigation>
          {slides.map((_slide, index) => {
            return (
              <CircleWrapper
                key={index}
                onClick={() =>
                  setCarouselState(() => ({
                    ...carouselState,
                    currentIndex: index,
                    transition: null
                  }))
                }>
                <Circle
                  color={
                    index === carouselState.currentIndex
                      ? 'var(--indigo)'
                      : 'var(--gray-light)'
                  }
                />
              </CircleWrapper>
            );
          })}
        </BottomNavigation>
      )}
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity
      ${({ duration }) => (!!duration ? `${duration}ms` : '1000ms')};
  }
  .fade-exit {
    display: none;
  }

  .fade-exit-active {
    display: none;
  }
`;

const BottomNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 1rem;
`;

const CircleWrapper = styled.div`
  cursor: pointer;
`;

const StyledChevron = styled(ThinChevron)`
  position: relative;
  top: 50%;
  transform: translateY(-10rem);
  width: 50px;
  margin-bottom: -1.9rem;
  z-index: 999;
`;

function propsAreEqual(prev, curr) {
  return (
    prev.slides?.length === curr.slides?.length &&
    prev.isBottomNavigation === curr.isBottomNavigation
  );
}

const MemoizedCarousel = React.memo(Carousel, propsAreEqual);

export default MemoizedCarousel;
