const colors = {
  grayLightest: 'var(--gray-lightest)',
  grayLighter: 'var(--gray-lighter)',
  grayLight: 'var(--gray-light)',
  gray: 'var(--gray)',
  grayDark: 'var(--gray-dark)',

  indigoLightest: 'var(--indigo-lightest)',
  indigoLighter: 'var(--indigo-lighter)',
  indigoLight: 'var(--indigo-light)',
  indigo: 'var(--indigo)',
  indigoDark: 'var(--indigo-dark)',

  blueLightest: 'var(--blue-lightest)',
  blueLighter: 'var(--blue-lighter)',
  blueLight: 'var(--blue-light)',
  blue: 'var(--blue)',
  blueDark: 'var(--blue-dark)',

  tealLightest: 'var(--teal-lightest)',
  tealLighter: 'var(--teal-lighter)',
  tealLight: 'var(--teal-light)',
  teal: 'var(--teal)',
  tealDark: 'var(--teal-dark)',

  greenLightest: 'var(--green-lightest)',
  greenLighter: 'var(--green-lighter)',
  greenLight: 'var(--green-light)',
  green: 'var(--green)',
  greenDark: 'var(--green-dark)',

  yellowLightest: 'var(--yellow-lightest)',
  yellowLighter: 'var(--yellow-lighter)',
  yellowLight: 'var(--yellow-light)',
  yellow: 'var(--yellow)',
  yellowDark: 'var(--yellow-dark)',

  orangeLightest: 'var(--orange-lightest)',
  orangeLighter: 'var(--orange-lighter)',
  orangeLight: 'var(--orange-light)',
  orange: 'var(--orange)',
  orangeDark: 'var(--orange-dark)',

  redLightest: 'var(--red-lightest)',
  redLighter: 'var(--red-lighter)',
  redLight: 'var(--red-light)',
  red: 'var(--red)',
  redDark: 'var(--red-dark)',

  pinkLightest: 'var(--pink-lightest)',
  pinkLighter: 'var(--pink-lighter)',
  pinkLight: 'var(--pink-light)',
  pink: 'var(--pink)',
  pinkDark: 'var(--pink-dark)',

  purpleLightest: 'var(--purple-lightest)',
  purpleLighter: 'var(--purple-lighter)',
  purpleLight: 'var(--purple-light)',
  purple: 'var(--purple)',
  purpleDark: 'var(--purple-dark)',

  white: 'var(--white)',
  black: 'var(--black)',

  gold: 'var(--gold)',
  silver: 'var(--silver)',
  bronze: 'var(--bronze)',

  primary: 'var(--primary)',
  secondary: 'var(--secondary)',
  success: 'var(--success)',
  info: 'var(--info)',
  warning: 'var(--warning)',
  danger: 'var(--danger)',
  light: 'var(--light)',
  dark: 'var(--dark)',

  bodyColor: 'var(--body-color)',
  bodyBg: 'var(--body-bg)',

  borderColor: 'var(--border-color)',
  tintColor: 'var(--gray-lightest)',
  highlightColor: 'var(--indigo-lightest)'

  //white: '#ffffff',

  //gray1: '#F4F5F7',
  //gray2: '#DDE0E2',
  //gray4: '#C1C1CA',
  //gray6: '#848395',
  //gray8: '#32314F',
  //black: '#050038',

  //success: '#ADEED3',
  //danger: '#FBCACA',
  //info: '#D7ECFF',
  //warning: '#FAF3D3',
  //secondary: '#ECEDFF',

  //botPurple: '#8D34FF',
  //botPurpleLight: '#F8F2FF',

  //purple1: 'rgba(67, 83, 255, 0.1)',
  //purple2: 'rgba(67, 83, 255, 0.2)',
  //purple4: 'rgba(67, 83, 255, 0.4)',
  //purple6: 'rgba(67, 83, 255, 0.6)',
  //purple8: 'rgba(67, 83, 255, 0.8)',
  //purple: 'rgb(67, 83, 255)',

  //aqua: '#DCF0EC',
  //blue: '#36ABFF',
  //indigo: '#6610f2',
  //pink: '#FF35C0',
  //accentPink: '#F965C8',
  //red: '#F53838',
  //orange: '#FC5926',
  //yellow: '#FEAC06',
  //green: '#09C18A',
  //veryLightGreen: '#D3F8E8',
  //cyan: '#17a2b8',
  //pearl: '#E8F1F7',
  //lightPink: '#FFD0D9',
  //bronze: '#D88C45',
  //lavender: '#945ED8',
  //darkBlue: '#302093',

  //banana: '#FAF4D3',
  //blond: '#FFEDB9',
  //sunShower: '#FFDB72',
  //gold: '#FFBB2F',

  //paleViolet: '#F4EBFF',
  //lightGrayBlue: '#F3F8FD',
  //darkerLightGrayBlue: '#DFEAF4',

  //grayishBlue: '#F5F6F7',
  //lightSteelBlue: '#9ABCDA',
  //aliceBlue: '#EBF5FF',
  //cornflower: '#A7C9E9',

  //pastelRed: '#CA576C',
  //pastelBlue: '#4F4CE9',
  //pastelPink: '#F9ABB9',
  //pastelPeach: '#FEE7D1',
  //pastelTeal: '#89C5FD',
  //pastelYellow: '#FCD54A',
  //pasteLightPink: '#FFD0D9',
  //accentPurple: '#4328EE',
  //mediumPurple: '#9284E3',

  //teamBlue: '#165BE2',

  //transparent: 'transparent',

  //shadow: '0px 2px 4px rgba(5, 0, 56, 0.1)',
  //shadow2: '0px 4px 8px rgba(5, 0, 56, 0.1)',
  //shadow3: '0px 8px 16px rgba(5, 0, 56, 0.1)',
  //shadow4: '0px 16px 32px rgba(5, 0, 56, 0.1)'
};

export default colors;
