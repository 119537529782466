import { Api } from 'api';
import { Button, Modal, Form, toaster, EmojiSpan } from 'components/common';
import TextInput from 'components/common/FormInputs/TextInput';
import useApiValidatedForm from 'hooks/useApiValidatedForm';
import { useNavigate } from 'react-router';

const SwitchToParentModal = ({ isOpen, closeModal }) => {
  const {
    control,
    reset,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useApiValidatedForm();

  const navigate = useNavigate();

  const handleLogin = async ({ email, password }) => {
    try {
      const token = await Api.signIn(email.trim(), password);
      if (token) {
        navigate(`/loginredirect?token=${token}`);
      } else {
        throw new Error('Unable to log in. Please try again.');
      }
    } catch (e) {
      console.error(e);
      // Reset form and prompt user to try again
      toaster.danger(e.message);
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header toggle={closeModal}>Log in to Parent Hub</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleLogin)} loading={isSubmitting}>
          <p>
            The Parent Hub is for parents only. We keep sensitive stuff there,
            like billing info. No worries, you’re not missing out on much{' '}
            <EmojiSpan ariaLabel="Grinning Face with Sweat" emoji="😅" />
          </p>
          <Form.Group>
            <Form.Group>
              <TextInput
                control={control}
                name="email"
                label="Parent Email"
                placeholder="parent@example.com"
                error={errors.email?.message}
                rules={{
                  required: 'Please enter a valid email address'
                }}
                autoFocus
              />
            </Form.Group>
            <TextInput
              control={control}
              type="password"
              name="password"
              label="Parent Password"
              placeholder="Password (10+ characters)"
              disabled={isSubmitting}
              error={errors.password?.message}
              rules={{
                required: 'Please enter a valid password',
                minLength: {
                  value: 10,
                  message: 'Your password must be at least 10 characters long'
                }
              }}
            />
          </Form.Group>
          <Button
            color="primary"
            size="lg"
            type="submit"
            className="mb-4"
            block>
            Log in to Parent Hub
          </Button>
          <span className="text-gray">
            Please note that logging in to Parent Hub will log you out of this
            account
          </span>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SwitchToParentModal;
