import { useDispatch } from 'react-redux';
import { userStates } from 'constants/userStates';
import { setNotifications, setUser, updateUserStateKvp } from 'actions/user';
import { useCallback } from 'react';
import { useRef } from 'react';
import {
  setAllStudents,
  setChildren,
  switchChild,
  switchSchool
} from 'actions/school';
import { HOME_SCHOOL_IDS } from 'config/settings';
import { toaster } from 'components/common';
import { useNavigate } from 'react-router';
import CachedApi from 'cache/CachedApi';
import { cache } from 'store';
import types from 'cache/types';
import { setRoutineState } from 'actions/routine';
import { setCoinsState } from 'actions/coins';
import { setShopItems } from 'actions/shopItems';

const useLoadUserContext = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRef = useRef({ loading: false, context: null });

  const isAccountDisabled = context => {
    if (!context || context?.userRole !== 'Student') return false;

    return !!context.featureFlagSchools?.find(
      s => s.eediHomeFeature === 'Disabled'
    );
  };

  const setStudentContext = useCallback(async () => {
    const context = userRef.current.context;

    if (!context || context?.userRole !== 'Student') return;

    try {
      const familyOrDefaultSchool =
        context.students.find(s => HOME_SCHOOL_IDS.includes(s.schoolId)) ??
        context.students[0];

      dispatch(
        switchSchool(
          familyOrDefaultSchool?.schoolId,
          familyOrDefaultSchool?.studentId
        )
      );

      dispatch(setAllStudents(context?.students ?? []));

      dispatch(setShopItems(context?.userItems ?? []));

      if (context?.routineState) {
        dispatch(setRoutineState(context?.routineState));
      }

      if (context?.transactionInsights) {
        dispatch(setCoinsState(context?.transactionInsights));
      }

      if (context?.notifications) {
        dispatch(setNotifications(context?.notifications));
      }

      if (!context.acceptTermsAndConditions) {
        navigate('/terms');
        return;
      } else {
        const from = localStorage.getItem('from');
        if (from) {
          localStorage.removeItem('from');
          navigate(from);
        }
      }
    } catch (e) {
      console.error(e);
      toaster.danger('Error setting student context!');
    }
  }, [dispatch, navigate]);

  const setParentContext = useCallback(async () => {
    const context = userRef.current.context;

    if (!context || context?.userRole !== 'Parent') return;

    try {
      const children =
        context.children?.map(c => {
          c.classroomStudents = c.students;
          return c;
        }) ?? [];

      dispatch(setChildren(children));

      if (children.length) {
        dispatch(switchSchool(children[0].schoolId, children[0].studentId));
        dispatch(switchChild(children[0].childId, true));
      }

      const from = localStorage.getItem('from');
      if (from) {
        localStorage.removeItem('from');
        navigate(from);
      }
    } catch (e) {
      console.error(e);
      toaster.danger('Error setting parent context!');
    }
  }, [dispatch, navigate]);

  const setUserContext = useCallback(
    async (isCached = false) => {
      userRef.current.loading = true;

      try {
        !isCached &&
          cache.bustTypeCache({ typenames: [types.userRoleContext.typename] });

        userRef.current.context = await CachedApi.getUserRoleContext();

        if (userRef.current.context) {
          dispatch(
            setUser({
              ...userRef.current.context,
              isAccountDisabled: isAccountDisabled(userRef.current.context)
            })
          );

          await setStudentContext();
          await setParentContext();

          if (userRef.current.context?.userRole !== 'Teacher') {
            dispatch(updateUserStateKvp(userStates.hasLoggedIn, 'true'));
          }
        }
      } catch (e) {
        console.error(e);
        toaster.danger('Error setting user context!');
      }

      userRef.current.loading = false;

      return userRef.current.context ?? {};
    },
    [dispatch, setParentContext, setStudentContext]
  );

  return async (isCached = false) => await setUserContext(isCached);
};

export default useLoadUserContext;
