import styled from 'styled-components';
import BadgeLockedSVG from 'assets/images/achievements/badge_locked.svg';
import { useLayoutEffect, useMemo, useState } from 'react';

const Badge = styled.img`
  max-height: 12rem;
  max-width: 12rem;

  user-select: none;
  transition: transform 0.3s cubic-bezier(0.64, 0.57, 0.4, 1.56);
  transform: scale(1) rotate(0);

  &:not(.locked) {
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
    }

    &:active {
      transition-duration: 0.2s;
      transform: scale(0.8);
    }
  }

  @keyframes shake {
    0% {
      transform: translate(0, 0);
    }

    20% {
      transform: translate(-5%, -5%);
    }

    40% {
      transform: translate(5%, 5%);
    }

    60% {
      transform: translate(5%, -5%);
    }

    80% {
      transform: translate(-5%, 5%);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(-10%);
    }

    40% {
      transform: rotate(30deg);
    }

    60% {
      transform: rotate(-30deg);
    }

    80% {
      transform: rotate(30deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes fadeToWhite {
    0% {
      filter: contrast(1) brightness(1);
    }

    80% {
      filter: contrast(0) brightness(2);
    }

    100% {
      filter: contrast(0) brightness(2);
    }
  }

  &.wiggle {
    animation: wiggle 0.4s linear;
  }

  &.unlock {
    animation: shake 1s linear reverse, shake 0.5s linear alternate 2 1s,
      shake 0.2s linear alternate 10 2s, fadeToWhite 5s linear,
      fadeToWhite 1s reverse 5s;
  }
`;

export const AchievementIcon = ({
  achievement = { iconUrl: '', name: 'achievement' },
  isLocked = false,
  playUnlock = false,
  ...props
}) => {
  const [shouldWiggleIcon, setShouldWiggleIcon] = useState(false);

  const iconClasses = useMemo(() => {
    const classes = [];
    if (isLocked) {
      classes.push('locked');
      if (shouldWiggleIcon) {
        classes.push('wiggle');
      }
    }

    if (playUnlock) {
      classes.push('unlock');
    }

    return classes.join(' ');
  }, [isLocked, playUnlock, shouldWiggleIcon]);

  const [badgeUrl, setBadgeUrl] = useState(
    isLocked ? BadgeLockedSVG : achievement.iconUrl
  );

  useLayoutEffect(() => {
    let handle;
    if (playUnlock) {
      setBadgeUrl(BadgeLockedSVG);
      setTimeout(() => setBadgeUrl(achievement.iconUrl), 4000);
    }

    return () => clearTimeout(handle);
  }, [achievement.iconUrl, playUnlock]);

  return (
    <Badge
      className={iconClasses}
      src={badgeUrl}
      onMouseEnter={() => setShouldWiggleIcon(true)}
      onMouseDown={() => setShouldWiggleIcon(true)}
      onAnimationEnd={() => setShouldWiggleIcon(false)}
      isLocked={isLocked}
      alt={achievement.name}
      {...props}
    />
  );
};
