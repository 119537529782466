import { Api } from 'api';

export const setQuizInsightStateActionType = 'SET_QUIZ_INSIGHT_STATE';
export const setQuizInsightState = newState => ({
  type: setQuizInsightStateActionType,
  quizInsight: newState
});

export const updateQuizInsight =
  (quizId, fromDaysAgo = null) =>
  async (dispatch, getState) => {
    const { quizInsight: storedQuizInsight } = getState();

    // NOTE: Assuming that all QuizInsights are for 'today', hence fromDaysAgo is always 0
    // Effectively treating the store as the local cache for a quiz insight for the day
    if (storedQuizInsight.quizId !== quizId) {
      const quizInsight = await Api.getQuizInsights(quizId, fromDaysAgo);

      dispatch(setQuizInsightState(quizInsight));
    }
  };
