import { Checkbox, Switch } from 'components/common';

import { Controller } from 'react-hook-form';
import { FormFeedback } from 'reactstrap';

const CheckboxInput = ({
  control,
  id,
  name,
  type = 'checkbox',
  label = undefined,
  error = undefined,
  rules = undefined,
  defaultValue = false,
  disabled = false,
  ...props
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onChange, onBlur, value }) => (
        <>
          {type === 'switch' ? (
            <Switch
              id={id ?? name}
              enabled={value}
              onClick={() => onChange(!value)}
              onBlur={onBlur}
              disabled={disabled}
              {...props}
            />
          ) : (
            <Checkbox
              id={id ?? name}
              label={label}
              name={name}
              checked={value}
              onChange={e => onChange(e.target.checked)}
              onBlur={onBlur}
              disabled={disabled}
              {...props}
            />
          )}
          {error && <FormFeedback>{error}</FormFeedback>}
        </>
      )}
      rules={rules}
    />
  );
};
export default CheckboxInput;
