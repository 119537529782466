import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DropAnimation } from '../DropAnimation';
import { config, useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import { CoinsModal } from '../CoinsModal';
import Coin from 'assets/icons/coin';

const animState = {
  initial: { background: 'var(--gray-lightest)', width: '0px' },
  CoinsGain: { background: 'var(--yellow)' }
};

export const CoinsIndicator = props => {
  const { currentBalance } = useSelector(s => s.user.coins);
  const previousCoins = useRef(currentBalance);
  const [isCoinModalOpen, setIsCoinModalOpen] = useState(false);

  const [{ background }, api] = useSpring(() => ({
    from: animState.initial,
    config: config.slow
  }));

  useEffect(() => {
    if (
      typeof currentBalance !== 'number' ||
      currentBalance <= previousCoins.current
    )
      return;

    previousCoins.current = currentBalance;

    api.start(animState.CoinsGain);
    const handle = setTimeout(() => api.start(animState.initial), 3000);

    return () => clearTimeout(handle);
  }, [api, currentBalance]);

  return (
    <>
      <CoinsContainer
        onClick={() => setIsCoinModalOpen(true)}
        {...props}
        style={{ background }}
        title={`You have ${currentBalance} total Coins`}>
        <div className="mr-2" style={{ flex: '0 0 1.5rem' }}>
          <Coin id={props.id ?? ''} height="1.5rem" />
        </div>
        <DropAnimation value={currentBalance} />
      </CoinsContainer>
      <CoinsModal
        open={isCoinModalOpen}
        onDismiss={() => setIsCoinModalOpen(false)}
      />
    </>
  );
};

const CoinsContainer = styled(animated.div)`
  display: flex;
  background: var(--gray-lightest);
  border-radius: 3rem;
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
  min-width: 4.5rem;
  padding: 0 0.75rem 0 0.5rem;
  overflow: hidden;
`;
