import { Badge as ReactstrapBadge } from 'reactstrap';
import styled from 'styled-components';
import { colors } from 'styles';

const StyledBadge = styled(ReactstrapBadge)`
  padding: 0.25rem 0.375rem;
  font-size: 0.85rem;
  font-weight: 500;

  ${({ color }) =>
    color &&
    `
  background-color: ${colors[color]};
`}
  color: ${({ textcolor }) => (textcolor ? colors[textcolor] : 'var(--black)')};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

const Badge = ({ color = 'secondary', ...props }) => (
  <StyledBadge color={color} {...props}></StyledBadge>
);
export default Badge;
