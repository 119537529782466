import { setSubscription } from 'actions/user';
import { Api } from 'api';
import { EediPlusLogo, toaster } from 'components/common';
import EmojiSpan from 'components/common/EmojiSpan';
import { navigateToStripeBillingPortal } from 'components/common/Stripe';
import { utc } from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Banner as NavBanner } from './Banner';
import { InlineTextButton } from './InlineTextButton';

const Banner = props => <NavBanner showInviteLink={false} {...props} />;

const BillingErrorBanner = ({ loginAsParent = () => {} }) => {
  const [userId, userRole, subscription] = useSelector(
    ({ user: { userId, userRole, subscription } }) => [
      userId,
      userRole,
      subscription
    ]
  );
  const dispatch = useDispatch();
  const [isRequesting, setIsRequesting] = useState(false);

  const renewSubscription = async () => {
    setIsRequesting(true);

    try {
      const subscription = await Api.renewEediHomeSubscription(userId);
      dispatch(setSubscription(subscription));
      toaster.success(
        <>
          <EmojiSpan ariaLabel="hooray!" emoji="🎉" />
          &nbsp;Your subscription has been renewed, good to have you back!
        </>
      );
    } catch (e) {
      toaster.danger(e.toString());
    }

    setIsRequesting(false);
  };

  const formattedEndDate = utc(subscription?.currentPeriodEndUTC).format(
    'MMMM Do YYYY'
  );

  if (!subscription) {
    return null;
  }

  if (
    subscription.subscriptionStatus === 'past_due' &&
    subscription.hasHadValidSubscription
  ) {
    if (userRole === 'Student') {
      return (
        <Banner color="var(--red-lighter)" showInviteLink={false}>
          We couldn’t collect your payment - please let your parent / guardian
          know they can{' '}
          <InlineTextButton onClick={loginAsParent}>
            fix this in the parent hub
          </InlineTextButton>
        </Banner>
      );
    }

    return (
      <Banner color="var(--red-lighter)">
        <span>We couldn't collect your payment - </span>
        <InlineTextButton onClick={navigateToStripeBillingPortal}>
          update billing details or try another card
        </InlineTextButton>
      </Banner>
    );
  }

  if (
    subscription.subscriptionStatus === 'unpaid' &&
    subscription.hasHadValidSubscription
  ) {
    if (userRole === 'Student') {
      return (
        <Banner color="var(--red-lighter)" showInviteLink={false}>
          We couldn’t collect your payment - please let your parent / guardian
          know they can{' '}
          <InlineTextButton onClick={loginAsParent}>
            fix this in the parent hub
          </InlineTextButton>
        </Banner>
      );
    }

    return (
      <Banner color="var(--red-lighter)">
        <span>
          We couldn’t collect your payment - to avoid losing access, please
        </span>
        <InlineTextButton onClick={navigateToStripeBillingPortal}>
          update billing details or try another card
        </InlineTextButton>
      </Banner>
    );
  }

  if (
    subscription.subscriptionStatus === 'canceled' &&
    !subscription.schoolId &&
    new Date(subscription.currentPeriodEndUTC) > new Date()
  ) {
    if (userRole === 'Student') {
      return (
        <Banner color="var(--blue-lighter)" showInviteLink={false}>
          <span>
            Your parent / guardian has cancelled your Eedi <EediPlusLogo />{' '}
            subscription. You’ll have access until {formattedEndDate}. You can
            still keep learning for free with Eedi Starter{' '}
            <EmojiSpan ariaLabel="tada" emoji="🎉" />
          </span>
        </Banner>
      );
    }

    return (
      <Banner color="var(--blue-lighter)">
        <span>
          You’ve cancelled your Eedi <EediPlusLogo /> subscription You'll move
          to our free plan, Eedi Starter, on {formattedEndDate}.
          {utc(subscription?.currentPeriodEndUTC).isBefore() && (
            <span>
              If you change your mind,
              <InlineTextButton
                onClick={renewSubscription}
                disabled={isRequesting}>
                renew your subscription here
              </InlineTextButton>
            </span>
          )}
        </span>
      </Banner>
    );
  }

  return null;
};

export default BillingErrorBanner;
