export const userStates = {
  isPreviewQuizDismissed: 'isPreviewQuizDismissed', // Obsolete
  isPreviewQuizCompleted: 'isPreviewQuizCompleted', // Obsolete
  isPreviewLessonStarted: 'isPreviewLessonStarted',
  isDynamicQuizCompleted: 'isDynamicQuizCompleted',
  isFirstQuizStarted: 'isFirstQuizStarted',
  isFirstQuizCompleted: 'isFirstQuizCompleted',
  isFirstLessonStarted: 'isFirstLessonStarted',
  hasLoggedIn: 'hasLoggedIn',
  hasPaymentDetails: 'hasPaymentDetails',
  currentTopicPathwayCollectionLevel: 'currentTopicPathwayCollectionLevel',
  isOnboardingQuizCompleted: 'isOnboardingQuizCompleted',
  isOnboardingQuizStarted: 'isOnboardingQuizStarted',
  isOnboardingQuizSkipped: 'isOnboardingQuizSkipped',
  isOnboardingQuizRemoved: 'isOnboardingQuizRemoved',
  isMysteryBoxAwardUnlocked: 'isMysteryBoxAwardUnlocked',
  isMysteryBoxAwardDismissed: 'isMysteryBoxAwardDismissed',
  isTeamSchoolModalSkipped: 'isTeamSchoolModalSkipped',
  isFirstTimeLearningHub: 'isFirstTimeLearningHub',
  isYearGroupChecked: 'isYearGroupChecked',
  isFlowIntroductionVideoWatched: 'isFlowIntroductionVideoWatched'
};
