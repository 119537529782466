import { SetParentInvitationStatus } from 'actions/user';
import { Api, useApiQuery } from 'api';
import { Button, Form, toaster, Loading } from 'components/common';
import CheckboxInput from 'components/common/FormInputs/CheckboxInput';
import TextInput from 'components/common/FormInputs/TextInput';
import { openWhatsappLink } from 'helpers/whatsAppHelper';
import useApiValidatedForm from 'hooks/useApiValidatedForm';
import is from 'is_js';
import { useDispatch, useSelector } from 'react-redux';
import analytics from 'analytics';

const InviteParentForm = ({ onComplete, onSkip }) => {
  const { userId } = useSelector(s => s.user);

  const {
    formState: { isSubmitting },
    control,
    errors,
    handleSubmit,
    getValues
  } = useApiValidatedForm({
    mode: 'onChange'
  });
  const { loading, data: parentInvitations } = useApiQuery(
    async () => await Api.getUsersParentInvitations(userId)
  );
  const values = getValues();
  const dispatch = useDispatch();

  const handleSave = async data => {
    try {
      if (!data?.mobile?.length && !data?.email?.length)
        throw new Error(
          'Please enter either a mobile number or an email address'
        );

      const source = { invitationSource: 'email' };

      if (data.mobile) {
        const unclaimedParent = parentInvitations.find(p => !p.isClaimed);

        if (!unclaimedParent) {
          throw new Error('Unable to generate invitiation code for parent!');
        }

        source.send = () => {
          openWhatsappLink(
            data.mobile,
            `Hi!%0a%0aI’d like to invite you to join my Eedi account, where you’ll be able to see updates on my homework. %0a%0a` +
              `If you already have an Eedi account you can enter the invitation code: ${unclaimedParent.uniqueCode} here: https://family.eedi.com/child/link %0a%0a` +
              `Alternatively if you don't have an account please sign up here: https://eedi.com/eedi-plus/choose-a-plan?code=${unclaimedParent.uniqueCode} and help me unlock the motivational features.`
          );
          analytics.track('Parent Invite Finished', {
            invitation_source: 'whatsapp'
          });
        };
        source.invitationSource = 'whatsapp';
        source.send();
        await Api.upsertParentMobileNumber(userId, data.mobile);
      } else if (data.email) {
        const submitModel = {
          email: data.email
        };

        await Api.sendParentInvitation(userId, submitModel);
      }

      // Set specifically for Intercom tracking. Note that "_at" suffix is required for unix timestamp dates
      // https://www.intercom.com/help/en/articles/3605703-how-dates-work-in-intercom
      // Math.floor(new Date().getTime() / 1000) is the current unix timestamp
      const intercomSet = {
        user_id: userId,
        invited_parent_source: source.invitationSource,
        invited_parent: 'true',
        invited_parent_at: Math.floor(new Date().getTime() / 1000)
      };

      if (source.invitationSource === 'whatsapp') {
        intercomSet.invited_parent_mobile = data.mobile;
      } else {
        intercomSet.invited_parent_email = data.email;
      }

      analytics.intercom.set(intercomSet);

      dispatch(SetParentInvitationStatus('Invited'));
      onComplete && onComplete(source);
      toaster.success('Parent details submitted successfully');
    } catch (e) {
      toaster.danger(e.message);
      throw e;
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Form onSubmit={handleSubmit(handleSave)} loading={isSubmitting}>
        <Form.Group>
          <TextInput
            control={control}
            label="Your parent's email"
            name="email"
            error={errors.email?.message}
            placeholder="Email"
            rules={
              !values?.mobile?.length
                ? {
                    required: 'Email cannot be blank',
                    validate: val =>
                      val && !is.email(val)
                        ? 'Please enter a valid email address'
                        : undefined
                  }
                : {}
            }
            disabled={isSubmitting || !!values?.mobile?.length}
            type="email"
          />
        </Form.Group>
        <Form.Group>
          <TextInput
            control={control}
            label="Or mobile number (we'll send an invite message via WhatsApp). Clear email field to send message."
            name="mobile"
            error={errors.mobile?.message}
            placeholder="+447123456789"
            rules={
              !values?.email?.length
                ? {
                    required: 'Mobile cannot be blank',
                    validate: (val, x) =>
                      !val
                        ? 'Please enter a valid mobile phone number'
                        : undefined
                  }
                : {}
            }
            disabled={isSubmitting || !!values?.email?.length}
            type="tel"
          />
        </Form.Group>
        <Form.Group style={{ fontSize: '14px' }} className="mb-0">
          <CheckboxInput
            id="invite-terms-agreement"
            control={control}
            name="termsAgreement"
            label={
              <span>
                By signing up, I confirm I have permission to share my parents'
                details
              </span>
            }
            defaultValue={false}
            error={errors.termsAgreement?.message}
            rules={{
              required: `Please confirm on your permission to share parents' details`
            }}
          />
        </Form.Group>

        <Form.Buttons>
          {onSkip && (
            <Button color="outline" onClick={onSkip}>
              Skip this for now
            </Button>
          )}
          <Button
            color="primary"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}>
            Send signup link
          </Button>
        </Form.Buttons>
      </Form>
    </>
  );
};

export default InviteParentForm;
