import appReducer from './appReducer';
import localForage from 'localforage';
import analytics from 'analytics';
import { cache } from 'store';
import { removeAuthToken } from 'config/settings';

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localForage.clear();
    sessionStorage.clear();
    cache.clear();
    window.Intercom && window.Intercom('shutdown');
    removeAuthToken();
    analytics.shutdown();
  }

  return appReducer(state, action);
};

export default rootReducer;
