import localForage from 'localforage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import authReducer from './auth';
import schoolReducer from './school';
import userReducer from './user';
import quizInsightReducer from './quizInsight';
import teamsReducer from './teams';
import guestReducer from './guest';
import { pricingReducer } from './pricingReducer';

const appReducer = combineReducers({
  auth: authReducer,
  user: persistReducer(
    {
      key: 'user',
      storage: localForage
    },
    userReducer
  ),
  school: persistReducer(
    {
      key: 'school',
      storage: localForage,
      whitelist: ['schoolId']
    },
    schoolReducer
  ),
  quizInsight: persistReducer(
    {
      key: 'quizInsight',
      storage: localForage
    },
    quizInsightReducer
  ),
  teams: persistReducer(
    {
      key: 'teams',
      storage: localForage
    },
    teamsReducer
  ),
  guest: guestReducer,
  pricing: pricingReducer
});

export default appReducer;
