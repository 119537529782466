import { useEffect, useState } from 'react';

export const useRewardfulReferral = () => {
  const [referral, setReferral] = useState({
    isLoaded: false,
    referral: undefined
  });

  useEffect(() => {
    window.rewardful('ready', () => {
      if (window.Rewardful.referral) {
        setReferral({
          isLoaded: true,
          referral: {
            id: window.Rewardful.referral,
            coupon:
              window.Rewardful.coupon !== false
                ? window.Rewardful.coupon.id
                : undefined,
            link: window.Rewardful.metadata.location
          }
        });
      }
    });
  }, []);

  return referral;
};
