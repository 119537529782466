import { store } from 'store';
import { HOME_SCHOOL_IDS } from 'config/settings';

const hasClassroomSchoolFeature = featureName => {
  try {
    const state = store.getState();

    if (state?.user?.featureFlagSchools?.length === 0) return false;

    const classroomSchoolFlags = state.user.featureFlagSchools?.filter(
      c => !HOME_SCHOOL_IDS.includes(c.schoolId)
    );

    if (classroomSchoolFlags?.length === 0) return false;

    return classroomSchoolFlags?.some(c =>
      c?.dynamicFeatures?.some(
        d =>
          d.description.toLowerCase() === featureName.toLowerCase() &&
          d.featureToggle === 'Enabled'
      )
    );
  } catch (e) {
    return false;
  }
};

const getFeatureFlagSchoolIds = () => {
  const state = store.getState();
  const schoolFeatures = state.user.featureFlagSchools;
  return schoolFeatures.map(ffs => ffs.schoolId);
};

// find feature toggle for feature flag school, enabled by default
const hasFeature = (featureName, overrideDefaultState = null) => {
  const state = store.getState();
  const schoolFeatures = state.user.featureFlagSchools;
  const schoolId = state.school.schoolId;

  if (schoolFeatures && schoolId && featureName) {
    // Find the features for this school
    const feature = schoolFeatures.find(ffs => ffs.schoolId === schoolId);

    // Get the toggle for this feature
    if (feature) {
      return feature[featureName] === 'Enabled';
    }
  }

  return overrideDefaultState ?? true;
};

const featureToggle = {
  NotSet: 'NotSet',
  Disabled: 'Disabled',
  Enabled: 'Enabled'
};

const getDynamicFeatures = () => {
  try {
    const state = store.getState();
    const schoolFeatures = state.user.featureFlagSchools;

    if (!schoolFeatures?.length) return featureToggle.NotSet;

    const dynamicFeatures = [].concat.apply(
      [],
      schoolFeatures?.map(s =>
        s.dynamicFeatures.map(d => ({ ...d, schoolId: s.schoolId }))
      )
    );

    return dynamicFeatures;
  } catch (e) {
    console.error(e);
  }
  return [];
};

const hasDynamicFeature = featureName => {
  if (!featureName) return featureToggle.NotSet;

  try {
    const dynamicFeatures = getDynamicFeatures();

    const dynamicFeature = dynamicFeatures.find(
      df => df.description === featureName
    );

    if (
      !!dynamicFeature &&
      [featureToggle.Disabled, featureToggle.Enabled].includes(
        dynamicFeature?.featureToggle
      )
    ) {
      return dynamicFeature.featureToggle;
    }
  } catch (e) {
    console.error(e);
  }

  return featureToggle.NotSet;
};

export {
  hasFeature,
  hasDynamicFeature,
  featureToggle,
  hasClassroomSchoolFeature,
  getDynamicFeatures,
  getFeatureFlagSchoolIds
};
