import { TopicPathwayContainer } from 'components/TopicPathway/TopicPathwayContainer';
import {
  activeLearningCompletedCount,
  activityCount,
  assignmentsCount
} from './tabCountHelpers';
import AssignmentsContainer from '../components/AssignmentsContainer';
import {
  ActivityEmptyState,
  OverdueAssignmentsEmptyState,
  ParentActivityEmptyState,
  StartedAssignmentsEmptyState,
  UpcomingAssignmentsEmptyState
} from '../components/emptyStates';
import ActivityContainer from '../components/ActivityContainer';

export const trimName = name =>
  name?.length < 22 ? name : name.substring(0, 19) + '...';

export const assignmentGroups = {
  Upcoming: 'Upcoming',
  Completed: 'Completed',
  Overdue: 'Overdue',
  Active: 'Active'
};

export const topicPathwayQuizGroups = {
  Started: 'Started',
  Recommended: 'Recommended',
  Active: 'Active',
  Completed: 'Completed',
  ActiveLearningAndCompletedAssignments: 'ActiveLearningAndCompletedAssignments'
};

export const learningModes = {
  dashboard: 'dashboard',
  activity: 'activity',
  parent: 'parent'
};

export const activityTypes = {
  all: 'all',
  topic: 'topic',
  assignment: 'assignment',
  topicpathway: 'topicpathway',
  retrievalpractice: 'retrievalpractice'
};

export const learningModeSettings = [
  {
    learningMode: 'dashboard',
    title: 'Learning',
    tabs: [
      {
        id: 0,
        value: 'Independent learning',
        emptyStateComponent: null,
        component: TopicPathwayContainer,
        defaultTabRule: ({ count }) => count > 0
      },
      {
        id: 1,
        value: 'Assignments',
        assignmentGroup: assignmentGroups.Active,
        count: 0,
        isCountSet: false,
        take: 3,
        countFunction: assignmentsCount,
        emptyStateComponent: UpcomingAssignmentsEmptyState,
        component: AssignmentsContainer,
        isSchool: true,
        defaultTabRule: ({ count }) => count > 0
      },
      {
        id: 2,
        value: 'Overdue assignments',
        assignmentGroup: assignmentGroups.Overdue,
        count: 0,
        isCountSet: false,
        take: 3,
        countFunction: assignmentsCount,
        emptyStateComponent: OverdueAssignmentsEmptyState,
        component: AssignmentsContainer,
        isSchool: true,
        defaultTabRule: ({ count }) => count > 0
      }
    ]
  },
  {
    learningMode: 'activity',
    title: 'Recent activity',
    tabs: [
      {
        id: 0,
        value: 'Everything',
        assignmentGroup: assignmentGroups.Active,
        count: 0,
        take: 5,
        isCountSet: false,
        countFunction: activityCount,
        activityType: activityTypes.all,
        emptyStateComponent: ActivityEmptyState,
        component: ActivityContainer
      },
      {
        id: 1,
        value: 'Assignments',
        assignmentGroup: assignmentGroups.Active,
        count: 0,
        take: 5,
        isCountSet: false,
        countFunction: activityCount,
        activityType: activityTypes.assignment,
        emptyStateComponent: StartedAssignmentsEmptyState,
        isSchool: true,
        component: ActivityContainer
      },
      {
        id: 2,
        value: 'Independent learning',
        assignmentGroup: assignmentGroups.Active,
        count: 0,
        take: 5,
        isCountSet: false,
        countFunction: activityCount,
        activityType: activityTypes.topicpathway,
        emptyStateComponent: ActivityEmptyState,
        isSchool: true,
        component: ActivityContainer
      }
    ]
  },
  {
    learningMode: 'parents',
    title: ({ name }) => `${name}'s topics`,
    tabs: [
      {
        id: 0,
        value: 'Completed work',
        assignmentGroup:
          topicPathwayQuizGroups.ActiveLearningAndCompletedAssignments,
        count: 0,
        take: 10,
        isCountSet: false,
        countFunction: activeLearningCompletedCount,
        activityType: activityTypes.all,
        emptyStateComponent: ParentActivityEmptyState,
        component: ActivityContainer
      },
      {
        id: 1,
        value: 'Upcoming assignments',
        assignmentGroup: assignmentGroups.Active,
        count: 0,
        take: 10,
        isCountSet: false,
        countFunction: assignmentsCount,
        emptyStateComponent: StartedAssignmentsEmptyState,
        isSchool: true,
        component: AssignmentsContainer,
        defaultTabRule: ({ count }) => count > 0
      },
      {
        id: 2,
        value: 'Overdue assignments',
        assignmentGroup: assignmentGroups.Overdue,
        count: 0,
        take: 10,
        isCountSet: false,
        countFunction: assignmentsCount,
        emptyStateComponent: OverdueAssignmentsEmptyState,
        isSchool: true,
        component: AssignmentsContainer,
        defaultTabRule: ({ count }) => count > 0
      }
    ]
  }
];
