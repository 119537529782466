import TopicIcon from 'components/TopicPathway/TopicIcon';
import TopicCalendarIcon from './TopicCalendarIcon';
import styled from 'styled-components';
import { activityTypes } from '../helpers/learningActivityHelpers';

const ActivityIcon = ({
  type = 'topic',
  topicId = -1,
  date = null,
  disabled = false,
  size = '3.5rem',
  isLastChild = false,
  ...props
}) => {
  switch (type) {
    case activityTypes.assignment:
    case activityTypes.retrievalpractice:
      return (
        <TopicCalendarIcon
          topicId={topicId}
          date={date}
          size={size}
          disabled={disabled}
          {...props}
        />
      );
    case activityTypes.topic:
      return (
        <GeneralTopicIcon>
          <TopicIcon
            topicId={topicId}
            size={size}
            disabled={disabled}
            {...props}
          />
        </GeneralTopicIcon>
      );
    case activityTypes.topicpathway:
      return (
        <TopicPathwayIcon>
          <div>
            <TopicIcon
              topicId={topicId}
              size={size}
              disabled={disabled}
              {...props}
            />
          </div>
          {!isLastChild && (
            <SeperatorWrapper>
              <Separator />
            </SeperatorWrapper>
          )}
        </TopicPathwayIcon>
      );
    default:
      return null;
  }
};

const GeneralTopicIcon = styled.div`
  margin-right: 1rem;
`;

const TopicPathwayIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SeperatorWrapper = styled.div`
  margin: 0.5rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Separator = styled.div`
  background: var(--gray-lighter);
  width: 0.5rem;
  height: 1.5rem;
  border-radius: 0.5rem;
`;

export default ActivityIcon;
