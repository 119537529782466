import { CloseButton, Modal, toaster } from 'components/common';
import { useRewardfulReferral } from 'hooks/useRewardfulReferral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { FeatureFocusModalPage } from './FeatureFocusModalPage';
import queryString from 'querystring-es3';
import { ParentLoginModalPage } from './ParentLoginModalPage';
import { navigateToStripeCheckout } from 'components/common/Stripe';
import { hasValidSubscription } from 'helpers/validateSubscription';
import { PricePlanModalPage } from './PricePlanModalPage';
import { media } from 'styles';
import InviteParentForm from 'components/Child/InviteParentForm';
import InviteParentModal from 'components/Child/InviteParentModal';

export const EediPlusUpSellModal = ({
  isOpen,
  onDismiss,
  initialPage = null
}) => {
  const location = useLocation();
  const { userRole, subscription, parentInvitationStatus } = useSelector(
    ({ user: { userRole, subscription, parentInvitationStatus } }) => ({
      userRole,
      subscription,
      parentInvitationStatus
    })
  );
  const { referral } = useRewardfulReferral();

  const [pageState, setPageState] = useState(initialPage);
  const [chosenPriceId, setChosenPriceId] = useState();

  useEffect(() => {
    setPageState(initialPage);
  }, [initialPage]);

  const reset = useCallback(() => {
    onDismiss();
    // Resets the page state after the exit animation for the modal finishes
    setTimeout(() => setPageState('featureFocus'), 500);
  }, [onDismiss]);

  const navigateToCheckoutUsingAuth = useCallback(
    async (token = null, priceId = undefined) => {
      try {
        const params = queryString.parse(location.search);
        params.subscriptionSuccess = true;

        const enterPaymentDetails =
          hasValidSubscription(subscription) && !subscription.hasPaymentDetails;
        const currentUrl = window.location.origin;
        await navigateToStripeCheckout({
          priceId,
          successUrl: `${currentUrl}${
            location.pathname
          }?${queryString.stringify(params)}`,
          cancelUrl: location.href,
          checkoutMode: enterPaymentDetails ? 'setup' : 'subscription',
          settings: {
            token
          },
          referral
        });
      } catch (error) {
        toaster.danger(error.message);
      }
    },
    [location.href, location.pathname, location.search, referral, subscription]
  );

  const modalPage = useMemo(() => {
    switch (pageState) {
      case 'parentLoginStripeSubscribe-checkout':
        return (
          <ParentLoginModalPage
            onSuccess={token =>
              navigateToCheckoutUsingAuth(token, chosenPriceId)
            }
          />
        );

      case 'inviteParent':
        return <InviteParentForm />;

      case 'choosePricePlan':
        return (
          <PricePlanModalPage
            onConfirm={priceId => {
              if (userRole !== 'Parent') {
                setChosenPriceId(priceId);
                setPageState('parentLoginStripeSubscribe-checkout');
              } else {
                navigateToCheckoutUsingAuth(null, priceId);
              }
            }}
          />
        );

      case 'featureFocus':
      default:
        return (
          <FeatureFocusModalPage
            onJoinEediPlus={() => setPageState('choosePricePlan')}
            onDismiss={reset}
          />
        );
    }
  }, [pageState, reset, userRole, navigateToCheckoutUsingAuth, chosenPriceId]);

  if (
    userRole === 'student' &&
    parentInvitationStatus !== 'Accepted' &&
    parentInvitationStatus
  ) {
    return <InviteParentModal isOpen={isOpen} focus="tutors" />;
  }

  return (
    <StyledModal
      isOpen={isOpen}
      toggle={() => {
        reset();
      }}
      size="lg">
      <Modal.Body>
        {modalPage}
        <Close onClick={reset} />
      </Modal.Body>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  max-width: 70rem;
  .modal-content {
    overflow: hidden;
  }
`;

const Close = styled(CloseButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: var(--white);
  ${media.md`
    color: var(--gray);
  `};
`;
