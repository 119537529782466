import './globals';
import { persistor, store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import AppRoutes from 'routes/AppRoutes';
import ScrollToTop from 'components/common/ScrollToTop';
import { ConfirmicProvider } from '@confirmic/react';
import AppProviders from 'providers/AppProviders';

const confirmicProjectId = 'prj:7b604390-6ce5-4865-bcce-356a87267605';

const App = () => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfirmicProvider projectId={confirmicProjectId}>
        <BrowserRouter>
          <ScrollToTop />
          <ErrorBoundary>
            <AppProviders>
              <AppRoutes />
            </AppProviders>
          </ErrorBoundary>
        </BrowserRouter>
      </ConfirmicProvider>
    </PersistGate>
  </ReduxProvider>
);

export default App;
