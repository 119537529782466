import styled from 'styled-components';
import answerMap from 'constants/answerMap';
import { Button } from 'components/common';

const QuizAnswers = ({
  answerValue,
  onAnswerChange,
  isCorrectAnswer,
  previousAnswerValue,
  disabled
}) => {
  const renderAnswer = value => {
    const selected = answerValue === value;

    let ButtonComponent = StyledButton;
    if (selected && typeof isCorrectAnswer === 'boolean') {
      ButtonComponent = isCorrectAnswer ? CorrectButton : IncorrectButton;
    }

    return (
      <ButtonComponent
        className={
          selected ? 'answer-button answer-button-selected' : 'answer-button'
        }
        key={value}
        onClick={() => handleClick(value, selected)}
        active={selected}
        disabled={disabled || previousAnswerValue === value}
        outline
        size="large">
        {answerMap[value]}
      </ButtonComponent>
    );
  };

  const handleClick = (value, selected) => {
    if (selected) return;
    onAnswerChange(value);
  };

  return <Wrapper>{[1, 2, 3, 4].map(renderAnswer)}</Wrapper>;
};

export default QuizAnswers;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem -0.5rem;
`;

const StyledButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 0.5rem;
  flex: 1;
  color: var(--black);
  &.active {
    color: var(--primary) !important;
    box-shadow: inset 0 0 0 1px var(--primary) !important;
    border-color: var(--primary) !important;
  }
`;

const CorrectButton = styled(StyledButton)`
  opacity: 1 !important;
  &.active {
    color: var(--black) !important;
    background: var(--green-lighter) !important;
    border-color: var(--green) !important;
    box-shadow: inset 0 0 0 1px var(--green) !important;
  }
`;

const IncorrectButton = styled(StyledButton)`
  opacity: 1 !important;
  &.active {
    color: var(--black) !important;
    background: var(--yellow-lighter) !important;
    border-color: var(--yellow) !important;
    box-shadow: inset 0 0 0 1px var(--yellow) !important;
  }
`;
