import { DISABLE_ANALYTICS } from 'config/settings';
import { Intercom } from './providers/Intercom';
import { FunctionsApi } from './providers/FunctionsApi';
import { Mixpanel } from './providers/Mixpanel';
import { Sentry } from './providers/Sentry';
import { GoogleAnalytics } from './providers/GoogleAnalytics';
import { Hotjar } from './providers/Hotjar';
export const FAMILY_PROJECT_KEY = 'eedi_family';

const vendors = [
  new GoogleAnalytics(),
  new Intercom(),
  new Sentry(),
  new FunctionsApi(),
  new Mixpanel(),
  new Hotjar()
];

let hasInitialised = false;

const init = () => {
  vendors.forEach(vendor => {
    if (typeof vendor.init === 'function') {
      vendor.init();
    }
  });
  hasInitialised = true;
};

// Short hand function to call the corresponding methods in each vendor
const fire = (method, ...args) => {
  try {
    vendors.forEach(vendor => {
      try {
        if (
          (vendor instanceof FunctionsApi ||
            (DISABLE_ANALYTICS !== 'true' && hasInitialised)) &&
          typeof vendor[method] === 'function'
        ) {
          vendor[method](...args);
        }
      } catch (e) {
        console.error(e);
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const analytics = {
  init: () => init(),
  page: (location = null) => fire('page', location || window.location.pathname),
  identify: user => fire('identify', user),
  set: (props, option) => fire('set', props, option),
  track: (event, data, option, userId) =>
    fire('track', event, data, option, userId),
  // ‘fatal’, ‘error’, ‘warning’, ‘info’, and ‘debug’
  trackError: (error, extra = {}, severity = 'error') =>
    fire('trackError', error, extra, severity),
  shutdown: () => fire('shutdown'),
  intercom: vendors[1]
};

export default analytics;
