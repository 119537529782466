import { EmojiSpan, Modal } from 'components/common';
import StreakFullFlame from 'assets/icons/streakFullFlame';
import styled from 'styled-components';
import { StreakPointsPill } from './Indicators/StreakPointsPill';

export const RoutinePointsModal = ({
  open,
  onDismiss,
  points = '-',
  ...props
}) => {
  return (
    <Modal isOpen={open} size="md" toggle={onDismiss} {...props}>
      <Modal.Title toggle={onDismiss}>
        <HeaderContent>
          <div className="mr-3">
            <StreakFullFlame width={63} height={84} shadow={false} />
          </div>
          <h2 className="mb-0">You have {points} streaks</h2>
        </HeaderContent>
      </Modal.Title>
      <Modal.Body>
        <p>
          Use your streaks to compete in the Eedi Leagues and win Eedi coins
        </p>
        <p>You can earn a maximum of 4 streaks a day by:</p>
        <StyledUl className="mb-4">
          <li className="mb-1">
            <div>Completing a topic or assignment:</div>
            <StreakPointsPill value={1} />
          </li>
          <li className="mb-1">
            <div>Taking a lesson:</div>
            <StreakPointsPill value={2} />
          </li>
          <li className="mb-1">
            <div>Completing a Stretch and Challenge:</div>
            <StreakPointsPill value={1} />
          </li>
        </StyledUl>
        <NewBox>
          <NewBoxHeader>
            <NewPill>NEW</NewPill>
            <h2 className="mb-0">Keep your streaks forever!</h2>
          </NewBoxHeader>
          <p className="mb-0">
            We've changed streaks to be about completing work rather than
            routines. In the past you could lose them if you didn't turn up on a
            certain day. Not any more! <EmojiSpan emoji="🎉" ariaLabel="tada" />
          </p>
        </NewBox>
      </Modal.Body>
    </Modal>
  );
};

const HeaderContent = styled.div`
  background: linear-gradient(
    180deg,
    #ffe9a8 0%,
    rgba(255, 233, 168, 0.41) 100%
  );
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

const NewBox = styled.div`
  padding: 1.5rem;
  background: var(--pink-lighter);
  border-radius: 0.75rem;
  h1 {
    margin: 0;
  }
`;

const NewPill = styled.div`
  display: flex;
  align-items: center;
  background: #d944ca;
  border-radius: 0.25rem;
  color: white;
  font-weight: bold;
  padding: 0.1rem 0.45rem 0.1rem 0.45rem;
  margin: 0 0.5rem 0 0;
`;

const NewBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  align-items: center;
`;

const StyledUl = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0 0.5rem 0;
    border-bottom: 1px solid var(--gray-lighter);
    align-items: center;
  }

  li:last-child {
    border: none;
  }
`;
