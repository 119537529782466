import styled from 'styled-components';
import moment from 'moment';
import { media } from 'styles';

const TopicCalendarIcon = ({
  date = null,
  size = '4.5rem',
  disabled = false,
  ...props
}) => {
  return (
    <TopicCalendar
      size={size}
      style={{ filter: disabled ? 'grayscale(1)' : 'none' }}
      {...props}>
      <TopicCalendarMonth>
        {!!date ? moment(date).format('MMM') : null}
      </TopicCalendarMonth>
      <TopicCalendarDay>
        {!!date ? moment(date).format('D') : null}
      </TopicCalendarDay>
    </TopicCalendar>
  );
};

const TopicCalendar = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  margin-right: 1rem;
  border-radius: 99rem;
  padding: 1rem;
  background-color: var(--green-lighter);
  align-self: center;
  ${media.md`
    align-self: flex-start;
  `}
`;

const TopicCalendarDay = styled.div`
  width: 100%;
  height: 1.75rem;
  background-color: var(--white);
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  padding: 0.125rem;
  line-height: 1;
  border-radius: 0 0 0.25rem 0.25rem;
`;
const TopicCalendarMonth = styled.div`
  width: 100%;
  height: 1rem;
  background-color: var(--green);
  color: var(--green-lighter);
  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;
  padding: 0.125rem;
  line-height: 1;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export default TopicCalendarIcon;
