import { GOOGLE_AUTH_CLIENT_ID } from 'config/settings';
import { useEffect, useState } from 'react';

const useGoogleauth = ({ callback = r => console.log(r) }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded) return undefined;

    const initializeGoogle = () => {
      if (!window.google || scriptLoaded) return;

      setScriptLoaded(true);
      window.google.accounts.id.initialize({
        client_id: GOOGLE_AUTH_CLIENT_ID,
        callback: callback
      });
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = initializeGoogle;
    script.async = true;
    script.id = 'google-client-script';
    document.querySelector('body')?.appendChild(script);

    return () => {
      window.google?.accounts.id.cancel();
      document.getElementById('google-client-script')?.remove();
    };
  }, [callback, scriptLoaded]);

  return { isLoaded: scriptLoaded };
};

export default useGoogleauth;
