import { track } from 'analytics/providers/TrackingApi';
import { ENVIRONMENT, FUNCTIONS_API_URL } from 'config/settings';
import { store } from 'store';

let tracked = [];

const TrackingEventConstants = {
  // Child Events
  ParentInviteStarted: 'Parent Invite Started',
  ShopEntered: 'Shop Entered',
  JoinClassStarted: 'Join Class Started',
  JoinClassFinished: 'Join Class Finished',
  QuizResumed: 'Quiz Resumed',
  DynamicQuizStarted: 'Dynamic Quiz Started',
  DynamicQuizFinished: 'Dynamic Quiz Finished'
  // Parent Events
};

const trackEvent = (action, isTrackOnce = false, values = {}) => {
  if (isTrackOnce && tracked.includes(action)) return;

  const state = store.getState();
  const { studentId, userId } = { ...state.user, ...state.school };

  !tracked.includes(action) && tracked.push(action);

  track(
    action,
    {
      action,
      studentId,
      ...values
    },
    null,
    userId
  );
};

const postEvent = (action, data, userId) => {
  if (!userId) return;

  try {
    const body = {
      action,
      userId,
      properties: data
    };

    fetch(`${FUNCTIONS_API_URL}/api/v3/users/${userId}/tracking-events`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        source: 'teacher_' + ENVIRONMENT
      }
    });
  } catch (e) {
    console.error(e);
  }
};

const tracking = {
  track: (action, isTrackOnce, values) =>
    trackEvent(action, isTrackOnce, values),
  trackOverride: (action, data, userId) => postEvent(action, data, userId),
  eventNames: TrackingEventConstants
};

export default tracking;
