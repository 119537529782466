import { Input } from 'components/common';

import { Controller } from 'react-hook-form';

const TextInput = ({
  control,
  name,
  label = undefined,
  error = undefined,
  rules = undefined,
  type = 'text',
  defaultValue = '',
  disabled = false,
  ...props
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onChange, onBlur, value }) => (
        <Input
          type={type}
          label={label}
          error={error}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          {...props}
        />
      )}
      rules={rules}
    />
  );
};
export default TextInput;
