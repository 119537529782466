import styled from 'styled-components';
import RedCross from 'assets/images/redCross.svg';
import GreenCheck from 'assets/images/greenCheck.svg';
import { motion, AnimatePresence } from 'framer-motion';

const ActivityProgress = ({ answers = [], corrections = [] }) => {
  if (answers.length === 0) return null;

  const allAnswers = [
    ...answers,
    ...corrections.map(c => {
      c.isCorrection = true;
      return c;
    })
  ].sort((a, b) => a.answerId - b.answerId);

  const answersAggregate = [];

  allAnswers.forEach((a, i) => {
    if (a.isCorrect && !a.isCorrection) {
      answersAggregate.push(a);
    } else if (!!allAnswers[i + 1]?.isCorrection) {
      answersAggregate.push(allAnswers[i + 1]);
    }
  });

  return (
    <AnimatePresence>
      <ProgressWrapper
        variants={{
          hidden: { opacity: 0 },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.075,
              delayChildren: 0.25
            }
          }
        }}
        initial="hidden"
        animate="show">
        {answersAggregate
          ?.sort((a, b) => a.answerId - b.answerId)
          ?.map((answer, index) => (
            <AnswerWrapper
              key={index}
              variants={{
                hidden: {
                  opacity: 0,
                  scale: 0.25,
                  x: -25
                },
                show: { opacity: 1, scale: 1, x: 0 }
              }}>
              <Answer
                key={index}
                src={answer.isCorrect ? GreenCheck : RedCross}
                alt={answer.isCorrect ? 'Correct' : 'Incorrect'}
              />
            </AnswerWrapper>
          ))}
      </ProgressWrapper>
    </AnimatePresence>
  );
};

const ProgressWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
`;

const Answer = styled.img`
  margin: 0 0.25rem 0 0;
`;

const AnswerWrapper = styled(motion.div)`
  display: flex;
`;

export default ActivityProgress;
