import { FAMILY_PROJECT_KEY } from '../index';
import { ENVIRONMENT } from 'config/settings';
export class Intercom {
  constructor() {
    this._userId = null;
    this._userRole = null;
    this._appId = ENVIRONMENT === 'prod' ? 'e3tvlko6' : 'h12v3b77';
  }
  identify(user) {
    if (!!user?.userRole) {
      this._userRole = user.userRole;
    }

    if (
      !window.Intercom ||
      this._userId === user?.userId ||
      !['Parent', 'Student'].includes(user?.userRole) ||
      !user?.userId
    )
      return;

    // Narrow the scope of data
    const userObject = {
      user_id: user.userId,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      date_joined: user.dateJoined,
      date_joined_at: user?.dateJoined
        ? Math.floor(new Date(user.dateJoined).getTime() / 1000)
        : null,
      username: user.userName,
      user_type: user.userRole,
      date_of_birth: user.dateOfBirth,
      school_name: user.schoolName,
      school_id: user.schoolId,
      allow_email_messages: user?.allowEmailMessages,
      allow_text_messages: user?.allowTextMessages
    };

    window.Intercom('update', userObject);

    this._userId = user.userId;
    this._userRole = user.userRole;
  }

  track(event, data, option) {
    if (!window.Intercom || this._userRole === 'Teacher') return null;
    if (option?.project === FAMILY_PROJECT_KEY) {
      window.Intercom('trackEvent', event, data);
    }
  }

  set(props) {
    if (!window.Intercom || this._userRole === 'Teacher') return null;
    window.Intercom('update', props);
  }

  shutdown() {
    window.Intercom('shutdown');
  }
}
