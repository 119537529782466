import { cache } from 'store';
import { Api } from 'api';
import types from './types';
import moment from 'moment';
import { activityTypes } from 'components/LearningActivity/helpers/learningActivityHelpers';
import { getAuthToken } from 'config/settings';

const getOrAddAsync = async ({ callback, props, defaultReturn = null }) =>
  await cache.getOrAddAsync({
    ...props,
    callback: async () => {
      try {
        return await callback();
      } catch (e) {
        console.error(e);
      }
      return defaultReturn;
    }
  });

class CachedApi {
  constructor() {
    this._loadingCalls = [];
  }

  static async getAccessToken(token) {
    return await getOrAddAsync({
      props: {
        key: `getAccessToken:${token}`,
        typename: types.accessToken.typename,
        ttlMinutes: 1,
        isUseSessionStorage: false
      },
      callback: async () => await Api.getAccessToken(token),
      defaultReturn: null
    });
  }

  static async getQuizInsights(quizId) {
    return await getOrAddAsync({
      props: {
        key: `quiz-insights:${quizId}`,
        typename: types.quizInsights.typename,
        ttlMinutes: 60 * 24,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getQuizInsights(quizId),
      defaultReturn: null
    });
  }

  static async getTopicPathwayQuizAssignmentInsights(
    userId,
    skip = 0,
    take = 10,
    assignmentGroup,
    tags = [],
    groupId = null
  ) {
    return await getOrAddAsync({
      props: {
        key: `getTopicPathwayQuizAssignmentInsights:${userId}:${take}:${assignmentGroup}:${skip}:${groupId}:${tags.join(
          '-'
        )}`,
        typename: types.topicPathwayQuizAssignmentInsights.typename,
        ttlMinutes: 1,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getTopicPathwayQuizAssignmentInsights(
          userId,
          skip,
          take,
          assignmentGroup,
          tags,
          groupId
        ),
      defaultReturn: { data: [], total: 0 }
    });
  }

  static async getRetrievalPracticeAssignmentInsights(
    userId,
    skip = 0,
    take = 10,
    assignmentGroup,
    groupId = null
  ) {
    return await getOrAddAsync({
      props: {
        key: `getRetrievalPracticeAssignmentInsights:${userId}:${take}:${assignmentGroup}:${skip}:${groupId}`,
        typename: types.retrievalPracticeAssignmentInsights.typename,
        ttlMinutes: 1,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getRetrievalPracticeAssignmentInsights(
          userId,
          skip,
          take,
          assignmentGroup,
          groupId
        ),
      defaultReturn: { data: [], total: 0 }
    });
  }

  static async getAssignmentInsights(
    userId,
    skip = 0,
    take = 10,
    assignmentGroup,
    tags,
    groupId = null
  ) {
    return await getOrAddAsync({
      props: {
        key: `getAssignmentInsights:${userId}:${take}:${assignmentGroup}:${skip}:${groupId}`,
        typename: types.assignmentInsights.typename,
        ttlMinutes: 1,
        isUseSessionStorage: true
      },
      callback: async () => {
        const tpqInsights = await this.getTopicPathwayQuizAssignmentInsights(
          userId,
          skip,
          take,
          assignmentGroup,
          tags,
          groupId
        );

        const rpInsights = await this.getRetrievalPracticeAssignmentInsights(
          userId,
          skip,
          take,
          assignmentGroup,
          groupId
        );

        const total = tpqInsights.total + rpInsights.total;
        const data = [
          ...tpqInsights.data.map(d => ({
            ...d,
            type: activityTypes.assignment
          })),
          ...rpInsights.data.map(d => ({
            ...d,
            type: activityTypes.retrievalpractice,
            name: `Retrieval Practice - ${moment(d.startDate).format(
              'YYYY-MM-DD'
            )}`
          }))
        ];

        return {
          data: data.sort((a, b) => {
            return new Date(b.startDate) - new Date(a.startDate);
          }),
          total
        };
      },
      defaultReturn: { data: [], total: 0 }
    });
  }

  static async getTopicPathwayQuizzesForUser(
    userId,
    topicPathwayQuizGroup = 'Active',
    skip = 0,
    take = 10,
    activityType = null
  ) {
    return await getOrAddAsync({
      props: {
        key: `getTopicPathwayQuizzesForUser:${userId}:${take}:${topicPathwayQuizGroup}:${skip}:${activityType}`,
        typename: types.topicPathwayQuizs.typename,
        ttlMinutes: 10,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getTopicPathwayQuizzesForUser(
          userId,
          topicPathwayQuizGroup,
          skip,
          take,
          activityType
        ),
      defaultReturn: { data: [], total: 0 }
    });
  }

  static async getQuiz(quizId) {
    return await getOrAddAsync({
      props: {
        key: `getQuiz:${quizId}`,
        typename: types.quiz.typename,
        ttlMinutes: 0,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getQuiz(quizId),
      defaultReturn: null
    });
  }

  static async getRCTExperiment(userId) {
    return await getOrAddAsync({
      props: {
        key: `getRCTExperiment:${userId}`,
        typename: types.rctExperiment.typename,
        ttlMinutes: 10,
        isUseSessionStorage: true
      },
      callback: async () => {
        try {
          return await Api.getRCTExperiment(userId);
        } catch (e) {
          return {};
        }
      },
      defaultReturn: null
    });
  }

  static async getTopicPathwayRecommendedTopics(
    studentId,
    topicPathwayCollectionId,
    take = 1
  ) {
    return await getOrAddAsync({
      props: {
        key: `getTopicPathwayRecommendedTopics:${studentId}:${topicPathwayCollectionId}:${take}`,
        typename: types.topicPathwayGrowthComfortQuizzes.typename,
        ttlMinutes: 10,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getTopicPathwayRecommendedTopics(
          studentId,
          topicPathwayCollectionId,
          take
        ),
      defaultReturn: []
    });
  }

  static async getParentInvitation(code, planId) {
    return await getOrAddAsync({
      props: {
        key: `getParentInvitation:${code}:${planId}`,
        typename: types.parentInvitation.typename,
        ttlMinutes: 1,
        isUseSessionStorage: false
      },
      callback: async () => await Api.getParentInvitation(code, planId),
      defaultReturn: null
    });
  }

  static async getUserPreferences(userId) {
    return await getOrAddAsync({
      props: {
        key: `getUserPreferences:${userId}`,
        typename: types.userPreferences.typename,
        ttlMinutes: 60,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getUserPreferences(userId),
      defaultReturn: null
    });
  }

  static async setUserPreferences(userId, params) {
    cache.bustTypeCache({ typenames: [types.userPreferences.typename] });
    return await getOrAddAsync({
      props: {
        key: `setUserPreferences:${userId}`,
        typename: types.userPreferences.typename,
        ttlMinutes: 60,
        isUseSessionStorage: true
      },
      callback: async () => await Api.setUserPreferences(params),
      defaultReturn: null
    });
  }

  static async getUserTransactionsInsights(userId) {
    return await getOrAddAsync({
      props: {
        key: `getUserTransactionsInsights:${userId}`,
        typename: types.userTransactionsInsights.typename,
        ttlMinutes: 1,
        isUseSessionStorage: false
      },
      callback: async () => await Api.getUserTransactionsInsights(userId),
      defaultReturn: null
    });
  }

  static async getUserItems(userId) {
    return await getOrAddAsync({
      props: {
        key: `getUserItems:${userId}`,
        typename: types.userItems.typename,
        ttlMinutes: 1,
        isUseSessionStorage: false
      },
      callback: async () => await Api.getUserItems(userId),
      defaultReturn: null
    });
  }

  static async getUserRoleContext() {
    return await getOrAddAsync({
      props: {
        key: `getUserRoleContext:${getAuthToken()}`,
        typename: types.userRoleContext.typename,
        ttlMinutes: 1,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getUserRoleContext(),
      defaultReturn: null
    });
  }

  static async getUserState(userId) {
    return await getOrAddAsync({
      props: {
        key: `getUserState:${userId}`,
        typename: types.userState.typename,
        ttlMinutes: 0.1,
        isUseSessionStorage: false
      },
      callback: async () => await Api.getUserState(userId),
      defaultReturn: null
    });
  }

  static async getUserRoutineState(userId) {
    return await getOrAddAsync({
      props: {
        key: `getUserRoutineState:${userId}`,
        typename: types.userRoutineState.typename,
        ttlMinutes: 1,
        isUseSessionStorage: false
      },
      callback: async () => await Api.getUserRoutineState(userId),
      defaultReturn: null
    });
  }

  static async getStudentAssignmentInsightsForAssignment(
    studentId,
    assignmentId,
    includes
  ) {
    return await getOrAddAsync({
      props: {
        key: `getLegacyInsightsForAssignment:assignmentId:${assignmentId}:studentId:${studentId}:includes-${includes.join(
          ':'
        )}`,
        typename: types.assignmentInsights.typename,
        ttlMinutes: 0,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getStudentAssignmentInsightsForAssignment(
          studentId,
          assignmentId,
          includes
        ),
      defaultReturn: null
    });
  }

  static async getTeamStudent(studentId) {
    return await getOrAddAsync({
      props: {
        key: `getTeamStudent:${studentId}`,
        typename: types.teamStudent.typename,
        ttlMinutes: 1440,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getTeamStudent(studentId),
      defaultReturn: null
    });
  }

  static async getAllLiveClasses(userId) {
    return await getOrAddAsync({
      props: {
        key: `getAllLiveClasses:${userId}`,
        typename: types.liveClass.typename,
        ttlMinutes: 1440,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getAllLiveClasses(userId),
      defaultReturn: null
    });
  }

  static async getQotdInsights(userId) {
    return await getOrAddAsync({
      props: {
        key: `getQotdInsights:${userId}`,
        typename: types.qotdInsights.typename,
        ttlMinutes:
          1440 - (new Date().getHours() * 60 + new Date().getMinutes()),
        isUseSessionStorage: true
      },
      callback: async () => {
        await Api.postQotdLesson(userId);
        return await Api.getQotdInsights(userId);
      },
      defaultReturn: null
    });
  }

  static async getBookings(studentId) {
    if (!studentId) return [];
    return await getOrAddAsync({
      props: {
        key: `getBookings:${studentId}`,
        typename: types.bookings.typename,
        ttlMinutes: 5,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getBookings(studentId),
      defaultReturn: []
    });
  }

  static async getUserQuizInferences(userId, quizId) {
    return await getOrAddAsync({
      props: {
        key: `getUserQuizInferences:${userId}:${quizId}`,
        typename: types.userQuizInferences.typename,
        ttlMinutes: 5,
        isUseSessionStorage: true
      },
      callback: async () => {
        try {
          return await Api.getUserQuizInferences(userId, quizId);
        } catch (e) {
          console.error(e);
        }
        return [];
      },
      defaultReturn: []
    });
  }

  static async getGuestUsers() {
    return await getOrAddAsync({
      props: {
        key: `get-guestUsers`,
        typename: 'guest-users-type',
        ttlMinutes: 30,
        isUseSessionStorage: true
      },
      callback: async () => await Api.getGuestUsers(),
      defaultReturn: null
    });
  }

  static async getAllTopicPathwayCollectionsForStudent(studentId) {
    return await getOrAddAsync({
      props: {
        key: `getAllTopicPathwayCollectionsForStudent:${studentId}`,
        typename: 'topicPathwayCollectionStudents',
        ttlMinutes: 30,
        isUseSessionStorage: true
      },
      callback: async () =>
        await Api.getAllTopicPathwayCollectionsForStudent(studentId),
      defaultReturn: []
    });
  }
}

export default CachedApi;
