import { Api } from 'api';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { cache } from 'store';
import styled from 'styled-components';
import ActivityIcon from './ActivityIcon';
import { getImageUrl } from 'config/settings';
import analytics, { FAMILY_PROJECT_KEY } from 'analytics';

const { Modal, Button } = require('components/common');

const ActivityPreviewModal = ({
  isOpen = false,
  toggle = () => {},
  quizId = null,
  topicId = null,
  type = 'topicpathway',
  assignmentId = null,
  name = null,
  userRole = null,
  isStarted = false
}) => {
  const [imageURL, setImageURL] = useState(null);

  const handleLoadQuizImage = useCallback(async () => {
    if (!quizId || !isOpen) return;
    const cacheKey = `activity-preview-${quizId}`;
    let imageURL = cache.get({ key: cacheKey });
    if (!imageURL) {
      try {
        const quiz = await Api.getQuizInsights(quizId);
        imageURL = quiz.imageURL;
        cache.set({
          key: cacheKey,
          value: imageURL,
          ttlMinutes: 60,
          isUseSessionStorage: true,
          typename: 'getQuizInsights-quizImageURL'
        });
      } catch (e) {
        console.error(e);
      }
    }
    setImageURL(imageURL);
  }, [isOpen, quizId]);

  useEffect(() => {
    if (!isOpen) return;
    handleLoadQuizImage();
  }, [handleLoadQuizImage, isOpen]);

  const startTopic = () => {
    analytics.track(
      'Quiz Intended',
      {
        source: type
      },
      {
        project: FAMILY_PROJECT_KEY
      }
    );
  };

  if (!imageURL) return null;

  return (
    <Modal isOpen={isOpen} size="md" toggle={toggle}>
      <Modal.Header toggle={toggle}>
        <ModalHeader>
          <div style={{ margin: '0 0.5rem 0 0' }}>
            <ActivityIcon type={type} topicId={topicId} isLastChild={true} />
          </div>
          <div>
            <h3>{name}</h3>
          </div>
        </ModalHeader>
      </Modal.Header>
      <Content>
        <div>
          <img src={getImageUrl(imageURL)} alt="Quiz Preview" />
        </div>
        {userRole === 'Student' && !isStarted && (
          <div>
            <Button
              color="primary"
              onClick={startTopic}
              to={`/topic/${topicId}${
                assignmentId ? `?assignmentId=${assignmentId}` : ''
              }`}>
              Start topic
            </Button>
          </div>
        )}
        {userRole === 'Student' && isStarted && (
          <div>
            <Button
              color="primary"
              onClick={startTopic}
              to={`/topic/${topicId}${
                assignmentId ? `?assignmentId=${assignmentId}` : ''
              }`}>
              Resume
            </Button>
          </div>
        )}
      </Content>
    </Modal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const Content = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    margin: 0 0 1rem 0;
  }
`;

export default ActivityPreviewModal;
