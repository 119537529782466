import React from 'react';

const Circle = ({ color = '#C4C4C4', radius = '9' }) => (
  <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <circle cx={radius} cy={radius} r={radius} />
    </g>
  </svg>
);

export default Circle;
