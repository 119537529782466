const AbstractCircle2x1 = ({
  width = '24px',
  height = '24px',
  flipped = false,
  disabled = false,
  ...props
}) => {
  const backgroundColor = disabled ? 'var(--gray-lighter)' : 'var(--indigo)';
  const foregroundColor = disabled
    ? 'var(--gray)'
    : flipped
    ? 'var(--blue-light)'
    : 'var(--orange-lighter)';
  const angle = flipped ? 90 : 0;

  return (
    <svg
      style={{ width, height }}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        width="60"
        height="60"
        rx="30"
        className="topic-circle-background"
        fill={backgroundColor}
      />
      <circle
        cx="20"
        cy="20"
        r="8"
        className="topic-circle-foreground"
        fill={foregroundColor}
        transform={`rotate(${angle} 30 30)`}
      />
      <circle
        cx="40"
        cy="40"
        r="8"
        className="topic-circle-foreground"
        fill={foregroundColor}
        transform={`rotate(${angle} 30 30)`}
      />
    </svg>
  );
};

export default AbstractCircle2x1;
