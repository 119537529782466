import { pathToRegexp } from 'path-to-regexp';

function matchPathname(patterns, pathname, options = { exact: false }) {
  if (
    !pathname ||
    !patterns?.length ||
    typeof pathname !== 'string' ||
    !Array.isArray(patterns)
  )
    return null;

  for (const pattern of patterns) {
    try {
      const keys = [];
      const re = pathToRegexp(pattern, keys, options);
      const match = re.exec(pathname);

      if (match) {
        const [url, ...values] = match;
        const params = keys.reduce((memo, key, index) => {
          memo[key.name] = values[index];
          return memo;
        }, {});

        return {
          path: pattern,
          url,
          params,
          isExact: pathname === url
        };
      }
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}

export default matchPathname;
