import { SetTeamsActionType, SetTeamStudentActionType } from 'actions/teams';

const initialState = {
  teams: [],
  defaultTeamId: null,
  teamStudent: null
};

const teams = (state = initialState, action) => {
  switch (action.type) {
    case SetTeamsActionType:
      return {
        ...state,
        teams: action.teams,
        defaultTeamId:
          action.teams.find(t => t.name.toLowerCase() === 'default')?.teamId ??
          null
      };

    case SetTeamStudentActionType:
      return {
        ...state,
        teamStudent: action.teamStudent
      };

    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default teams;
