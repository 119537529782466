import styled from 'styled-components';
import ActivityButton from './ActivityButton';
import ActivityIcon from './ActivityIcon';
import ActivityProgress from './ActivityProgress';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ActivityPreviewModal from './ActivityPreviewModal';
import { useSelector } from 'react-redux';
import { media } from 'styles';
import SmallCheck from 'assets/images/small_check.svg';
import format from 'date-fns/format';
import { getUTCDate } from 'helpers/dateTimeHelper';
import { activityTypes } from '../helpers/learningActivityHelpers';
import { Button } from 'components/common';

const dataMap = ({ type, data }) => {
  switch (type) {
    case 'retrievalpractice':
      return {
        ...data,
        topicPathwayQuizStatistics: {
          quizSessionId: data.quizSession?.quizSessionId,
          answersCount: data.quizSession?.answers?.length ?? 0,
          questionsCount: data.quizQuestionCount ?? 7,
          incompleteQuestionLessonsCount:
            data.quizSession?.lessons?.filter(
              l => l.lessonType === 'Question' && l.dateCompleted === null
            )?.length || 0,
          correctAnswersCount:
            data.quizSession?.answers?.filter(a => a.isCorrect)?.length || 0,
          correctCorrectionsCount:
            data.quizSession?.corrections?.filter(a => a.isCorrect)?.length || 0
        },
        quizSessions: !!data.quizSession ? [data.quizSession] : []
      };
    case 'topic':
    case 'assignment':
      return {
        ...data,
        topicPathwayQuizStatistics: {
          quizSessionId: data.quizSession?.quizSessionId,
          stretchLessonsCount: data.isChallengeWorksheetCompleted ? 1 : 0,
          answersCount: data.quizSession?.answers?.length ?? 0,
          questionsCount: data.quizQuestionCount ?? 5,
          incompleteQuestionLessonsCount:
            data.quizSession?.lessons?.filter(
              l => l.lessonType === 'Question' && l.dateCompleted === null
            )?.length || 0,
          correctAnswersCount:
            data.quizSession?.answers?.filter(a => a.isCorrect)?.length || 0,
          correctCorrectionsCount:
            data.quizSession?.corrections?.filter(a => a.isCorrect)?.length || 0
        },
        quizSessions: !!data.quizSession ? [data.quizSession] : []
      };
    default:
      return data;
  }
};

const ActivityCard = ({
  activity,
  isLastChild = false,
  iconSize = '3rem',
  ...props
}) => {
  const userRole = useSelector(s => s.user.userRole);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { name, topicId, data } = activity;
  const quizId = activity?.quizId ?? data?.quizId;
  const type = activity.type.toLowerCase();
  const assignmentId = data?.assignmentId;
  const studentId = data?.studentId;

  const mappedData = dataMap({ type, data });

  const quizSession =
    mappedData.quizSessions?.length > 0 ? mappedData.quizSessions[0] : null;
  const answers = quizSession?.answers?.length > 0 ? quizSession.answers : [];
  const corrections =
    quizSession?.corrections?.length > 0 ? quizSession.corrections : [];

  const isDisabled =
    assignmentId &&
    getUTCDate(mappedData?.startDate ?? new Date()) >
      getUTCDate(new Date().toISOString());

  return (
    <ActivityCardWrapper
      {...props}
      isBorderBottom={!isLastChild && type !== 'topicpathway'}
      isPadded={type !== 'topicpathway'}>
      <IconWrapper>
        <ActivityIcon
          type={type}
          topicId={topicId}
          isLastChild={isLastChild}
          size={iconSize}
          date={mappedData?.startDate}
          disabled={isDisabled}
        />
      </IconWrapper>
      <InfoWrapper>
        <div className="mb-1">
          <h4>{name}</h4>
          {mappedData?.groupName && (
            <h5 className="subtitle">{mappedData.groupName}</h5>
          )}
        </div>
        {mappedData?.startDate && (
          <DateWrapper>
            <strong>Start: </strong>
            {format(getUTCDate(mappedData?.startDate), 'EEEE do MMMM')}
            <br />
          </DateWrapper>
        )}
        {mappedData?.endDate && (
          <DateWrapper>
            <strong>End: </strong>
            {format(getUTCDate(mappedData?.endDate), 'EEEE do MMMM')}
            <br />
          </DateWrapper>
        )}
        {!assignmentId && (
          <>
            <DateWrapper>
              Independent learning <br />
            </DateWrapper>
            {mappedData?.quizSession?.dateCompleted && (
              <DateWrapper>
                <strong>Completed: </strong>
                {format(
                  getUTCDate(mappedData?.quizSession?.dateCompleted),
                  'EEEE do MMMM'
                )}
                <br />
              </DateWrapper>
            )}
          </>
        )}

        <ActivityProgressWrapper>
          <ActivityProgress
            assignmentId={assignmentId}
            answers={answers}
            corrections={corrections}
          />

          {type !== activityTypes.retrievalpractice && (
            <>
              {!!mappedData?.topicPathwayQuizStatistics
                ?.stretchLessonsCount && (
                <StretchCompleted>
                  <img src={SmallCheck} alt="Stretch Completed" /> Stretch &
                  Challenge
                </StretchCompleted>
              )}
              {!!quizSession?.dateCompleted &&
              !mappedData?.topicPathwayQuizStatistics?.stretchLessonsCount &&
              userRole === 'Student' ? (
                <ReviewLink>
                  <Link to={`/topic/${quizSession.quizSessionId}/review`}>
                    Results
                  </Link>
                </ReviewLink>
              ) : !answers?.length && !assignmentId ? (
                <PreviewLink>
                  <ActivityPreviewModal
                    isOpen={isModalOpen}
                    toggle={() => setIsModalOpen(false)}
                    quizId={quizId}
                    topicId={topicId}
                    name={name}
                    assignmentId={assignmentId}
                    userRole={userRole}
                    isStarted={!!quizSession}
                  />
                  <button onClick={setIsModalOpen}>Preview</button>
                </PreviewLink>
              ) : null}
            </>
          )}
        </ActivityProgressWrapper>
      </InfoWrapper>
      <ButtonWrapper>
        {userRole === 'Student' ? (
          <ActivityButton activity={mappedData} type={type} />
        ) : userRole === 'Parent' && !!quizSession ? (
          <Button
            color="primary"
            {...props}
            to={
              assignmentId
                ? `/assignments/${studentId}/${assignmentId}`
                : `/independent-study/${quizId}`
            }>
            Results
          </Button>
        ) : null}
      </ButtonWrapper>
    </ActivityCardWrapper>
  );
};

const ActivityCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: ${props => (props.isBorderBottom ? '1px solid #e0e0e0' : 0)};
  padding: ${props => (props.isPadded ? '1rem 0' : 0)};
`;

const IconWrapper = styled.div`
  margin: 0 1rem 0 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h4,
  h5,
  strong {
    margin: 0;
  }
  margin: 0 1.5rem 0 0;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;

const ActivityProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  div {
    margin: 0.25rem 0 0 0;
  }
  ${media.sm`
    flex-direction: row;
    align-items: center;
    margin-top: 0.25rem;
    div {
      margin: 0;
    }
  `}
`;

const ReviewLink = styled.div`
  margin: 0 0 0 0.5rem;
  a {
    text-decoration: underline;
  }
  button {
    border: none;
    padding: none;
    text-decoration: underline;
    background: none;
    color: #4353ff;
    font-weight: 500;
  }
`;

const PreviewLink = styled.div`
  a {
    text-decoration: underline;
  }
  button {
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    background: none;
    color: #4353ff;
    font-weight: 500;
    outline: none;
  }
  button:focus {
    border: none;
    outline: none;
  }
`;

const DateWrapper = styled.div`
  font-size: 14px;
  margin: 0;
`;

const StretchCompleted = styled.div`
  font-size: 12px;
  font-weight: 500;
  background: var(--yellow);
  padding: 1px 5px 1px 5px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-direction: row;
  align-items: center;
  img {
    margin: 0 0.1rem 0 0;
  }

  ${media.sm`
    margin: 0;
  `}
`;

export default ActivityCard;
