import AbstractCircle2x1 from 'assets/icons/abstractCircle2x1';
import AbstractCircle2x2 from 'assets/icons/abstractCircle2x2';
import AbstractCircleHalf, {
  directions
} from 'assets/icons/abstractCircleHalf';
import AbstractSquare from 'assets/icons/abstractSquare';
import AbstractTriangle from 'assets/icons/abstractTriangle';

const TopicIcon = ({ topicId, size, disabled, ...props }) => {
  switch (topicId % 10) {
    case 1:
      return (
        <AbstractCircle2x1
          width={size}
          height={size}
          disabled={disabled}
          {...props}
        />
      );
    case 2:
      return (
        <AbstractCircle2x1
          width={size}
          height={size}
          disabled={disabled}
          flipped
          {...props}
        />
      );
    case 3:
      return (
        <AbstractCircleHalf
          width={size}
          height={size}
          disabled={disabled}
          direction={directions.north}
          {...props}
        />
      );
    case 4:
      return (
        <AbstractCircleHalf
          width={size}
          height={size}
          disabled={disabled}
          direction={directions.east}
          {...props}
        />
      );
    case 5:
      return (
        <AbstractCircleHalf
          width={size}
          height={size}
          disabled={disabled}
          direction={directions.south}
          {...props}
        />
      );
    case 6:
      return (
        <AbstractCircleHalf
          width={size}
          height={size}
          disabled={disabled}
          direction={directions.west}
          {...props}
        />
      );
    case 7:
      return (
        <AbstractSquare
          width={size}
          height={size}
          disabled={disabled}
          {...props}
        />
      );
    case 8:
      return (
        <AbstractTriangle
          width={size}
          height={size}
          disabled={disabled}
          {...props}
        />
      );
    case 9:
      return (
        <AbstractTriangle
          width={size}
          height={size}
          disabled={disabled}
          flipped
          {...props}
        />
      );
    default:
      return (
        <AbstractCircle2x2
          width={size}
          height={size}
          disabled={disabled}
          {...props}
        />
      );
  }
};

export default TopicIcon;
