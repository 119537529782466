import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Loading } from 'components/common';
import ActivityCard from 'components/LearningActivity/components/ActivityCard';
import { motion, AnimatePresence } from 'framer-motion';
import EmptyState from 'components/EmptyState';
import useQuery from 'hooks/useQuery';

const TopicPathwayGrid = ({
  topicPathwayCollectionGroups,
  currentTab: currentSubject = -1,
  loading = false
}) => {
  const search = useQuery().get('search');
  const quizGroups = useMemo(() => {
    let topicPathwayCollectionGroup;
    if (currentSubject) {
      topicPathwayCollectionGroup = topicPathwayCollectionGroups.find(
        t => t.topicPathwayCollectionGroupId === currentSubject
      );
    }
    if (!topicPathwayCollectionGroup) {
      topicPathwayCollectionGroup = topicPathwayCollectionGroups[0];
    }

    let sequence = 1;
    return topicPathwayCollectionGroup?.topicPathwayQuizGroups.map(
      quizGroup => {
        const orderedPathwayQuizs = orderBy(
          quizGroup.topicPathwayQuizs,
          _ => _.sequence
        );
        quizGroup.sequence = sequence++;
        let quizSequence = 1;
        return {
          ...quizGroup,
          topicPathwayQuizs: orderedPathwayQuizs.map(topicPathwayQuiz => {
            topicPathwayQuiz.sequence = quizSequence++;
            return {
              ...topicPathwayQuiz,
              canStart: true
            };
          })
        };
      }
    );
  }, [currentSubject, topicPathwayCollectionGroups]);

  if (loading) return <Loading />;

  if (!quizGroups?.length)
    return (
      <StyledEmptyState
        title={`Found no results for "${search}"`}
        message="Try searching for something else."
      />
    );

  return (
    <AnimatePresence>
      <Wrapper
        variants={{
          hidden: { opacity: 0 },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1,
              delayChildren: 0.1
            }
          }
        }}
        initial="hidden"
        animate="show">
        {quizGroups.map((quizGroup, i) => {
          return (
            <motion.div
              key={i}
              variants={{
                hidden: {
                  opacity: 0,
                  scale: 0.5,
                  y: -50
                },
                show: { opacity: 1, scale: 1, y: 0 }
              }}>
              <div className="grid-row grid-columns">
                {quizGroup.topicPathwayQuizs.map((topicPathwayQuiz, index) => (
                  <ActivityCard
                    key={index}
                    activity={{
                      name: topicPathwayQuiz.name,
                      topicId: topicPathwayQuiz.topicPathwayQuizId,
                      type: 'topicpathway',
                      data: topicPathwayQuiz
                    }}
                    isLastChild={i === quizGroups.length - 1}
                  />
                ))}
              </div>
            </motion.div>
          );
        })}
      </Wrapper>
    </AnimatePresence>
  );
};

const StyledEmptyState = styled(EmptyState)`
  padding: 1rem;
  margin: 0 0 1rem 0;
`;

const Wrapper = styled(motion.div)`
  display: grid;
  width: 100%;
  padding: 1rem 0;
  gap: 1rem;
  grid-auto-columns: 1fr 1fr;
  border-bottom: 1px solid #ddd;
  margin: 0 0 1rem 0;
  .grid-row {
    width: 100%;
    place-self: center;
    display: grid;
    gap: 1rem;
    grid-auto-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
  .grid-columns {
    grid-column: 1 / span 2;
  }
`;

export default TopicPathwayGrid;
