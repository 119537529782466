import { Controller } from 'react-hook-form';
import Select from '../Select';

const defaultValueComparator = (a, b) => a === b;

const SelectInput = ({
  control,
  name,
  onChange: onValueChange,
  isCreatable = false,
  isAsync = false,
  options = [],
  label = undefined,
  error = undefined,
  rules = undefined,
  defaultValue = '',
  disabled = false,
  valueComparator = defaultValueComparator,
  ...props
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ onChange, onBlur, value }) => {
        return (
          <Select
            isAsync={isAsync}
            isCreatable={isCreatable}
            name={name}
            error={error}
            options={options}
            label={label}
            value={(isAsync ? props.defaultOptions : options).find(x =>
              valueComparator(x.value, value)
            )}
            onChange={option => {
              onChange(option?.value);
              onValueChange && onValueChange(option?.value);
            }}
            onBlur={onBlur}
            disabled={disabled}
            {...props}
          />
        );
      }}
      rules={rules}
    />
  );
};

export default SelectInput;
