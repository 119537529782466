import CachedApi from 'cache/CachedApi';
import {
  assignmentGroups,
  topicPathwayQuizGroups
} from './learningActivityHelpers';

export const assignmentsCount = async ({
  userId,
  assignmentGroup = assignmentGroups.Active
}) => {
  if (!userId) return 0;

  const insights = await CachedApi.getAssignmentInsights(
    userId,
    0,
    1,
    assignmentGroup
  );

  if (!insights?.total) return 0;

  return parseInt(insights?.total, 10);
};

export const activityCount = async ({
  userId,
  topicPathwayQuizGroup = assignmentGroups.Active,
  activityType = null
}) => {
  if (!userId) return 0;

  const insights = await CachedApi.getTopicPathwayQuizzesForUser(
    userId,
    topicPathwayQuizGroup,
    0,
    1,
    activityType
  );

  if (!insights?.total) return 0;

  return parseInt(insights?.total, 10);
};

export const activeLearningCompletedCount = async ({
  userId,
  topicPathwayQuizGroup = topicPathwayQuizGroups.ActiveLearningAndCompletedAssignments,
  activityType = null
}) => {
  if (!userId) return 0;

  const insights = await CachedApi.getTopicPathwayQuizzesForUser(
    userId,
    topicPathwayQuizGroup,
    0,
    1,
    activityType
  );

  if (!insights?.total) return 0;

  return parseInt(insights?.total, 10);
};
