import { useMemo } from 'react';
import ReactSelect, { components as ReactSelectComponents } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { FormFeedback, Label } from 'reactstrap';

const TypeToSearchMessage = props => (
  <ReactSelectComponents.NoOptionsMessage {...props}>
    Type to search or add
  </ReactSelectComponents.NoOptionsMessage>
);

const Select = ({
  label,
  name,
  error,
  isCreatable,
  isAsync,
  onChange,
  components = {},
  disabled = false,
  isSearchable = true,
  styles,
  size,
  ...props
}) => {
  const SelectComponent = isCreatable
    ? isAsync
      ? AsyncCreatableSelect
      : CreatableSelect
    : ReactSelect;

  const componentOverrides = useMemo(
    () => ({
      ...(isAsync ? { NoOptionsMessage: TypeToSearchMessage } : {}),
      ...components
    }),
    [components, isAsync]
  );

  const customStyles = {
    container: base => ({
      ...base,
      ...(styles?.container ? styles.container(base) : {}),
      width: '100%'
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused
        ? 'var(--gray-lightest)'
        : 'var(--white)',
      minHeight: size === 'sm' ? '2rem' : '2.5rem',
      paddingLeft: size === 'sm' ? '0' : '8px',
      paddingRight: '0',
      boxShadow: 'none',
      ...(props.dropdown
        ? {
            borderColor: state.isFocused ? 'var(--black)' : 'var(--gray-light)'
          }
        : {
            borderColor: !!error
              ? 'var(--red)'
              : state.isFocused
              ? 'var(--black)'
              : props.navbar
              ? 'transparent'
              : 'var(--gray-light)',
            fontSize: '1rem'
          }),
      '&:hover': {
        borderColor: state.isFocused ? 'var(--black)' : 'var(--body-color)'
      },
      ...(state.isDisabled && {
        backgroundColor: 'var(--gray-lighter)',
        borderColor: 'var(--gray-light)'
      }),
      ...props.style,
      ...(styles?.control ? styles.control(base) : {})
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--gray-light)'
    }),
    menu: base => ({
      ...base,
      boxShadow: 'var(--shadow)',
      textAlign: 'left',
      zIndex: 1000
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? 'var(--tint-color)' : 'var(--white)',
      padding: '0.5rem 1rem',
      color: 'var(--body-color)'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: 'var(--body-color)',
      padding: size === 'sm' ? '0.25rem' : '0.5rem'
    }),
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: 'transparent'
    }),
    valueContainer: base => ({
      ...base,
      paddingRight: '0',
      ...(props.navbar ? { justifyContent: 'flex-end' } : {}),
      ...(styles?.valueContainer ? styles.valueContainer(base) : {})
    }),
    singleValue: base => ({
      ...base,
      maxWidth: 'calc(100% - 16px)',
      ...(props.navbar
        ? {
            color: 'var(--gray)',
            right: '10px'
          }
        : {}),
      ...(styles?.singleValue ? styles.singleValue(base) : {})
    })
  };

  return (
    <>
      {label && <Label for={name}>{label}</Label>}
      <SelectComponent
        isDisabled={disabled}
        isSearchable={isSearchable}
        components={componentOverrides}
        styles={customStyles}
        {...props}
        onChange={value => {
          if (onChange) {
            onChange(props.isMulti ? value || [] : value);
          }
        }}
        placeholder={props.placeholder || 'Select...'}
      />
      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export default Select;
