import { useCallback, useEffect, useState } from 'react';
import {
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import TablePaginationControls from './TablePaginationControls';
import TableWithHeader from './TableWithHeader';

export const DataTable = ({
  columns = [],
  data = [],
  fetchDataPage = () => {},
  isLoading = false,
  totalDataItems = data.length,
  currentPageIndex = 0,
  pageSize = 10,
  sortByOptions = [],
  stickyHeaderContent = undefined,
  enableBulkSelection = false,
  rowIdSelector = 'rowId',
  clearCurrentSelection = { value: false },
  onSelectedRowsChange = () => {},
  emptyState = null,
  enablePagination = true,
  ...props
}) => {
  const pageCount = Math.ceil(totalDataItems / pageSize);

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  useEffect(() => {
    onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  const setRowSelectedState = useCallback(
    (rowIds, state) => {
      if (!Array.isArray(rowIds)) {
        rowIds = [rowIds];
      }

      if (state) {
        setSelectedRowIds(
          selectedRowIds.concat(rowIds.filter(x => !selectedRowIds.includes(x)))
        );
      } else {
        setSelectedRowIds(selectedRowIds.filter(x => !rowIds.includes(x)));
      }
    },
    [selectedRowIds]
  );

  useEffect(() => {
    if (clearCurrentSelection.value) {
      setSelectedRowIds([]);
    }
  }, [clearCurrentSelection]);

  const getRowId = useCallback(
    (row, relativeIndex, parent) => {
      const providedKey = row[rowIdSelector];
      if (providedKey !== undefined) {
        return providedKey;
      }
      return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
    },
    [rowIdSelector]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    canNextPage,
    canPreviousPage,
    allColumns,
    state: { pageIndex, pageSize: internalPageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      disableSortRemove: true,
      pageCount: pageCount,
      getRowId,
      initialState: {
        pageSize,
        sortBy: sortByOptions,
        pageIndex: currentPageIndex
      }
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    gotoPage(currentPageIndex);
  }, [currentPageIndex, gotoPage]);

  const fetchDataPageDebounced = useAsyncDebounce(fetchDataPage, 200);

  useEffect(() => {
    fetchDataPageDebounced(pageIndex, internalPageSize, sortBy);
  }, [fetchDataPageDebounced, pageIndex, internalPageSize, sortBy]);

  return (
    <TableWithHeader
      isLoading={isLoading}
      {...props}
      {...{
        rows,
        prepareRow,
        setRowSelectedState,
        selectedRowIds,
        headerContent: <>{stickyHeaderContent}</>,
        footerContent: (
          <>
            {data.length > 0 && enablePagination && (
              <TablePaginationControls
                pageIndex={pageIndex ?? 0}
                setPageIndex={gotoPage}
                pageSize={internalPageSize}
                totalItems={totalDataItems}
                pageCount={pageCount}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
              />
            )}
          </>
        ),
        totalColumns: allColumns.length,
        enableBulkSelection,
        enablePagination,
        headerGroups,
        getTableProps,
        getTableBodyProps,
        emptyState
      }}
    />
  );
};

export default DataTable;
