import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Api, FlowApi } from 'api';
import { ENVIRONMENT } from 'config/settings';
import { createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

export const SignalRConnectionContext = createContext();

const GetHubInfo = async (hubName, userId, userRole) => {
  switch (hubName) {
    case 'LessonHub':
      return await FlowApi.getSignalRConnectionInfo(userId, hubName, userRole);
    case 'SignalRHub':
      return await Api.getSignalRConnectionInfo(userId, hubName, userRole);
    default:
      throw new Error(`${hubName} not implemented`);
  }
};

export const SignalRProvider = ({ hubName, children, context }) => {
  const { userId, userRole, subscriptionStatus, schoolIds } = useSelector(
    ({ user }) => ({
      userId: user.userId,
      userRole: user.userRole,
      subscriptionStatus: user?.subscription?.subscriptionStatus,
      schoolIds: user?.students?.map(s => s.schoolId) ?? []
    })
  );
  const [connection, setConnection] = useState(null);
  const connectionInfoRef = useRef({ calls: 0 });

  useEffect(() => {
    let wasCancelled = false;

    async function createSignalRConnection() {
      if (!userId || !!connection) return;

      connectionInfoRef.current.calls += 1;

      try {
        let isValid = true;

        // We only want users with active susbcriptions to connect to live lessons.
        if (hubName === 'LessonHub') {
          isValid =
            subscriptionStatus === 'active' ||
            subscriptionStatus === 'trialing';
        }

        if (
          userId &&
          connectionInfoRef?.current?.userId !== userId &&
          isValid
        ) {
          let info = await GetHubInfo(hubName, userId, userRole);

          const options = {
            accessTokenFactory: async () => {
              const token = jwt_decode(info.accessToken);
              const expiry = new Date(token.exp * 1000);
              const msDifference = expiry - new Date();
              const minutes = Math.floor(msDifference / 1000 / 60);

              // If we're less than 30 minutes from expiry, refetch the token
              if (minutes <= 30) {
                info = await GetHubInfo(hubName, userId, userRole);
              }

              return info.accessToken;
            }
          };

          connectionInfoRef.current = {
            ...connectionInfoRef.current,
            ...info,
            userId
          };

          const newConnection = new HubConnectionBuilder()
            .withUrl(info.url, options)
            .withAutomaticReconnect()
            .configureLogging(
              ENVIRONMENT === 'prod' ? LogLevel.Error : LogLevel.Debug
            )
            .build();

          if (!wasCancelled) {
            await newConnection.start();
            setConnection(newConnection);
            // Required for .NET 8 update.
            for (const schoolId of schoolIds) {
              try {
                await Api.signalRJoinSchoolGroups(
                  userId,
                  `SchoolGroup_${schoolId}`
                );
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
      } catch (e) {
        console.log('invoke?', e);
        console.error(e);
      }
    }

    if (userRole === 'Student') createSignalRConnection();

    return () => (wasCancelled = true);
  }, [connection, hubName, schoolIds, subscriptionStatus, userId, userRole]);

  const Context = context || SignalRConnectionContext;

  return <Context.Provider value={connection}>{children}</Context.Provider>;
};
