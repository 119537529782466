import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Loading } from 'components/common';
import { NavBarProvider } from 'providers/NavBarProvider';
import { redirectToTeacher } from 'helpers/redirectAndLogin';
import useTrackPageChange from 'hooks/useTrackPageChange';
import CompatibilityCheckerPage from 'pages/compatibility/CompatibilityCheckerPage';
import useRCTRedirect from 'hooks/useRCTRedirect';
import DynamicQuizStepper from 'components/DynamicQuiz/GuestQuiz/DynamicQuizStepper';

const OAuthLoginPage = lazy(() => import('components/Auth/OAuthLoginPage'));
const PrivateRoute = lazy(() => import('routes/PrivateRoute'));
const Suspended = lazy(() => import('pages/child/Suspended'));
const LoginRedirect = lazy(() => import('pages/LoginRedirect'));
const Logout = lazy(() => import('pages/Logout'));
const Unsubscribe = lazy(() => import('pages/Unsubscribe'));
const AccountEdit = lazy(() => import('pages/account/Edit'));
const EmailConfig = lazy(() => import('pages/parent/EmailConfig'));
const ComfortGrowth = lazy(() => import('pages/child/ComfortGrowth'));
const PageNotFound = lazy(() => import('components/Errors/PageNotFound'));
const StyleGuide = lazy(() => import('components/StyleGuide'));
const LoadingPage = lazy(() => import('components/LoadingPage'));
const ParentInviteSignup = lazy(() =>
  import('components/ParentInvite/ParentInviteSignup')
);
const LoginRoutes = lazy(() => import('./LoginRoutes'));
const ParentRoutes = lazy(() => import('./ParentRoutes'));
const ChildRoutes = lazy(() => import('./ChildRoutes'));
const SignupRoutes = lazy(() => import('./SignupRoutes'));
const ConfigManagement = lazy(() =>
  import('components/ConfigManagement/ConfigManagement')
);
const AccountDisabled = lazy(() => import('components/Errors/AccountDisabled'));
const schoolIdBlackList = [207221, 77074, 59714];

const AppRoutes = () => {
  useTrackPageChange();
  useRCTRedirect();
  const {
    schoolId,
    allStudents,
    featureFlagSchools,
    userRole,
    isAdmin,
    isAccountDisabled
  } = useSelector(s => ({
    userId: s.user.userId,
    schoolId: s.school.schoolId,
    allStudents: s.school.allStudents,
    featureFlagSchools: s.user.featureFlagSchools,
    userRole: s.user.userRole,
    isAdmin: s.user.isAdmin,
    isAccountDisabled: s.user.isAccountDisabled
  }));

  const isBlackListed =
    allStudents?.filter(s => schoolIdBlackList.includes(s.schoolId)).length >
      0 ||
    (featureFlagSchools?.length &&
      featureFlagSchools?.filter(s => schoolIdBlackList.includes(s.schoolId))
        .length > 0);

  if (userRole === 'Teacher') {
    redirectToTeacher(schoolId);
    return <Loading />;
  }

  return (
    <NavBarProvider>
      <React.Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route
            path="/guest/dynamic-quiz/*"
            element={<DynamicQuizStepper />}
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/loginredirect" element={<LoginRedirect />} />

          {isAccountDisabled && (
            <Route path="*" noNavigation element={<AccountDisabled />} />
          )}

          {isBlackListed && (
            <Route path="*" noNavigation element={<Suspended />} />
          )}

          <Route path="/invite-parent" element={<ParentInviteSignup />} />

          <Route element={<PrivateRoute />}>
            <Route path="/account/edit" element={<AccountEdit />} />
            <Route path="/account/email" element={<EmailConfig />} />
            <Route path="/about/comfortgrowth" element={<ComfortGrowth />} />
            {isAdmin === true && (
              <Route path="/config" element={<ConfigManagement />} />
            )}
          </Route>

          <Route path="/style-guide" element={<StyleGuide />} noNavigation />

          {userRole === 'Parent' && (
            <Route path="/*" element={<ParentRoutes />} />
          )}

          {userRole === 'Student' && (
            <Route path="/*" element={<ChildRoutes />} />
          )}

          <Route path="/oauth-login" element={<OAuthLoginPage />} />
          <Route path="/signup/*" element={<SignupRoutes />} />
          <Route path="/login/*" element={<LoginRoutes />} />
          <Route
            path="/compatibility-check"
            element={<CompatibilityCheckerPage />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </React.Suspense>
    </NavBarProvider>
  );
};

export default AppRoutes;
