import { Api } from 'api';

export const SetAchievementsActionType = 'SET_ACHIEVEMENTS';
export const setAchievements = achievements => ({
  type: SetAchievementsActionType,
  achievements
});

export const setAchievementsAndMarkAsAcknowledged =
  achievements => async (dispatch, getState) => {
    const {
      user: { userId }
    } = getState();
    dispatch(setAchievements(achievements));

    try {
      const needAcknowledging = achievements
        .filter(a => !a.wasAcknowledged && !!a.dateCompletedUTC)
        .map(ua => ua.achievement.achievementId);

      if (!needAcknowledging.length) return;

      await Api.acknowledgeCompletedUserAchievements(userId, needAcknowledging);
    } catch (e) {
      console.error('Unable to mark achievements as acknowledged');
    }
  };

export const DismissCompletedAchievementType = 'DISMISS_ACHIEVEMENT';
export const dismissCompletedAchievement = achievementId => ({
  type: DismissCompletedAchievementType,
  achievementIds: [achievementId]
});
export const dismissCompletedAchievements = achievementIds => ({
  type: DismissCompletedAchievementType,
  achievementIds
});
