import { useEffect, useRef, useCallback } from 'react';

const useInterval = (callback, interval) => {
  const callbackRef = useRef();

  const clearUseInterval = useCallback(
    () => clearInterval(callbackRef.current),
    []
  );

  const setUseInterval = useCallback(
    (callBackOverride = null, intervalOverride = null) => {
      callbackRef.current = setInterval(
        callBackOverride ?? callback,
        intervalOverride ?? interval
      );
    },
    [callback, interval]
  );

  useEffect(() => {
    const runEffect = async () => setUseInterval(callback, interval);
    runEffect();
    return clearUseInterval;
  }, [callback, clearUseInterval, interval, setUseInterval]);

  return { clearInterval: clearUseInterval, setInterval: setUseInterval };
};

export default useInterval;
