import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Popover, PopoverBody } from 'reactstrap';
import CloseButton from './CloseButton';

const PopOver = ({
  initialVisibleMs = 0,
  isVisible = false,
  target,
  placement = 'top',
  children,
  title,
  content,
  ...props
}) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    if (!initialVisibleMs) return;

    setVisible(true);
    const timer = setTimeout(() => setVisible(false), initialVisibleMs);

    return () => clearTimeout(timer);
  }, [initialVisibleMs]);

  if (children === null) return null;

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onClick={() => setVisible(false)}>
      <span id={target}>{children}</span>
      <StyledPopover
        placement={placement}
        target={target}
        isOpen={visible}
        toggle={() => setVisible(!visible)}
        {...props}>
        <StyledPopoverBody>
          <div className="pvr-header">
            <div style={{ fontWeight: '500' }}>{title}</div>
            <div>
              <StyledCloseButton onClick={() => setVisible(false)} />
            </div>
          </div>
          <div className="pvr-content">{content}</div>
        </StyledPopoverBody>
      </StyledPopover>
    </div>
  );
};

const StyledCloseButton = styled(CloseButton)`
  float: right;
  span {
    font-size: 1.4rem;
    color: white;
    margin-right: 0.5rem;
  }
`;

const StyledPopoverBody = styled(PopoverBody)`
  display: flex;
  flex-direction: column;
  color: #fff;
  //styleName: Eedi / text / small;
  font-weight: 300;
  font-family: Post Grotesk;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  .pvr-header {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledPopover = styled(Popover)`
  z-index: 999;

  .popover {
    background-color: #32314f;
    color: #fff;
    max-width: 250px;
  }

  .arrow::before {
    border-top-color: #32314f !important;
  }

  .arrow::after {
    border-top-color: #32314f !important;
  }
`;

export default PopOver;
