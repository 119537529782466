import { FUNCTIONS_API_URL, getAuthToken } from 'config/settings';

export const track = (action, data, option, userId) => {
  if (!userId) return;

  try {
    const body = {
      action,
      userId,
      properties: data
    };

    fetch(`${FUNCTIONS_API_URL}/api/v3/users/${userId}/tracking-events`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export class TrackingApi {
  init() {}
  identify() {}
  page() {}
  async track(action, data, option, userId) {
    await track(action, data, option, userId);
  }
}
