import { Api } from 'api';
import uniq from 'lodash/uniq';

export const SetUserActionType = 'SET_USER';
export const setUser = user => async dispatch => {
  dispatch({
    type: SetUserActionType,
    user
  });
};

export const SetNotificationsActionType = 'SET_NOTIFICATIONS';
export const setNotifications = notifications => ({
  type: SetNotificationsActionType,
  notifications
});

export const SetSubscriptionActionType = 'SET_SUBSCRIPTION';
export const setSubscription = subscription => ({
  type: SetSubscriptionActionType,
  subscription
});

export const SetUserPreferencesActionType = 'SET_USER_PREFERENCES';
export const setUserPreferences = userPreferences => ({
  type: SetUserPreferencesActionType,
  userPreferences
});

export const SetUserStateActionType = 'SET_USER_STATE';
export const setUserState = userState => ({
  type: SetUserStateActionType,
  userState
});

export const SetParentInvitationStatusActionType =
  'SET_PARENT_INVITATION_STATUS';
export const SetParentInvitationStatus = status => ({
  type: SetParentInvitationStatusActionType,
  status
});

export const RefreshAvatarActionType = 'REFRESH_AVATAR';
export const refreshAvatar = () => ({
  type: RefreshAvatarActionType
});

export const updateUserState =
  (newPartialState, predictUpdate = true) =>
  async (dispatch, getState) => {
    const {
      user: { userId, userState: initialUserState }
    } = getState();

    if (!initialUserState?.stateValues) return;

    // Don't update when there is no change
    if (
      Object.keys(newPartialState).every(
        key =>
          initialUserState.stateValues.find(s => s.key === key)?.value ===
          newPartialState[key]
      )
    ) {
      return;
    }

    if (predictUpdate) {
      // We pre-emptively update the store state, to prevent UI hangs whilst we
      // await confirmation from the server that the update has gone through.
      const updatedKeys = Object.keys(newPartialState);
      const existingKeys = initialUserState.stateValues.map(s => s.key);

      const allKeys = uniq(updatedKeys.concat[existingKeys]);

      const preEmptiveStateValues = allKeys.map(key => {
        const newState = newPartialState[key];

        if (!!newState) {
          return {
            key,
            value: newState,
            dateSet: 'Preempted Value'
          };
        }

        return initialUserState.stateValues.find(s => s.key === key);
      });

      dispatch(
        setUserState({
          ...initialUserState,
          stateValues: preEmptiveStateValues
        })
      );
    }

    // Now we try to update the remote state store
    try {
      const userState = await Api.updateUserState(userId, newPartialState);
      dispatch(setUserState(userState));
    } catch (error) {
      if (predictUpdate) {
        // If something goes wrong, we reset the user state to the initial value
        dispatch(setUserState(initialUserState));
      }

      throw error;
    }
  };

export const updateUserStateKvp = (key, value, predictUpdate = true) => {
  const userPartialState = {};
  userPartialState[key] = value;
  return updateUserState(userPartialState, predictUpdate);
};
