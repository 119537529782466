import styled from 'styled-components';
import { IntentIcon, Avatar } from 'components/common';
import { TypingIndicator } from '../Lessons/Chat/TypingIndicator';

const BotMessage = ({
  message: Component,
  loading,
  size,
  intent,
  title,
  avatarOnEnd = false,
  ...props
}) => (
  <Wrapper reverse={avatarOnEnd} {...props}>
    <Avatar
      userId="eedi"
      width={5}
      size={size === 'small' ? 'thumbnail' : 'avatar'}
    />
    <Bubble size={size}>
      {loading ? (
        <TypingIndicator />
      ) : (
        <Message>
          {intent && (
            <Icon
              color={
                intent === 'success'
                  ? 'var(--green-lighter)'
                  : 'var(--yellow-lighter)'
              }>
              <IntentIcon
                intent={intent}
                color={intent === 'success' ? 'var(--green)' : 'var(--yellow)'}
                width="1.5rem"
                height="1.5rem"
              />
            </Icon>
          )}
          <MessageContent>
            {title && <h4 className="mb-0">{title}</h4>}
            {Component}
          </MessageContent>
        </Message>
      )}
    </Bubble>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: ${props => (!!props.reverse ? 'row-reverse' : 'row')};
`;

const Bubble = styled.div`
  background-color: var(--tint-color);
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 0 ${props => (props.size === 'small' ? '0.5rem' : '1.5rem')};

  p {
    font-size: ${props => (props.size === 'small' ? '1rem' : '1.1rem')};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Message = styled.div`
  display: flex;
`;

const MessageContent = styled.div`
  flex: 1;
`;

const Icon = styled.span`
  margin-right: 1rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  flex-basis: 3rem;
  flex-shrink: 0;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default BotMessage;
