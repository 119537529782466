const AbstractSquare = ({
  width = '24px',
  height = '24px',
  disabled = false,
  ...props
}) => {
  const backgroundColor = disabled ? 'var(--gray-lighter)' : 'var(--indigo)';
  const foregroundColor = disabled ? 'var(--gray)' : 'var(--blue-light)';

  return (
    <svg
      style={{ width, height }}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect
        width="60"
        height="60"
        rx="30"
        className="topic-circle-background"
        fill={backgroundColor}
      />
      <rect
        x="16"
        y="16"
        width="28"
        height="28"
        rx="6"
        className="topic-circle-foreground"
        fill={foregroundColor}
      />
    </svg>
  );
};

export default AbstractSquare;
