import analytics, { FAMILY_PROJECT_KEY } from 'analytics';
import { Api } from 'api';
import { Button, Loading, toaster } from 'components/common';
import isFunction from 'lodash/isFunction';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TopicChatWithTutorBox from '../TopicChatWithTutorBox';
import { TopicSearch } from './TopicSearch';

const PAGE_SIZE = 10;

export const TopicSearchContainer = ({
  topicPathwayCollectionId,
  subjectFilters = [],
  onSearchTextChange = () => {},
  searchSubjects = [],
  setCurrentSubject = () => {},
  currentSubject = null
}) => {
  const { userId, studentId } = useSelector(
    ({ user: { userId }, school: { studentId } }) => ({
      userId,
      studentId
    })
  );

  const [searchQueryState, setSearchQueryState] = useState({
    searchText: '',
    filteredSubjectIds: [],
    rawSearchText: '',
    isTyping: false,
    totalItems: 0,
    currentPageIndex: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  // Fetch and update search results
  useEffect(() => {
    async function searchTopics() {
      if (searchQueryState.searchText.length < 3 || searchQueryState.isTyping) {
        return;
      }

      const loadMore = searchQueryState.currentPageIndex > 0;

      if (loadMore) {
        setIsLoadingMore(true);
      } else {
        setIsLoading(true);
        analytics.track(
          'Topic Searched',
          {
            searchQuery: searchQueryState.searchText
          },
          { project: FAMILY_PROJECT_KEY },
          userId
        );
      }

      try {
        const results = await Api.searchTopicsAndStatisticsForStudent(
          studentId,
          topicPathwayCollectionId,
          searchQueryState.searchText,
          searchQueryState.filteredSubjectIds,
          PAGE_SIZE * searchQueryState.currentPageIndex,
          PAGE_SIZE
        );

        if (isMounted) {
          setSearchResults(r => r.concat(results.data));
          setSearchQueryState(s => ({ ...s, totalItems: results.total }));
        }
      } catch (error) {
        toaster.danger(
          'There was an error performing your search, please try again.'
        );
      } finally {
        if (isMounted) {
          if (loadMore) {
            setIsLoadingMore(false);
          } else {
            setIsLoading(false);
          }
        }
      }
    }

    let isMounted = true;
    searchTopics();
    return () => (isMounted = false);
  }, [
    searchQueryState.currentPageIndex,
    searchQueryState.filteredSubjectIds,
    searchQueryState.isTyping,
    searchQueryState.searchText,
    studentId,
    topicPathwayCollectionId,
    userId
  ]);

  const onSearchChangeRef = useRef();
  onSearchChangeRef.current = onSearchTextChange;
  useEffect(() => {
    if (isFunction(onSearchChangeRef.current)) {
      onSearchChangeRef.current(searchQueryState.rawSearchText);
    }
  }, [searchQueryState.rawSearchText]);

  const hasMoreResults =
    !isLoading &&
    !searchQueryState.isTyping &&
    searchQueryState.totalItems > searchResults.length;

  return (
    <div>
      <TopicSearch
        onSearchQueryChange={q => {
          setSearchQueryState(s => ({
            ...s,
            ...q,
            totalItems: 0,
            currentPageIndex: 0
          }));
          setSearchResults([]);
        }}
        isLoading={isLoading}
        subjects={subjectFilters}
        searchResults={searchResults}
        searchSubjects={searchSubjects}
        setCurrentSubject={setCurrentSubject}
        currentSubject={currentSubject}
      />

      {hasMoreResults &&
        (isLoadingMore ? (
          <Loading />
        ) : (
          <LoadMoreButton
            onClick={() =>
              setSearchQueryState(s => ({
                ...s,
                currentPageIndex: s.currentPageIndex + 1
              }))
            }>
            Load more results
          </LoadMoreButton>
        ))}

      <TopicChatWithTutorBox
        hidden={
          !(
            searchQueryState.searchText.length >= 3 &&
            !isLoading &&
            !searchQueryState.isTyping
          )
        }
      />
    </div>
  );
};

const LoadMoreButton = styled(Button)`
  width: 100%;
`;
