import { animated, useTransition } from '@react-spring/web';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const AnimationWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 1rem;
  min-width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnimatedDiv = styled(animated.div)`
  will-change: transform, opacity;
`;

export const DropAnimation = ({ value, ...props }) => {
  const previousValue = useRef(value);

  useEffect(() => {
    previousValue.current = value;
  }, [value]);

  const reverseAnimation = value <= previousValue.current;

  const transitions = useTransition(value, {
    from: {
      opacity: 0,
      transform: `translateY(${reverseAnimation ? '100' : '-100'}%)`
    },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: {
      position: 'absolute',
      opacity: 0,
      transform: `translateY(${reverseAnimation ? '-100' : '100'}%)`
    }
  });

  return (
    <AnimationWrapper {...props}>
      {transitions((style, item) => (
        <AnimatedDiv style={style}>{item.toLocaleString()}</AnimatedDiv>
      ))}
    </AnimationWrapper>
  );
};
