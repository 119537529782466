import { Api } from 'api';

export const SetPricingInfoLoadingActionType = 'SET_PRICING_INFO_LOADING';
export const SetPricingInfoLoading = (isLoading = true) => ({
  type: SetPricingInfoLoadingActionType,
  isLoading
});

export const SetPricingInfoActionType = 'SET_PRICING_INFO';
export const SetPricingInfo = pricingInfo => ({
  type: SetPricingInfoActionType,
  pricingInfo
});

export const LoadPricingInfo = () => async (dispatch, getState) => {
  dispatch(SetPricingInfoLoading());
  try {
    const pricingInfo = await Api.getEediPlusPricingInfo();
    await dispatch(SetPricingInfo(pricingInfo));
  } catch (e) {
    console.error('Error retrieving Eedi Plus Pricing Info', e);
  } finally {
    dispatch(SetPricingInfoLoading(false));
  }

  return getState().pricing;
};
