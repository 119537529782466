import mixpanel from 'mixpanel-browser';
import { BUILD_ID, ENVIRONMENT } from 'config/settings';

export class Mixpanel {
  init() {
    if (ENVIRONMENT === 'prod') {
      mixpanel.init('ba91bf7dc6b0184989452e76c5f4e556');
    } else {
      mixpanel.init('2e15f8570fa2e53ba2ae3fed06fe1140');
    }
  }

  identify(user) {
    if (!user?.userId || !user?.userRole) return;

    mixpanel.identify(user.userId.toString());

    const person = {
      // Properties that start with a $ are reserved by Mixpanel
      $first_name: user.firstName,
      $last_name: user.lastName,
      $email: user.email,
      $created: user.dateJoined,
      username: user.userName,
      user_type: user.userRole,
      date_of_birth: user.dateOfBirth,
      school_name: user.schoolName,
      school_id: user.schoolId
    };

    // Setup super properties
    mixpanel.register({
      Platform: 'web',
      Build: BUILD_ID,
      ...person
    });

    // Update user profile
    mixpanel.people.set(person);
  }

  track(event, data) {
    mixpanel.track(event, data);
  }

  set(props) {
    mixpanel.people.set(props);
  }

  shutdown() {
    mixpanel.reset();
  }
}
