import moment from 'moment';

const parseDateToUtc = (dateInput, formatString = 'ddd D/M/Y') => {
  if (!dateInput) {
    return {
      momentUtcDate: null,
      dateString: null
    };
  }
  const momentUtcDate = moment.utc(dateInput);
  return {
    momentUtcDate,
    dateString: momentUtcDate.format(formatString)
  };
};

const convertGMTZero = dateMoment =>
  dateMoment &&
  dateMoment.subtract(dateMoment.parseZone().utcOffset(), 'minutes');

const getUTCDate = date => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();

  return new Date(year, month, day, hour, minute, second);
};

const getWeekStart = (quantity = 1) => {
  let result = [];
  for (let i = 0; i < quantity; i++) {
    result.push(
      moment()
        .add(-1 * i, 'weeks')
        .startOf('isoWeek')
    );
  }
  return result;
};

export { parseDateToUtc, convertGMTZero, getUTCDate, getWeekStart };
