import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useRef } from 'react';
import useLoadUserContext from './useLoadUserContext';

const useSetUserContext = () => {
  const dispatch = useDispatch();
  const userRef = useRef({ loading: false, context: null });
  const { userId, refreshToken, user } = useSelector(s => ({
    refreshToken: s.user.refreshToken,
    userId: s.user.userId,
    user: s.user,
    acceptTermsAndConditions: s.user.acceptTermsAndConditions
  }));
  const loadUserContext = useLoadUserContext();

  useEffect(() => {
    const runEffect = async () => {
      if (
        userRef.current.context ||
        userRef.current.loading ||
        !userId ||
        !refreshToken
      ) {
        return;
      }

      userRef.current.loading = true;
      userRef.current.context = await loadUserContext(true);
      userRef.current.loading = false;
    };
    runEffect();
  }, [dispatch, refreshToken, loadUserContext, user, userId]);

  return {
    context: userRef.current.context,
    loading: userRef.current.loading,
    reload: async (isCached = false) => await loadUserContext(isCached)
  };
};

export default useSetUserContext;
