import { SignalRConnectionContext } from 'providers/SignalRProvider';
import { FlowSignalRConnectionContext } from 'providers/FlowSignalRProvider';

export const getSignalRContext = hubName => {
  switch (hubName) {
    case 'LessonHub':
      return FlowSignalRConnectionContext;
    case 'SignalRHub':
      return SignalRConnectionContext;
    default:
      throw new Error(`${hubName} not mapped`);
  }
};
