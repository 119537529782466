import styled from 'styled-components';

const EmptyState = ({ title, message, ...props }) => (
  <Wrapper {...props}>
    <Content>
      {title && <h2>{title}</h2>}
      {message && <h3 className="subtitle">{message}</h3>}
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  border-radius: 0.25rem;
  background-color: var(--gray-lightest);
`;

const Content = styled.div`
  text-align: center;
  max-width: 24rem;
  img {
    width: 200px;
    height: auto;
    margin-bottom: 2rem;
  }
`;

export default EmptyState;
