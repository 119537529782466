function Coin({ height = 22, id, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 124 124"
      style={{ width: height, height }}
      {...props}>
      <circle cx="61.981" cy="61.582" r="61.496" fill={`url(#${id}_a`}></circle>
      <circle
        cx="61.979"
        cy="61.581"
        r="47.498"
        fill={`url(#${id}_b`}
        transform="rotate(90 61.979 61.58)"></circle>
      <path
        fill={`url(#${id}_c`}
        d="M87.383 5.055a61.755 61.755 0 0128.808 27.949l-82.648 82.648a61.755 61.755 0 01-27.95-28.808l81.79-81.79z"
        opacity="0.3"></path>
      <g fill="#F08D02" filter={`url(#${id}_d`}>
        <path d="M38.113 37.714h8.952v47.741h-8.952V37.714zm47.74 10.444v8.951H58.998v-8.951h26.855zm0 26.852v-8.95H58.998v8.951h26.855z"></path>
      </g>
      <defs>
        <linearGradient
          id={`${id}_a`}
          x1="21.171"
          x2="80.417"
          y1="9.83"
          y2="126.843"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEC706"></stop>
          <stop offset="1" stopColor="#FEAC06"></stop>
        </linearGradient>
        <linearGradient
          id={`${id}_b`}
          x1="30.458"
          x2="76.219"
          y1="21.609"
          y2="111.986"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEC706"></stop>
          <stop offset="1" stopColor="#FEAC06"></stop>
        </linearGradient>
        <linearGradient
          id={`${id}_c`}
          x1="60.892"
          x2="60.892"
          y1="5.055"
          y2="115.652"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <filter
          id={`${id}_d`}
          width="47.738"
          height="47.741"
          x="38.113"
          y="37.714"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow"></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Coin;
