export const getWhatsappLink = (phoneNumber, message) =>
  `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

export const getShortWhatsappLink = (phoneNumber, message) =>
  `https://wa.me/${removePreceedingZeros(
    removeNonDigits(phoneNumber)
  )}?text=${message}`;

const removeNonDigits = str => str.replace(/\D/g, '');

const removePreceedingZeros = str => str.replace(/^0+/, '');

export const openWhatsappLink = (phoneNumber, message) =>
  window.open(getShortWhatsappLink(phoneNumber, message));
