export const SetShopItemsActionType = 'SET_SHOP_ITEMS';
export const setShopItems = shopItems => ({
  type: SetShopItemsActionType,
  shopItems
});

export const PurchaseShopItemsActionType = 'PURCHASE_SHOP_ITEM';
export const purchaseShopItem = shopItem => ({
  type: PurchaseShopItemsActionType,
  shopItem
});

export const EquipShopItemsActionType = 'EQUIP_SHOP_ITEM';
export const equipShopItem = shopItem => ({
  type: EquipShopItemsActionType,
  shopItem
});
